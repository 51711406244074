import React, { useEffect, useRef, useState } from "react";
import api, { defaultBackendUrl } from "services/api";
import styled from "styled-components";
import noImageCar from "assets/defaultImages/no-image.png";
import ImgWithFallback from "components/ImgWithFallback";
import { Product } from "pages/PickupLocationPF/index_new";
import formatValue from "utils/formatValue";
import { FormOutlined, WhatsAppOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";

interface ProposalPDFProps {
    parameters: any;
}

const ProposalPDF: React.FC<ProposalPDFProps> = ({ parameters }) => {
    const [productListData, setProductListData] = useState<any[]>([]);
    const [product, setProduct] = useState<Product>();
    const [colors, setColors] = useState<any>(null);
    const [price, setPrice] = useState<any>();
    const [totalMonthly, setTotalMonthly] = useState<any>();
    const [totalContract, setTotalContract] = useState<any>();
    const [totalVehicles, setTotalVehicles] = useState<any>();
    const [totalModels, setTotalModels] = useState<any>();
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const svgRef = useRef(null);
    const [pngWhatsappUrl, setPngWhatsappUrl] = useState(null);
    const productId = parameters?.product;
    const imageLoadedRef = useRef(false);
    const [showSvg, setShowSvg] = useState(true);

    const svgHeadPjRef = useRef(null);
    const [pngHeadPjUrl, setPngHeadPjUrl] = useState(null);
    const [showSvgHeadPj, setShowSvgHeadPj] = useState(true);
    const [offerImage, setOfferImage] = useState<any>(null);
    const [fetchedLogo, setFetchedLogo] = useState(false);


    
    useEffect(() => {
        if (parameters?.segment) {
            setColors({
                headerBackground: parameters?.segment?.headerBackground || "#000000",
                headerText: parameters?.segment?.headerText || "#000000",
                bodyHeadline: parameters?.segment?.bodyHeadline || "#000000",
                bodySubtitle: parameters?.segment?.bodySubtitle || "#000000",
                bodyLabels: parameters?.segment?.bodyLabels || "#000000",
                bodyText: parameters?.segment?.bodyText || "#000000",
                bodyBackgroudBox: parameters?.segment?.bodyBackgroudBox || "#f6f6f6",
                footerBackground: parameters?.segment?.footerBackground || "#000000",
                footerTitle: parameters?.segment?.footerTitle || "#000000",
                footerText: parameters?.segment?.footerText || "#000000",
            });
            if (pngWhatsappUrl === null) {
              convertWhatsAppSvgToPng();
            }
            if(imageSrc === null && !fetchedLogo){
                fetchImageLogo(parameters?.segment);
            }
        }
    }, [parameters?.segment]);

    const fetchImageLogo = async (segment: any) => {
        try {
            const response = await api.get("/s3files", {
                params: {
                    fileName: `logo${segment?.id}.png`,
                    segmentPath: "template-proposal",
                    type: "image/png",
                    bucket: "Image",
                },
                responseType: "blob",
            });

            const imageUrl = URL.createObjectURL(response.data);
            setImageSrc(imageUrl || noImageCar);
            imageLoadedRef.current = true;
            setFetchedLogo(true);
        } catch (error) {
            setImageSrc(noImageCar);
            imageLoadedRef.current = true;
            setFetchedLogo(true);
            // message.error('Erro ao carregar a imagem.');
            console.error(error);
        }
    };

    const fetchCarImage = async (modelCode: any): Promise<string> => {
        try {
            const response = await api.get("/s3files", {
                params: {
                    fileName: `${modelCode}.png`,
                    segmentPath: "veiculos/canal-indireto",
                    type: "image/png",
                    bucket: "Image",
                },
                responseType: "blob",
            });

            const blob = response.data;
            const reader = new FileReader();
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve(reader.result as string);
                };
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error("Erro ao carregar a imagem do carro:", error);
            return noImageCar;
        }
    };

    useEffect(() => {
        
        const fetchProduct = async (id: string) => {
            try {
                const res = await api.get(`/offers/${id}`);
                const data = res.data;
                setProduct({ ...data, productId: id });
                loadImage(data.modelCode);
            } catch (error) {
                console.error("Erro ao buscar o produto:", error);
            }
        };
        const loadImage = async (modelCode:any) => {
            const imageUrl = await fetchCarImage(String(modelCode));
            setOfferImage(imageUrl);
        };
        if (!product || product.productId !== productId) {
            fetchProduct(productId);
          }
        if(imageSrc === null && parameters?.segment && !fetchedLogo){
          fetchImageLogo(parameters?.segment);
        }

        if(offerImage === null && parameters?.productItem?.modelCode && product?.modelCode){
            // loadImage();
            
        }


    }, [productId, product]);

    const fetchProductById = async (id: string) => {
        try {
            const res = await api.get(`/offers/${id}`);
            
            return { ...res.data, productId: id };

        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        if (parameters?.productsList && parameters.productsList.length > 0) {
    
            const fetchAllProducts = async () => {
                try {
                    // Primeiro faz o fetch de todos os produtos
                    const productsData = await Promise.all(parameters.productsList.map(async (product: any) => {
                        const fetchedProduct = await fetchProductById(product.productId);
                        if (fetchedProduct) {
                            return {
                                ...fetchedProduct,
                                quantity: product?.quantity || 1,
                            };
                        }
                        return null;
                    }));
    
                    let validProducts = productsData.filter((product): product is Product => product !== null);
    
                    // Atualiza o estado com os produtos válidos, sem imagens ainda
                    // setProductListData(validProducts);
    
                    // Cálculos com os produtos
                    const totalMonthlyInstallment = validProducts.reduce((total, product: any) => {
                        return total + (product.monthlyInstallment * product.quantity);
                    }, 0);
    
                    const totalContract = validProducts.reduce((total, product: any) => {
                        return total + (product.deadline * product.monthlyInstallment * product.quantity);
                    }, 0);
    
                    const maxMonthlyInstallment = validProducts.reduce((max, product: any) => {
                        return (product.monthlyInstallment * product.quantity) > max
                            ? (product.monthlyInstallment * product.quantity)
                            : max;
                    }, 0);
    
                    const totalVehicles = validProducts.reduce((total, product: any) => {
                        return total + product.quantity;
                    }, 0);
    
                    const uniqueModelCodes = new Set(validProducts.map((product) => product.modelCode));
                    const uniqueModelCodeCount = uniqueModelCodes.size;
    
                    setPrice(maxMonthlyInstallment);
                    setTotalContract(totalContract);
                    setTotalMonthly(totalMonthlyInstallment);
                    setTotalModels(uniqueModelCodeCount);
                    setTotalVehicles(totalVehicles);
    
                    if (pngHeadPjUrl === null) {
                        convertHeadPjSvgToPng();
                    }
    
                    // Depois de tudo, faz as requisições de imagens e atualiza o estado
                    const productsWithImages = await Promise.all(
                        validProducts.map(async (product) => {
                            const imageUrl = await fetchCarImage(product.modelCode);
                            return { ...product, imageUrl };
                        })
                    );
    
                    // Atualiza o estado com os produtos contendo as imagens

                    setProductListData(productsWithImages);
    
                } catch (error) {
                    console.error("Erro ao buscar todos os produtos:", error);
                }
            };
    
            fetchAllProducts();
        }
    }, [parameters?.productsList]);
    
    
    

    const convertWhatsAppSvgToPng = () => {
        const svgElement: any = svgRef.current;
        const svgString = new XMLSerializer().serializeToString(svgElement);
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // Definir dimensões do SVG
        const svgWidth = svgElement.clientWidth;
        const svgHeight = svgElement.clientHeight;

        // Definir fator de escala para melhorar a qualidade (ex: 2x)
        const scaleFactor = 2;

        // Definir dimensões do canvas com base no fator de escala
        canvas.width = svgWidth * scaleFactor;
        canvas.height = svgHeight * scaleFactor;

        // Aplicar escala no contexto do canvas
        context?.scale(scaleFactor, scaleFactor);

        // Criar uma imagem a partir do SVG
        const img = new Image();
        const svgBase64 = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(svgString)))}`;

        img.onload = () => {
            // Desenhar o SVG no canvas
            context?.clearRect(0, 0, canvas.width, canvas.height);
            
            context?.drawImage(img, 0, 0);

            // Converter o canvas para PNG e salvar no estado
            const pngDataUrl: any = canvas.toDataURL("image/png");
            setPngWhatsappUrl(pngDataUrl); // Salvar a URL PNG no estado

            // Remover o SVG da tela
            setShowSvg(false);
        };

        // Carregar a imagem como SVG base64
        img.src = svgBase64;
    };

    const convertHeadPjSvgToPng = () => {
      const svgElement: any = svgHeadPjRef.current;
    
      // Verifique se o SVG foi corretamente referenciado
      if (!svgElement) {
        console.error('SVG element not found!');
        return;
      }
    
      try {
        const svgString = new XMLSerializer().serializeToString(svgElement);
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
    
        // Definir dimensões do SVG
        const svgWidth = svgElement.clientWidth;
        const svgHeight = svgElement.clientHeight;
    
        // Definir fator de escala para melhorar a qualidade (ex: 2x)
        const scaleFactor = 2;
    
        // Definir dimensões do canvas com base no fator de escala
        canvas.width = svgWidth * scaleFactor;
        canvas.height = svgHeight * scaleFactor;
    
        // Aplicar escala no contexto do canvas
        context?.scale(scaleFactor, scaleFactor);
    
        // Criar uma imagem a partir do SVG
        const img = new Image();
        const svgBase64 = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(svgString)))}`;
    
        img.onload = () => {
          // Desenhar o SVG no canvas
          context?.drawImage(img, 0, 0);
    
          // Converter o canvas para PNG e salvar no estado
          const pngDataUrl: any = canvas.toDataURL("image/png");
          setPngHeadPjUrl(pngDataUrl); // Salvar a URL PNG no estado
    
          // Remover o SVG da tela
          setShowSvgHeadPj(false);
        };
    
        // Carregar a imagem como SVG base64
        img.src = svgBase64;
      } catch (error) {
        console.error('Failed to serialize SVG:', error);
      }
    };
    

    const renderProductSections = (item: any, index: number) => (
        <div className="list-items" key={index}>
            <Section>
                <SectionTitleList bodySubtitle={colors?.bodySubtitle || "#000000"}>{item?.model}</SectionTitleList>
                <SectionTitle bodySubtitle={colors?.bodySubtitle || "#000000"}>Informações Gerais</SectionTitle>
                <GeneralInfo>
                    <CarImage src={item.imageUrl} fallbacks={[`${noImageCar}`]} alt={item.model?.model || "Modelo não disponível"} />
                    <CarDetails>
                        <ModelInfo>
                            <InfoModelLabel bodyHeadline={colors?.bodyHeadline || "#000000"}>Modelo:</InfoModelLabel>
                            <ModelName bodyHeadline={colors?.bodyHeadline || "#000000"}>{item?.model}</ModelName>
                        </ModelInfo>
                        <SpecInfo>
                            <SpecItem backgroundColor={colors?.bodyBackgroudBox}>
                                <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Cor:</InfoLabel>
                                <InfoValue bodyText={colors?.bodyText || "#000000"}>{item.color}</InfoValue>
                            </SpecItem>
                            <SpecItem backgroundColor={colors?.bodyBackgroudBox}>
                                <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Ano:</InfoLabel>
                                <InfoValue bodyText={colors?.bodyText || "#000000"}>{item.modelYear}</InfoValue>
                            </SpecItem>
                        </SpecInfo>
                    </CarDetails>
                </GeneralInfo>
            </Section>

            <Section>
                <SectionTitle bodySubtitle={colors?.bodySubtitle || "#000000"}>Informações de Contrato</SectionTitle>
                <ContractInfo>
                    <ContractItem backgroundColor={colors?.bodyBackgroudBox}>
                        <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Quantidade:</InfoLabel>
                        <InfoValue bodyText={colors?.bodyText || "#000000"}>{item.quantity}</InfoValue>
                    </ContractItem>
                    <ContractItem backgroundColor={colors?.bodyBackgroudBox}>
                        <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Período:</InfoLabel>
                        <InfoValue bodyText={colors?.bodyText || "#000000"}>{item.deadline} meses</InfoValue>
                    </ContractItem>
                    <ContractItem backgroundColor={colors?.bodyBackgroudBox}>
                        <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Franquia mensal:</InfoLabel>
                        <InfoValue bodyText={colors?.bodyText || "#000000"}>{item.monthlyKmValue} Km</InfoValue>
                    </ContractItem>
                    <ContractItem backgroundColor={colors?.bodyBackgroudBox}>
                        <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Total da parcela:</InfoLabel>
                        <InfoValue bodyText={colors?.bodyText || "#000000"}>{formatValue(item.monthlyInstallment * item.quantity)}</InfoValue>
                    </ContractItem>
                    <ContractItem backgroundColor={colors?.bodyBackgroudBox}>
                        <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Total do contrato</InfoLabel>
                        <InfoValue bodyText={colors?.bodyText || "#000000"}>{formatValue(item.deadline * item.monthlyInstallment * item.quantity)}</InfoValue>
                    </ContractItem>
                    <ContractItem backgroundColor={colors?.bodyBackgroudBox}>
                        <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Valor do km excedente:</InfoLabel>
                        <InfoValue bodyText={colors?.bodyText || "#000000"}>{formatValue(item.overrunKm)}</InfoValue>
                    </ContractItem>
                    <ContractItem backgroundColor={colors?.bodyBackgroudBox} className="double">
                        <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Prazo de Entrega:</InfoLabel>
                        <DoubleInfoValue bodyText={colors?.bodyText || "#000000"}>{item.deadlineInfo}</DoubleInfoValue>
                    </ContractItem>
                    {item.accessoryText && (
                        <ContractItem backgroundColor={colors?.bodyBackgroudBox} className="double">
                            <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Acessórios:</InfoLabel>
                            <DoubleInfoValue bodyText={colors?.bodyText || "#000000"}>{item.accessoryText}</DoubleInfoValue>
                        </ContractItem>
                    )}
                    {item.optionalText && (
                        <ContractItem backgroundColor={colors?.bodyBackgroudBox} className="double">
                            <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Opcionais:</InfoLabel>
                            <DoubleInfoValue bodyText={colors?.bodyText || "#000000"}>{item.optionalText}</DoubleInfoValue>
                        </ContractItem>
                    )}
                </ContractInfo>
            </Section>
        </div>
    );

    return (
        <ProposalWrapper>
            <Header backgroundColor={colors?.headerBackground} textColor={colors?.headerText}>
                <LogoContainer>
                    {imageSrc && fetchedLogo && (
                        <Logo loading="lazy" src={imageSrc || ""} alt="Company Logo" />
                    )}
                </LogoContainer>
                <DealerInfo>
                    <InfoItem>
                        <InfoLabelHeader>Concessionária :</InfoLabelHeader>
                        <InfoValue bodyText={"#FFFFFF"}> {parameters?.dealer}</InfoValue>
                    </InfoItem>
                    <InfoItem>
                        <InfoLabelHeader>Vendedor :</InfoLabelHeader>
                        <InfoValue bodyText={"#FFFFFF"}> {parameters?.seller}</InfoValue>
                    </InfoItem>
                    <InfoItem>
                        <InfoLabelHeader>Data/hora :</InfoLabelHeader>
                        <InfoValue bodyText={"#FFFFFF"}> {parameters?.proposalDate}</InfoValue>
                    </InfoItem>
                </DealerInfo>
            </Header>
            <Main>
                <ProposalTitle bodyHeadline={colors?.bodyHeadline || "#000000"}>Proposta Comercial</ProposalTitle>
                {productListData && productListData.length > 0 ? (
                    <>
                        <Section className="head-pj">
                            <SectionTitle bodySubtitle={colors?.bodySubtitle || "#000000"}>Resumo do Pedido</SectionTitle>
                            <TotalCars bodyHeadline={colors?.bodyHeadline || "#000000"}>
                                {showSvgHeadPj && (
                                    <svg ref={svgHeadPjRef} width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4.25 9.75H11.8943L13.3943 8.25H4.25V9.75ZM4.25 5.75H9.75V4.25H4.25V5.75ZM2 2V12H9.64425L8.14425 13.5H0.5V0.5H19.5V3.64425H18V2H2ZM20.2268 7.127C20.3038 7.20383 20.3423 7.28908 20.3423 7.38275C20.3423 7.47625 20.3038 7.5615 20.2268 7.6385L19.3845 8.48075L18.1152 7.2115L18.9578 6.36925C19.0346 6.29225 19.1198 6.25375 19.2135 6.25375C19.307 6.25375 19.3923 6.29225 19.4693 6.36925L20.2268 7.127ZM11.1538 15.4615V14.1923L17.5345 7.8115L18.8038 9.08075L12.423 15.4615H11.1538Z"
                                            fill={colors?.bodyHeadline || "#000000"}
                                        />
                                    </svg>
                                )}
                                {pngHeadPjUrl !== null && <img src={pngHeadPjUrl || ""} alt="Converted Head PJ Icon" width={21} height={16} />}
                                {totalModels} Modelos / {totalVehicles} veículos
                            </TotalCars>
                            <ContractItem className="head-box" backgroundColor={colors?.bodyBackgroudBox}>
                                <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Maior parcela:</InfoLabel>
                                <InfoValue bodyText={colors?.bodyText || "#000000"}>{formatValue(price)}</InfoValue>
                            </ContractItem>
                            <ContractItem className="head-box" backgroundColor={colors?.bodyBackgroudBox}>
                                <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Valor total de contrato:</InfoLabel>
                                <InfoValue bodyText={colors?.bodyText || "#000000"}>{formatValue(totalContract)}</InfoValue>
                            </ContractItem>
                            <ContractItem className="head-box" backgroundColor={colors?.bodyBackgroudBox}>
                                <InfoLabel bodyLabels={colors?.bodyLabels || "#000000"}>Parcela Mensal:</InfoLabel>
                                <InfoValue bodyText={colors?.bodyText || "#000000"}>{formatValue(totalMonthly)}</InfoValue>
                            </ContractItem>
                        </Section>
                        {productListData.map((item, index) => renderProductSections(item, index))}
                    </>
                ) : (
                    <>
                        <Section>
                            <SectionTitle bodySubtitle={colors?.bodySubtitle || "#000000"}>Informações Gerais</SectionTitle>
                            <GeneralInfo>
                                <CarImage src={offerImage} fallbacks={[`${noImageCar}`]} alt={parameters.model?.model || "Modelo não disponível"} />
                                <CarDetails>
                                    <ModelInfo>
                                        <InfoModelLabel bodyHeadline={colors?.bodyHeadline || "#000000"}>Modelo:</InfoModelLabel>
                                        <ModelName bodyHeadline={colors?.bodyHeadline || "#000000"}>{product?.model}</ModelName>
                                    </ModelInfo>
                                    <SpecInfo>
                                        <SpecItem backgroundColor={colors?.bodyBackgroudBox || '#ffffff'}>
                                            <InfoLabel bodyLabels={colors?.bodyLabels || "#FFFFFF"}>Cor:</InfoLabel>
                                            <InfoValue bodyText={colors?.bodyText || "#FFFFFF"}>{product?.color}</InfoValue>
                                        </SpecItem>
                                        <SpecItem backgroundColor={colors?.bodyBackgroudBox || '#ffffff'}>
                                            <InfoLabel bodyLabels={colors?.bodyLabels || "#FFFFFF"}>Ano:</InfoLabel>
                                            <InfoValue bodyText={colors?.bodyText || "#FFFFFF"}>{product?.modelYear}</InfoValue>
                                        </SpecItem>
                                    </SpecInfo>
                                </CarDetails>
                            </GeneralInfo>
                        </Section>

                        <Section>
                            <SectionTitle bodySubtitle={colors?.bodySubtitle || "#000000"}>Informações de Contrato</SectionTitle>
                            <ContractInfo>
                                <ContractItem backgroundColor={colors?.bodyBackgroudBox || '#ffffff'}>
                                    <InfoLabel bodyLabels={colors?.bodyLabels || "#ffffff"}>Período:</InfoLabel>
                                    <InfoValue bodyText={colors?.bodyText || "#ffffff"}>{product?.deadline} meses</InfoValue>
                                </ContractItem>
                                <ContractItem backgroundColor={colors?.bodyBackgroudBox || '#ffffff'}>
                                    <InfoLabel bodyLabels={colors?.bodyLabels || "#ffffff"}>Franquia mensal:</InfoLabel>
                                    <InfoValue bodyText={colors?.bodyText || "#ffffff"}>{parameters?.monthKmValue} Km</InfoValue>
                                </ContractItem>
                                <ContractItem backgroundColor={colors?.bodyBackgroudBox || '#ffffff'}>
                                    <InfoLabel bodyLabels={colors?.bodyLabels || "#ffffff"}>Total da parcela:</InfoLabel>
                                    <InfoValue bodyText={colors?.bodyText || "#ffffff"}>{formatValue(product?.monthlyInstallment!)}</InfoValue>
                                </ContractItem>
                                <ContractItem backgroundColor={colors?.bodyBackgroudBox || '#ffffff'}>
                                    <InfoLabel bodyLabels={colors?.bodyLabels || "#ffffff"}>Total do contrato</InfoLabel>
                                    <InfoValue bodyText={colors?.bodyText || "#ffffff"}>{parameters?.contractValue}</InfoValue>
                                </ContractItem>
                                <ContractItem backgroundColor={colors?.bodyBackgroudBox || '#ffffff'}>
                                    <InfoLabel bodyLabels={colors?.bodyLabels || "#ffffff"}>Valor do km excedente:</InfoLabel>
                                    <InfoValue bodyText={colors?.bodyText || "#ffffff"}>{parameters?.overrunKM}</InfoValue>
                                </ContractItem>
                                <ContractItem backgroundColor={colors?.bodyBackgroudBox || '#ffffff'} className="double">
                                    <InfoLabel bodyLabels={colors?.bodyLabels || "#ffffff"}>Prazo de Entrega:</InfoLabel>
                                    <DoubleInfoValue bodyText={colors?.bodyText || "#ffffff"}>{product?.deadlineInfo}</DoubleInfoValue>
                                </ContractItem>
                                {product?.accessoryText && (
                                    <ContractItem backgroundColor={colors?.bodyBackgroudBox || '#ffffff'} className="double">
                                        <InfoLabel bodyLabels={colors?.bodyLabels || "#ffffff"}>Acessórios:</InfoLabel>
                                        <DoubleInfoValue bodyText={colors?.bodyText || "#ffffff"}>{product.accessoryText}</DoubleInfoValue>
                                    </ContractItem>
                                )}

                                {product?.optionalText && (
                                    <ContractItem backgroundColor={colors?.bodyBackgroudBox || '#ffffff'} className="double">
                                        <InfoLabel bodyLabels={colors?.bodyLabels || "#ffffff"}>Opcionais:</InfoLabel>
                                        <DoubleInfoValue bodyText={colors?.bodyText || "#ffffff"}>{product.optionalText}</DoubleInfoValue>
                                    </ContractItem>
                                )}
                            </ContractInfo>
                        </Section>
                    </>
                )}
                <Section>
                    <SectionTitle bodySubtitle={colors?.bodySubtitle || "#000000"}>Resumo do Pedido</SectionTitle>
                    <OrderSummary color={colors?.bodyText || '#FFFFFF'} titleColor={colors?.bodyHeadline || '#FFFFFF'}>
                        <p  dangerouslySetInnerHTML={{ __html: parameters?.segmentSummary }} />
                    </OrderSummary>
                </Section>
            </Main>
            <Footer footerBackground={colors?.footerBackground || "#000000"} footerBorder={colors?.headerBackground}>
                <FooterTitle footerTitle={colors?.footerTitle || "#FFFFFF"}>Canais de atendimento:</FooterTitle>
                <ContactInfo>
                    {showSvg && (
                        <svg ref={svgRef} width="17" height="16" viewBox="0 0 17 16" fill={parameters?.segment?.footerTitle} xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.538002 16L1.66267 11.8913C0.968669 10.6887 0.604002 9.32533 0.604669 7.92733C0.606669 3.55667 4.16334 0 8.53333 0C10.654 0.000666667 12.6447 0.826667 14.142 2.32533C15.6387 3.824 16.4627 5.816 16.462 7.93467C16.46 12.306 12.9033 15.8627 8.53333 15.8627C7.20667 15.862 5.89933 15.5293 4.74133 14.8973L0.538002 16ZM4.936 13.462C6.05333 14.1253 7.12 14.5227 8.53067 14.5233C12.1627 14.5233 15.1213 11.5673 15.1233 7.93333C15.1247 4.292 12.18 1.34 8.536 1.33867C4.90133 1.33867 1.94467 4.29467 1.94334 7.928C1.94267 9.41133 2.37734 10.522 3.10734 11.684L2.44134 14.116L4.936 13.462ZM12.5273 9.81933C12.478 9.73667 12.346 9.68733 12.1473 9.588C11.9493 9.48867 10.9753 9.00933 10.7933 8.94333C10.612 8.87733 10.48 8.844 10.3473 9.04267C10.2153 9.24067 9.83533 9.68733 9.72 9.81933C9.60467 9.95133 9.48867 9.968 9.29067 9.86867C9.09267 9.76933 8.454 9.56067 7.69733 8.88533C7.10867 8.36 6.71067 7.71133 6.59533 7.51267C6.48 7.31467 6.58333 7.20733 6.682 7.10867C6.77133 7.02 6.88 6.87733 6.97933 6.76133C7.08 6.64667 7.11267 6.564 7.17934 6.43133C7.24534 6.29933 7.21267 6.18333 7.16267 6.084C7.11267 5.98533 6.71667 5.01 6.552 4.61333C6.39067 4.22733 6.22733 4.27933 6.106 4.27333L5.726 4.26667C5.594 4.26667 5.37933 4.316 5.198 4.51467C5.01667 4.71333 4.50467 5.192 4.50467 6.16733C4.50467 7.14267 5.21467 8.08467 5.31333 8.21667C5.41267 8.34867 6.71 10.35 8.69733 11.208C9.17 11.412 9.53933 11.534 9.82667 11.6253C10.3013 11.776 10.7333 11.7547 11.0747 11.704C11.4553 11.6473 12.2467 11.2247 12.412 10.762C12.5773 10.2987 12.5773 9.902 12.5273 9.81933Z"
                                fill={parameters?.segment?.footerTitle}
                            />
                        </svg>
                    )}
                    {pngWhatsappUrl !== null && <img src={pngWhatsappUrl || ""} alt="Telefone" width={17} height={16} />}
                    <PhoneNumber footerTitle={colors?.footerTitle || "#FFFFFF"}>0800-071-8090</PhoneNumber>
                </ContactInfo>
                <FooterText>De segunda a sexta-feira das 7h às 18h para atendimento de serviços, dúvidas contratuais, faturas e outros assuntos. Atendimento 24h para assistência, furtos ou roubos.</FooterText>
            </Footer>
        </ProposalWrapper>
    );
};

const ProposalWrapper = styled.div`
    background-color: #fff;
    display: flex;
    max-width: 800px;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-bottom: 140px;
    }
    .list-items {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 32px;
        margin-bottom: 24px;
    }
`;

const Header = styled.header`
    border-bottom: 1px solid #152d49;
    background-color: ${(props: { backgroundColor: string; textColor: string }) => props.backgroundColor};
    border-bottom: 1px solid ${(props: { backgroundColor: string; textColor: string }) => props.backgroundColor};
    color: ${(props: { backgroundColor: string; textColor: string }) => props.textColor};
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 16px 32px;
    font-size: 12px;
    @media (max-width: 991px) {
        max-width: 100%;
        padding: 0 20px;
    }
`;

const LogoContainer = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 135px;
    margin: auto 0;
`;

const Logo = styled.img`
    aspect-ratio: 3.32;
    object-fit: contain;
    object-position: center;
    width: 166px;
    max-width: 100%;
`;

const DealerInfo = styled.div`
    align-self: stretch;
    display: flex;
    min-width: 240px;
    flex-direction: column;
    color: #fff;
    justify-content: flex-start;
    flex: 1;
    flex-basis: 0%;
    margin: auto 0;
    font: 12px/1;
`;

const InfoItem = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 4px;
    justify-content: flex-end;
    &:not(:first-child) {
        margin-top: 4px;
    }
`;

const InfoLabel = styled.span`
    color: ${(props: { bodyLabels: string }) => props.bodyLabels};
    font-weight: 700;
    align-self: stretch;
    margin: auto 0;
`;

const InfoLabelHeader = styled.span`
    font-weight: 700;
    align-self: stretch;
    margin: auto 0;
`;

const InfoModelLabel = styled.span`
    font-weight: 700;
    align-self: stretch;
    margin: auto 0;
    font-size: 12px;
    color: ${(props: { bodyHeadline: string }) => props.bodyHeadline};
`;

const InfoValue = styled.span`
    color: ${(props: { bodyText: string }) => props.bodyText};
    text-align: right;
    font-weight: 400;
    align-self: stretch;
    margin: auto 0;
`;

const DoubleInfoValue = styled.span`
    color: ${(props: { bodyText: string }) => props.bodyText};
    text-align: left;
    font-weight: 400;
    align-self: stretch;
    margin: auto 0;
`;

const Main = styled.main`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 32px;
    font-family: Arial !important;
    @media (max-width: 991px) {
        max-width: 100%;
        padding: 0 20px;
    }
`;

const TotalCars = styled.div`
    color: ${(props: { bodyHeadline: string }) => props.bodyHeadline};
    font-size: 16px;
    font-weight: 700;
    margin: 14px 0;
    display: flex;
    align-items: center;
    gap: 5px;
`;

const ProposalTitle = styled.h1`
    color: ${(props: { bodyHeadline: string }) => props.bodyHeadline};
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
    font-family: Arial !important;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const Section = styled.section`
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
    @media (max-width: 991px) {
        max-width: 100%;
    }
    &.head-pj {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 32px;
        margin-bottom: 24px;
    }

    .head-box {
        font-size: 12px;
    }
`;

const SectionTitle = styled.h2`
    width: 100%;
    font-size: 14px;
    color: ${(props: { bodySubtitle: string }) => props.bodySubtitle};
    font-weight: 700;
    line-height: 1;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const SectionTitleList = styled.h2`
    width: 100%;
    font-size: 18px;
    color: ${(props: { bodySubtitle: string }) => props.bodySubtitle};
    font-weight: 700;
    margin-bottom: 32px;
    line-height: 1;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const GeneralInfo = styled.div`
    display: flex;
    margin-top: 16px;
    width: 100%;
    gap: 8px;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const CarImage = styled(ImgWithFallback)`

    object-fit: contain;
    object-position: center;
    width: 216px;
    align-self: flex-start;
`;

const CarDetails = styled.div`
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    flex-basis: 0%;
`;

const ModelInfo = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    color: #152d49;
    font-weight: 700;
    justify-content: flex-start;
`;

const ModelName = styled.div`
    font-size: 16px;
    line-height: 16px;
    margin-top: 4px;
    color: ${(props: { bodyHeadline: string }) => props.bodyHeadline};
`;

const SpecInfo = styled.div`
    display: flex;
    margin-top: 16px;
    width: 100%;
    flex-direction: column;
    font-size: 12px;
    line-height: 1;
    justify-content: center;
`;

const SpecItem = styled.div`
    background-color: ${(props: { backgroundColor: string }) => props.backgroundColor};
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 10px;
    justify-content: flex-start;
    padding: 4px 8px;
    &:not(:first-child) {
        margin-top: 4px;
    }
`;

const ContractInfo = styled.div`
    display: flex;
    margin: 20px 0;
    width: 100%;
    flex-direction: column;
    font-size: 12px;
    justify-content: flex-start;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const ContractItem = styled.div`
    background-color: ${(props: { backgroundColor: string }) => props.backgroundColor};
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 6px 8px;
    @media (max-width: 991px) {
        max-width: 100%;
    }
    &:not(:first-child) {
        margin-top: 4px;
    }
    &.double {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 0px;
    }
`;

const OrderSummary = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 12px;
    justify-content: flex-start;
    @media (max-width: 991px) {
        max-width: 100%;
    }
    p{
      color: ${(props: { color: string, titleColor: string  }) => props.color};
      font-size: 12px;
      font-weight: 400;
    }
    h2{
      color: ${(props: { color: string, titleColor: string }) => props.titleColor};
      font-size:12px;
      font-weight: 700;
      margin: 16px 0;
    }
`;

const SummaryItem = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    &:not(:first-child) {
        margin-top: 20px;
    }
`;

const SummaryTitle = styled.h3`
    color: #152d49;
    font-weight: 700;
    line-height: 1;
`;

const SummaryContent = styled.p`
    color: #738f99;
    font-weight: 400;
    line-height: 12px;
    margin-top: 16px;
`;

const Footer = styled.footer`
    background-color: ${(props: { footerBackground: string; footerBorder: string }) => props.footerBackground};
    border-bottom: 1px solid ${(props: { footerBackground: string; footerBorder: string }) => props.footerBorder};
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    color: #152d49;
    font-weight: 700;
    text-align: center;
    line-height: 1;
    justify-content: center;
    padding: 32px 64px;
    @media (max-width: 991px) {
        max-width: 100%;
        padding: 0 20px;
    }
`;

const FooterTitle = styled.h2`
    color: ${(props: { footerTitle: string }) => props.footerTitle};
    font-size: 14px;
    font-weight: 700;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const ContactInfo = styled.div`
    align-self: stretch;
    display: flex;
    margin-top: 8px;
    width: 100%;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    white-space: nowrap;
    justify-content: center;
    @media (max-width: 991px) {
        max-width: 100%;
        white-space: initial;
    }
`;

const PhoneIcon = styled.img`
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 16px;
    align-self: stretch;
    margin: auto 0;
`;

const PhoneNumber = styled.span`
    color: ${(props: { footerTitle: string }) => props.footerTitle};
    align-self: stretch;
    margin: auto 0;
`;

const FooterText = styled.p`
    color: #738f99;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    margin-top: 8px;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

export default ProposalPDF;
