/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useCallback, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'

import api from '../../services/api'
import { useToast } from '../../hooks/toast'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { Container, WrapperTable, Table, Content, Status } from './styles'
import Button from '../../components/Button'
import iconEdit from '../../assets/icons/edit-2.svg'
import iconTrash from '../../assets/icons/trash-2.svg'
import { useAuth } from '../../hooks/auth'

interface DealershipGroupProps {
  name: string
  id: number
  status: boolean
}

const DealershipsGroup: FC = () => {
  const history = useHistory()
  const { addToast } = useToast()
  const { role, userId } = useAuth()

  const [dealershipsGroups, setDealershipsGroups] = useState<
    DealershipGroupProps[]
  >([])

  useEffect(() => {
    if (!userId || !(role === 'Super Admin' || role === 'Root')) {
      history.push('/')
    }
    getLogisticsByCustomer()
  }, [])

  const getLogisticsByCustomer = useCallback(async () => {
    await api.get(`/dealerships/dealershipsGroup`).then((response: any) => {
      setDealershipsGroups(response.data)
    })
  }, [])

  const handleRemove = (id: number) => {
    api.delete(`/dealerships/dealershipsGroup/${id}`).then(() => {
      addToast({
        title: 'Grupo Removido!',
        type: 'success',
        description: 'O grupo foi removido com sucesso.'
      })

      setDealershipsGroups(dealershipsGroups.filter((item) => item.id !== id))
    })
  }

  return (
    <Container>
      <Header />
      <Content>
        <h1 data-testid="title_page">GRUPO DE CONCESSIONÁRIAS</h1>
        <p data-testid="description">
          Visualize, edite e crie perfis de controle de acesso
        </p>
        <div className="wrapper-button">
          <Link to="/new-group-dealership">
            <Button data-testid="btn_new_group">NOVO GRUPO</Button>
          </Link>
        </div>
        <WrapperTable>
          <Table data-testid="table">
            <thead>
              <tr>
                <th className="th-left">Nome</th>
                <th className="th-center">Status</th>
                <th className="th-right">Ação</th>
              </tr>
            </thead>
            <tbody>
              {dealershipsGroups.length ? (
                dealershipsGroups.map((item: any, i: number) => {
                  return (
                    <tr key={i}>
                      <td>{item.name}</td>
                      <td style={{ textAlign: 'center' }}>
                        <div className="wrapper-status">
                          <Status status={item.status} />
                          {item.status ? 'Ativo' : 'Inativo'}
                        </div>
                      </td>
                      <td className="actions-button">
                        <Link to={`/edit-dealership-group/${item.id}`}>
                          <button>
                            <img src={iconEdit} alt="Editar" />
                          </button>
                        </Link>
                        <button onClick={() => handleRemove(item.id)}>
                          <img src={iconTrash} alt="Deletar" />
                        </button>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={7}>Nenhum registro encontrado!</td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="pagination" style={{ display: 'none' }}>
            <label>Mostrando 4 de 4 registros</label>
            <div>
              <button className="btn-previous">Anterior</button>
              <button className="btn-page">1</button>
              <button className="btn-next">Próximo</button>
            </div>
          </div>
        </WrapperTable>
      </Content>
      <Footer />
    </Container>
  )
}

export default DealershipsGroup
