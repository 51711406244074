import styled from 'styled-components'

export const WrapperLoading = styled.div`
  border: 8px solid #fff;
  border-left-color: #236fbc;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  span {
    color: #000;
    font-size: 14px;
  }
`
