/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Modal from 'antd/lib/modal/Modal'
import api from '../../../../services/api'

interface INotes {
  onOpenModalNotes: () => void
  showModalNotes: boolean
  orderId: number
}

const Notes = ({ orderId, onOpenModalNotes, showModalNotes }: INotes) => {
  const [notes, setNotes] = useState('')

  useEffect(() => {
    api
      .get(`/orders/GetObservation?orderId=${orderId}`)
      .then((res) => setNotes(res.data))
  }, [showModalNotes])

  return (
    <Modal
      title="Observações"
      footer={null}
      onCancel={onOpenModalNotes}
      visible={showModalNotes}
    >
      <div>
        <p>{notes}</p>
      </div>
    </Modal>
  )
}

export default Notes
