import ImgWithFallback from 'components/ImgWithFallback'
import styled from 'styled-components'

interface Props {
  windowWidth?: number | undefined
}

export const Container = styled.div<Props>`
  max-width: ${(props) => props.windowWidth};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;


  &.printable{
    padding-left:15px;
    width: 100%;
    max-width: none;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    background:#fff;
    display:none;
    *{
      pageBreakInside: avoid;
    }
    img{
      width: 450px;
    }
    .infos-group{
      margin-bottom:30px;
      .title{
        font-size: 22px;
        font-weight: bold;
        border-bottom:1px solid;
        margin-bottom:5px;
        padding-bottom: 5px;
        color:#000;
        text-align:left;
      }
      .items{
        .item{
          display: flex;
          gap: 9px;
          font-size: 18px;
          span{
            font-weight: bold;
          }
        }
      }
      &.resume{
        margin-bottom: 25px;
        padding-bottom:25px;
        border-bottom:1px solid;
        .items{
          .item{
            display:block;
            pageBreakInside: avoid;
            ul{
              list-style:none;
            }
          }
        }
      }
    }
    @media print {
      display:flex;
    }
  }

  @media (min-width: 1100px) {
    max-width: 1100px;
  }
  h1 {
    color: #0c5faf;
    font-size: 25px;
    font-weight: bold;
    text-align: right;
    letter-spacing: 0px;
    line-height: 55px;
    opacity: 1;

    @media (min-width: 768px) {
      font-size: 40px;
    }
  }

  .message-user-renew {
    color: red;
    font-size: 14px;
    margin-top: 12px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  .ant-modal-content {
    border-radius: 10px;
  }
`

export const ContentObs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 40px auto;
  align-items: center;
  font-family: 'Open Sans';
  padding-bottom: 16px;
  border: 2px solid #236fbc;
  border-radius: 16px;
  background: #ffffff;
  padding: 25px;
  color: #000000;

  h2 {
    text-align: center;
    width: -webkit-fill-available;
    font-size: 16px;
    font-weight: bold;
    color: #005faf;
    margin-bottom: 5px;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  flex-direction: column;
  border: 2px solid #236fbc;
  padding: 35px;
  border-radius: 16px;
  width: 100%;

  h2 {
    font-size: 13pt;
    margin: 35px 0 15px 0;
  }

  h3 {
    font-size: 11pt;
    margin: 35px 0 15px 0;
  }

  p,
  li {
    color: #000000;
  }

  .wrapper-print {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    .print {
      display: flex;

      svg {
        margin-right: 6px;
      }
    }

    .btn-print {
      border: transparent;
      background: transparent;
      color: #236fbc;
    }
  }
`

export const WrapperLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column !important;

  @media (min-width: 768px) {
    flex-direction: row !important;
  }
`

export const ContentLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column !important;
`

export const ImageCar = styled(ImgWithFallback)`
  height: auto;
  margin-bottom: 25px;
  width: 200px;
`

export const InfoContract = styled.div`
  margin-right: 13px;
`

export const TitleDescription = styled.p`
  margin: 0 !important;
  color: #3c505a;
  letter-spacing: 0px;
  line-height: 21px;
  max-width: 752px;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  max-width: 750px;
  margin: 0;
`

export const Description = styled.p`
  color: #3c505a;
  margin: 0;
`

export const LineVertical = styled.div`
  height: 40px;
  border-right: 1px solid #6c6c6c;
`

export const LineCupomDiscount = styled.div`
  color: #005faf;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 2px;
  padding-right: 15px;
  margin-top: 5px;
  font-size: 12px;
  border: 1px solid #005faf;
`

export const InfoValueMonth = styled.div`
  color: #005faf;
  font-size: x-large;
  font-weight: bold;
`

export const LineDescriptionDiscount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const Wrapper = styled.div`
  border-bottom: 1px solid #3b3b3b;
  padding-bottom: 15px;
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > h2 {
    align-self: flex-start;
    width: 200px;
    font-size: 14px;
    font-weight: bold;
  }

  p {
    width: 100%;
    font-size: 14px;
    text-align: left;
    max-width: unset;
  }

  ul {
    li {
      margin: 0 0 0 17px;
      font-size: 14px;
    }
  }
`

export const WrapperClient = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid #3b3b3b;
  padding-bottom: 15px;

  @media (min-width: 768px) {
    height: 165px;
  }
  & > h2 {
    align-self: flex-start;
    width: 170px;
    font-size: 14px;
    font-weight: bold;
  }

  & > div {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;

      p {
        width: unset;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
`

export const WrapperInfo = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
`

export const WrapperInfoCheckbox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const WrapperAction = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;

  div {
    width: 190px;
  }

  button {
    width: 190px;
    height: 41px;
    font-size: 12px;
    text-transform: uppercase;
    margin: 8px;
  }

  .btn-back {
    background: transparent;
    border: 2px solid #226fbb;
    color: #226fbb;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }

  a {
    margin: 5px;
  }

  .ant-checkbox-wrapper {
    display: flex;
  }
`

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`
export const WrapperInfoRadioButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;

  .ant-radio-wrapper {
    padding-right: 90px;
    margin-right: 0px;
    color: #005faf;
    font-size: 14px;
  }
`

export const Modal = styled.div`
  width: 300px;
  height: 300px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;

  @media (min-width: 425px) {
    width: 400px;
  }

  h2 {
    font-size: 24px;
    color: #0c5faf;
    font-weight: 600;
    text-align: center;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-child(2) {
      justify-content: flex-start;
      flex-direction: column;
      width: 80%;

      p {
        font-size: 14px;
        text-align: left;

        &:first-child {
          font-weight: 700;
          margin-bottom: 15px;
        }
      }
    }

    &:last-child {
      width: 90%;
      margin-top: 30px;

      button {
        width: 48%;
        border: none;
        color: white;
        border-radius: 24px;
        border: 2px solid #236fbc;
        font-size: 14px;
        height: 38px;

        &:first-child {
          background-color: white;
          color: #236fbc;
        }

        &:last-child {
          background-color: #236fbc;
        }
      }
    }
  }
`

export const Notes = styled.textarea`
  border: none;
  width: 100%;
`
