import React from 'react'

import formatValue from 'utils/formatValue'
import { Container } from './styles'

type PlanDescriptionOfferProps = {
  model: string
  totalValue: number
  deadline: number
  monthKmValue: number
  deliveryDeadline: string
}

const PlanDescriptionOffer = ({
  model,
  totalValue,
  deadline,
  monthKmValue,
  deliveryDeadline
}: PlanDescriptionOfferProps) => (
  <Container>
    <div className="description">
      <div className="line">
        <h2>Resumo</h2>
      </div>
      <div className="line">
        <p>{model}</p>
        <p>Aluguel mensal {formatValue(totalValue)}</p>
      </div>
      <div className="line">
        <p>Período</p>
        <p>{deadline} meses</p>
      </div>
      <div className="line">
        <p>Km / Mês</p>
        <p>{monthKmValue} Km/mês</p>
      </div>
      <div className="line">
        <p>
          Serviços inclusos: Documentação, proteção/seguro, manutenção
          preventiva, assistência 24 horas, gestão de multas e rastreador.
        </p>
        <p>Sem custos adicionais</p>
      </div>
      <div className="line">
        <p>Prazo de entrega após assinatura de contrato:</p>
        <p>{deliveryDeadline}</p>
      </div>
      <div className="line">
        <p>VW S&amp;D e VW Truck Rental</p>
        <p>proteção contra danos a terceiros e ao veículo.</p>
      </div>
      <div className="line">
        <p>Audi Signature</p>
        <p>seguro do veículo.</p>
      </div>
    </div>
    <hr />
    <div className="line-totalizer">
      <h3>
        Aluguel mensal <span>{formatValue(totalValue)}</span>
      </h3>
      <span>
        Possui um cupom promocional? Você poderá inserir no final do cadastro.
      </span>
    </div>
  </Container>
)

export default PlanDescriptionOffer
