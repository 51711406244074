import styled from 'styled-components'

export const Container = styled.div`
  flex-direction: column;
  margin: 0 auto;
  display: flex;
  background: #f1f1f1;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  .ant-tabs .ant-tabs-top .ant-tabs-mobile .tabs,
  .ant-tabs-nav,
  .ant-tabs-nav-list {
    width: 100%;
  }

  .tabs {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    &.local-retirada{
      .ant-tabs-content-holder{
        width:100%

      }
    }
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    span svg {
      margin-right: 25px;
    }

    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 100%;
      margin: 0;
      opacity: 1;
      color: #070707;
      font-size: 15px;
      font-weight: 500;
    }

    .ant-tabs-nav {
      margin-bottom: 50px;
    }

    .ant-tabs-tab-active {
      div {
        color: #0c5fb1;
        border-bottom: 0;
      }
    }
  }
`
