import styled from 'styled-components'

export const Container = styled.div`
  border: 3px solid #236fbc;
  width: 90%;
  margin: 38px auto;
  border-radius: 35px;
  max-width: 1160px;
  display: flex;
  flex-direction: column;
  align-items: center;

  hr {
    border: 1px solid #707070;
    width: 90%;
    margin: 40px 0 40px;
  }

  .description {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .optional {
      margin: 30px 0px !important;

      .wrapper-optional {
        p {
          width: auto !important;
          text-align: end;
        }
      }
    }
  }

  .description {
    color: #070707;
    margin-top: -20px;
    font-size: 22px;

    .line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 90%;
      align-items: center;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        height: 65px;
        margin-bottom: 0;
      }

      @media (max-width: 768px) {
        p:nth-child(2) {
          text-align: right;
        }
      }

      &:first-child {
        margin: 55px 0;
      }

      h2 {
        color: #236fbc;
        font-size: 25px;
        font-weight: 600;
      }

      p {
        color: #070707;
        font-size: 14px;
        margin: 0;
        width: 50%;
        word-break: break-word;

        @media (min-width: 768px) {
          font-size: 18px;
          width: 50%;
        }
      }

      p:last-child {
        text-align: right;
      }
    }
  }

  .line-totalizer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 90%;
    height: 50px;
    align-items: center;
    margin-bottom: 45px;

    span {
      align-self: flex-end;
    }

    h3 {
      align-self: flex-end;
      font-size: 18px;
      span {
        font-size: 24px;
        font-weight: bold;
        color: #236fbc;
      }
    }
  }
`
