import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table, Pagination, Input, Button, Modal, Select } from "antd";
import api from "services/api";
import { useAuth } from "hooks/auth";
import { ColumnType } from "antd/lib/table";
import { Container, Content, Status, ContentModal } from "./styles";
import Footer from "components/Footer";
import Header from "components/Header";
import iconTrash from "../../../assets/icons/trash-2.svg";
import iconEdit from "../../../assets/icons/edit-2.svg";
import rolesDePara from "../../../utils/rolesdePara";
import { useToast } from "hooks/toast";
const { Option } = Select;

type RoleProps = {
    name: string;
    roleId: number;
};

type DealershipProps = {
    id: number;
    name: string;
};

const { Search } = Input;

const DealersList = () => {
    const { userId, role, dealershipGroupId: userDealershipGroupId } = useAuth()
    const history = useHistory()
    const [nameFilter, setNameFilter] = useState<any>(null);
    const [numberFilter, setNumberFilter] = useState<any>(null);
    const [CNPJFilter, setCNPJFilter] = useState<any>(null);
    const [openModal, setOpenModal] = useState(false);
    const [cleanedFilters, setCleanedFilters] = useState(false);
    const [dealershipId, setDealershipId] = useState(0)
    const [dealership, setDealership] = useState<DealershipProps>()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const { addToast } = useToast();

    const fetchData = async (page = 1, pageSize = 10) => {
        let filter = "";


        setLoading(true);

        if(nameFilter){
            filter = filter + '&name=' + nameFilter;
        }

        if(numberFilter){
            filter = filter + '&number=' + numberFilter;
        }

        if(CNPJFilter){
            filter = filter + '&cnpj=' + CNPJFilter.replace(/[^\w\s]/gi, '');
        }

        if(nameFilter || numberFilter || numberFilter){
            setPagination({
                ...pagination,
                current: 1,
            });
        }


        let filterDealer = ''

        if (role !== 'Super Admin' && role !== 'Root') {
          filter = `&DealershipGroupID=${userDealershipGroupId}`
        }

        // Fazer a chamada para a API
        const response = await api.get(`dealerships/GetWithFilters?page=true&currentPage=${page}&quantityPerPage=${pageSize}${filter}${filterDealer}`);

        // Extrair os dados e informações de paginação do response
        const { itens, totalAmount } = response.data;

        setData(itens);
        setPagination({
            ...pagination,
            current: page,
            pageSize,
            total: totalAmount,
        });

        setLoading(false);
    };

    useEffect(() => {
        if (
        !userId ||
        !(role === 'Super Admin' || role === 'Admin do Grupo' || role === 'Root')
        ) {
        history.push('/my-orders')
        }
        fetchData();
    }, []);

    useEffect(() => {
        if(cleanedFilters){
            fetchData();
            setCleanedFilters(false)
        }
    }, [cleanedFilters]);

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        const { current, pageSize } = pagination;
        fetchData(current, pageSize);
    };

    const handleRemove = (id: number) => {
        setOpenModal(false)
        api.delete(`/dealerships/${id}`).then(() => {
          addToast({
            title: 'Concessionária Removida!',
            type: 'success',
            description: 'A concessionária foi removida com sucesso.'
          })
    
          fetchData();
        })
      }

    const modalOk = () => {
        handleRemove(dealershipId)
      }
    
      const modalClose = () => {
        setOpenModal(false)
      }

    const setFilterName = (name: string) => {
        setNameFilter(name ? name : null)
    };

    const setFilterNumber = (number: string) => {
        setNumberFilter(number ? number : null)
    };

    const setFilterCNPJ = (cnpj: string) => {
        setCNPJFilter(cnpj ? cnpj : null)
    };

    const handleRemoveDealership = (item: DealershipProps) => {
        setDealershipId(item.id)
        setOpenModal(true)
        setDealership(item)
    }

    const cleanFilters = () => {
        setNameFilter(null)
        setNumberFilter(null)
        setCNPJFilter(null)
        setCleanedFilters(true)
    }


    const columns: ColumnType<any>[] = [
        {
            title: "Nome",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Número",
            dataIndex: "number",
            key: "number"
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text: any, record: { status: boolean }) => <>{record.status ? 'Ativo' : 'Inativo'}</>,
        },
        {
            title: "Ação",
            dataIndex: "acao",
            key: "acao",
            render: (text: any, record) => (
                <div className="actions-button">
                  <Link to={`/edit-dealership/${record.id}`}>
                    <button key={`edit-button-${record.id}`}>
                      <img src={iconEdit} alt="Editar" />
                    </button>
                  </Link>

                </div>
              ),
        },
    ];

    return (
        <Container>
            <Header />
            <Content>
                <h1 data-testid="title_page">CONCESSIONÁRIAS</h1>
                <p data-testid="description">Visualize, edite e crie concessionárias</p>
                <div className="filters">
                    <div className="filter-item">
                        <Input key="name-default" value={nameFilter} onChange={(item) => setFilterName(item.target.value)} className="input" placeholder="Nome" id="name" name="name" type="text" />
                    </div>
                    <div className="filter-item">
                        <Input key="number-default" value={numberFilter} onChange={(item) => setFilterNumber(item.target.value)} className="input" placeholder="Número" id="number" name="number" type="text" />
                    </div>
                    <div className="filter-item">
                        <Input key="doc-default" value={CNPJFilter} onChange={(item) => setFilterCNPJ(item.target.value)} className="input" placeholder="CNPJ" id="doc" name="doc" type="text" />
                    </div>
                    <div className="filter-item">
                        <Button className="clean-filters" key="clean-default" type="link" onClick={cleanFilters}>Limpar filtros</Button>
                    </div>
                    <div className="filter-item">
                        <Button key="search-default" type="primary" onClick={() => fetchData()}>BUSCAR</Button>
                    </div>
                    <div className="filter-item">
                        <Link to={`/new-dealership`}>
                            <Button type="primary" data-testid="btn_new_user">NOVA CONCESSIONÁRIA</Button>
                        </Link>
                    </div>
                </div>
                <Table columns={columns} dataSource={data} pagination={pagination} loading={loading} onChange={handleTableChange} rowKey={(record: any) => record.id} />


                <Modal footer={null} title="" visible={openModal} onCancel={modalClose}>
                    <ContentModal>
                    <p>Deseja remover a concessionária {dealership?.name}?</p>
                    <div>
                        <button onClick={() => modalOk()}>Sim</button>
                        <button onClick={() => modalClose()}>Não</button>
                    </div>
                    </ContentModal>
                </Modal>
            </Content>
            <Footer />
        </Container>
    );
};

export default DealersList;
