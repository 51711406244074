import React, { FC } from 'react'

import iconFacebook from '../../assets/icons/facebook.svg'
import iconInstagram from '../../assets/icons/instagram.svg'
import iconWhatsapp from '../../assets/icons/whatsapp.svg'
import {
  Container,
  Content,
  FooterF,
  Category,
  CategoryMinimal,
  Contact,
  Info
} from './styles'

const Footer: FC = () => {
  return (
    <Container>
      <Content>
        <CategoryMinimal>
          <h3>REDES SOCIAIS</h3>
          <div className="icons">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/lmmobilidade"
            >
              <img src={iconFacebook} alt="Facebook Icon" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/lmmobilidade_"
            >
              <img src={iconInstagram} alt="Instagram Icon" />
            </a>
            <a href="https://wa.me/08007755566">
              <img src={iconWhatsapp} alt="Whatsapp Icon" />
            </a>
          </div>
        </CategoryMinimal>
        <CategoryMinimal>
          <h3>INSTITUCIONAL</h3>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://lmmobilidade.com.br/quem-somos"
                style={{ color: 'white' }}
              >
                LM Frotas
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://lmvagas.gupy.io"
                style={{ color: 'white' }}
              >
                Trabalhe conosco
              </a>
            </li>
          </ul>
        </CategoryMinimal>
        <Category>
          <h3>PARA SUA EMPRESA</h3>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://lmmobilidade.com.br/lmassinecar"
                style={{ color: 'white' }}
              >
                Automóveis
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://lmmobilidade.com.br/lmassinetruck"
                style={{ color: 'white' }}
              >
                Caminhões
              </a>
            </li>
          </ul>
        </Category>
        <Contact>
          <h3>CANAIS DE COMUNICAÇÃO</h3>

          <p>0800 775 5566</p>

          <p>contato@lmmobilidade.com.br</p>
        </Contact>
        <Category>
          <h3>DÚVIDAS FREQUENTES</h3>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://lmmobilidade.com.br/faq_dealers"
                style={{ color: 'white' }}
              >
                Clique aqui e acesse a FAQ
              </a>
            </li>
          </ul>
        </Category>
      </Content>
      <Info>
        <p>
          Política de Privacidade | Política de Cookies | Informações Legais
        </p>
      </Info>
      <FooterF>
        <hr />
        <p>2022 LM Soluções de Mobilidade - Todos os direitos reservado</p>
      </FooterF>
    </Container>
  )
}

export default Footer
