import { Button } from 'antd'
import styled from 'styled-components'

interface iStatus {
  status: boolean
}

export const Container = styled.div``

export const WrapperTable = styled.div`
  width: 80%;
  margin-bottom: 100px;

  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    label {
      font-size: 14px;
      color: #767676;
      font-weight: 500;
    }

    button {
      margin: 2px;
    }

    .btn-page {
      width: 27px;
      height: 27px;
      background: #0c5faf 0% 0% no-repeat padding-box;
      border: 1px solid #e8e8e8;
      color: #fff;
      font: normal normal bold 11px/15px Open Sans;
    }

    .btn-previous {
      width: 59px;
      height: 27px;
      font: normal normal normal 11px/15px 'Open Sans';
      border: 1px solid #e8e8e8;
      color: #b1afaf;
    }

    .btn-next {
      width: 59px;
      height: 27px;
      font: normal normal normal 11px/15px 'Open Sans';
      border: 1px solid #e8e8e8;
    }
  }
  @media (max-width: 700px) {
    form {
      div {
        margin: 5px;
      }
    }
  }
`

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: inherit;
  margin: 15px 0px 5px 0px;
  border: none;

  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  tbody {
    .wrapper-status {
      display: flex;
      justify-content: center;
      margin: 0 auto;

      div {
        margin: 8px;
      }
    }

    .actions-button {
      width: 100%;
      display: flex;
      justify-content: center;

      button {
        width: 20px;
        background: transparent;
        border: none;
        margin: 4px;
      }
    }
  }

  tr {
    font-size: 14px;

    img {
      margin-left: 8px;
    }

    .th-center {
      text-align: center !important;
    }

    .status {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #51ae30;
      margin-right: 4px;
    }
  }

  td {
    padding: 10px;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    padding: 10px;
  }

  .large {
    width: 110px;
  }

  .th-left {
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
  }

  .th-right {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 660px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    thead tr {
      font-size: 11px;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

  h1 {
    color: #17536c;
    margin-top: 30px;
    margin-bottom: 0px !important;
    font-size: 1.6em !important;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    justify-content: space-between;

    button {
      width: 190px;
      margin-left: 8px;
      font-size: 14px;
    }

    .wrapper-search {
      display: flex;
      flex-direction: row;
      align-items: center;

      select {
        padding: 8px;
        border-radius: 23px;
        height: 41px;
      }

      div:nth-child(1) {
        margin: 0 15px;
        background: #ffffff;
      }

      div:nth-child(2) {
        margin: 0 15px;
        background: #ffffff;
      }

      div:nth-child(3) {
        margin: 0;
      }
    }
  }

  .wrapper-btn-show-files {
    display: flex;
    margin-top: -20px;
    justify-content: flex-end;
    flex-direction: row;
    align-content: flex-end;
  }

  .btn-power-of-attorney,
  .btn-show-notes,
  .btn-show-files {
    background: transparent;
    border: none;
    color: #226fbb;
    text-decoration-line: underline;
    font-size: 12px;
    a {
      text-decoration: underline;
    }
  }

  .wrapper-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .wrapper-button {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: flex-end;
    align-content: flex-end;

    button {
      width: 192px;
      font-size: 12px;
    }

    .btn-back {
      background: transparent;
      border: 2px solid #226fbb;
      color: #226fbb;
    }
  }

  .btn-submit-contract {
    margin-left: 10px;
  }

  @media (max-width: 450px) {
    .wrapper-button {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      flex-direction: column;
      justify-content: center;
      align-content: center;

      button {
        width: 154px;
        font-size: 11px;
      }

      .button-rigth {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .btn-submit-contract {
          margin-left: -1px;
        }
      }

      .btn-back {
        background: transparent;
        border: 2px solid #226fbb;
        color: #226fbb;
      }
    }

    .wrapper-link {
      display: flex;
      width: 80%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: -10px;
    }
  }

  @media (min-width: 320px) {
    p {
      text-align: center;
    }

    form {
      flex-direction: column;
      flex-wrap: wrap;

      .wrapper-search {
        flex-direction: column;
        flex-wrap: wrap;

        div {
          margin-top: 10px !important;
        }
      }
    }

    p {
      color: #3c505a;
      margin-top: 5px;
    }

    h1,
    p {
      text-align: center;
    }
  }
`

export const ContainerModal = styled.div`
  .ant-modal-content {
    .ant-modal-close {
      top: -9px;
    }
  }
`

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    color: #838383;
    margin-top: -10px;
  }

  textarea {
    width: 100%;
    border: none;
  }

  a {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 5px;
    margin-bottom: -20px;
    text-decoration: underline;
  }
`

export const ContainerModalUpload = styled.div`
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h1`
  color: #17536c;
  font-size: 25px;
  text-transform: uppercase;
  text-align: center;
  margin: 28px 0 16px 0;

  @media (max-width: 500px) {
    font-size: 18px;
  }
`

export const Description = styled.p`
  color: #3c505a;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`

export const WrapperDoc = styled.div`
  background: #ffffff;
  border: 1px solid #005faf;
  border-radius: 36px;
  width: 80%;
  display: flex;
  padding: 18px;
  margin: 12px auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container-name-file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin: 0;
      color: #005faf;
      font-size: 14px;
      font-weight: bold;
    }

    .name-file {
      font-size: 18px;
      font-weight: bold;

      span {
        font-weight: 500;
      }
    }
  }

  .more-info {
    text-decoration: underline;
    font-size: 14px;
    color: #000;
    cursor: pointer;
  }

  .right {
    .upload {
      flex-direction: column;

      .ant-tooltip-inner {
        display: none;
      }
    }

    .btn-upload {
      width: 192px;
      border: 3px solid #005faf;
      background: #fff;
      color: #005faf;
      font-size: 11px;
      margin-left: 12px;
      align-items: center;
      display: flex;
      justify-content: space-between;

      svg {
        color: #005faf;
      }
    }
  }

  @media (max-width: 470px) {
    height: auto;
    flex-direction: column;
    width: auto;

    .container-name-file {
      font-size: 16px;

      .name-file {
        font-size: 15px;
      }

      p {
        font-size: 11px;
      }
    }

    .rigth {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`

export const ContentModalUpload = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .btn-submit {
    width: 105px;
    font-size: 15px;
  }

  .btn-submit:disabled {
    opacity: 0.5;
    cursor: default;
  }

  @media (max-width: 470px) {
    .btn-submit {
      width: 82px;
      font-size: 11px;
    }
  }
`

export const ButtonUpload = styled(Button)`
  width: 192px;
  border: 3px solid #005faf;
  border-radius: 36px;
  background: #fff;
  color: #005faf;
  font-size: 11px;
  font-weight: bold;
  margin-top: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  svg {
    color: #005faf;
  }

  @media (max-width: 470px) {
    width: 155px;
    font-size: 8px;
  }
`
