/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useEffect, useState, useRef } from 'react'
import { Form } from '@unform/web'
import { Switch, Select as SelectAntd } from 'antd'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useHistory, useParams } from 'react-router-dom'

import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import Input from '../../../components/Input'
import Select from '../../../components/Select'
import iconTrash from '../../../assets/icons/trash-2.svg'
import { useAuth } from '../../../hooks/auth'
import { Container, Content } from './styles'
import api from '../../../services/api'
import { FormHandles } from '@unform/core'
import getValidationErrors from '../../../utils/getValidationErrors'
import { useToast } from '../../../hooks/toast'
import { cnpjMask } from '../../../utils/cnpjMask'
import { ISegment } from 'utils/types'
import SegmentList from '../SegmentList'

interface iGroups {
  name: string
  id: string
}

interface Domains {
  name: string
}

interface CreateDealershipFormData {
  id?: number
  name: string
  number: string
  cnpj: string
  status: boolean
  dealershipGroupID: number
  street: string
  addressNumber: string
  domainList: any
  email: string
  segments: string
  uf: string
  city: string
}

const NewDealership: FC = () => {
  const { id } = useParams<any>()
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const { addToast } = useToast()
  const { role, dealershipGroupId: userDealershipGroupId } = useAuth()
  const { Option } = SelectAntd

  const [groups, setGroups] = useState<iGroups[]>([])
  const [active, setActive] = useState(true)

  const [domains, setDomains] = useState<Domains[]>([])
  const [domain, setDomain] = useState('')
  const [callApi, setCallApi] = useState(false)
  const [dealershipGroupID, setDealershipGroupID] = useState(0)
  const [dealership, setDealership] = useState<CreateDealershipFormData>()
  const [CNPJ, setCNPJ] = useState('')

  const [segments, setSegments] = useState<ISegment[]>([])

  const [statesBr, setStatesBr] = useState([])
  const [citiesBr, setCitiesBr] = useState([])
  const [city, setCity] = useState('')
  const [uf, setUf] = useState('')

  useEffect(() => {
    api.get('/dealerships/DealershipsGroup').then((response) => {
      const { data } = response
      setGroups(data)
      if (role !== 'Super Admin' && role !== 'Root') {
        setDealershipGroupID(parseInt(userDealershipGroupId))
      }
    })

    if (id) {
      api.get(`/dealerships/${id}`).then(({ data }) => {
        const { dealershipGroupID, status, domainList, uf, city } = data
        setUf(uf)
        setCity(city)
        setDealership(data)
        setDealershipGroupID(dealershipGroupID)
        setActive(status)
        setCNPJ(cnpjMask(data.cnpj))

        const formattedDomains = domainList.map((item: any) => {
          return {
            name: item
          }
        })

        const formatSegments = (list: any) => {
          const format = (item: any) => ({
            ...item,
            iD_Fleet: item.idSegment
          })

          return list.map(format)
        }

        setDomains(formattedDomains)
        setSegments(formatSegments(data.listSegments))
      })
    }

    fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((res) =>
        res.json().then(function (data) {
          setStatesBr(
            data.sort((a: any, b: any) => {
              if (a.nome > b.nome) {
                return 1
              }
              if (a.nome < b.nome) {
                return -1
              }

              return 0
            })
          )
        })
      )
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const initialValue = {
    name: dealership?.name,
    number: dealership?.number,
    addressNumber: dealership?.addressNumber,
    street: dealership?.street,
    cnpj: dealership?.cnpj,
    email: dealership?.email
  }

  const handleSubmit = useCallback(
    async (data: CreateDealershipFormData) => {
      const domainsFormatted = domains.map((item) => item.name)
      data.domainList = domainsFormatted
      data.status = active
      data.dealershipGroupID = dealershipGroupID
      data.city = city
      data.uf = uf

      if (!callApi) {
        return
      }

      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string().required('E-mail obrigatório'),
          number: Yup.string().required('Número obrigatório'),
          cnpj: Yup.string()
            .min(18, 'CNPJ inválido')
            .required('CNPJ obrigatório'),
          dealershipGroupID: Yup.number()
            .typeError('Informe um número')
            .required('Grupo obrigatório'),
          street: Yup.string().required('Endereço obrigatório'),
          addressNumber: Yup.number()
            .typeError('Informe um número')
            .required('Número obrigatório')
        })

        await schema.validate(data, {
          abortEarly: false
        })

        data.segments = segments
          .map((segmentItem: ISegment) => `${segmentItem.iD_Fleet},`)
          .join('')

        if (dealership) {
          data.id = id
          api.put('/dealerships', data)

          addToast({
            title: 'Concessionária editada!',
            type: 'success',
            description: 'Concessionária editada com sucesso.'
          })

          setTimeout(() => {
            history.push('/dealerships')
          }, 1000)

          return
        } else {
          await api.post('/dealerships', data)

          addToast({
            title: 'Concessionária criada!',
            type: 'success',
            description: 'Concessionária criada com sucesso.'
          })

          setTimeout(() => {
            history.push('/dealerships')
          }, 1000)

          return
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
        } else {
          addToast({
            title: 'Ocorreu um erro!',
            type: 'error',
            description:
              'Erro ao criar concessionária, tente novamente mais tarde.'
          })
        }
      }
    },
    [active, callApi, domains]
  )

  const handleChangeActive = () => {
    setActive(!active)
  }

  const handleAddDomain = () => {
    setDomains([...domains, { name: domain }])
    setDomain('')
  }

  const handleRemoveDomain = (name: string) => {
    setDomains(domains.filter((item) => item.name !== name))
  }

  const handleChangeGroup = (item: any) => {
    setDealershipGroupID(item.target.value)
  }

  const handleChangeCnpj = (e: any) => {
    setCNPJ(cnpjMask(e.target.value))
  }

  const handleChangeDealership = (value: string) => {
    setUf(value)
    const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`
    fetch(url)
      .then((res) =>
        res.json().then(function (data) {
          setCitiesBr(data)
        })
      )
      .catch((err) => {
        console.log(err)
      })
  }

  const handleChangeCities = (value: string) => {
    setCity(value)
  }

  return (
    <Container>
      <Header />
      <Content>
        <h1>NOVA CONCESSIONÁRIA</h1>
        <p>Cadastre uma nova concessionária</p>

        <Form initialData={initialValue} ref={formRef} onSubmit={handleSubmit}>
          <div className="isActive">
            <span>CONCESSIONÁRIA ATIVA</span>
            <Switch
              onChange={handleChangeActive}
              checked={active}
              disabled={!id}
            />
          </div>
          <div className="wrapper-info">
            <label>Grupo</label>
            <Select
              onChange={handleChangeGroup}
              required
              name="profile"
              id="profile"
              value={dealershipGroupID}
              disabled={role !== 'Super Admin' && role !== 'Root'}
            >
              <option value="">Selecione</option>
              {groups.map((item: iGroups) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="wrapper-info other-style">
            <label>Número</label>
            <Input id="number" name="number" type="number" maxLength={15} />
          </div>
          <div className="wrapper-info other-style">
            <label>Nome</label>
            <Input id="name" name="name" type="text" maxLength={100} />
          </div>
          <div className="wrapper-content">
            <div className="state">
              <p>Estado</p>
              <SelectAntd
                defaultValue="Selecione"
                value={uf}
                style={{ width: 127, border: '1px solid #0C5FB1' }}
                onChange={(value) => handleChangeDealership(value)}
              >
                {statesBr.map((item: any, idx: number) => (
                  <Option key={idx} value={item.sigla}>
                    {item.sigla}
                  </Option>
                ))}
              </SelectAntd>
            </div>
            <div className="city">
              <p>Cidade</p>
              <SelectAntd
                showSearch
                value={city}
                style={{
                  width: 300,
                  border: '1px solid #0C5FB1'
                }}
                onChange={handleChangeCities}
              >
                {citiesBr.map((item: any, idx: number) => (
                  <Option key={idx} value={item.nome}>
                    {item.nome}
                  </Option>
                ))}
              </SelectAntd>
            </div>
          </div>
          <div className="wrapper-info-address">
            <div className="address">
              <label>Endereço</label>
              <Input id="street" name="street" type="text" maxLength={150} />
            </div>
            <div className="number">
              <label>Número</label>
              <Input
                id="addressNumber"
                name="addressNumber"
                type="number"
                maxLength={10}
              />
            </div>
          </div>
          <div className="wrapper-info other-style">
            <label>CNPJ</label>
            <Input
              id="cnpj"
              name="cnpj"
              maxLength={18}
              onChange={handleChangeCnpj}
              value={CNPJ}
            />
          </div>
          <div className="wrapper-info">
            <label>Domínios</label>
            <div className="wrapper-btn-add-domain">
              <Input
                placeholder="Preencha o domínio de e-mail"
                id="domain"
                name="domain"
                type="text"
                maxLength={50}
                onChange={(item) => setDomain(item.target.value)}
              />
              <div>
                <button disabled={!domain} onClick={handleAddDomain}>
                  Adicionar +
                </button>
              </div>
            </div>
          </div>
          <div className="wrapper-info other-style trash">
            {domains.length > 0 &&
              domains.map((item) => (
                <>
                  <div className="domains">{item.name}</div>
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRemoveDomain(item.name)}
                    src={iconTrash}
                    alt="Icon remover domínio"
                  />
                </>
              ))}
          </div>
          <div className="wrapper-info other-style">
            <label>E-mail</label>
            <Input id="email" name="email" type="email" maxLength={100} />
          </div>

          {(role === 'Super Admin' || role === 'Root') && (
            <SegmentList
              value={segments}
              onSet={(value) => setSegments(value)}
            />
          )}

          <div className="wrapper-button">
            <Link to="/dealerships">
              <button className="btn-cancel">Cancelar</button>
            </Link>

            <button
              onClick={() => setCallApi(true)}
              type="submit"
              className="btn-save"
            >
              Salvar
            </button>
          </div>
        </Form>
      </Content>
      <Footer />
    </Container>
  )
}

export default NewDealership
