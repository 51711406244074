import { Reducer } from 'redux'

const INITIAL_STATE = { type: '' }

const clientType: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLIENTTYPE': {
      const { type } = action.payload
      return {
        ...state,
        type
      }
    }
    default: {
      return state
    }
  }
}

export default clientType
