/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { rolesName } from './rolesName'
export default function rolesdePara(value: any) {
  let newName = ''
  rolesName.map((r) => {
    if (r.de.toLowerCase() === value.toLowerCase()) {
      newName = r.para
    }
  })
  return newName
}
