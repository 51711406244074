/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import iconEdit from '../../assets/icons/edit-2.svg'
import iconTrash from '../../assets/icons/trash-2.svg'

import * as S from './styles'
import Header from 'components/Header'
import Footer from 'components/Footer'
import api from 'services/api'
import { COMMISSIONING, KICKBACK_COMMISSIONING } from 'utils/urls'
import { useToast } from 'hooks/toast'
import Loading from 'components/Loading'
import { useHistory } from 'react-router-dom'

interface ICommissionItem {
  id: number
  dealerShips: string
  kickbacks: string
  name: string
  segments: string
}

const Commissioning = () => {
  const history = useHistory()
  const { addToast } = useToast()

  const [commission, setCommission] = useState<ICommissionItem[]>([])

  const [isLoading, setIsLoading] = useState(false)

  const loadCommissions = () => {
    api
      .get(COMMISSIONING)
      .then(({ data }) => {
        setCommission(data)
      })
      .catch(() => {
        addToast({
          type: 'info',
          title: `Informação`,
          description: 'Não existem dados de comissionamentos.'
        })
      })
  }

  useEffect(() => {
    setIsLoading(true)
    loadCommissions()
    setIsLoading(false)
  }, [])

  const handleAdd = () => {
    history.push(`/commissio/create-update`)
  }

  const handleRemoveCommission = async (id: number) => {
    await api
      .delete(`${KICKBACK_COMMISSIONING}?id=${id}`)
      .then(() => {
        loadCommissions()
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível trazer os dados`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
  }

  const handleUpdateCommission = async (commision: ICommissionItem) => {
    history.push(`/commissio/create-update/${commision.id}`)
  }

  return (
    <S.Container>
      <Header />
      <h1 data-testid="title_page" className="title">
        Comissionamento
      </h1>

      <S.WrapperButtons>
        <S.ButtonsRight>
          <Button onClick={handleAdd}>Novo Grupo</Button>
        </S.ButtonsRight>
      </S.WrapperButtons>

      {isLoading ? (
        <>
          <Loading />

          <p className="message-wait">Aguarde...</p>
        </>
      ) : (
        <div style={{ overflowX: 'auto' }}>
          <S.TableContent data-testid="table">
            <thead>
              <tr>
                <th>Grupo</th>
                <th>Segmentos</th>
                <th>Comissões</th>
                <th>Concessionárias</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {commission.map((commissio: ICommissionItem, index: number) => (
                <tr key={index}>
                  <td>{commissio.name}</td>
                  <td>{commissio.segments}</td>
                  <td>{commissio.kickbacks}</td>
                  <td>{commissio.dealerShips}</td>
                  <td className="actions-button">
                    <button onClick={() => handleUpdateCommission(commissio)}>
                      <img src={iconEdit} alt="Editar" />
                    </button>
                    <button
                      onClick={() => handleRemoveCommission(commissio.id)}
                    >
                      <img src={iconTrash} alt="Deletar" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </S.TableContent>
        </div>
      )}

      <Footer />
    </S.Container>
  )
}

export default Commissioning
