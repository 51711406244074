/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'

import { Container, WrapperFiles } from './styles'

import api from '../../../services/api'
import { useParams } from 'react-router-dom'

import Loading from 'components/Loading'

interface IParams {
  orderId: string
}

interface ICompanyDocuments {
  onOpenModal: () => void
  showModal: boolean
  clientType: number
  userId: number
}

interface IFiles {
  balanceSheetPreviousYear: string
  bankIndebtedness: string
  ccmei: string
  currentYearBalanceSheet: string
  dreCurrentYear: string
  drePreviousYear: string
  irpjLastYear: string
  lastContractChange: string
  lastYearRevenue: string
  lastYearSocialIncomeTax: string
  others: string
  simplesStatementLastYear: string
  procuration: string
}

const DocumentsCompany = ({
  onOpenModal,
  showModal,
  clientType,
  userId
}: ICompanyDocuments) => {
  const { orderId } = useParams<IParams>()
  const [isLoading, setLoading] = useState(false)

  const [files, setFiles] = useState<IFiles>()

  useEffect(() => {
    setLoading(true)
    if (clientType === 1) {
      api.get(`/upload/${userId}`).then((res) => console.log(res))
    } else {
      api
        .get(`/document-company-upload/${userId}`)
        .then((res) => setFiles(res.data))
    }
    setLoading(false)
  }, [])

  return (
    <Container>
      <Modal
        footer={null}
        title="Documentos disponíveis para download"
        onOk={onOpenModal}
        onCancel={onOpenModal}
        visible={showModal}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {files !== undefined && (
              <WrapperFiles>
                {files.balanceSheetPreviousYear !== null && (
                  <a
                    href={`data:application/pdf;base64,${files.balanceSheetPreviousYear}`}
                    download={`${orderId}-Balanço-do-ano-anterior.pdf`}
                  >
                    Balanço do ano anterior
                  </a>
                )}
                {files.bankIndebtedness !== null && (
                  <a
                    href={`data:application/pdf;base64,${files.bankIndebtedness}`}
                    download={`${orderId}-Relação-de-Endividamento-Bancário.pdf`}
                  >
                    Relação de Endividamento Bancário
                  </a>
                )}
                {files.ccmei !== null && (
                  <a
                    href={`data:application/pdf;base64,${files.ccmei}`}
                    download={`${orderId}-CCMEI.pdf`}
                  >
                    CCMEI
                  </a>
                )}
                {files.currentYearBalanceSheet !== null && (
                  <a
                    href={`data:application/pdf;base64,${files.currentYearBalanceSheet}`}
                    download={`${orderId}-Balanço-ano-vigente.pdf`}
                  >
                    Balanço ano vigente
                  </a>
                )}
                {files.dreCurrentYear !== null && (
                  <a
                    href={`data:application/pdf;base64,${files.dreCurrentYear}`}
                    download={`${orderId}-DRE-ano-vigente.pdf`}
                  >
                    DRE ano vigente
                  </a>
                )}
                {files.drePreviousYear !== null && (
                  <a
                    href={`data:application/pdf;base64,${files.drePreviousYear}`}
                    download={`${orderId}-DRE-ano-anterior.pdf`}
                  >
                    DRE ano anterior
                  </a>
                )}
                {files.irpjLastYear !== null && (
                  <a
                    href={`data:application/pdf;base64,${files.irpjLastYear}`}
                    download={`${orderId}-IRPJ-último-ano.pdf`}
                  >
                    IRPJ último ano
                  </a>
                )}
                {files.lastContractChange !== null && (
                  <a
                    href={`data:application/pdf;base64,${files.lastContractChange}`}
                    download={`${orderId}-Última-alteração-contratual.pdf`}
                  >
                    Última alteração contratual
                  </a>
                )}
                {files.lastYearRevenue !== null && (
                  <a
                    href={`data:application/pdf;base64,${files.lastYearRevenue}`}
                    download={`${orderId}-Relação-de-faturamento-último-ano.pdf`}
                  >
                    Relação de faturamento último ano
                  </a>
                )}
                {files.lastYearSocialIncomeTax !== null && (
                  <a
                    href={`data:application/pdf;base64,${files.lastYearSocialIncomeTax}`}
                    download={`${orderId}-IRPF-do-socio-último-ano.pdf`}
                  >
                    IRPF do socio último ano
                  </a>
                )}
                {files.simplesStatementLastYear !== null && (
                  <a
                    href={`data:application/pdf;base64,${files.simplesStatementLastYear}`}
                    download={`${orderId}-Declaração-do-SIMPLES-último-ano.pdf`}
                  >
                    Declaração do SIMPLES último ano
                  </a>
                )}
                {files.procuration !== null && (
                  <a
                    href={`data:application/pdf;base64,${files.procuration}`}
                    download={`${orderId}-Procuração.pdf`}
                  >
                    Procuração
                  </a>
                )}
                {files.others !== null && (
                  <a
                    href={`data:application/pdf;base64,${files.others}`}
                    download={`${orderId}-Outros.pdf`}
                  >
                    Outros
                  </a>
                )}
              </WrapperFiles>
            )}
          </>
        )}
      </Modal>
    </Container>
  )
}

export default DocumentsCompany
