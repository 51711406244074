import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../../components/Button'
import { Container } from './styles'

const ConfirmEmail: React.FC = () => {
  return (
    <Container>
      <p data-testid="title_page">
        Seu e-mail foi verificado, agora você já pode utilizar a aplicação.
      </p>
      <Link to="/">
        <Button data-testid="button_fazer_login">Fazer login</Button>
      </Link>
    </Container>
  )
}

export default ConfirmEmail
