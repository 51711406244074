import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 40px auto;
  align-items: center;
  font-family: 'Open Sans';
  padding-bottom: 16px;
  border: 2px solid #236fbc;
  border-radius: 16px;
  background: #ffffff;
  padding: 25px;
  color: #000000;

  h2 {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #0668b4;
  }
`

export const Table = styled.table`
  width: 100%;
  text-align: center;
  border-spacing: inherit;
  margin: 15px 0px 30px 0px;
  border: 1pt solid #cfd7d9;

  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  tr,
  td {
    border: 1pt solid #cfd7d9;
  }

  tr {
    font-size: 14px;
  }

  thead {
    tr {
      height: 36px;
    }
  }

  td {
    padding: 7px 15px;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    height: 36px;
  }

  .large {
    width: 110px;
  }

  .status-color-wait {
    color: #ba1818;
  }

  .status-color-wait-finance {
    color: #ce651a;
  }

  .status-color-available {
    color: #369617;
  }

  .status-color-license-plate {
    color: #ba1818;
  }

  .status-color-active {
    color: #167c9d;
  }
`

export const WrapperLogo = styled.div`
  margin: 20px;
`
