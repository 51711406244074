/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from 'react'
import { Switch, Button, Input, Select, Modal, Upload } from 'antd'
import qs from 'qs'

import * as S from './styles'
import Header from 'components/Header'
import Footer from 'components/Footer'
import TableProduct from 'components/TableProduct'
import api from 'services/api'
import { SEGMENTS_ACTIVE, PRODUCT_IMAGE_URL } from 'utils/urls'
import { useToast } from 'hooks/toast'
import { vwPF } from 'utils/segment'
import { useHistory } from 'react-router-dom'
import { findPersonType } from 'utils/helpers'
import imageCar from '../../assets/defaultImages/image-car-offer.svg'
import exampleImg from '../../assets/defaultImages/example.jpg'
import noImageColor from '../../assets/defaultImages/no-image-color.jpg'
import noImageCar from 'assets/defaultImages/no-image.png'
import { RcFile, UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { defaultBackendUrl } from 'services/api'

interface IFilters {
  segment?: string
  modelCode?: string
  model?: string
  optional?: string
  status?: boolean
}

interface IFilterOptions {
  modelCode: Array<string>
  model: Array<string>
  optional: Array<string>
}

const DEFAULT_SEGMENT = vwPF

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

const ImageLoading = () => {
  const { addToast } = useToast()
  const history = useHistory()

  const [segments, setSegments] = useState([])
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>()
  const [filters, setFilters] = useReducer(
    (prev: IFilters, value: IFilters) => ({ ...prev, ...value }),
    { segment: DEFAULT_SEGMENT, status: true }
  )
  const [inputs, setInputs] = useState({ model: '', optional: '' })
  const [stocks, setStocks] = useState([])
  const [showModalSelectImage, setShowModalSelectImage] = useState(false)
  const [showModalImageRefer, setShowModalImageRefer] = useState(false)
  const [textLabelModel, setTextLabelModel] = useState('')
  const [modelCode, setModelCode] = useState('')
  const [colorCode, setColorCode] = useState('')
  const [previewImage, setPreviewImage] = useState(imageCar)
  const [previewImageColor, setPreviewImageColor] = useState(noImageColor)

  const loadSegments = () => {
    api
      .get(SEGMENTS_ACTIVE)
      .then(({ data }) => {
        setSegments(data)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível listar os segmentos`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
  }

  const loadStocks = () => {
    api
      .get(PRODUCT_IMAGE_URL, {
        params: { ...filters },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        }
      })
      .then(({ data }) => {
        setStocks(data.values)
        setFilterOptions(data.filters)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível listar os estoques`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
  }

  const loadFilterOptions = () => {}

  useEffect(() => {
    loadSegments()
  }, [])

  useEffect(() => {
    loadFilterOptions()
    loadStocks()
  }, [filters])

  const handleChangeSelect = (name: string, value: string) => {
    setFilters({ [name]: value })
  }

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget
    setInputs((prev) => ({ ...prev, [name]: value }))
  }

  const handleSearch = (name: string, value: string) => {
    setFilters({ [name]: value })
  }

  const handleChangeInactive = (value: boolean) => {
    setFilters({ status: !value })
  }

  const handleCleanFilters = () => {
    setInputs({ model: '', optional: '' })
    setFilters({
      segment: DEFAULT_SEGMENT,
      modelCode: undefined,
      model: '',
      optional: '',
      status: true
    })
  }

  const handleReturn = () => {
    history.push('/my-orders')
  }

  const handleCloseModalSelectImage = () => {
    setShowModalSelectImage(false)
  }

  const handleCloseModalImageRefer = () => {
    setShowModalImageRefer(false)
  }

  const handleModalImage = (
    colorCode: string,
    modelCode: string,
    model: string
  ) => {
    setTextLabelModel(model)
    setModelCode(modelCode)
    setColorCode(colorCode)
    setPreviewImage(imageCar)
    setPreviewImageColor(noImageColor)
    setPreviewImage(
      `${defaultBackendUrl}/s3/veiculos/canal-indireto/${modelCode}.png?${Math.random()}`
    )
    setPreviewImageColor(
      `${defaultBackendUrl}/s3/veiculos/Cores/${colorCode}.png?${Math.random()}`
    )
    setShowModalSelectImage(true)
  }

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
  }

  const propsImage = {
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    accept: '.png',
    onPreview: handlePreview,
    beforeUpload: (file: any) => {
      const { type } = file
      if (type !== 'image/png') {
        addToast({
          title: 'Erro ao enviar arquivo',
          type: 'error',
          description: 'Envie arquivos do tipo PNG.'
        })

        return false
      }
      return true
    },
    onChange(info: UploadChangeParam) {
      const { type } = info.file
      if (type !== 'image/png') {
        return
      }
      handleFormatImage(info)
    }
  }

  const handleFormatImage = async (image: any) => {
    const formData = new FormData()
    if (image.file.size > 5000000 && image.file.status !== 'uploading') {
      return addToast({
        title: 'Falha ao enviar documento',
        type: 'error',
        description: 'O tamanho do arquivo é maior que 5 mb'
      })
    } else {
      if (image.file.status === 'done' || image.file.status === 'error') {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            Accept: 'text/plain'
          }
        }

        formData.append('file', image.file.originFileObj)

        await handleSendImage(config, formData, image)
      }
    }
  }

  const handleSendImage = async (config: any, formData: any, image: any) => {
    try {
      await api.post(
        `/s3files/UploadImageS3?code=${modelCode}&type=0`,
        formData,
        config
      )
      addToast({
        title: 'Imagem veículo!',
        type: 'success',
        description: 'Imagem veículo enviada com sucesso.'
      })
      setPreviewImage(
        `${defaultBackendUrl}/s3/veiculos/canal-indireto/${modelCode}.png?${Math.random()}`
      )
    } catch (err) {
      addToast({
        title: 'Erro ao fazer upload',
        type: 'error',
        description: `${err.response.data}`
      })
    }
  }

  const propsImageColor = {
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    accept: '.png',
    onPreview: handlePreview,
    beforeUpload: (file: any) => {
      const { type } = file
      if (type !== 'image/png') {
        addToast({
          title: 'Erro ao enviar arquivo',
          type: 'error',
          description: 'Envie arquivos do tipo PNG.'
        })

        return false
      }
      return true
    },
    onChange(info: UploadChangeParam) {
      const { type } = info.file
      if (type !== 'image/png') {
        return
      }
      handleFormatImageColor(info)
    }
  }

  const handleFormatImageColor = async (image: any) => {
    const formData = new FormData()
    if (image.file.size > 5000000 && image.file.status !== 'uploading') {
      return addToast({
        title: 'Falha ao enviar documento',
        type: 'error',
        description: 'O tamanho do arquivo é maior que 5 mb'
      })
    } else {
      if (image.file.status === 'done' || image.file.status === 'error') {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            Accept: 'text/plain'
          }
        }

        formData.append('file', image.file.originFileObj)

        await handleSendImageColor(config, formData, image)
      }
    }
  }

  const handleSendImageColor = async (
    config: any,
    formData: any,
    image: any
  ) => {
    try {
      await api.post(
        `/s3files/UploadImageS3?code=${colorCode}&type=1`,
        formData,
        config
      )
      addToast({
        title: 'Imagem cor!',
        type: 'success',
        description: 'Imagem cor enviada com sucesso.'
      })
      setPreviewImageColor(
        `${defaultBackendUrl}/s3/veiculos/Cores/${colorCode}.png?${Math.random()}`
      )
    } catch (err) {
      addToast({
        title: 'Erro ao fazer upload',
        type: 'error',
        description: `${err.response.data}`
      })
    }
  }

  return (
    <S.Container>
      <Header />
      <h1 data-testid="title_page" className="title">
        Gestão de Conteúdo
      </h1>

      <S.Filter>
        <S.WrapperFilters>
          <S.Wrapper>
            <span>Segmento</span>

            <Select
              className="select-segment input-medium"
              placeholder="Selecione o segmento"
              data-testid="segment"
              value={filters.segment}
              onChange={(value) => handleChangeSelect('segment', value)}
            >
              {segments.map(({ id, iD_Fleet, type, name }) => (
                <Select.Option key={id} value={String(iD_Fleet)}>
                  {findPersonType(type)?.label} - {name}
                </Select.Option>
              ))}
            </Select>
          </S.Wrapper>

          <S.Wrapper>
            <span>Código do modelo</span>

            <Select
              className="select-segment model-code"
              placeholder="Selecione"
              data-testid="model_code"
              value={filters.modelCode}
              onChange={(value) => handleChangeSelect('modelCode', value)}
            >
              {filterOptions?.modelCode.map((item, index) => (
                <Select.Option key={index} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </S.Wrapper>

          <S.Wrapper>
            <span>Modelo</span>

            <Input.Search
              className="input-model"
              name="model"
              data-testid="model"
              type="text"
              placeholder="Digite o modelo"
              value={inputs.model}
              onSearch={(value) => handleSearch('model', value)}
              onChange={handleChangeInput}
            />
          </S.Wrapper>
        </S.WrapperFilters>
        <S.FilterActions>
          <S.WrapperSwitch>
            <span>Mostrar inativos</span>
            <Switch
              data-testid="show_inactives"
              checked={!filters.status}
              onChange={handleChangeInactive}
            />
          </S.WrapperSwitch>
          <Button
            data-testid="btn_clear_filters"
            type="link"
            onClick={handleCleanFilters}
          >
            Limpar filtros
          </Button>
        </S.FilterActions>
      </S.Filter>
      <TableProduct stocks={stocks} handleModalImage={handleModalImage} />

      <S.WrapperButtons>
        <Button onClick={handleReturn}>Voltar</Button>
      </S.WrapperButtons>

      <Modal
        onCancel={handleCloseModalImageRefer}
        width="100%"
        footer={null}
        visible={showModalImageRefer}
      >
        <S.ModalContent>
          <img width="80%" src={exampleImg} alt="example" />
        </S.ModalContent>
      </Modal>

      <Modal
        onCancel={handleCloseModalSelectImage}
        width="680"
        footer={null}
        visible={showModalSelectImage}
      >
        <S.ModalContent>
          <>
            <p className="title-message">{textLabelModel}</p>
            <Upload className="upload" {...propsImage}>
              <Button className="btn-upload">
                <img width={500} src={previewImage} alt="ImageCar" />
              </Button>
            </Upload>
            <S.WrapperButtonsCenter>
              <Upload className="upload" {...propsImageColor}>
                <p className="sub-title">Cores disponíveis</p>
                <Button className="btn-upload">
                  <img width={40} src={previewImageColor} alt="ImageColor" />
                </Button>
              </Upload>
              <Button
                className="btn-refer"
                onClick={() => setShowModalImageRefer(true)}
              >
                Referência
              </Button>
            </S.WrapperButtonsCenter>
          </>
        </S.ModalContent>
      </Modal>
      <Footer />
    </S.Container>
  )
}

export default ImageLoading
