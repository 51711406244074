export const rolesName = [
  {
    de: 'Admin da concessionária',
    para: 'Gerente de Concessionária'
  },
  {
    de: 'Admin do Grupo',
    para: 'Gestor do Grupo'
  },
  {
    de: 'Atendimento',
    para: 'Formalização'
  },
  {
    de: 'Super Admin',
    para: 'Admin Fleet'
  },
  {
    de: 'Usuário da Concessionária',
    para: 'Vendedor da Concessionária'
  },
  {
    de: 'Root',
    para: 'Super Admin'
  },
  {
    de: "Vendedor Parceiro S&D",
    para: "Vendedor Parceiro S&D"
  },
  {
    de: "Vendedor Parceiro Audi",
    para: "Vendedor Parceiro Audi"
  }
]
