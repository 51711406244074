import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import pt_BR from 'antd/lib/locale/pt_BR'

import GlobalStyle from './styles/global'

import Routes from './routes'
import store from './store'
import RouteChangeTracker from 'components/RouteChangeTracker'

const App: React.FC = () => (
  <ConfigProvider locale={pt_BR}>
    <Provider store={store}>
      <Router>
        <RouteChangeTracker />
        <Routes />
        <GlobalStyle />
      </Router>
    </Provider>
  </ConfigProvider>
)

export default App
