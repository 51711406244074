import { ILocation } from './types'

export function setTabs(id: ILocation) {
  return {
    type: 'TABS',
    payload: {
      id
    }
  }
}
