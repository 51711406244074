/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import * as S from './styles'

interface IStockItem {
  color: string
  colorCode: string
  model: string
  modelCode: string
  segment: string
}
interface PropsType {
  stocks: Array<IStockItem>
  handleModalImage: (
    colorCode: string,
    modelCode: string,
    model: string
  ) => void
}

const defaultProps = {
  stocks: []
}

const TableProduct: React.FC<PropsType> = ({ stocks, handleModalImage }) => {
  const handleChangeImage = (
    colorCode: string,
    modelCode: string,
    model: string
  ) => {
    handleModalImage(colorCode, modelCode, model)
  }

  return (
    <div style={{ overflowX: 'auto' }}>
      <S.TableContent data-testid="table">
        <thead>
          <tr>
            <th>Item</th>
            <th>Cód. Modelo</th>
            <th>Modelo</th>
            <th>Cor</th>
            <th>Carregamento Imagem</th>
          </tr>
        </thead>
        <tbody>
          {stocks.map((stock: IStockItem, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{stock.modelCode}</td>
              <td>{stock.model}</td>
              <td>{stock.color}</td>
              <td>
                <button
                  onClick={() =>
                    handleChangeImage(
                      stock.colorCode,
                      stock.modelCode,
                      stock.model
                    )
                  }
                >
                  Buscar Imagem
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </S.TableContent>
    </div>
  )
}

TableProduct.defaultProps = defaultProps
export default TableProduct
