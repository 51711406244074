import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;

`

export const Content = styled.div`
  border: 2px solid #236fbc;
  border-radius: 6px;
  padding: 20px;
  margin: 6px;
  max-width: 1400px;
  h3,
  p {
    margin: 0;
  }

  @media (min-width: 768px) {
    width: 768px;
  }
`
export const WrapperSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  max-width: 1175px;
  width: 790px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 50px;
  }

  button {
    width: 300px;
    height: 50px;
    border-radius: 30px;
    background-color: #236fbc;
    border: 2px solid #236fbc;
    color: white;

    @media (min-width: 768px) {
      width: 230px;
    }

    @media (min-width: 1024px) {
      width: 300px;
      height: 59px;
    }
  }

  div {
    margin: 5px 0;
  }
  .ant-select {
    width: 50%;
  }

  .ant-select-selection-placeholder {
    color: #797979;
  }

  .ant-select-single:not(.ant-select-customize-input) {
    & > .ant-select-selector {
      height: 40px;
      border: 2px solid #236fbc !important;
      border-radius: 30px;

      @media (min-width: 768px) {
        width: 100%;
      }

      @media (min-width: 1024px) {
        width: 100%;
        height: 40px;
      }

      .ant-select-selection-item {
        color: #797979;
        line-height: 43px;

        @media (min-width: 1024px) {
          line-height: 55px;
        }
      }
    }
  }
`

export const Message = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;

  p {
    color: #0c5faf;
    font-size: 18px;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }
`

export const LocationAddress = styled.div`
  border: 2px solid #236FBC;
  border-radius: 6px;
  cursor: pointer;
  display:flex;
  flex-direction: column;
  height: 127px;
  justify-content: center;
  margin: 0 0 44px 0;
  padding: 14px 20px;
  /*width: 95%;*/
  position: relative;
  max-width: 1175px;
  width: 780px;
  align-items: center;
  
  @media(min-width: 768px){
    align-items: flex-start;
  }

  .background-filter{
    background-color: #0c5fb152;
    border: 1px solid #0C5FB1;
    border-radius: 6px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .header-location-address h3{
    color: #3C505A;
    font-size: 14px;
    font-weight: 600;
    margin: 0
    text-align: center;
  }

  @media(min-width: 768px){
    .header-location-address h3{
      font-size: 20px;
    }
  }

  .text{
    font-size: 12px;
    color: #3C505A;
    margin: 0;
    text-align: center;

    @media(min-width: 768px){
      font-size: 16px;
      text-align: left;
    }
  }
`

export const WrapperAction = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;

  button {
    border: none;
    width: 190px;
    height: 41px;
    font-size: 12px;
    text-transform: uppercase;
    margin: 8px;
    border-radius: 24px;
    background-color: #236fbc;
    color: white;

    &.btn-continue {
      background-color: #236fbc;
      color: white;
      margin-top: 20px;
    }
    &.btn-back {
      border: 2px solid #226fbb;
      background-color: white;
      color: #236fbc;
    }
  }
`

export const Line = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin: 0 !importat;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  label {
    margin-left: 25px;
  }
`

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin: 8px;
`

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
