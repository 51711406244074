/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect, useRef } from "react";

import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { useHistory, Link } from "react-router-dom";
import { Checkbox, Modal, Space } from "antd";
import { useToast } from "../../hooks/toast";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import { Container, WrapperStatus, Select, WrapperTable, Table, ContentModal, WrapperLogo, SelectSegmentButtons } from "./styles";
import Button from "../../components/Button";
import Input from "../../components/Input";
import isonSearch from "../../assets/icons/search-icon.svg";
import { formatDate } from "../../utils/formatDate";
import formatValue from "../../utils/formatValue";
import { cpfMask } from "../../utils/cpfMask";
import Loading from "../../components/Loading";
import PaginationList from "../../components/PaginationList";
import { AutoComplete } from "antd";
import { cnpjMask } from "utils/cnpjMask";
import { onlyNumbersRegex } from "utils/regex";
import { Dropdown, Menu } from "antd";
import { Button as AntButton } from "antd";
import { DownOutlined, PoweroffOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const { Option } = AutoComplete;

interface IStatusOrder {
    ordersCreating: number;
    ordersAwaitingContractSigning: number;
    ordersCreditAnalysis: number;
    vehicleInProccess: number;
    vehiclesDelivered: number;
    ordersCanceled: number;
}
const PER_PAGE = 20;
const INITIAL_PAGE = 1;

const IntegrationManagement: FC = () => {
    const history = useHistory();
    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();
    const { dealershipId, dealershipGroupId, role, userId } = useAuth();

    const [statusOrders, setStatusOrders] = useState<IStatusOrder>();
    const [loadingOrderStatus, setLoadingOrderStatus] = useState(true);
    const [orders, setOrders] = useState([]);
    const [newOrder, setNewOrder] = useState(false);
    const [clientType, setClientType] = useState("");
    const [cnpjSearch, setCnpjSearch] = useState("");
    const [doc, setDoc] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [orderIdSearch, setOrderNumberSearch] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [orderId, setOrderId] = useState(0);
    const [motivoStatus, setMotivoStatus] = useState("");
    const [codigoStatus, setCodigoStatus] = useState();

    const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
    const [total, setTotal] = useState(0);
    const [isFilterOrder, setIsFilterOrder] = useState(false);

    const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
    const [value, setValue] = useState("");

    const [userDnId, setUserDnId] = useState(0);

    const [allSelected, setAllSelected] = useState<boolean>(false)

    const [batchUpdateItems, setBatchUpdateItems] = useState<any[]>([]);

    const [loadingBatchReprocess, setLoadingBatchReprocess] = useState(false);

    const cleanCpfCnpj = cnpjSearch.replace(onlyNumbersRegex, "");

    useEffect(() => {
        if (!userId && history !== undefined) {
            history.push("/");
        }

        if (!isFilterOrder) {
            setIsLoading(true);
            setLoadingOrderStatus(true);

            api.get(`/dealership-management`, {
                params: {
                    dealershipId,
                    dealershipGroupId,
                    role,
                    status: "1,10,3,6,18,13,",
                    QuantityPerPage: PER_PAGE,
                    Page: true,
                    CurrentPage: currentPage,
                },
            }).then((response) => {
                const { data } = response;

                setTotal(data?.items?.totalAmount);
                setIsLoading(false);
                setOrders(data?.items?.itens);
            });

            // GET Contador
            api.get(`/dealership-management/totals`, {
                params: {
                    dealershipId,
                    dealershipGroupId,
                    role,
                    status: "1,10,3,6,18,13,",
                    QuantityPerPage: PER_PAGE,
                    Page: true,
                    CurrentPage: currentPage,
                },
            }).then((response) => {
                const { data } = response;

                setStatusOrders(data?.orderQuantity);
                setLoadingOrderStatus(false);
            });
        } else {
            api.get(`/dealership-management`, {
                params: {
                    dealershipId,
                    dealershipGroupId,
                    role,
                    cpfCnpj: cleanCpfCnpj,
                    orderId: orderIdSearch,
                    client: clientType,
                    status: "1,10,3,6,18,13,",
                    userDnId,
                    QuantityPerPage: PER_PAGE,
                    Page: true,
                    CurrentPage: currentPage,
                },
            }).then((response) => {
                const { data } = response;

                setTotal(data?.items?.totalAmount);
                setIsLoading(false);
                setOrders(data?.items?.itens);
            });

            // GET Contador
            api.get(`/dealership-management/totals`, {
                params: {
                    dealershipId,
                    dealershipGroupId,
                    role,
                    cpfCnpj: cleanCpfCnpj,
                    orderId: orderIdSearch,
                    client: clientType,
                    status: "1,10,3,6,18,13,",
                    userDnId,
                    QuantityPerPage: PER_PAGE,
                    Page: true,
                    CurrentPage: currentPage,
                },
            }).then((response) => {
                const { data } = response;
                setStatusOrders(data?.orderQuantity);
            });
        }
    }, [currentPage, isFilterOrder, userDnId]);

    const showOrderStatus = (item: any) => {
        localStorage.setItem("@FleetSolutions:userIdCompany", item.userId);

        if (item.clientType === 0) {
            history.push(`/order-status/${item.orderId}`);
        } else {
            history.push(`/order-status-pf/${item.orderId}`);
        }
    };

    const handleClearFilters = () => {
        setCurrentPage(1);
        setNewOrder(false);
        setCnpjSearch("");
        setOrderNumberSearch("");
        setClientType("");
        setOrderNumber("");
        setDoc("");
        setIsFilterOrder(false);

        setOptions([]);
        onChange(undefined);
        setValue("");

        api.get(`/dealership-management`, {
            params: {
                dealershipId,
                dealershipGroupId,
                role,
                status: "1,10,3,6,18,13,",
                QuantityPerPage: PER_PAGE,
                Page: true,
                CurrentPage: INITIAL_PAGE,
            },
        }).then((response) => {
            const { data } = response;

            setTotal(data?.items?.totalAmount);
            setIsLoading(false);
            setOrders(data?.items?.itens);
        });

        // GET Contador
        api.get(`/dealership-management/totals`, {
            params: {
                dealershipId,
                dealershipGroupId,
                role,
                status: "1,10,3,6,18,13,",
                QuantityPerPage: PER_PAGE,
                Page: true,
                CurrentPage: INITIAL_PAGE,
            },
        }).then((response) => {
            const { data } = response;
            setStatusOrders(data?.orderQuantity);
        });
    };

    const handleFilterOrder = async (ct: string) => {
        setClientType(ct);
        setIsFilterOrder(true);

        await api
            .get(`/dealership-management`, {
                params: {
                    dealershipId,
                    dealershipGroupId,
                    role,
                    cpfCnpj: cleanCpfCnpj,
                    orderId: orderIdSearch,
                    client: ct || "",
                    status: "1,10,3,6,18,13,",
                    userDnId,
                    QuantityPerPage: PER_PAGE,
                    Page: true,
                    CurrentPage: INITIAL_PAGE,
                },
            })
            .then((response) => {
                const { data } = response;

                setCurrentPage(INITIAL_PAGE);
                setTotal(data?.items?.totalAmount);
                setIsLoading(false);
                setOrders(data?.items?.itens);
            });

        // GET Contador
        await api
            .get(`/dealership-management/totals`, {
                params: {
                    dealershipId,
                    dealershipGroupId,
                    role,
                    cpfCnpj: cleanCpfCnpj,
                    orderId: orderIdSearch,
                    client: ct || "",
                    status: "1,10,3,6,18,13,",
                    userDnId,
                    QuantityPerPage: PER_PAGE,
                    Page: true,
                    CurrentPage: INITIAL_PAGE,
                },
            })
            .then((response) => {
                const { data } = response;
                setStatusOrders(data?.orderQuantity);
            });
    };

    const handleSubmitSearch = () => {
        console.log("OK");
    };

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const onSearch = (searchText: string) => {
        getUsers(searchText);
    };

    const onSelect = (val: string, option: any) => {
        setIsFilterOrder(true);
        setUserDnId(option.key);
    };

    const onChange = (data: any) => {
        setValue(data);
        if (data === undefined) setUserDnId(0);
    };

    const handleSubmitCancel = async () => {
        try {
            await api
                .post("/orderstatus", {
                    orderId,
                    status: 10,
                })
                .then((response) => {
                    addToast({
                        title: "Sucesso",
                        description: "Requisição processada com sucesso.",
                        type: "success",
                    });
                    handleClearFilters();
                    setVisible(false);
                    // history.go(0);
                })
                .catch((error) => {
                    addToast({
                        title: "Atenção!",
                        description: "Ocorreu um erro ao processar a requisição",
                        type: "error",
                    });
                });
        } catch (error) {
            addToast({
                title: "Atenção!",
                description: "Ocorreu um erro ao processar a requisição",
                type: "error",
            });
            throw error;
        }
    };

    const handleRemove = async (item: any) => {
        setOrderId(item.orderId);
        setCodigoStatus(item.orderStatusId);
        await api.get(`/orderlog/${item.orderId}`).then((response) => {
            const { data } = response;
            if (data) {
                let message = "";
                data.forEach((element: any) => {
                    message += element.message;
                });
                setMotivoStatus(message);
            }
        });
        setVisible(true);
    };

    const getUsers = async (value: string) => {
        try {
            await api
                .get(`/users/dealership-all-users-filter?name=${value}`)
                .then((response) => {
                    const user: any = [];
                    response.data.forEach((element: { userId: any; fullname: any }) => {
                        const item = { value: element.userId, label: element.fullname };
                        user.push(item);
                    });
                    setOptions(user);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const reprocessItem = (item: any) => {
        setOrderId(item.orderId);
        setCodigoStatus(item.orderStatusId);

        handleSubmitCancel();
    };

    const handleVisibleChange = (item: any) => (visible: boolean) => {
        if (visible) {
            setOrderId(item.orderId);
            setCodigoStatus(item.orderStatusId);
        }
    };

    const handleBatchUpdate = (item: any) => (e: CheckboxChangeEvent) => {
        const filteredOrders = orders.filter((order: any) => order.orderStatusId === 10);
        setBatchUpdateItems(batchUpdateItems.length === filteredOrders.length ? [] : filteredOrders);
        setAllSelected(batchUpdateItems.length === filteredOrders.length  ? false : true)
    };

    const handleSingleBatchUpdate = (item: any) => (e: CheckboxChangeEvent) => {
        let batchItems = [...batchUpdateItems];
        const itemIndex = batchItems.findIndex((batchItem) => batchItem.orderId === item.orderId);

        if (itemIndex !== -1) {
            batchItems.splice(itemIndex, 1);
        } else {
            batchItems.push(item);
        }
        setBatchUpdateItems(batchItems);
        setAllSelected(batchUpdateItems.length !== batchItems.length ? false : true)
    };

    const reprocessAll = async () => {
        setLoadingBatchReprocess(true);
        try {
            const promises = batchUpdateItems.map((element) =>
                api.post("/orderstatus", {
                    orderId: element.orderId,
                    status: 10,
                })
            );

            const responses = await Promise.all(promises);
            addToast({
                title: "Sucesso",
                description: "Todas as requisições foram processadas com sucesso.",
                type: "success",
            });
            handleClearFilters();
            setLoadingBatchReprocess(false);
        } catch (error) {
            setLoadingBatchReprocess(false);

            addToast({
                title: "Atenção!",
                description: "Ocorreu um erro ao processar as requisições",
                type: "error",
            });
            console.error("Ocorreu um erro ao processar as requisições:", error);
        }
    };

    return (
        <>
            <Header />

            <Container>
                <h1 data-testid="title">GESTÃO DE INTEGRAÇÕES</h1>
                <WrapperStatus>
                    {loadingOrderStatus ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="status">
                                <div className="box-finance" style={{ paddingTop: "15px" }}>
                                    <p>{statusOrders?.ordersCreditAnalysis}</p>
                                </div>
                                <p style={{ paddingTop: "8px" }}>Processados</p>
                            </div>
                            <div className="status">
                                <div className="box-finance" style={{ paddingTop: "15px" }}>
                                    <p>{statusOrders?.ordersAwaitingContractSigning}</p>
                                </div>
                                <p style={{ paddingTop: "8px" }}>Não processados</p>
                            </div>
                        </>
                    )}
                </WrapperStatus>

                <WrapperTable data-testid="el_wrapper_table">
                    <Form onSubmit={handleSubmitSearch} ref={formRef}>
                        <div>
                            <div>
                                <label>Documento</label>
                            </div>
                            <div className="wrapper-input">
                                <Input
                                    placeholder="Digite o documento"
                                    id="doc"
                                    data-testid="document"
                                    name="doc"
                                    type="text"
                                    value={doc}
                                    onChange={(e) => {
                                        setCnpjSearch(e.target.value);
                                        setDoc(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleFilterOrder("");
                                        }
                                    }}
                                />
                                <img onClick={() => handleFilterOrder("")} src={isonSearch} alt="Search button" />
                            </div>
                        </div>
                        <div className="div-filtros">
                            <label htmlFor="clientType">Pessoa</label>
                            <Select onChange={(e) => handleFilterOrder(e.target.value)} value={clientType} name="clientType" data-testid="client_type">
                                <option value=""> Todos</option>
                                <option value="1">Física</option>
                                <option value="0">Jurídica</option>
                            </Select>
                        </div>
                        <div>
                            <div>Pedido</div>
                            <div className="wrapper-input">
                                <Input
                                    data-testid="order_number"
                                    placeholder="Número do pedido"
                                    id="number-order"
                                    name="number-order"
                                    type="text"
                                    style={{ width: "150px" }}
                                    value={orderNumber}
                                    onChange={(e) => {
                                        setOrderNumberSearch(e.target.value);
                                        setOrderNumber(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleFilterOrder("");
                                        }
                                    }}
                                />

                                <img onClick={() => handleFilterOrder("")} src={isonSearch} alt="Search button" />
                            </div>
                        </div>
                        <div className="div-filtros">
                            <label htmlFor="clientType">Usuário</label>
                            <AutoComplete style={{ width: 250, color: "#8994a0" }} className="select-auto" onSelect={(key, option) => onSelect(key, option)} onSearch={onSearch} onChange={(option) => onChange(option)} placeholder="Digite o usuário" allowClear={true} value={value}>
                                {options.map((option, i) => (
                                    <Option key={option.value} value={option.label}>
                                        {option.label}
                                    </Option>
                                ))}
                            </AutoComplete>
                        </div>

                        <div style={{ textAlign: "right", height: "5px", paddingTop: "10px" }}>
                            {
                                // eslint-disable-next-line
                                <a href="#" onClick={() => handleClearFilters()}>
                                    Limpar filtros
                                </a>
                            }
                        </div>
                    </Form>
                    <div className="batch-update">
                        <AntButton loading={loadingBatchReprocess} onClick={reprocessAll} disabled={batchUpdateItems.length === 0} type="primary">
                            Reprocessar pedidos selecionados
                        </AntButton>
                    </div>
                    <div style={{ overflowX: "auto" }}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Pedido</th>
                                    <th>Segmento</th>
                                    <th>Tipo</th>
                                    <th>Nome/Razão Social</th>
                                    <th>Documento</th>
                                    <th>Data de Inclusão</th>
                                    <th>Data de Status</th>
                                    <th>Valor Total</th>
                                    <th>Status</th>
                                    <th>Ações</th>
                                    <th>
                                        <Checkbox checked={allSelected} onChange={(e) => handleBatchUpdate("all")(e)}></Checkbox>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.length && !isLoading ? (
                                    orders.map((item: any, i: number) => {
                                        return (
                                            <tr key={i} style={{ cursor: "pointer" }}>
                                                <td onClick={() => showOrderStatus(item)}>
                                                    {item?.prefix}
                                                    {item.orderId}
                                                </td>
                                                <td onClick={() => showOrderStatus(item)}>{item?.segmentName}</td>
                                                <td onClick={() => showOrderStatus(item)}>{item.clientType === 0 ? "Jurídica" : "Física"}</td>
                                                <td onClick={() => showOrderStatus(item)}>{item.name}</td>
                                                <td onClick={() => showOrderStatus(item)}>{item.clientType === 0 ? cnpjMask(item.cpfCnpj) : cpfMask(item.cpfCnpj)}</td>
                                                <td onClick={() => showOrderStatus(item)}>{formatDate(item.dateCreated)}</td>
                                                <td onClick={() => showOrderStatus(item)}>{formatDate(item.dateLastUpdated)}</td>
                                                <td onClick={() => showOrderStatus(item)}>{formatValue(item.totalOrder)}</td>
                                                <td>
                                                    <Link to="#" onClick={() => handleRemove(item)}>
                                                        {item.orderStatus}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Dropdown
                                                        overlay={
                                                            <Menu>
                                                                <Menu.Item key="1" onClick={() => handleRemove(item)}>
                                                                    Ver logs
                                                                </Menu.Item>
                                                                {codigoStatus === 10 && (
                                                                    <Menu.Item key="2" onClick={() => reprocessItem(item)}>
                                                                        Reprocessar
                                                                    </Menu.Item>
                                                                )}
                                                            </Menu>
                                                        }
                                                        trigger={["click"]}
                                                        onVisibleChange={handleVisibleChange(item)}
                                                    >
                                                        <AntButton>
                                                            <Space>
                                                                Ações
                                                                <DownOutlined />
                                                            </Space>
                                                        </AntButton>
                                                    </Dropdown>
                                                </td>
                                                <td>{item.orderStatusId === 10 ? <Checkbox checked={batchUpdateItems.find((batchItem) => batchItem.orderId === item.orderId)} onChange={handleSingleBatchUpdate(item)}></Checkbox> : <> - </>}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        {isLoading ? (
                                            <td colSpan={8}>
                                                <WrapperLogo>
                                                    <Loading />
                                                </WrapperLogo>
                                            </td>
                                        ) : (
                                            <td colSpan={8}> Nenhum registro encontrado! </td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                        <PaginationList paginate={paginate} currentPage={currentPage} total={total} perPage={PER_PAGE} />
                        <br />
                    </div>
                </WrapperTable>
            </Container>

            <Footer />

            <Modal visible={visible} onCancel={() => setVisible(false)} footer="">
                <ContentModal>
                    <h3>Status do Pedido</h3>

                    <p>{motivoStatus}</p>

                    <SelectSegmentButtons>
                        {codigoStatus === 10 && (
                            <Button
                                onClick={() => {
                                    handleSubmitCancel();
                                }}
                            >
                                Reprocessar
                            </Button>
                        )}
                        <Button
                            onClick={() => {
                                setVisible(false);
                            }}
                            style={{
                                backgroundColor: "GrayText",
                            }}
                        >
                            Cancelar
                        </Button>
                    </SelectSegmentButtons>
                </ContentModal>
            </Modal>
        </>
    );
};

export default IntegrationManagement;
