import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker, ColorResult } from 'react-color';

interface SimpleColorPickerProps {
  label: string;
  id: string;
  name: string;
  value: string; // A cor inicial, por exemplo, '#ffffff'
  onChange: (event: { target: { name: string; value: string } }) => void;
  error?: string;
}

class SimpleColorPicker extends React.Component<SimpleColorPickerProps> {
  state = {
    displayColorPicker: false,
    color: this.props.value || '#000000', // Inicializa com a cor em formato hexadecimal
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color: ColorResult) => {
    this.setState({ color: color.hex }); // Atualiza o estado com a cor hexadecimal
    this.props.onChange({ target: { name: this.props.name, value: color.hex } }); // Passa a cor hexadecimal para o onChange
  };

  render() {
    const styles: { [key: string]: React.CSSProperties } = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: this.state.color, // Usa a cor hexadecimal para o background
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute' as const,
          zIndex: 2,
          top: '10px',
          left: '0px',
        },
        cover: {
          position: 'fixed' as const,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        pickerContainer: {
          position: 'relative',
          display: 'inline-block',
        }
      },
    });

    return (
      <div className="wrapper-info">
        <label htmlFor={this.props.id}>{this.props.label}</label>
        <div style={styles.pickerContainer}>
          <div style={styles.swatch} onClick={this.handleClick}>
            <div style={styles.color} />
          </div>
          {this.state.displayColorPicker ? (
            <div style={styles.popover}>
              <div style={styles.cover} onClick={this.handleClose} />
              <SketchPicker color={this.state.color} onChange={this.handleChange} />
            </div>
          ) : null}
        </div>
        {this.props.error && <span className="error">{this.props.error}</span>}
      </div>
    );
  }
}

export default SimpleColorPicker;
