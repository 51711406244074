/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { Container, Content } from './styles'
import Button from '../../components/Button'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'
import { setTabs } from '../../store/modules/tabsRegisterUser/actions'
import { useCallback } from 'react'
// import { useAuth } from 'hooks/auth'
import UploadDocOther from 'components/UploadDocOther'

interface IProductsOrder {
  quantity: number
  productId: number
  cityForPickup: string
  uf: string
}

const UploadFileOthers = () => {
  // const { vendedorMaster } = useAuth()
  const history = useHistory()
  const { isCompany, orderId } = useParams<any>()
  const dispatch = useDispatch()
  const { addToast } = useToast()
  const [numDocs, setNumDocs] = useState(1)

  const [location, setLocation] = useState<IProductsOrder[]>([])
  const [companyType, setCompanyType] = useState(0)

  const [disabledOrder, setDisabledOrder] = useState(false)

  const [downloadDocOthers, setDownloadDocOthers] = useState('')

  const userIdCompany = Number(
    localStorage.getItem('@FleetSolutions:userIdCompany')
  )

  useEffect(() => {
    searchCompany()
    const locationLocal = localStorage.getItem('@FleetSolutions:location')
    localStorage.setItem('@FleetSolutions:uploadFileDocumentoUser', 'false')

    if (locationLocal) {
      const locationStorage = JSON.parse(locationLocal)

      setLocation(locationStorage)
    }
  }, [])

  const searchCompany = async () => {
    const cnpjCompany = localStorage.getItem('@FleetSolutions:cnpjCompany')

    if (cnpjCompany) {
      const cnpjRemovedMask = cnpjCompany.replace(/\D/g, '')

      await api.get(`serasa/${cnpjRemovedMask}`).then((res: any) => {
        setCompanyType(res.data.companyType)
      })
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    getFiles()
  }, [])

  const getFiles = () => {
    api.get(`/document-company-upload/${userIdCompany}`).then((res: any) => {
      const { others } = res.data

      if (isCompany) return

      if (others !== null) {
        setDownloadDocOthers(others)
      }
    })
  }

  const handleContinueOrder = useCallback(async () => {
    setDisabledOrder(true)
    handleSendOrder()
  }, [addToast, companyType, history, location, userIdCompany])

  const handleSendOrder = async () => {
    // if (isCompany) {
    return dispatch(setTabs({ id: '2' }))
    // }

    // await api
    //   .post('/dealership-management', {
    //     products: location,
    //     userId: userIdCompany,
    //     dealershipId: Number(
    //       localStorage.getItem('@FleetSolutions:dealershipId')
    //     ),
    //     dealershipGroupId: Number(
    //       localStorage.getItem('@FleetSolutions:dealershipGroupId')
    //     ),
    //     note: localStorage.getItem('@FleetSolutions:note'),
    //     vendedorMaster: vendedorMaster
    //   })
    //   .then((res: any) => {
    //     addToast({
    //       title: 'Pedido criado com sucesso!',
    //       type: 'success',
    //       description: 'Seu pedido foi criado com sucesso.'
    //     })

    //     localStorage.removeItem('@FleetSolutions:cart')
    //     localStorage.removeItem('@FleetSolutions:location')
    //     localStorage.removeItem('@FleetSolutions:userIdCompany')
    //     localStorage.removeItem('@FleetSolutions:note')
    //     setDisabledOrder(false)

    //     history.push('/my-orders')
    //   })
    //   .catch(({ response }: any) => {
    //     if (response.status !== 422) {
    //       addToast({
    //         title: 'Ocorreu um erro!',
    //         type: 'error',
    //         description: response.data
    //       })
    //       return
    //     }
    //     const outOfStockProductsData = response.data?.OutOfStockProducts
    //     const getProductsWithStock = (
    //       products: Array<IProductsOrder>,
    //       outOfStockProducts: Array<{
    //         ProductId: number
    //         QuantityStock: number
    //       }>
    //     ) => {
    //       return products.reduce(
    //         (withStock: Array<IProductsOrder>, product: IProductsOrder) => {
    //           const isOutOfStock = (outOfStock: {
    //             ProductId: number
    //             QuantityStock: number
    //           }) => {
    //             return product.productId === outOfStock.ProductId
    //           }
    //           const firstOutOfStok = outOfStockProducts.find(isOutOfStock)
    //           if (firstOutOfStok === undefined) return [...withStock, product]
    //           const currentItemAdded = withStock.filter(
    //             (item) => item.productId === product.productId
    //           )
    //           const hasQuantityInStock =
    //             currentItemAdded.length < firstOutOfStok.QuantityStock
    //           return hasQuantityInStock ? [...withStock, product] : withStock
    //         },
    //         []
    //       )
    //     }
    //     const locationWithStock = getProductsWithStock(
    //       location,
    //       outOfStockProductsData
    //     )
    //     setLocation(locationWithStock)
    //   })
  }

  const handleBackPage = () => {
    if (orderId) {
      return history.goBack()
    }
    dispatch(setTabs({ id: '3' }))
  }

  const renderUploadDocument = () => {
    return (
      <>
        <UploadDocOther
          numberDoc={12}
          downloadDoc={downloadDocOthers}
          docName="Outros"
          description=""
          fileType="Arquivos (JPG, PNG ou PDF) até 5 mb"
          typeDocName="outros"
        />
        <div className="wrapper-btn">
          <Button
            type="button"
            className="btn-add"
            onClick={() => setNumDocs(numDocs + 1)}
          >
            + Adicionar
          </Button>
        </div>
      </>
    )
  }

  return (
    <Container>
      <h1 className="title">Carregar documentos</h1>
      <p className="description">
        Por favor, inclua os documentos abaixo do cliente para seguirmos a
        validação do cadastro.
      </p>
      <Content>
        {Array.from(Array(numDocs), () => renderUploadDocument())}
      </Content>
      <div className="wrapper-btn">
        <Button type="button" className="btn-back" onClick={handleBackPage}>
          Voltar
        </Button>
        <Button
          disabled={disabledOrder}
          className="btn-continue"
          onClick={handleContinueOrder}
        >
          CONTINUAR
        </Button>
      </div>
    </Container>
  )
}

export default UploadFileOthers
