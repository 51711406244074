import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .content-to-print {
    display: none;
  }

  
  &.printable{
    padding-left:15px;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    background:#fff;
    display:none;
    *{
      pageBreakInside: avoid;
    }
    img{
      width: 450px;
      display:block;
      margin-bottom:25px;
    }
    .infos-group{
      display: block;
      margin-bottom:30px;
      .title{
        font-size: 22px;
        font-weight: bold;
        border-bottom:1px solid;
        margin-bottom:5px;
        padding-bottom: 5px;
        color:#000;
        text-align:left;
      }
      .items{
        .item{
          display: flex;
          gap: 9px;
          font-size: 18px;
          span{
            font-weight: bold;
          }
        }
      }
      &.resume{
        margin-bottom: 25px;
        padding-bottom:25px;
        border-bottom:1px solid;
        .items{
          .item{
            display:block;
            pageBreakInside: avoid;
            ul{
              list-style:none;
            }
          }
        }
      }
    }
    @media print {
      display:flex;
    }
  }
  .wrapper-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .btn-print {
      border: transparent;
      background: transparent;
      color: #236fbc;
      width: auto;
      display: flex;
      align-items: flex-end;
      margin-bottom: 5px;
      width: auto;
      align-items: center;
    }

    .print {
      display: flex;
font-size: 14px;
    align-items: center;
      svg {
        margin-right: 8px;
      }
    }
  }

  .buttons {
    width: 82%;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    border-radius: 24px;
    flex-wrap: wrap;

    button {
      width: 190px;
      height: 41px;
      font-size: 12px;
    }

    .btn-back {
      background-color: #fff;
      color: #236fbc;
      border: 2px solid #226fbb;
    }
  }

  @media (max-width: 600px) {
    .buttons {
      justify-content: center;
      flex-direction: column-reverse;
      align-items: center;
    }

    .wrapper-actions {
      flex-direction: column;
      width: auto;
    }
  }

  @media print {
    .ffMYBn,
    button,
    .colors,
    .spn-menu,
    .none,
    .logo {
      display: none;
    }

    .content-to-print {
      display: block;

      p {
        margin-bottom: 8px;
      }
    }

    .bSJtJK {
      h1,
      p {
        display: flex;
      }
    }

    .dFKWWp {
      flex-direction: column;

      .info-car {
        h1 {
          font-size: 16px;
        }

        .info-offer {
          flex-direction: row;
        }
      }
    }

    img {
      margin: 30px;
      width: 192px !important;
      height: 98px !important;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 40px auto;
  align-items: center;
  font-family: 'Open Sans';
  border-bottom: 1px solid #707070;
  padding-bottom: 16px;
  position:relative;
  
  .loader{
    position: absolute;
    background: #ffffffa8;
    width: 100%;
    height: 100%;
    display: flex;
    z-index:2;
  }

  p {
    color: #0a0a0a;
  }

  img {
    margin-right: 20px;
    width: 192px;
    height: fit-content;
  }

  .info-important {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    p {
      margin: 4px;
    }

    .value-total {
      font-size: 24px;
      color: #236fbc;
      font-weight: bold;
    }

    .unitary-value {
      font-size: 21px;
      color: #484848;
      font-weight: bold;
    }

    .title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: -2px;
    }

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;

      p,
      span {
        margin-right: 10px;
        font-size: 12px;
      }

      span {
        font-weight: bold;
      }
    }
  }

  .info-deadline {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    p {
      margin: 4px;
    }

    .title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: -2px;
    }

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;

      p,
      span {
        margin-right: 10px;
        font-size: 12px;
      }

      span {
        font-weight: bold;
      }
    }
  }
`

export const DetailsCar = styled.div`
  display: flex;
  flex-direction: row;

  .options-offers {
    margin-top: 15px;
    min-width: 220px;

    p {
      margin: 0;
    }
  }

  .options-offers-audi {
    margin-top: 15px;
    min-width: 220px;
    width: 560px;

    p {
      margin: 0;
    }

    .select-item {
      margin: 0;
      padding: 5px;
      width: 100%;

      .ant-select {
        width: 100% !important;
      }
    }

    .items-table {
      width: 100%;
      td:first-child {
        width: 100%;
      }
    }

    .delete-button {
      width: 18px;
      img {
        width: 14px;
      }
    }
  }

  .info-car {
    h1 {
      font-size: 24px;
      margin-top: -10px;
    }

    .info-offer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .options,
      .button {
        margin-right: 17px;
      }

      .options,
      .colors {
        p {
          margin-bottom: 6px;
        }
      }

      .button-quantity {
        text-align: center;
        margin: 0 20px;

        p {
          margin-bottom: 6px;
          font-weight: 600;
        }
      }

      .wrapper-quantity {
        width: 122px;
        height: 41px;
        background: #f7f7f7;
        border: 2px solid #005faf;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        border-radius: 23px;

        span {
          font-size: 15px;
          color: #070707;
          font-weight: bold;
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          color: #005faf;
          background: #f7f7f7;
          font-size: 26px;
          padding: 0;
          margin: 0;
        }
      }

      .stock {
        color: #999;
        font-size: 12px;
        margin-top: 5px;
        text-align: center;
      }
    }
  }

  .colors {
    color: #070707;
    margin-right: 18px;

    div:last-child {
      width: 181px !important;
    }

    p {
      padding: 0;
    }
    img {
      margin-right: 5px;
      border-radius: 50%;
      width: 33px;
      height: 33px;
      padding: 2px;
    }

    .color-name {
      width: 100px;
      padding: 0;
      font-size: 12px;
      word-break: break-word;
    }

    .color-options {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 600px) {
    .info-car {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        text-align: center;
      }

      .info-offer {
        flex-direction: column;
        align-items: baseline;

        .button-quantity {
          margin: 16px;
        }
      }
    }
  }
         .custom-optionals{
    width:100%;
    max-height:650px;
    overflow-x:scroll;
    .head-group{
      display: flex;
      gap: 6px;
    }
    span{
      font-size:12px;
    }
      h4{
      font-size:14px
      }
    .optional-group{
    border: 1px solid #999999;
    margin-bottom: 15px;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 0px 6px #e9e9e9;
      &.selected{
        border-color:#236fbc;
        h4{
          color:#236fbc;
        }
      }
    }
  }
`

export const Summary = styled.div`
  border: 3px solid #236fbc;
  width: 82%;
  margin: 20px auto;
  border-radius: 32px;

  h3 {
    font-size: 25px;
    color: #236fbc;
    font-weight: 500;
    margin: 20px;
  }

  .stock-availability {
    margin: -24px 20px 0;
    font-size: 15px;
    font-weight: 500;
  }

  .longer-term,
  .monthly,
  .credit {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
  }

  .monthly {
    border-bottom: 2px solid #707070;
  }
`

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 275px;
  justify-content: center;

  h3 {
    font-size: 21px;
    color: #0c5faf;
    font-weight: 500;
  }

  p {
    margin-bottom: 40px;
  }

  input {
    border: 2px solid #cfd7d9;
    border-radius: 30px;
    width: 256px;
    height: 41px;
  }

  button {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }
`
