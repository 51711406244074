/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react'
import { Button, Input, Select } from 'antd'

import * as S from './styles'
import Header from 'components/Header'
import Footer from 'components/Footer'
import api from 'services/api'
import {
  SEGMENTS,
  KICKBACK,
  DEALERSHIP_SEGMENTS,
  KICKBACK_COMMISSIONING
} from 'utils/urls'
import { useToast } from 'hooks/toast'
import { useHistory, useParams } from 'react-router-dom'
import Loading from 'components/Loading'

interface IKickbackItem {
  id: number
  dealershipsId: []
  kickbacks: []
  name: string
  segments: []
}

interface ISegment {
  id: number
  iD_Fleet: number
  iD_Ibratan: string
  name: string
  prefix: string
  type: string
  status: boolean
  diasExpirar: number
  createDate: string
  updateDate: string
  deleteDate: string
  orderSummary: string
  emailFrom: string
  emailHost: string
  emailPort: number
  emailUser: string
  emailPwd: string
  templateUnico: string
  clientId: string
  signerEmail: string
  impersonatedUserId: string
  privateKeyFile: string
  filesContract: []
}

interface IDealerships {
  id: number
  name: string
  addressNumber: string
  city: string
  cnpj: string
  createDate: string
  dealershipGroupID: number
  domainList: []
  domains: string
  email: string
  listSegments: string
  number: string
  segments: string
  status: boolean
  street: string
  uf: string
}

const CreateUpdateCommissioning: FC = () => {
  const history = useHistory()
  const { id } = useParams<any>()
  const { addToast } = useToast()

  const [segment, setSegment] = useState<ISegment[]>([])
  const [kickBack, setKickBack] = useState([])
  const [dealerships, setDealerships] = useState<IDealerships[]>([])

  const [segmentSelected, setSegmentSelected] = useState<[]>([])
  const [commissionSelected, setCommissionSelected] = useState<[]>([])
  const [dealershipsSelected, setDealershipsSelected] = useState<[]>([])
  const [nameCommission, setNameCommission] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const loadDealerships = async () => {
    await api
      .get(DEALERSHIP_SEGMENTS)
      .then(({ data }) => {
        setDealerships(data)
      })
      .catch(() => {
        addToast({
          type: 'info',
          title: `Informação`,
          description: 'Não existem dados de comissionamentos.'
        })
      })
  }

  const loadSegments = async () => {
    await api
      .get(SEGMENTS)
      .then(({ data }) => {
        const segments = data.filter((item: ISegment) => item.status === true)
        setSegment(segments)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível listar os segmentos`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
  }

  const loadKickBack = async () => {
    await api
      .get(KICKBACK)
      .then(({ data }) => {
        setKickBack(data)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível listar os segmentos`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
  }

  const loadData = async () => {
    setIsLoading(true)
    await loadSegments()
    await loadKickBack()
    await loadDealerships()
    if (id) handleSetUpdate()
    setIsLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const handleBack = () => {
    history.push(`/commissioning`)
  }

  const convertToNumberArray = (stringArray: any) => {
    return stringArray.map((str: string) => parseInt(str, 10))
  }

  const convertToStringArray = (stringArray: any) => {
    return stringArray.map((str: number) => String(str))
  }

  const handleUpdateModal = async () => {
    if (
      !nameCommission ||
      segmentSelected.length === 0 ||
      commissionSelected.length === 0 ||
      dealershipsSelected.length === 0
    ) {
      addToast({
        type: 'error',
        title: `Atenção campos obrigatórios`,
        description: 'Todos os campos são de preenchimento obrigatório.'
      })
      return
    }

    const dataToSend = {
      name: nameCommission,
      status: true,
      segments: segmentSelected,
      kickbacks: commissionSelected,
      dealershipsId: convertToNumberArray(dealershipsSelected)
    }

    await api
      .put(`${KICKBACK_COMMISSIONING}/${id}`, dataToSend)
      .then(() => {
        addToast({
          type: 'success',
          title: `Sucesso na alteração dos dados`,
          description: 'Dados atualizados com sucesso.'
        })
        history.push(`/commissioning`)
      })
      .catch((error) => {
        const errorMessage = error.response?.data || 'Por favor, entre em contato com o suporte.';
        addToast({
          type: 'error',
          title: `Não foi possível alterar os dados`,
          description: errorMessage
        })
        return 
      })
  }

  const handleSetUpdate = async () => {
    await api
      .get(`${KICKBACK_COMMISSIONING}/${id}`)
      .then(({ data }) => {
        const result = data as IKickbackItem
        setNameCommission(data.name)
        handleChangeSelectSegment(result.segments)
        setSegmentSelected(result.segments)
        setCommissionSelected(result.kickbacks)
        setDealershipsSelected(convertToStringArray(result?.dealershipsId))
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível trazer os dados`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
  }

  const handleAddModal = async () => {
    if (
      !nameCommission ||
      segmentSelected.length === 0 ||
      commissionSelected.length === 0 ||
      dealershipsSelected.length === 0
    ) {
      addToast({
        type: 'error',
        title: `Atenção campos obrigatórios`,
        description: 'Todos os campos são de preenchimento obrigatório.'
      })
      return
    }

    const dataToSend = {
      name: nameCommission,
      status: true,
      segments: segmentSelected,
      kickbacks: commissionSelected,
      dealershipsId: convertToNumberArray(dealershipsSelected)
    }

    await api
      .post(KICKBACK_COMMISSIONING, dataToSend)
      .then(() => {
        addToast({
          type: 'success',
          title: `Sucesso na gravação`,
          description: 'Dados gravados com sucesso.'
        })
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível trazer os dados`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
    history.push(`/commissioning`)
  }

  const handleChangeSelectCommission = (value: []) => {
    setCommissionSelected(value)
  }

  const handleChangeSelectDealerships = (value: []) => {
    setDealershipsSelected(value)
  }

  const handleChangeSelectSegment = (value: []) => {
    api
      .get(`${DEALERSHIP_SEGMENTS}?segments=${value.join(',')}`)
      .then(({ data }) => {
        setDealerships(data)
      })
      .catch(() => {
        addToast({
          type: 'info',
          title: `Informação`,
          description: 'Não existem dados de comissionamentos.'
        })
      })
    api
      .get(`${KICKBACK}?segments=${value.join('|')}`)
      .then(({ data }) => {
        setKickBack(data)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível listar os segmentos`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
  }

  return (
    <S.Container>
      <Header />

      <h1 data-testid="title_page" className="title">
        Comissionamento
      </h1>
      <p className="title-message">
        {id ? 'Alterar grupo' : 'Adicionar grupo'}
      </p>

      {isLoading ? (
        <>
          <Loading />

          <p className="message-wait">Aguarde...</p>
        </>
      ) : (
        <S.WrapperButtonsModal>
          <S.Wrapper>
            <span>Grupo</span>

            <Input
              placeholder="Nome do Grupo"
              id="grupo"
              name="grupo"
              type="text"
              value={nameCommission}
              onChange={(e) => {
                setNameCommission(e.target.value)
              }}
              style={{
                width: 290
              }}
            />
          </S.Wrapper>

          <S.Wrapper>
            <span>Segmentos</span>

            <Select
              id="segmentSelect"
              className="select-segment input-medium"
              placeholder="Segmentos"
              data-testid="segment"
              mode="multiple"
              allowClear
              value={segmentSelected}
              onChange={(value: []) => {
                setSegmentSelected(value)
                handleChangeSelectSegment(value)
              }}
            >
              {segment.map(({ id, iD_Fleet, name }) => (
                <Select.Option key={id} value={String(iD_Fleet)}>
                  {`${iD_Fleet} - ${name}`}
                </Select.Option>
              ))}
            </Select>
          </S.Wrapper>

          <S.Wrapper>
            <span>Comissões</span>

            <Select
              id="segmentCommission"
              className="select-segment input-medium"
              placeholder="Comissões"
              data-testid="commission"
              mode="multiple"
              allowClear
              value={commissionSelected}
              onChange={handleChangeSelectCommission}
            >
              {kickBack.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </S.Wrapper>

          <S.Wrapper>
            <span>Concessionárias</span>

            <Select
              id="segmentConcessionaires"
              className="select-segment input-medium"
              placeholder="Concessionárias"
              data-testid="concessionaires"
              mode="multiple"
              allowClear
              value={dealershipsSelected}
              onChange={handleChangeSelectDealerships}
            >
              {dealerships.map(({ id, name }) => (
                <Select.Option key={id} value={String(id)}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </S.Wrapper>

          <S.WrapperButtonContainer>
            <S.WrapperButtons>
              <Button data-testid="btn-return-modal" onClick={handleBack}>
                Voltar
              </Button>
            </S.WrapperButtons>
            <S.WrapperButtons>
              <S.ButtonsRight>
                <Button
                  data-testid="btn-add-modal"
                  className="btn-add"
                  onClick={id ? handleUpdateModal : handleAddModal}
                >
                  {id ? 'Alterar' : 'Adicionar'}
                </Button>
              </S.ButtonsRight>
            </S.WrapperButtons>
          </S.WrapperButtonContainer>
        </S.WrapperButtonsModal>
      )}

      <Footer />
    </S.Container>
  )
}

export default CreateUpdateCommissioning
