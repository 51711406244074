import styled from 'styled-components'

export const Container = styled.div`
  flex-direction: column;
  margin: 0 auto;
  display: flex;

  .ant-pagination {
    position: absolute;
    bottom: 0;
    right: 95px;
    display: flex;
    justify-content: flex-end;

    button {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .header-filter {
    display: flex;
    margin: 40px;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    flex-wrap: wrap;

    img,
    p {
      margin: 10px;
    }

    img {
      cursor: pointer;
      width: 30px;
    }

    p {
      font-size: 24px;
      color: #000;
      letter-spacing: -0.22px;
    }

    .wrapper-filter {
      display: flex;

      p {
        font-size: 22px;
      }
    }

    .wrapper-title {
      align-items: center;
      text-align: center;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      height: auto;

      label,
      h1 {
        color: #3c505a;
        font-size: 25px;
      }

      label {
        font-size: 16px;
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 550px) {
    .header-filter {
      .wrapper-title {
        width: 100%;
        margin-top: 15px;

        h1 {
          font-size: 20px;
        }

        .wrapper-select {
          position: absolute;
          right: 0;
          margin-top: 80px;
          right: 25px;
        }
      }
    }

    .ant-pagination {
      position: inherit;
      margin: 40px auto;
    }
  }
`

export const Content = styled.div`
  display: flex;
  margin-bottom: 250px;
  flex-direction: row;

  .drawer {
    div {
      position: relative;
      z-index: auto;
    }

    .MuiDrawer-paperAnchorDockedLeft {
      border-right: 0;
      height: auto;
    }

    @media (max-width: 800px) {
      position: absolute;
    }
  }

  @media (max-width: 440px) {
    margin-bottom: 0;

    .drawer {
      width: 100% !important;

      div {
        padding: 0;
      }
    }
  }
`

export const WrapperRange = styled.div`
  width: 260px;
  margin: 20px 10px 20px 20px;
  padding: 15px;

  span {
    color: #0c5faf;
  }

  .ant-slider-track {
    height: 10px;
    margin-top: -5px;
    background: #0c5faf;
  }

  .ant-slider-handle {
    margin-top: -15px;
  }

  .ant-slider-dot {
    opacity: 0;
  }

  .value-investiment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 11px;
  }
`

export const Filter = styled.div`
  width: 312px;
  margin-top: -25.5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;

  hr {
    margin-left: 25px;
  }

  .model,
  .color {
    margin: 30px;

    p {
      span {
        text-align: left;
        font: normal normal bold 22px Arial;
        color: #0c5faf;
      }

      img {
        margin-right: 22px;
      }
    }

    label {
      margin: 14px;
      font-size: 16px;
    }
  }

  .color {
    display: flex;
    flex-direction: column;
  }

  .option-filter {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: 60px;
    margin: 30px;

    img {
      margin-right: 20px;
    }

    p {
      text-align: left;
      font: normal normal normal 17px/20px Arial;
      letter-spacing: 0px;
      color: #0c5faf;
      opacity: 1;
      margin: 0;
    }
  }
`

export const WrapperCards = styled.div`
  align-items: center;
  margin: 0px 47px 20px 47px;
  width: 100%;

  h1 {
    text-align: center;
    color: #3d4f59;
    font-size: 25px;
    margin-top: -50px;
  }

  ul {
    position: initial !important;
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 16px;
    }
  }
`

export const Cards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`

export const Cart = styled.div`
  max-width: 331px;
  width: 100%;
  box-shadow: 0px -6px 6px #00000029;
  height: 125px;
  position: fixed;
  z-index: 9999;
  right: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  bottom: 0px;

  span {
    font-weight: 600;
    font-size: 15px;
  }

  button {
    background: #3c505a;
    width: 227px;
    height: 41px;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
  }
`
