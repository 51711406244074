import React from 'react'
import { Pagination as PaginationAntd } from 'antd'

import { Container } from './styles'

interface IPagination {
  total: number
  paginate: any
  currentPage: number
  perPage: number
  hideOnSinglePage?: boolean
}

const PaginationList = ({
  paginate,
  total,
  currentPage,
  perPage,
  hideOnSinglePage = false
}: IPagination) => {
  const handlePaginate = (item: number) => {
    paginate(item)
  }

  return (
    <Container>
      <PaginationAntd
        current={currentPage}
        total={total}
        onChange={(e) => handlePaginate(e)}
        defaultPageSize={perPage}
        showSizeChanger={false}
        hideOnSinglePage={hideOnSinglePage}
      />
    </Container>
  )
}

export default PaginationList
