import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteChangeTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if(localStorage.getItem('@FleetSolutions:editData') && !location.pathname.includes('/offers/pf/') && !location.pathname.includes('/offer/')){
        localStorage.removeItem('@FleetSolutions:editData')
    }
  }, [location]);

  return null;
};

export default RouteChangeTracker;
