/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import 'antd/dist/antd.css'
import { Content, Table, WrapperLogo } from './styles'
import { useHistory } from 'react-router-dom'

import api from '../../services/api'

import { useAuth } from '../../hooks/auth'
import Loading from 'components/Loading'
import { formatDateTime } from 'utils/formatDateTime'

interface Items {
  orderStatusId: number
  orderId: number
  userId: number
  status: number
  description: string
  note: string
  dateCreated: string
  userCancellation: string
  justifyCancellation: string
  userLoggedId: number
  userLoggedName: string
}

type OrderHistoryViewProps = {
  id: number
}

const OrderHistoryView = ({ id }: OrderHistoryViewProps) => {
  const { userId, dealershipId } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()

  const [items, setItems] = useState<Items[]>([])

  useEffect(() => {
    if (!userId || !dealershipId) {
      history.push('/')
    }
    try {
      api
        .get(`/orderstatus/${id}`)
        .then((res) => {
          res.data.sort(
            (a: { orderStatusId: number }, b: { orderStatusId: number }) => {
              return a.orderStatusId < b.orderStatusId
                ? -1
                : a.orderStatusId > b.orderStatusId
                ? 1
                : 0
            }
          )
          setItems(res.data)
          setIsLoading(false)
        })
        .catch((err) => {
          setIsLoading(false)
        })
    } catch (err) {
      setIsLoading(false)
    }
  }, [])

  return (
    <>
      <Content>
        <div style={{ width: '100%' }}>
          <Table>
            <thead>
              <tr>
                <th>Data/Hora</th>
                <th>Status</th>
                <th>Observações</th>
                <th>Usuário</th>
              </tr>
            </thead>
            <tbody>
              {items.length && !isLoading ? (
                items.map((item: any, i: number) => {
                  return (
                    <tr key={i} style={{ cursor: 'pointer' }}>
                      <td>{formatDateTime(item.dateCreated)}</td>
                      <td>
                        {item.status} - {item.description}
                      </td>
                      <td>{item.note}</td>
                      <td>{item.userLoggedName}</td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  {isLoading ? (
                    <td colSpan={8}>
                      <WrapperLogo>
                        <Loading />
                      </WrapperLogo>
                    </td>
                  ) : (
                    <td colSpan={8}> Nenhum registro encontrado! </td>
                  )}
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Content>
    </>
  )
}

export default OrderHistoryView
