import React from 'react'

import { Container } from './styles'

interface IPagination {
  perPage: number
  total: number
  paginate: any
  page: number
}

const Pagination = ({ perPage, total, paginate, page }: IPagination) => {
  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i)
  }

  const handlePaginate = (item: number) => {
    paginate(item)
  }

  return (
    <Container>
      <nav>
        {pageNumbers.map((number) => (
          <button key={number} onClick={() => handlePaginate(number)}>
            {number}
          </button>
        ))}
      </nav>
    </Container>
  )
}

export default Pagination
