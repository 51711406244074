import React, { useRef, useState, useEffect } from 'react'
import { FiMail } from 'react-icons/fi'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Modal } from 'antd'
import { useDispatch } from 'react-redux'

import { Container, ContentModal } from './styles'
import Input from '../../components/Input'
import Button from '../../components/Button'
import getValidationErrors from '../../utils/getValidationErrors'
import { loginApp } from '../../store/modules/login/actions'
import api from '../../services/api'
import { useToast } from '../../hooks/toast'
import logoLmCor from '../../assets/logo/logolm-cor.png'

interface ForgotPasswordFormData {
  environment: number
  email: string
  IsCanalIndireto: boolean
}

const ForgotPassword = () => {
  const formRef = useRef<FormHandles>(null)
  const dispatch = useDispatch()
  const { addToast } = useToast()

  const [isForgotPassword, setIsForgotPassword] = useState(false)

  useEffect(() => {
    setIsForgotPassword(true)
  }, [])

  const handleSubmit = async (data: ForgotPasswordFormData) => {
    try {
      formRef.current?.setErrors({})

      const schema = Yup.object().shape({
        email: Yup.string()
          .required('E-mail obrigatório')
          .email('Digite um e-mail válido')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      data.environment = document.location.href.includes('localhost') || document.location.href.includes('aa11ad064e30b403eb6628027a144d7f-158586650') || document.location.href.includes('a4ef09dde15f84d458ef091ac85ad6ed-153016449')  ? 7 : 8;
      data.IsCanalIndireto = true

      await api.post('/reset-password', data).then((res) => {
        const { status } = res
        if (status === 200) {
          return addToast({
            type: 'success',
            title: 'Sucesso ao recuperar senha',
            description:
              'Enviamos um link para o seu e-mail para redefinição de senha.'
          })
        }
      })
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)

        formRef.current?.setErrors(errors)

        return
      }
    }
  }

  const handleOk = (e: any) => {
    setIsForgotPassword(false)
    dispatch(loginApp({ status: false }))
  }

  const handleCancel = (e: any) => {
    setIsForgotPassword(false)
    dispatch(loginApp({ status: false }))
  }

  return (
    <Container>
      <Modal
        footer={null}
        title=""
        visible={isForgotPassword}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ContentModal>
          <img
            width="250"
            src={logoLmCor} //"https://signanddrive.s3.sa-east-1.amazonaws.com/Imagens/logolm-cor.png"
            alt="Fleet Solutions"
            data-testid="logo_img"
          />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              icon={FiMail}
              placeholder="E-mail"
              id="email"
              name="email"
              type="text"
            />
            <br />
            <Button type="submit">Recuperar senha</Button>
          </Form>
        </ContentModal>
      </Modal>
    </Container>
  )
}

export default ForgotPassword
