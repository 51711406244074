interface iPropsProducts {
  color: string
  productId: number
  quantity: number
  modelCode: number
  model: string
  optional: string
  deadline: number
  monthlyKmValue: number
  monthlyInstallment: number
  hullFranchiseValue: string
  overrunKm: number
  id?: number
  stateForPickup?: string
  defaultValueCity?: string
  totalRequestCredit?: number
  defaultOptional?: string
}

export const listProducts = (item: iPropsProducts) => {
  const emptyList = new Array(item.quantity).fill({})

  return emptyList.map(() => ({
    name: item.model,
    totalRequestCredit: item.totalRequestCredit,
    id: Math.random(),
    hullFranchiseValue: item.hullFranchiseValue,
    overrunKm: item.overrunKm,
    deadline: item.deadline,
    monthlyKmValue: item.monthlyKmValue,
    modelCode: item.modelCode,
    optional: item.optional,
    color: item.color,
    productId: item.productId,
    monthlyInstallment: item.monthlyInstallment,
    defaultValueCity: item.defaultValueCity,
    stateForPickup: item.stateForPickup,
    defaultOptional: item.defaultOptional,
    quantity: item.quantity
  }))
}
