/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { Carousel as Slider } from 'antd'

import Footer from '../../components/Footer'

import { Container, Content } from './styles'
import SignIn from '../SignIn'
import { useDispatch } from 'react-redux'
import { loginApp } from '../../store/modules/login/actions'
import Header from '../../components/Header'
import { useAuth } from '../../hooks/auth'
import { useLocation } from 'react-router'
import {
  BannerAudi,
  BannerDefault,
  BannerTrucks,
  BannerVW
} from '../../assets/banner'

const Home: React.FC = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { signOut } = useAuth()

  const BANNERS: any = {
    audi: BannerAudi,
    default: BannerDefault,
    trucks: BannerTrucks,
    vw: BannerVW
  }
  const segment = pathname.split('/')[1]
  const bannerName = segment === '' ? 'default' : segment

  useEffect(() => {
    signOut()
    dispatch(loginApp({ status: true }))
  }, [])

  return (
    <Container>
      <Header home />
      <Content>
        <SignIn />
        <Slider autoplay>
          <div className="img-banner">
            <img src={BANNERS[bannerName]} alt="Banner carro" />
          </div>
        </Slider>
      </Content>
      <Footer />
    </Container>
  )
}

export default Home
