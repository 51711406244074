import React from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import { Container, Error } from './styles'

interface Props {
  name: string
  value: string
  containerStyle?: object
  error: string
  onChange: ({ name, value }: { name: string; value: string }) => void
}

const NewTextAreaFleet: React.FC<Props> = ({
  name,
  value,
  onChange,
  containerStyle = {},
  error,
  ...rest
}) => {
  const handleChange = (event: any) => {
    onChange({ name: event?.target.name, value: event?.target.value })
  }

  return (
    <Container isErrored={!!error} style={containerStyle}>
      <textarea
        name={name}
        value={value || ''}
        onChange={handleChange}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#C53030" size={20} />
        </Error>
      )}
    </Container>
  )
}

export default NewTextAreaFleet
