import { Reducer } from 'redux'
import { ILogin } from './types'

const INITIAL_STATE: ILogin = {
  status: false
}

const login: Reducer<ILogin> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOGIN_REQUIRED': {
      const { status } = action.payload

      return {
        ...state,
        status: status.status
      }
    }
    default: {
      return state
    }
  }
}

export default login
