import styled from 'styled-components'

export const Container = styled.div`
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3c505a;

  button {
    width: 208px;
    font-size: 15px;
  }

  nav {
    margin: 0 0 50px 16px;

    button {
      width: 38px;
    }
  }

  h1 {
    color: #17536c;
    margin-top: 30px;
    margin-bottom: 0px !important;
    font-size: 1.6em !important;
    text-align: center;
  }

  p {
    color: #3c505a;
    margin-top: 5px;
  }

  .wrapper-button {
    width: 90%;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 500px) {
    .wrapper-button {
      justify-content: center;
    }
  }

  
`

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 21px;
    color: #0c5faf;
    font-weight: 600;
  }

  p {
    font-size: 17px;
    color: #3c505a;
  }
`
export const ContentModalContrato = styled.div`
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

p {
  margin: 20px 0 0 0;
  font-size: 16px;
  color: #3c505a;
}

div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button {
    width: 78px;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    background: #0c5faf;
    margin: 10px;
    color: white;
    border: none;
    height: 30px;
    &.cancel{
    background-color:#868686}
  }
}
`



export const WrapperStatus = styled.div`
  width: 90%;
  height: 77px;
  background: #fff;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  .status {
    display: flex;
    flex-direction: row;
    align-items: center;

    .box-finance {
      width: 49px;
      height: 44px;
      background: #3c505a;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      font-weight: 500;

      p {
        color: #fff;
        font-size: 20px;
      }
    }

    .license-plate {
      background: #3c505a;
    }

    .available {
      background: #3c505a;
    }

    .active {
      background: #3c505a;
    }
  }

  @media (max-width: 857px) {
    flex-direction: column;
    height: auto;
    width: auto;
    align-items: baseline;

    .status {
      margin: 15px;
    }
  }
`

export const Select = styled.select`
  width: 265px;
  height: 31px;
  /*margin-left: 10px;*/
  font-size: 14px;
  color: #8994a0;
  border: 1px solid #cfd7d9;
  background: #fff;
  padding-left: 10px;
  padding-top: 2px;
`

export const WrapperTable = styled.div`
  width: 90%;

  form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:15px;

    select{
      width:100%;
    }

    .wrapper-input {
      display: flex;
      justify-content: left;
      align-items: center;
      flex:1;
      img {
        margin-left: -30px;
        cursor: pointer;
      }

      label {
        margin-right: 4px;
      }

      div {
        border-radius: 0;
        display: flex;
        flex-direction: row-reverse;
        background: #ffffff;
        border: 1px solid #cfd7d9;
        height: 31px;
        width: 100%;

        svg {
          margin: 0 -10px 0 5px;
          width: 15px;
          color: #0161af;
        }
      }

      input {
        width: 100%;

      }

      input::placeholder {
        font-size: 14px;
      }
    }

    .select-auto {
      width: 100%;

      /*margin-left: 10px;*/
      font-size: 14px;
      color: #8994a0 !important;
      border: 1px solid #cfd7d9 !important;
      background: #fff;
      padding-top: 4px;
    }
  }

  @media (max-width: 700px) {
    form {
      div {
        margin: 5px;
      }
    }
  }

  .div-filtros {
    display: flex;
    flex-direction: column;
    flex:1;
  }
`

export const Table = styled.table`
  width: 100%;
  text-align: center;
  border-spacing: inherit;
  margin: 15px 0px 30px 0px;
  border: 1pt solid #cfd7d9;

  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  tr,
  td {
    border: 1pt solid #cfd7d9;
  }

  tr {
    font-size: 14px;
  }

  thead {
    tr {
      height: 36px;
    }
  }

  td {
    padding: 7px 15px;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    height: 36px;
  }

  .large {
    width: 110px;
  }

  .status-color-wait {
    color: #ba1818;
  }

  .status-color-wait-finance {
    color: #ce651a;
  }

  .status-color-available {
    color: #369617;
  }

  .status-color-license-plate {
    color: #ba1818;
  }

  .status-color-active {
    color: #167c9d;
  }
`

export const WrapperLogo = styled.div`
  margin: 20px;
`

export const SelectSegmentButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`


export const SelectedOption = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  h3 {
    color: #17536c;
    font-size: 24px;
    padding-right: 20px;
  }

  h4 {
    color: #cccccc;
    font-size: 20px;
    padding-right: 20px;
  }
`

export const PanelInputSelected = styled.div`
  height: 34px;
  border: 1px solid #cfd7d9;
  background-color: white;
  text-align: left;
  padding-right: 10px;
  margin-right: 20px;

  label {
    text-align: left;
    color: #3c505a;
    padding-left: 20px;
  }
`


export const Content = styled.div`
  display: flex;
  flex: 0;
  flex-direction: row;
  padding-top: 30px;
  padding-left: 30px;
`


export const HeaderCollapse = styled.div`
  height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
`

export const ContentCharts = styled.div`
  display: flex;
  flex: 3;
  flex-direction: row;
`

export const PainelChart = styled.div`
  border: 1px solid #cfd7d9;
  border-radius: 10px;
  background-color: white;
  width: 98%;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;

  hr {
    border: 0;
    border-top: 1px solid #cfd7d9;
    margin-left: 10px;
    margin-right: 10px;
  }
`

export const Painel = styled.div`
  border: 1px solid #cfd7d9;
  border-radius: 10px;
  background-color: white;
  width: 40%;
  height: 100%;
  margin: 20px;
`

export const PainelLine = styled.div`
  border: 1px solid #cfd7d9;
  border-radius: 10px;
  background-color: white;
  width: 100%;
  height: 100%;
  margin: 20px;
`

export const ContentChart = styled.div`
  margin: 20px;
`

export const ContentPainelChart = styled.div`
  display: flex;
  flex: 0;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 40px;
`

export const ContentPainelChartText = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
`

export const Title = styled.label`
  color: #666666;
  font-weight: 100;
  font-size: 12px;
  padding-left: 10px;
  text-align: left;
`

export const SubTitle = styled.label`
  font-size: 20px;
  text-align: left;
  padding-left: 10px;
`

