/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import 'antd/dist/antd.css'
import {
  Content,
  WrapperLine,
  Contact,
  OrderInfo,
  ModalContainer,
  Modal,
  WrapperStep
} from './styles'
import { useHistory } from 'react-router-dom'
import { defaultBackendUrl } from 'services/api'
import api from '../../services/api'
import formatValue from '../../utils/formatValue'
import { cpfMask } from '../../utils/cpfMask'
import { phoneMask } from '../../utils/phoneMask'

import { useAuth } from '../../hooks/auth'
import ImgWithFallback from '../../components/ImgWithFallback'
import { cnpjMask } from 'utils/cnpjMask'
import { useToast } from 'hooks/toast'
import { useDispatch } from 'react-redux'
import { setTabs } from 'store/modules/tabsRegisterUser/actions'
import noImageCar from 'assets/defaultImages/no-image.png'
import type { StepsProps } from 'antd'
import { Input, Popover, Steps } from 'antd'

const customDot: StepsProps['progressDot'] = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        step {index} status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
)

const { Step } = Steps

interface OrderItemStatus {
  orderItemStatusId: number
  orderItemId: number
  chassis: string
  status: number
  statusDescription: string
  cpfCnpj: string
  supplierRemoval: string
  deliveryLocation: string
  deliveryPlate: string
  deliveryModel: string
  deliveryColor: string
  dateCreated: string
}

interface Items {
  orderId: number
  productId: number
  model: string
  color: string
  monthlyKmValue: number
  monthlyInstallment: number
  deadline: number
  modelCode: string
  cityForPickup: string
  cityforPickup: string
  uf: string
  optional: string
  finalPlate?: string | null
  statusDescription: string
  orderItemStatus: OrderItemStatus[]
  dn: string
  deliveryOption:number
  orderItemId: number
}

interface ICompany {
  cnpj: string
  contactName: string
  mail: string
  companyName: string
  cpf: string
  phone: string
  address: {
    street: string
    number: string
    district: string
    city: string
    complement: string
    uf:any
  }
}

interface Order {
  orderId: number
  statusDescription: any
  note: string
  status: number
  showButtonsCreditReproved: boolean
  deliveryOption: number
}

type OrderStatusViewProps = {
  id: number
}

export interface AddressItem {
  addressId:   number;
  userId:      number;
  orderItemId: number;
  zipCode:     string;
  street:      string;
  number:      string;
  district:    string;
  city:        string;
  state:       number;
  complement:  string;
  dateCreated: Date;
  dateUpdated: Date;
}


const listItemStatus = [
  'Aguardando Faturamento',
  'Aguardando Documentação',
  'Aguardando Preparação',
  'Aguardando Transporte',
  'Veículo Disponível',
  'Veículo Entregue'
]

const OrderStatusView = ({ id }: OrderStatusViewProps) => {
  const { userId, dealershipId, role } = useAuth()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast } = useToast()

  const validStatusValues = [11]

  const [isModalResponse, setIsModalResponse] = useState(false)
  const [textMessageJustify, setTextMessageJustify] = useState('')

  const [items, setItems] = useState<Items[]>([])
  const [addressArray, setAddressArray] = useState<AddressItem[]>([])
  const [order, setOrder] = useState<Order>()
  const [company, setCompany] = useState<ICompany>()
  const [dealership, setDealership] = useState({} as any)

  const [isAdminSuperFleet, setIsAdminSuperFleet] = useState(false)

  useEffect(() => {
    setIsAdminSuperFleet(role === 'Root' || role === 'Super Admin')
  }, [])

  useEffect(() => {
    if (!userId || !dealershipId) {
      history.push('/')
    }
    try {
      api.get(`/orderitems?orderId=${id}&noCache=true`).then((res) => {
        setItems(res.data)

        let requestsArr:any = [];
        res.data?.forEach((element: any) => {
          if(element.deliveryOption === 3){
            requestsArr.push(element.orderItemId)
          }
        });
        getOtherAddress(requestsArr)

      })

      api.get(`/orders/${id}`).then((res) => {
        setOrder(res.data)

        if (res.data.dealershipId) {
          api
            .get(`/dealerships/${res.data.dealershipId}`)
            .then((response: any) => {
              setDealership(response.data)
            })
        }

        api.get(`/company/${res.data.cpfCnpj}`).then((res) => {
          setCompany(res.data)
        })
      })
    } catch (err) {
      console.log(err)
    }
  }, [])

  const resendDoc = () => {
    dispatch(setTabs({ id: '1' }))
    history.push(
      `/list-dealership/register-company/${company?.cnpj}/${order?.orderId}/isCompany`
    )
  }

  const getAccessories = (value: any) => {
    let accessories = ''

    if (!value) return

    value.split('|').forEach((ite: string) => {
      if (ite.indexOf('acs') > -1) {
        accessories += ite.replace('acs=', '') + '; '
      }
    })

    return accessories
  }

  const handleResponse = async () => {
    try {
      await api.post(`/ordermessage`, {
        orderMessageId: 0,
        orderId: order?.orderId,
        message: textMessageJustify,
        dateCreated: new Date().toISOString(),
        userId: Number(userId),
        typeMessage: 'Enviado'
      })
    } catch (err) {
      addToast({
        title: 'Erro ao enviar mensagem',
        type: 'error',
        description: `${err.response.data}`
      })
    }

    setIsModalResponse(false)
  }

  const handleMessageJustify = (event: any) => {
    const { value } = event.currentTarget
    setTextMessageJustify(value)
  }

  const getOptionals = (value: any) => {
    let optionals = ''

    if (!value) return

    value.split('|').forEach((ite: string) => {
      if (ite.indexOf('opt') > -1) {
        optionals += ite.replace('opt=', '') + '; '
      }
    })

    return optionals
  }

  const updateStatus = async () => {
    const param = {
      orderId: order?.orderId,
      status: 8,
      description: 'Retentativa de análise de crédito'
    }

    try {
      await api
        .post('/orderstatus', param)
        .then(async (resp) => {
          if (resp.status === 200) {
            await api.get(`/orders/${id}`).then((res) => {
              setOrder(res.data)
            })
          }
        })
        .catch((err) => {
          addToast({
            title: 'Erro ao tentar enviar para reanálise!',
            type: 'error',
            description: `${err.response.data}`
          })
        })
        .finally(() => {
          console.log('finally')
        })
    } catch (err) {
      addToast({
        title: 'Erro ao tentar enviar para reanálise!',
        type: 'error',
        description: `${err.response.data}`
      })
    }
  }

  const isVisibleTimeLine = () => {
    if (
      order &&
      order.status !== undefined &&
      validStatusValues.includes(order?.status)
    ) {
      return true
    }
    return false
  }

  const getItemOrderStatus = (orderItemStatus: OrderItemStatus[]) => {
    if (isVisibleTimeLine()) {
      return orderItemStatus.length - 1
    }
    return 0
  }

  const setItemStatusPass = (orderItemStatus: string) => {
    let index = listItemStatus.indexOf(orderItemStatus)
    if (index < 0) index = 0

    return index
  }

  const getOtherAddress = (id:number[]):any => {
    api.post(`/location/address/items`, id).then((res) => {
      const { data } = res;
      setAddressArray(data)
    });
  }

  const otherAddress = (id:number):any =>{
    return addressArray?.find(item => item.orderItemId === id)
  }

  return (
    <>
      <Content>
        <Contact>
          <div className="data-company">
            <h2>Dados da empresa</h2>
            <div className="wrapper-info">
              <div>
                <p className="title-description">CNPJ: </p>
                <p>{company?.cnpj && cnpjMask(company.cnpj)}</p>
              </div>
              <div>
                <p className="title-description">Razão Social: </p>
                <p>{company?.companyName}</p>
              </div>
            </div>
          </div>

          <div className="data-contact">
            <h2>Contatos da empresa</h2>
            <div className="wrapper">
              <div className="wrapper-info">
                <div>
                  <p className="title-description">Nome: </p>
                  <p>{company?.companyName}</p>
                </div>
                <div>
                  <p className="title-description">CPF: </p>
                  <p>{company?.cpf && cpfMask(company.cpf)}</p>
                </div>
              </div>
              <div className="wrapper-info">
                <div>
                  <p className="title-description">Telefone: </p>
                  <p>{company?.phone && phoneMask(company.phone)}</p>
                </div>
                <div>
                  <p className="title-description">E-mail: </p>
                  <p>{company?.mail}</p>
                </div>
              </div>
            </div>
          </div>
        </Contact>
        <hr />
        <OrderInfo>
          <div className="data-order">
            <h2>Dados do pedido</h2>
            <div className="wrapper-info">
              <p className="title-description">
                <span>Número:</span> {order?.orderId}
              </p>
              <p className="title-description">
                <span>Status:</span> {order?.statusDescription}
              </p>
              {isAdminSuperFleet &&
                order?.status === 7 &&
                order?.showButtonsCreditReproved && (
                  <>
                    <p>
                      <button className="btn-resend-doc" onClick={updateStatus}>
                        Solicitar Reanálise
                      </button>
                    </p>
                  </>
                )}
              {order?.status === 8 && (
                <>
                  <p>
                    <button className="btn-resend-doc" onClick={resendDoc}>
                      Ajustar pedido
                    </button>
                  </p>
                </>
              )}
              {((isAdminSuperFleet &&
                order?.status === 7 &&
                order?.showButtonsCreditReproved) ||
                order?.status === 8) && (
                <>
                  <p>
                    <button
                      className="btn-resend-doc"
                      onClick={() => setIsModalResponse(true)}
                    >
                      Resposta
                    </button>
                  </p>
                </>
              )}
            </div>
          </div>

          <div className="note">
            <p>Observação: {order?.note}</p>
            {order?.status === 8 && (
              <p>
                Clique no botão abaixo para ajustar as informações e reenviar
                pedido
              </p>
            )}
          </div>
        </OrderInfo>
        {items.length
          ? items.map((item: Items, idx: number) => (
              <>
                <WrapperLine key={idx}>
                  <div className="wrapper-img item">
                    <ImgWithFallback
                      className="image-car"
                      src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.webp`}
                      fallbacks={[
                        `${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.png`,
                        `${noImageCar}`
                      ]}
                      alt="Carro"
                    />
                    <div>
                      <p className="title-description">
                        <span>Modelo:</span> {item.model}
                      </p>
                      <p className="title-description">
                        <span>Cor: </span>
                        {item.color}
                      </p>
                      {item.optional?.indexOf('acs=') === -1 &&
                        item.optional?.indexOf('opt=') === -1 && (
                          <p className="title-description">
                            <span>Opcionais inclusos:</span> {item.optional}
                          </p>
                        )}
                      {getAccessories(item.optional) && (
                        <p className="title-description">
                          <span>Acessórios:</span>{' '}
                          {getAccessories(item.optional)}
                        </p>
                      )}
                      {getOptionals(item.optional) && (
                        <p className="title-description">
                          <span>Opcionais inclusos:</span>{' '}
                          {getOptionals(item.optional)}
                        </p>
                      )}
                      <p className="title-description">
                        <span>Final da placa:</span> {item.finalPlate || "S/PLACA"}
                      </p>
                      <p className="title-description">
                        <span>Placa:</span>
                        {item.orderItemStatus[getItemOrderStatus(item.orderItemStatus)] && ` ${item.orderItemStatus[getItemOrderStatus(item.orderItemStatus)].deliveryPlate ? item.orderItemStatus[getItemOrderStatus(item.orderItemStatus)].deliveryPlate : ""}`}
                      </p>
                    </div>
                  </div>
                  <div className='item middle'>
                    <p className="title-description">
                      <span>Período:</span> {item.deadline} meses
                    </p>
                    <p className="title-description">
                      <span>Km contratado:</span> {item.monthlyKmValue}{' '}
                    </p>
                    <p className="title-description">
                      <span>Parcela mensal:</span>{' '}
                      {formatValue(item.monthlyInstallment)}
                    </p>
                    <p className="title-description">
                      <span>Chassi:</span>
                      {item.orderItemStatus[
                        getItemOrderStatus(item.orderItemStatus)
                      ] &&
                        ` ${
                          item.orderItemStatus[
                            getItemOrderStatus(item.orderItemStatus)
                          ].chassis
                            ? item.orderItemStatus[
                                getItemOrderStatus(item.orderItemStatus)
                              ].chassis
                            : ''
                        }`}
                    </p>
                  </div>
                  <div className='item'>
                  <p className="title-description">
                    <span>Local de retirada:&nbsp;</span>
                    {
                      item?.deliveryOption === 4 ? (
                        <div className='address-line'>
                          <span className="address-head">DN do pedido</span>
                          
                          {dealership?.street}, {dealership?.addressNumber}  - {dealership?.city}/{dealership?.uf}
                        </div>
                      ) : item?.deliveryOption === 2 ? (
                        <div className='address-line'>
                          <span className="address-head">Endereço do cadastro do cliente</span>
                          {company?.address?.street}, {company?.address?.number} - {company?.address?.district} - {company?.address?.city}
                        </div>
                      ) : item?.deliveryOption === 3 ? (
                        <div className='address-line'>
                          <span className="address-head">Outro endereço</span>
                          {otherAddress(item?.orderItemId)?.street}, {otherAddress(item?.orderItemId)?.number} - {otherAddress(item?.orderItemId)?.district} - {otherAddress(item?.orderItemId)?.city}
                        </div>
                      ): item?.deliveryOption === 1 ? (
                        <div className='address-line'>
                          <span className="address-head">Concessionária/Parceiro</span>
                          {otherAddress(item?.orderItemId)?.street}, {otherAddress(item?.orderItemId)?.number} - {otherAddress(item?.orderItemId)?.district} - {otherAddress(item?.orderItemId)?.city}
                        </div>
                      ) : item?.deliveryOption === 5 && item?.uf && item?.cityForPickup && (
                        <div className='address-line'>
                          <span className="address-head">Estado/Cidade indicados</span>
                          {item?.uf} / {item?.cityForPickup}
                        </div>
                      )
                    }
                  </p>
                  { item?.deliveryOption === 1 && !item?.uf && !item?.cityForPickup && <p className="title-description">
                      <span>DN de entrega:&nbsp;</span>
                      
                         {dealership?.name}
                    </p>}
                    <p className="title-description">
                      <span>Status de entrega:</span>
                      {isVisibleTimeLine() && (
                        <>
                          {item.orderItemStatus[
                            getItemOrderStatus(item.orderItemStatus)
                          ] &&
                            ` ${
                              item.orderItemStatus[
                                getItemOrderStatus(item.orderItemStatus)
                              ].statusDescription
                            }`}
                        </>
                      )}
                    </p>
                  </div>
                  {isVisibleTimeLine() && (
                    <WrapperStep key={`step-${idx}`}>
                      <Steps
                        current={setItemStatusPass(
                          item?.orderItemStatus[
                            getItemOrderStatus(item.orderItemStatus)
                          ]?.statusDescription
                        )}
                        progressDot={customDot}
                      >
                        <Step title="Aguardando Atribuição do Veículo" />
                        <Step title="Veículo em Preparação - Documentação" />
                        <Step title="Veículo em Preparação de Acessórios" />
                        <Step title="Veículo em Transporte" />
                        <Step title="Aguardando disponibilidade de retirada" />
                        <Step title="Veículo Entregue" />
                      </Steps>
                    </WrapperStep>
                  )}
                </WrapperLine>
              </>
            ))
          : ''}
      </Content>
      {isModalResponse && (
        <ModalContainer>
          <Modal>
            <>
              <h2>Resposta</h2>
              <div>
                <p>Informe uma resposta:</p>
                <Input.TextArea
                  id="textDeliveryDeadline"
                  name="textDeliveryDeadline"
                  onChange={handleMessageJustify}
                  value={textMessageJustify}
                />
              </div>
              <div>
                <button onClick={() => setIsModalResponse(false)}>
                  Voltar
                </button>
                <button onClick={handleResponse}>Confirmar resposta</button>
              </div>
            </>
          </Modal>
        </ModalContainer>
      )}
    </>
  )
}

export default OrderStatusView
