export const mockProductPayload = {
  quantity: 1,
  productId: 1,
  cityForPickup: '',
  uf: ''
}

export const mockProductPayloadTwo = {
  quantity: 1,
  productId: 2,
  cityForPickup: 'São Paulo',
  uf: 'SP'
}

export const mockOutOfStockData = {
  ProductId: 1,
  Model: 'fake model',
  Color: 'fake color',
  Optional: 'fake optional',
  QuantityStock: 1,
  QuantityRequest: 2
}

export const mockGetDocCompanyUploadData = {
  ccmei: 'file',
  simplesStatementLastYear: 'file',
  lastYearSocialIncomeTax: 'file',
  lastContractChange: 'file',
  irpjLastYear: 'file',
  lastYearRevenue: 'file',
  balanceSheetPreviousYear: 'file',
  currentYearBalanceSheet: 'file',
  drePreviousYear: 'file',
  dreCurrentYear: 'file',
  bankIndebtedness: 'file',
  others: 'file'
}

export const mockProductsPayload = [
  mockProductPayload,
  mockProductPayload,
  mockProductPayloadTwo
]

export const mockLocalStorage = {
  '@FleetSolutions:location': JSON.stringify(mockProductsPayload)
}

export const mockPostDealershipManagementPayload = {
  products: mockProductsPayload,
  userId: 0,
  dealershipId: 0,
  dealershipGroupId: 0,
  note: null
}

export const mockPostDealearshipManagementData = {
  Message: 'fake message',
  OutOfStockProducts: [mockOutOfStockData]
}

export const mockStockItem = {
  segment: '1',
  modelCode: '1111',
  model: 'fake model',
  color: 'fake color',
  optional: 'fake optional',
  quantity: '10',
  status: 'fake status',
  stockId: 1
}

export const mockStock = [mockStockItem]

export const mockSegmentsData = [
  { id: 9, description: 'fake segment 1' },
  { id: 1, description: 'fake segment 2' }
]

export const mockGetInventoryFiltersPayload = {
  segment: '1',
  modelCode: '1111',
  model: 'fake model',
  optional: 'fake optional',
  status: false
}

export const mockStockUpdateItem = {
  stockId: 1,
  quantity: undefined
}

export const mockPutStockUpdatePayload = {
  stockUpdate: [{ ...mockStockUpdateItem, quantity: 1 }]
}

export const mockGetFiltersData = {
  modelCode: ['1111'],
  model: ['fake model'],
  optional: ['fake optional']
}
