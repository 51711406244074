import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface IProps {
  isPositionAbsolute?: boolean
  isPositionFixed?: boolean
}

export const Container = styled.div<IProps>`
  height: 73px;
  align-items: center;
  justify-content: center;
  background: rgba(60, 80, 90) 0% 0% no-repeat padding-box;
  position: ${(props) => (props.isPositionAbsolute ? 'absolute' : 'inherit')};
  position: ${(props) => props.isPositionFixed && 'fixed'};
  width: 100%;
  z-index: 10;

  h1 {
    font-size: 15px;
    font-weight: bold;
    margin-left: 40px;
    color: white;
  }

  @media (max-width: 830px) {
    position: inherit;
    height: 63px;
  }
`

export const WrapperLogo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  img {
    width: 300px;
    cursor: pointer;
  }

  @media (max-width: 830px) {
    h1 {
      display: none;
    }

    img {
      width: 161px;
      height: 22px;
    }
  }
`

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
      z-index: 1;
  img {
    margin-left: 10px;
  }

  @media (max-width: 830px) {
    .btn-start-now {
      display: none;
    }
  }

  button {
    font-size: 16px;
    color: white;
    font-weight: bold;
    margin: 10px;
  }

  button:hover {
    color: white;
    border-bottom: 1px solid white;
    background: transparent;
  }

  .icon-menu {
    margin-left: 50px;
  }

  .btn-menu {
    background: transparent;
    border: 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 98%;
  height: inherit;
  margin: 0 auto;

  .wrapper-names {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    color: #fff;
    margin-right: 8px;

    p {
      margin: 0;
      font-size: 14px;
    }

    .name-user {
      font-weight: 500;
    }
  }

  @media (max-width: 470px) {
    .wrapper-names {
      display: none;
    }
  }
`

export const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const NavLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-size: 16px;
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-right: 50px;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  position: relative;
  transition: color 0.3s;

  &:after {
    content: '';
    width: 0;
    height: 2px;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.3s;
  }

  &:hover {
    color: blue;
  }

  &:focus {
    outline: none;
  }

  &.active:after {
    width: 100%;
  }
`
