export const formatDateTime = (date: string) => {
  const formattedDate = new Date(date)
  const year = formattedDate.getFullYear()
  const month = formattedDate.getMonth() + 1
  const day = formattedDate.getDate()
  const hour = formattedDate.getHours()
  const minute = formattedDate.getMinutes()
  const seconds = formattedDate.getSeconds()

  return `${day <= 9 ? '0' : ''}${day}/${
    month <= 9 ? '0' : ''
  }${month}/${year} ${hour <= 9 ? '0' : ''}${hour}:${
    minute <= 9 ? '0' : ''
  }${minute}:${seconds <= 9 ? '0' : ''}${seconds}`
}
