import styled from 'styled-components'

export const Container = styled.div``

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    margin-top: 20px;
  }

  img {
    margin: 40px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 20px 0 0 0;
      font-size: 20px;
      color: #3c505a;
    }

    button {
      box-shadow: 0px 3px 6px #00000029;
      background: #0c5faf;
    }

    .jxFTYV {
      border: 2px solid #8994a0;
    }
  }
`
