/* eslint-disable @typescript-eslint/no-explicit-any */
export function phoneMask(value: any) {
  if (value !== null) {
    if (value.length === 15) {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
    } else {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
    }
  } else {
    return ''
  }
}
