/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";

import { Button, Select, Select as SelectAntd, Space } from "antd";

import * as S from "./styles";
import Footer from "components/Footer";
import Header from "components/Header";
import formatValue from "utils/formatValue";
import { Collapse } from "@material-ui/core";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

import iconCalendar from "../../assets/icons/calendar.svg";
import { Bar, Column } from "@ant-design/charts";
import { findPersonType } from "utils";
import { ISegment } from "utils/types";

import api from "services/api";

import { DatePicker } from "antd";
import moment from "moment";
import { useAuth } from "hooks/auth";

const { RangePicker } = DatePicker;

const { Option } = Select;

interface iDashboardParam {
    segments?: any[] | undefined;
    initial: string;
    final: string;
    clientType?: number;
    status?: any[] | undefined;
    dealershipsId?:any
    userIdDn?:any;
}

export interface Dealer {
    id: number;
    name: string;
    street: string;
    addressNumber: string;
    domains: string;
    domainList: string[];
    number: string;
    dealershipGroupID: number;
    status: boolean;
    createDate: Date;
    cnpj: string;
    email: string;
    segments: string;
    uf: null;
    city: null;
    listSegments: null;
    kickbacks: null;
}

interface iSeller {
    userId: number;
    fullname: string;
}

const Dashboard: FC = () => {
    const { roleId, dealershipGroupId } = useAuth();
    const [openCollapseData, setOpenCollapseData] = useState(true);
    const [openCollapseDataSales, setOpenCollapseDataSales] = useState(true);
    const [segmentField, setSegmentField] = useState<ISegment | any>();
    const [segmentOptions, setSegmentOptions] = useState<ISegment[]>([]);
    const [dealerIdsList, setDealerIdsList] = useState([]);
    const [selectedFisicaJuridica, setSelectedFisicaJuridica] = useState("-1");

    const [totalsOrdersByMonthAsync, setTotalsOrdersByMonthAsync] = useState([]);
    const [totalsOrderItemsSoldByMonthAsync, setTotalsOrderItemsSoldByMonthAsync] = useState([]);
    const [totalsOrdersBySellerAsync, setTotalsOrdersBySellerAsync] = useState([]);
    const [totalOrdersBySegmentAsync, setTotalOrdersBySegmentAsync] = useState([]);
    const [createdOrdersByClientTypesAsync, setCreatedOrdersByClientTypesAsync] = useState([]);
    const [totalOrdersByStatusAsync, setTotalOrdersByStatusAsync] = useState([]);
    const [creditAnalysisOrdersAsync, setCreditAnalysisOrdersAsync] = useState([]);
    const [signedContractsByFranchiseAsync, setSignedContractsByFranchiseAsync] = useState([]);
    const [signedContractsByClientTypesAsync, setSignedContractsByClientTypesAsync] = useState([]);
    const [signedContractsByModelAsync, setSignedContractsByModelAsync] = useState([]);
    const [bestClientsBySellerAsync, setBestClientsBySellerAsync] = useState([]);
    const [totalVehiclesByDeadlineAsync, setTotalVehiclesByDeadlineAsync] = useState([]);
    const [getAverageTicketPerVehicleAsync, setGetAverageTicketPerVehicleAsync] = useState([]);

    const [signedContractsByClientVehicleQuantityAsync, setSignedContractsByClientVehicleQuantityAsync] = useState([]);

    const [dealershipOrdersByChannelAsync, setDealershipOrdersByChannelAsync] = useState([]);

    const [dealershipTotalOrdersBySegmentAsync, setDealershipTotalOrdersBySegmentAsync] = useState([]);

    const [dealershipRankingByOrdersAsync, setDealershipRankingByOrdersAsync] = useState([]);

    const [dealershipOrdersByClientTypesAsync, setDealershipOrdersByClientTypesAsync] = useState([]);

    const [signedContractsByModelForDealershipAsync, setSignedContractsByModelForDealershipAsync] = useState([]);

    const [signedContractsBySellerAsync, setSignedContractsBySellerAsync] = useState([]);
    const [totalVehiclesByDeadlineAndMonthlyKmValueAsync, setTotalVehiclesByDeadlineAndMonthlyKmValueAsync] = useState([]);

    const [selectedDealer, setSelectedDealer] = useState();
    const [selectedSeller, setSelectedSeller] = useState();
    const [dealersList, setDealersList] = useState<Dealer[]>([]);
    const [sellers, setSellers] = useState<iSeller[]>([]);

    const [dataStart, setDataStart] = useState(moment().startOf("month"));
    const [dataEnd, setDataEnd] = useState(moment().endOf("month"));
    const [showAdminDash, setShowAdminDash] = useState(false);

    useEffect(() => {
      fetchInitialData();
    }, [dataStart, dataEnd, segmentField, selectedFisicaJuridica]);

    useEffect(() =>{
        if(roleId && roleId.toString() === '34' && dealerIdsList?.length > 0){
            fetchDealerAdminDashboard(dealerIdsList as any, 0);
        }
    }, [roleId, dealerIdsList])

    const fetchDashboard = async (dealerIds?: [], seller?: number) => {
        

        const params: iDashboardParam = {
            initial: dataStart.format("YYYY-MM-DD"),
            final: dataEnd.format("YYYY-MM-DD"),
        };
        if (segmentField && segmentField.iD_Ibratan !== "-1") {
            params.segments = [Number(segmentField.iD_Ibratan)];
        }

        if (selectedFisicaJuridica && selectedFisicaJuridica !== "-1") {
            params.clientType = selectedFisicaJuridica === "F" ? 1 : 0;
        }

        const dealerParams: any = {};

        if (roleId?.toString() === "34" && dealerIdsList && sellers) {
            // dealerParams.status = [10, 11, 17];
            params.dealershipsId = dealerIds ? dealerIds : dealerIdsList;
            params.userIdDn = seller ? seller : selectedSeller ? selectedSeller : 0;
        }

        try {
            const [
                totalsOrdersByMonthResponse,
                totalsOrderItemsSoldByMonthResponse,
                totalsOrdersBySellerResponse,
                totalOrdersBySegmentResponse,
                createdOrdersByClientTypesResponse,
                totalOrdersBySegmentItensResponse,
                totalOrdersByStatusResponse,
                signedContractsByClientTypesResponse,
                signedContractsByModelResponse,
                bestClientsBySellerResponse,
                signedContractsByFranchiseResponse,
                totalVehiclesByDeadlineAsyncResponse,
                getAverageTicketPerVehicleAsyncResponse,
                signedContractsByClientVehicleQuantityAsyncResponse,
            ] = await Promise.all([
                api.post("/charts/TotalsOrdersByMonthAsync", params),
                api.post("/charts/TotalsOrderItemsSoldByMonthAsync", {
                    ...params,
                    status: [10, 11, 17],
                }),
                api.post("/charts/TotalsOrdersBySellerAsync", {
                    ...params,
                    status: [10, 11, 17],
                }),
                api.post("/charts/TotalOrdersBySegmentAsync", params),
                api.post("/charts/createdOrdersByClientTypesAsync", params),
                api.post("/charts/TotalOrdersBySegmentItensAsync", {
                    ...params,
                    status: [10, 11, 17],
                }),
                api.post("/charts/TotalOrdersByStatusAsync", params),
                api.post("/charts/signedContractsByClientTypesAsync", {
                    ...params,
                    status: [10, 11, 17],
                }),
                api.post("/charts/signedContractsByModelAsync", params),
                api.post("/charts/bestClientsBySellerAsync", params),
                api.post("/charts/signedContractsByFranchiseAsync", params),
                api.post("/charts/TotalVehiclesByDeadlineAsync", {
                    ...params,
                    userIdDn: 0,
                    dealershipsId: dealerIds,
                }),
                api.post("/charts/GetAverageTicketPerVehicleAsync", params),
                api.post("/charts/signedContractsByClientVehicleQuantityAsync", params)
            ]);

            



            setTotalsOrdersByMonthAsync(totalsOrdersByMonthResponse.data);
            setTotalsOrderItemsSoldByMonthAsync(totalsOrderItemsSoldByMonthResponse.data);
            setTotalsOrdersBySellerAsync(totalsOrdersBySellerResponse.data);
            setTotalOrdersBySegmentAsync(totalOrdersBySegmentResponse.data);
            setCreatedOrdersByClientTypesAsync(createdOrdersByClientTypesResponse.data);
            setCreditAnalysisOrdersAsync(totalOrdersBySegmentItensResponse.data);
            setTotalOrdersByStatusAsync(totalOrdersByStatusResponse.data);
            setSignedContractsByClientTypesAsync(signedContractsByClientTypesResponse.data);
            setSignedContractsByModelAsync(signedContractsByModelResponse.data);
            setBestClientsBySellerAsync(bestClientsBySellerResponse.data);
            setSignedContractsByFranchiseAsync(signedContractsByFranchiseResponse.data);
            setTotalVehiclesByDeadlineAsync(totalVehiclesByDeadlineAsyncResponse.data);
            setGetAverageTicketPerVehicleAsync(getAverageTicketPerVehicleAsyncResponse.data);
            setSignedContractsByClientVehicleQuantityAsync(signedContractsByClientVehicleQuantityAsyncResponse.data);



        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    };

    const fetchDealerAdminDashboard = async (dealerIds?: [], seller?: number) => {
        
        const params: iDashboardParam = {
            initial: dataStart.format("YYYY-MM-DD"),
            final: dataEnd.format("YYYY-MM-DD"),
        };
        if (segmentField && segmentField.iD_Ibratan !== "-1") {
            params.segments = [Number(segmentField.iD_Ibratan)];
        }

        if (selectedFisicaJuridica && selectedFisicaJuridica !== "-1") {
            params.clientType = selectedFisicaJuridica === "F" ? 1 : 0;
        }

        const dealerParams: any = {};



        dealerParams.status = [10, 11, 17];
        dealerParams.dealershipsId = dealerIds ? dealerIds : dealerIdsList;
        dealerParams.userIdDn = seller;


        const [
            dealershipOrdersByChannelAsyncResponse,
            dealershipTotalOrdersBySegmentAsyncResponse,
            dealershipRankingByOrdersAsyncResponse,
            dealershipOrdersByClientTypesAsyncResponse,
            signedContractsByModelForDealershipAsyncResponse,
            signedContractsBySellerAsyncResponse,
            totalVehiclesByDeadlineAndMonthlyKmValueAsyncResponse,
        ] = await Promise.all([
            
            api.post("/charts/dealershipOrdersByChannelAsync", {
                ...params,
                ...dealerParams,
            }),
            api.post("/charts/TotalOrdersBySegmentAsync", {
                ...params,
                ...dealerParams,
            }),
            api.post("/charts/dealershipRankingByOrdersAsync", {
                ...params,
                ...dealerParams,
            }),
            api.post("/charts/dealershipOrdersByClientTypesAsync", {
                ...params,
                ...dealerParams,
            }),
            api.post("/charts/signedContractsByModelForDealershipAsync", {
                ...params,
                ...dealerParams,
            }),
            api.post("/charts/signedContractsBySellerAsync", {
                ...params,
                ...dealerParams,
            }),
            api.post("/charts/totalVehiclesByDeadlineAndMonthlyKmValueAsync", {
                ...params,
                ...dealerParams,
            }),
        ]);
          setDealershipOrdersByChannelAsync(dealershipOrdersByChannelAsyncResponse.data ? dealershipOrdersByChannelAsyncResponse.data : []);
          setDealershipTotalOrdersBySegmentAsync(dealershipTotalOrdersBySegmentAsyncResponse.data ? dealershipTotalOrdersBySegmentAsyncResponse.data : []);
          setDealershipRankingByOrdersAsync(dealershipRankingByOrdersAsyncResponse.data ? dealershipRankingByOrdersAsyncResponse.data : []);
          setDealershipOrdersByClientTypesAsync(dealershipOrdersByClientTypesAsyncResponse.data ? dealershipOrdersByClientTypesAsyncResponse.data : []);
          setSignedContractsByModelForDealershipAsync(signedContractsByModelForDealershipAsyncResponse.data ? signedContractsByModelForDealershipAsyncResponse.data : []);
          setSignedContractsBySellerAsync(signedContractsBySellerAsyncResponse.data ? signedContractsBySellerAsyncResponse.data : []);
          setTotalVehiclesByDeadlineAndMonthlyKmValueAsync(totalVehiclesByDeadlineAndMonthlyKmValueAsyncResponse.data ? totalVehiclesByDeadlineAndMonthlyKmValueAsyncResponse.data : []);

          setShowAdminDash(true)
    }

    const fetchInitialData = async () => {
        try {
            const [segmentsResponse, dealersResponse] = await Promise.all([api.get("/segments/active"), api.get(`dealerships/GetWithFilters?page=false&dealershipGroupID=${dealershipGroupId}`)]);
            setSegmentOptions(segmentsResponse.data);

            const ids = dealersResponse.data.itens.map((item: { id: any }) => item.id);
            setDealerIdsList(ids)
            setDealersList(dealersResponse.data.itens);
            fetchDashboard(ids);
        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    };

    const handleChangeField = (_: any, { value, children }: any) => {
        setSegmentField({ iD_Ibratan: value, type: children[0], name: children[2] });
        // fetchDashboard();

    };

    const handleChangeDealer = async (value: any) => {
        setSelectedDealer(value);
        setSelectedSeller(0 as any)
        let selected: any = [value];

        const response = await api.get(`/users/dealership-all-users-filter?dealershipId=${value}&page=false`);

        if (response) {
            setSellers(response.data.itens);
            fetchDashboard(selected, 0);
            fetchDealerAdminDashboard(selected, 0);
        }
    };

    const handleChangeSeller = async (value: any) => {
        setSelectedSeller(value);
        let dealersList: any = [selectedDealer];
        fetchDashboard(dealersList, value);
        fetchDealerAdminDashboard(dealersList, value);
    };

    const handleChangedSelectedFisicaJuridica = (_: any, { value }: any) => {
        setSelectedFisicaJuridica(value);
        // fetchDashboard();

    };

    const handleCleanFilters = () => {
        setSegmentField(null);
        setSelectedFisicaJuridica("-1");
        setDataStart(moment().startOf("month"));
        setDataEnd(moment().endOf("month"));
        setSelectedDealer(null as any)
        setSelectedSeller(0 as any)
    };

    let chart;

    const configTotalsOrdersByMonthAsync = {
        data: totalsOrdersByMonthAsync,
        autoFit: false,
        xField: "label",
        yField: "value",
        point: {
            size: 30,
            shape: "diamond",
        },
        label: {
            style: {
                fill: "#3D83A6",
            },
        },
        xAxis: {
            label: {
                formatter: (label: any) => {
                    return label.replaceAll("/", "\n");
                },
            },
        },
    };

    const configCreatedOrdersByClientTypesAsync = {
        data: createdOrdersByClientTypesAsync,
        autoFit: false,
        xField: "label",
        yField: "percentual",
        point: {
            size: 30,
            shape: "diamond",
        },
        label: {
            style: {
                fill: "#3D83A6",
            },
            content: (item: any) => {
                return `${item.percentual}% (${item.value})`;
            },
        },
        tooltip: {
            formatter: (datum: any) => {
                return { name: datum.label, value: datum.percentual + "%" };
            },
        },
    };

    const configTotalOrdersByStatusAsync = {
        data: totalOrdersByStatusAsync,
        autoFit: false,
        xField: "label",
        yField: "value",
        point: {
            size: 30,
            shape: "diamond",
        },
        label: {
            style: {
                fill: "#3D83A6",
            },
        },
        xAxis: {
            label: {
                formatter: (label: any) => {
                    return label.replaceAll(" ", "\n");
                },
            },
        },
    };

    const configSignedContractsByClientTypesAsync = {
        data: signedContractsByClientTypesAsync,
        autoFit: false,
        xField: "label",
        yField: "percentual",
        point: {
            size: 30,
            shape: "diamond",
        },
        label: {
            style: {
                fill: "#3D83A6",
            },
            content: (item: any) => {
                return `${item.percentual}% (${item.value})`;
            },
        },
        tooltip: {
            formatter: (datum: any) => {
                return { name: datum.label, value: datum.percentual + "%" };
            },
        },
    };

    const configDealershipOrdersByClientTypesAsync = {
        data: dealershipOrdersByClientTypesAsync,
        autoFit: false,
        xField: "label",
        yField: "percentual",
        point: {
            size: 30,
            shape: "diamond",
        },
        label: {
            style: {
                fill: "#3D83A6",
            },
            content: (item: any) => {
                return `${item.percentual}% (${item.value})`;
            },
        },
        tooltip: {
            formatter: (datum: any) => {
                return { name: datum.label, value: datum.percentual + "%" };
            },
        },
    };

    const createChartConfig = (data: any, xField: string, yField: string) => ({
        data,
        autoFit: false,
        xField,
        yField,
        point: {
            size: 30,
            shape: "diamond",
        },
        label: {
            style: {
                fill: "#3D83A6",
            },
        },
    });

    const createCurrencyChartConfig = (data: any, xField: string, yField: string) => ({
        data,
        autoFit: false,
        xField,
        yField,
        point: {
            size: 30,
            shape: "diamond",
        },
        label: {
            style: {
                fill: "#3D83A6",
            },
            content: (item: any) => {
                return `${formatValue(item?.value)}`;
            },
        },
        tooltip: {
            formatter: (datum: any) => {
                return { name: "valor", value: formatValue(datum?.value) };
            },
        },
    });

    const configDealershipOrdersByChannelAsync = {
        data: dealershipOrdersByChannelAsync,
        autoFit: false,
        xField: "label",
        yField: "percentual",
        point: {
            size: 30,
            shape: "diamond",
        },
        label: {
            style: {
                fill: "#3D83A6",
            },
            content: (item: any) => {
                return `${item.percentual}% (${item.value})`;
            },
        },
        tooltip: {
            formatter: (datum: any) => {
                return { name: datum.label, value: datum.percentual + "%" };
            },
        },
    };

    const handleCollapseData = () => {
        setOpenCollapseData(!openCollapseData);
    };

    const handleCollapseDataSales = () => {
        setOpenCollapseDataSales(!openCollapseDataSales);
    };

    const adjustDateMonth = (dataString1: string, dataString2: string) => {
        const [dia1, mes1, ano1] = dataString1.split("/");
        const [dia2, mes2, ano2] = dataString2.split("/");

        const dataInicio1 = moment(`${ano1}-${mes1}-${dia1}`, "YYYY-MM-DD");
        const dataInicio2 = moment(`${ano2}-${mes2}-${dia2}`, "YYYY-MM-DD");

        setDataStart(dataInicio1);
        setDataEnd(dataInicio2);
    };

    return (
        <S.Container>
            <Header />
            <S.Content>
                <S.SelectedOption>
                    <h3>Dashboard</h3>
                    <h4>|</h4>
                    <S.Filters>
                        <S.FiltersRow>
                            <S.PanelInputSelected>
                                <img
                                    src={iconCalendar}
                                    style={{
                                        paddingLeft: "20px",
                                    }}
                                    alt="Calendario"
                                />
                                <RangePicker
                                    style={{ border: 0 }}
                                    format={"DD/MM/YYYY"}
                                    allowClear={false}
                                    value={[dataStart, dataEnd]}
                                    onChange={(_, dateString) => {
                                        if (dateString) {
                                            adjustDateMonth(dateString[0], dateString[1]);
                                        }
                                    }}
                                />
                            </S.PanelInputSelected>

                            <S.PanelInputSelected>
                                <label>Segmento:</label>

                                <SelectAntd className="filter-select"  dropdownClassName="large-dropdown" onChange={handleChangeField} placeholder="Todos" value={segmentField?.iD_Ibratan}>
                                    <SelectAntd.Option key={0} value={"0"}>
                                        Todos
                                    </SelectAntd.Option>

                                    {segmentOptions.map(({ id, iD_Ibratan, type, name }) => (
                                        <SelectAntd.Option key={id?.toString()} value={iD_Ibratan}>
                                            {findPersonType(type)?.label} - {name}
                                        </SelectAntd.Option>
                                    ))}
                                </SelectAntd>
                            </S.PanelInputSelected>

                            <S.PanelInputSelected>
                                <label>Tipo de Cliente:</label>

                                <SelectAntd className="filter-select" onChange={handleChangedSelectedFisicaJuridica} placeholder="Todos" value={selectedFisicaJuridica}>
                                    <SelectAntd.Option key={-1} value={"-1"}>
                                        Todos
                                    </SelectAntd.Option>

                                    <SelectAntd.Option key={"F"} value={"F"}>
                                        Física
                                    </SelectAntd.Option>
                                    <SelectAntd.Option key={"J"} value={"J"}>
                                        Jurídica
                                    </SelectAntd.Option>
                                </SelectAntd>
                            </S.PanelInputSelected>
                            {showAdminDash && (
                                <S.PanelInputSelected>
                                    <label>Concessionária:</label>

                                    <SelectAntd dropdownClassName="medium-dropdown" className="filter-select" onChange={handleChangeDealer} placeholder="Todas" value={selectedDealer}>
                                        <SelectAntd.Option key={0} value={"0"}>
                                            Todas
                                        </SelectAntd.Option>

                                        {dealersList.map(({ id, name }) => (
                                            <SelectAntd.Option key={id?.toString()} value={id}>
                                                {name}
                                            </SelectAntd.Option>
                                        ))}
                                    </SelectAntd>
                                </S.PanelInputSelected>
                            )}
                            {showAdminDash && selectedDealer && selectedDealer !== 0 && (
                                <S.PanelInputSelected>
                                    <label>Vendedor:</label>

                                    <SelectAntd dropdownClassName="medium-dropdown" className="filter-select" onChange={handleChangeSeller} placeholder="Todos" value={selectedSeller}>
                                        <SelectAntd.Option key={0} value={0}>
                                            Todos
                                        </SelectAntd.Option>

                                        {sellers.map(({ fullname, userId }) => (
                                            <SelectAntd.Option key={userId?.toString()} value={userId}>
                                                {fullname}
                                            </SelectAntd.Option>
                                        ))}
                                    </SelectAntd>
                                </S.PanelInputSelected>
                            )}
                        </S.FiltersRow>

                        <S.FiltersRow></S.FiltersRow>
                    </S.Filters>
                    <Button data-testid="btn_clear_filters" type="link" onClick={handleCleanFilters}>
                        Limpar filtros
                    </Button>
                </S.SelectedOption>
            </S.Content>

            <S.PainelChart>
                <S.HeaderCollapse onClick={handleCollapseData}>
                    <span>Pedidos Criados</span>
                    {openCollapseData ? <IoIosArrowDown /> : <IoIosArrowForward />}
                </S.HeaderCollapse>
                <hr />
                <Collapse in={openCollapseData} timeout="auto" unmountOnExit>
                    <S.ContentCharts>
                        <S.Painel>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Pedidos Criados</S.Title>
                                    <S.SubTitle>Qtd. Total</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>
                            <S.ContentChart>
                                <Column {...configTotalsOrdersByMonthAsync} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.Painel>

                        <S.Painel>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Pedidos Criados</S.Title>
                                    <S.SubTitle>Por Segmento</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>

                            <S.ContentChart>
                                <Bar {...createChartConfig(totalOrdersBySegmentAsync, "value", "label")} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.Painel>

                        <S.Painel>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Pedidos Criados</S.Title>
                                    <S.SubTitle>% Por Tipo de Cliente</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>

                            <S.ContentChart>
                                <Column {...configCreatedOrdersByClientTypesAsync} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.Painel>
                    </S.ContentCharts>
                    <S.ContentCharts>
                        <S.PainelLine>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Pedidos Criados</S.Title>
                                    <S.SubTitle>Por Status</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>

                            <S.ContentChart>
                                <Column {...configTotalOrdersByStatusAsync} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.PainelLine>
                    </S.ContentCharts>
                    <S.ContentCharts>
                        <S.PainelLine>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Pedidos Criados</S.Title>
                                    <S.SubTitle>Quantidade por Cliente</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>

                            <S.ContentChart>
                                <Bar {...createChartConfig(bestClientsBySellerAsync, "value", "label")} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.PainelLine>
                    </S.ContentCharts>
                </Collapse>
            </S.PainelChart>

            <S.PainelChart>
                <S.HeaderCollapse onClick={handleCollapseDataSales}>
                    <span>Vendas</span>
                    {openCollapseDataSales ? <IoIosArrowDown /> : <IoIosArrowForward />}
                </S.HeaderCollapse>
                <hr />
                <Collapse in={openCollapseDataSales} timeout="auto" unmountOnExit>
                    {showAdminDash && (
                        <>
                            <S.ContentCharts>
                                <S.Painel>
                                    <S.ContentPainelChart>
                                        <S.ContentPainelChartText>
                                            <S.Title>Contratos Assinados</S.Title>
                                            <S.SubTitle>Por plano</S.SubTitle>
                                        </S.ContentPainelChartText>
                                    </S.ContentPainelChart>

                                    <S.ContentChart>
                                        <Bar {...createChartConfig(totalVehiclesByDeadlineAndMonthlyKmValueAsync, "value", "label")} onReady={(chartInstance) => (chart = chartInstance)} />
                                    </S.ContentChart>
                                </S.Painel>
                                <S.Painel>
                                    <S.ContentPainelChart>
                                        <S.ContentPainelChartText>
                                            <S.Title>Contratos Assinados</S.Title>
                                            <S.SubTitle>Por Concessionária</S.SubTitle>
                                        </S.ContentPainelChartText>
                                    </S.ContentPainelChart>

                                    <S.ContentChart>
                                        <Bar {...createChartConfig(dealershipRankingByOrdersAsync, "value", "label")} onReady={(chartInstance) => (chart = chartInstance)} />
                                    </S.ContentChart>
                                </S.Painel>

                                <S.Painel>
                                    <S.ContentPainelChart>
                                        <S.ContentPainelChartText>
                                            <S.Title>Contratos Assinados</S.Title>
                                            <S.SubTitle>% Por Tipo de Cliente</S.SubTitle>
                                        </S.ContentPainelChartText>
                                    </S.ContentPainelChart>
                                    <S.ContentChart>
                                        <Column {...configDealershipOrdersByClientTypesAsync} onReady={(chartInstance) => (chart = chartInstance)} />
                                    </S.ContentChart>
                                </S.Painel>
                            </S.ContentCharts>
                            <S.ContentCharts>
                                <S.Painel>
                                    <S.ContentPainelChart>
                                        <S.ContentPainelChartText>
                                            <S.Title>Contratos Assinados</S.Title>
                                            <S.SubTitle>Por Canal</S.SubTitle>
                                        </S.ContentPainelChartText>
                                    </S.ContentPainelChart>

                                    <S.ContentChart>
                                        <Column {...configDealershipOrdersByChannelAsync} onReady={(chartInstance) => (chart = chartInstance)} />
                                    </S.ContentChart>
                                </S.Painel>

                                <S.Painel>
                                    <S.ContentPainelChart>
                                        <S.ContentPainelChartText>
                                            <S.Title>Contratos Assinados</S.Title>
                                            <S.SubTitle>Ranking de Vendedores</S.SubTitle>
                                        </S.ContentPainelChartText>
                                    </S.ContentPainelChart>

                                    <S.ContentChart>
                                        <Bar {...createChartConfig(signedContractsBySellerAsync, "value", "label")} onReady={(chartInstance) => (chart = chartInstance)} />
                                    </S.ContentChart>
                                </S.Painel>

                                <S.Painel>
                                    <S.ContentPainelChart>
                                        <S.ContentPainelChartText>
                                            <S.Title>Contratos Assinados</S.Title>
                                            <S.SubTitle>Quantidade de veículos por modelo</S.SubTitle>
                                        </S.ContentPainelChartText>
                                    </S.ContentPainelChart>

                                    <S.ContentChart>
                                        <Bar {...createChartConfig(signedContractsByModelForDealershipAsync, "value", "label")} onReady={(chartInstance) => (chart = chartInstance)} />
                                    </S.ContentChart>
                                </S.Painel>
                            </S.ContentCharts>
                            <S.ContentCharts>
                                <S.Painel>
                                    <S.ContentPainelChart>
                                        <S.ContentPainelChartText>
                                            <S.Title>Contratos Assinados</S.Title>
                                            <S.SubTitle>Por Segmento</S.SubTitle>
                                        </S.ContentPainelChartText>
                                    </S.ContentPainelChart>

                                    <S.ContentChart>
                                        <Bar {...createChartConfig(dealershipTotalOrdersBySegmentAsync, "value", "label")} onReady={(chartInstance) => (chart = chartInstance)} />
                                    </S.ContentChart>
                                </S.Painel>
                            </S.ContentCharts>
                        </>
                    )}

                    
                    {!showAdminDash && (
                        <>
                        <S.ContentCharts>
                        <S.Painel>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Contratos Assinados</S.Title>
                                    <S.SubTitle>Qtd. de Veículos</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>
                            <S.ContentChart>
                                <Column {...createChartConfig(totalsOrderItemsSoldByMonthAsync, "label", "value")} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.Painel>

                        <S.Painel>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Contratos Assinados</S.Title>
                                    <S.SubTitle>Qtd. de Veículos por Segmento</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>

                            <S.ContentChart>
                                <Bar {...createChartConfig(creditAnalysisOrdersAsync, "value", "label")} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.Painel>

                        <S.Painel>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Contratos Assinados</S.Title>
                                    <S.SubTitle>% por Tipo de Cliente</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>

                            <S.ContentChart>
                                <Column {...configSignedContractsByClientTypesAsync} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.Painel>
                    </S.ContentCharts>
                    <S.ContentCharts>
                        <S.LinePainel>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Contratos Assinados</S.Title>
                                    <S.SubTitle>Qtd. de Veículos por Modelo</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>

                            <S.ContentChart>
                                <Bar {...createChartConfig(signedContractsByModelAsync, "value", "label")} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.LinePainel>

                        <S.LinePainel>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Contratos Assinados</S.Title>
                                    <S.SubTitle>Ranking de Vendedores</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>

                            <S.ContentChart>
                                <Bar {...createChartConfig(totalsOrdersBySellerAsync, "value", "label")} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.LinePainel>

                        <S.LinePainel>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Contratos Assinados</S.Title>
                                    <S.SubTitle>Quantidade por Franquia de KM</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>

                            <S.ContentChart>
                                <Column {...createChartConfig(signedContractsByFranchiseAsync, "label", "value")} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.LinePainel>
                    </S.ContentCharts>
                    <S.ContentCharts>
                        <S.LinePainel>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Contratos Assinados</S.Title>
                                    <S.SubTitle>Qtd. por Prazo de Contrato</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>
                            <S.ContentChart>
                                <Column {...createChartConfig(totalVehiclesByDeadlineAsync, "label", "value")} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.LinePainel>

                        <S.LinePainel>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Contratos Assinados</S.Title>
                                    <S.SubTitle>Ticket Médio por Veículo</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>

                            <S.ContentChart>
                                <Bar {...createCurrencyChartConfig(getAverageTicketPerVehicleAsync, "value", "label")} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.LinePainel>

                        <S.LinePainel>
                            <S.ContentPainelChart>
                                <S.ContentPainelChartText>
                                    <S.Title>Contratos Assinados</S.Title>
                                    <S.SubTitle>Qtd. Veículo por Cliente</S.SubTitle>
                                </S.ContentPainelChartText>
                            </S.ContentPainelChart>

                            <S.ContentChart>
                                <Bar {...createChartConfig(signedContractsByClientVehicleQuantityAsync, "value", "label")} onReady={(chartInstance) => (chart = chartInstance)} />
                            </S.ContentChart>
                        </S.LinePainel>
                    </S.ContentCharts>
                        </>
                    )}
                    

                    


                </Collapse>
            </S.PainelChart>

            <Footer />
        </S.Container>
    );
};

export default Dashboard;
