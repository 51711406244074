import React, {
  createContext,
  FC,
  useCallback,
  useState,
  useContext
} from 'react'
import api from '../services/api'

interface SignInCredentials {
  login: string
  password: string
  dealership: boolean
}

interface AuthState {
  token: string
  fullname: string
  userId: string
  dealershipId: string
  dealershipGroupId: string
  role: string
  roleId: string
  vendedorMaster: boolean
}

interface AuthContextData {
  fullname: string
  signIn(credentials: SignInCredentials): Promise<void>
  signOut(): void
  userId: string
  dealershipId: string
  dealershipGroupId: string
  role: string
  roleId: string
  vendedorMaster: boolean
}

export const getToken = () => localStorage.getItem('@FleetSolutions:token')

export const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@FleetSolutions:token')
    const fullname = localStorage.getItem('@FleetSolutions:fullname')
    const userId = localStorage.getItem('@FleetSolutions:userId')
    const dealershipId = localStorage.getItem('@FleetSolutions:dealershipId')
    const dealershipGroupId = localStorage.getItem(
      '@FleetSolutions:dealershipGroupId'
    )
    const role = localStorage.getItem('@FleetSolutions:role')
    const roleId = localStorage.getItem('@FleetSolutions:roleId')
    const vendedorMaster =
      localStorage.getItem('@FleetSolutions:vendedorMaster') === 'true'
        ? true
        : false
    if (
      token &&
      fullname &&
      userId &&
      dealershipId &&
      dealershipGroupId &&
      role &&
      roleId
    ) {
      return {
        token,
        fullname,
        userId,
        dealershipId,
        dealershipGroupId,
        role,
        roleId,
        vendedorMaster
      }
    }

    return {} as AuthState
  })

  const signIn = useCallback(async ({ login, password }) => {
    const response = await api.post('/signin/dealership', {
      login,
      password,
      dealership: true,
      app: 'canalindireto'
    })

    const {
      token,
      fullname,
      userId,
      dealershipId,
      dealershipGroupId,
      role,
      roleId,
      vendedorMaster
    } = response.data

    localStorage.setItem('@FleetSolutions:login', login)
    localStorage.setItem('@FleetSolutions:token', token)
    localStorage.setItem('@FleetSolutions:fullname', fullname)
    localStorage.setItem('@FleetSolutions:userId', userId)
    localStorage.setItem('@FleetSolutions:dealershipId', dealershipId)
    localStorage.setItem('@FleetSolutions:dealershipGroupId', dealershipGroupId)
    localStorage.setItem('@FleetSolutions:role', role)
    localStorage.setItem('@FleetSolutions:roleId', roleId)
    localStorage.setItem('@FleetSolutions:vendedorMaster', vendedorMaster)
    localStorage.setItem('@FleetSolutions:uploadFileDocumentoUser', 'false')

    setData({
      token,
      fullname,
      userId,
      dealershipId,
      dealershipGroupId,
      role,
      roleId,
      vendedorMaster
    })
  }, [])

  const signOut = useCallback(() => {
    localStorage.clear()

    setData({} as AuthState)

    const chat = document.getElementById('znv-chat-auto-0')

    if (chat) {
      chat.style.display = 'none'
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        fullname: data.fullname,
        userId: data.userId,
        signIn,
        signOut,
        dealershipId: data.dealershipId,
        dealershipGroupId: data.dealershipGroupId,
        role: data.role,
        roleId: data.roleId,
        vendedorMaster: data.vendedorMaster
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}
