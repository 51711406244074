export const REPORTS_ORDERS_URL = `/reports-orders`

export const REPORTS_ORDERS_TYPE_URL = '/reports-orders/TypeOrdersEnum'

export const REPORTS_DEALERSHIP_URL = '/reports-dealership'

export const REPORTS_USERS_URL = '/reports-users'

export const REPORTS_CUSTOMERS_URL = '/reports-clients'

export const REPORTS_ENTREGA_URL = '/reports-orders/GetOrderReportDelivery'

export const REPORTS_STOCKS_URL = (segmentId: string) =>
  `/reports-stocks/${segmentId}`

export const REPORTS_STOCKS_SEGMENTS = '/reports-stocks/SegmentsEnum'

export const STOCK_URL = '/reports-stocks/Values'
export const PRODUCT_IMAGE_URL = '/offers/listProductImageAsync'

export const UPDATE_STOCK_URL = '/stock/UpdateStockBatch'
export const UPDATE_DELIVERY_DEADLINE_URL = '/offers/deadline-info'

export const INVENTORY_MANAGEMENT_FILTERS_URL = '/reports-stocks/Filters'

export const SEGMENTS_ACTIVE =
  '/segments/active'

export const SEGMENTS_FILTER =
  '/segments/filter'

export const DOWNLOAD_DOCX = '/s3files'

export const COMMISSIONING = '/kickbackgroup/getall'

export const KICKBACK_COMMISSIONING = '/kickbackgroup'

export const SEGMENTS = '/segments'

export const DEALERSHIP_SEGMENTS = '/dealerships/bysegments'

export const KICKBACK = '/offers/GetKickback'
