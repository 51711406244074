import styled from "styled-components";

export const StyledTableWrapper = styled.div`
    .ant-table-wrapper {
        overflow-y: scroll;
    }

    .ant-table {
        margin-bottom: 32px;
        table {
            border-bottom: 1px solid #dddddd;
        }
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td,
        .ant-table tfoot > tr > th,
        .ant-table tfoot > tr > td {
            padding: 10px 8px;
            letter-spacing: 0.4px;
        }

        .status-badge {
            font-weight: 700;
            border: 1px solid #333333;
            border-radius: 24px;
            background-color: #eeeeee;
            padding: 2px 10px;
            white-space: nowrap; /* Para garantir que o texto não quebre */
            overflow: hidden; /* Esconder o overflow */
            text-overflow: ellipsis; /* Mostrar elipse para textos longos */
            position: relative; /* Adicionar posição relativa */
            z-index: 1; /* Definir z-index menor */
        }

        .ant-table-tbody > tr > td:first-child {
            a {
                color: #0161af;
                font-weight: 700;
            }
        }
        .ant-table-tbody > tr > td:last-child {
            padding: 0;
            text-align: center;
            a {
                color: #1c1b1f;
                font-weight: bold;
                font-size: 23px;
            }
            &::before {
                content: "";
                width: 1px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            background-color: #333f46;
        }

        .ant-table-container table > thead > tr:first-child th:last-child {
            border-top-right-radius: 8px;
            text-align: center;
            &::before {
                content: "";
                width: 1px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                box-shadow: -5px -3px 7px #00000063;
            }
        }

        .ant-table-container table > thead > tr:first-child th:first-child {
            border-top-left-radius: 8px;
        }

        .ant-table-thead > tr > th {
            background-color: #3c505a;
            color: white;
            font-size: 12px;
            font-weight: 700;
            border-bottom: 1px solid #dddddd;
        }

        .ant-table-thead th.ant-table-column-has-sorters:hover,
        .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
        .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
            background-color: #3c505a;
        }

        .ant-table-tbody > tr:nth-child(odd),
        .ant-table-tbody > tr:nth-child(odd) .ant-table-cell-fix-left,
        .ant-table-tbody > tr:nth-child(odd) .ant-table-cell-fix-right {
            background-color: #f1f1f1;
        }

        .ant-table-tbody > tr:nth-child(even),
        .ant-table-tbody > tr:nth-child(even) .ant-table-cell-fix-left,
        .ant-table-tbody > tr:nth-child(even) .ant-table-cell-fix-right {
            background-color: #f9f9f9;
        }

        /* Estilo para hover em linhas ímpares */
        .ant-table-tbody > tr:nth-child(odd):hover > td,
        .ant-table-tbody > tr:nth-child(odd):hover .ant-table-cell-fix-left,
        .ant-table-tbody > tr:nth-child(odd):hover .ant-table-cell-fix-right {
            background-color: #f1f1f1 !important; /* Cor de hover para linhas ímpares */
        }

        /* Estilo para hover em linhas pares */
        .ant-table-tbody > tr:nth-child(even):hover > td,
        .ant-table-tbody > tr:nth-child(even):hover .ant-table-cell-fix-left,
        .ant-table-tbody > tr:nth-child(even):hover .ant-table-cell-fix-right {
            background-color: #f9f9f9 !important; /* Cor de hover para linhas pares */
        }

        .ant-table-tbody > tr > td {
            font-size: 12px;
            font-weight: 400;
        }
    }

    .ant-pagination {
        margin: 16px 0;
        text-align: right;
    }

    .ant-table-column-sorter {
        color: #fff;
    }

    .ant-table-cell-ellipsis {
        .ant-table-filter-column {
            justify-content: flex-start;
            .ant-table-column-title {
                flex: none;
            }
        }
    }

    .ant-pagination-options {
        display: none;
    }

    .ant-pagination-item, .ant-pagination-item-link {
        border-radius: 50%;
        font-size: 12px;
        border-color: #333333;
    }

    .ant-pagination-item-active {
        color: #0161af !important;
        border-color: #0161af !important;
    }
`;

export const PageContainer = styled.div`
    background: #f1f1f1;
    // Estilos adicionais para o contêiner da página, se necessário
    * {
        font-family: "DM Sans", sans-serif;
    }

    .view-selection {
        .ant-select-selector {
            background: none !important;
            color: #333333;
            font-weight: 700 !important;
            .ant-select-selection-item {
                font-weight: 700;
                font-size: 14px;
                letter-spacing: 0.01;
            }

            .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
                border: none;
                box-shadow: none;
            }
        }
        .ant-select-arrow {
            color: #333333;
        }
    }

    .statusCardList {
        display: flex;
        gap: 8px;
        .ant-col {
            flex: 1;
        }
    }

    .new-view {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        margin-right: 16px;
        margin-left: auto;
        justify-content: flex-end;
        padding-top: 16px;
    }
`;

export const Content = styled.div`
    // Estilos adicionais para o contêiner da página, se necessário
    width: 95%;
    margin: 0 auto;
`;

export const StyledCard = styled.div`
    // Estilos adicionais para o contêiner da página, se necessário
    * {
        font-family: "DM Sans", sans-serif;
    }
`;

export const PageHead = styled.div`
    // Estilos adicionais para o contêiner da página, se necessário
    display: flex;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid #e0e0e0;
    padding: 16px 5px;
    margin-bottom: 16px;
    h1 {
        margin: 0;
        color: #3c505a;
        font-size: 22px;
        font-weight: 700;
    }

    .ant-picker {
        border: 1px solid #e0e0d9 !important;
        padding: 8px 16px;
        border-radius: 23px;
    }
    .orders-totals {
        color: #3c505a;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        align-items: center;
        line-height: 1.1;
        gap: 8px;
        span {
            font-size: 22px;
            font-weith: 700;
        }
    }
    .separator {
        width: 1px;
        height: 35px;
        background: #e0e0e0;
    }

    .ant-btn {
        background: #0161af;
        color: #fff;
        border: none;
        font-size: 14px;
        font-weight: 700;
        padding: 9px 20px 9px 20px;
        border-radius: 23px;
        height: auto;
    }
`;


export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 21px;
    color: #0c5faf;
    font-weight: 600;
  }

  p {
    font-size: 17px;
    color: #3c505a;
  }
`

export const ContentModalContrato = styled.div`
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

p {
  margin: 20px 0 0 0;
  font-size: 16px;
  color: #3c505a;
}

div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button {
    width: 78px;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    background: #0c5faf;
    margin: 10px;
    color: white;
    border: none;
    height: 30px;
    &.cancel{
    background-color:#868686}
  }
}`


export const ContractModal = styled.div`

`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  button {
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    background: #0c5faf;
    margin: 10px;
    color: white;
    border: none;
    height: 30px;
    padding: 4px 14px;
    &.cancel{
    background-color:#868686}
  }
`