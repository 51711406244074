/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from 'react'
import { Switch, Button, Input, Select, Modal } from 'antd'
import qs from 'qs'

import * as S from './styles'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Table from 'components/Table'
import api from 'services/api'
import {
  SEGMENTS_ACTIVE,
  STOCK_URL,
  UPDATE_STOCK_URL,
  UPDATE_DELIVERY_DEADLINE_URL
} from 'utils/urls'
import { useToast } from 'hooks/toast'
import Loading from 'components/Loading'
import { vwPF } from 'utils/segment'
import { useHistory } from 'react-router-dom'
import { findPersonType } from 'utils/helpers'

interface IStockUpdate {
  stockId: number
  quantity: number | undefined
}
interface IFilters {
  segment?: string
  modelCode?: string
  model?: string
  optional?: string
  status?: boolean
}

interface IFilterOptions {
  modelCode: Array<string>
  model: Array<string>
  optional: Array<string>
}

const DEFAULT_SEGMENT = vwPF

const InventoryManagement = () => {
  const { addToast } = useToast()
  const history = useHistory()

  const [segments, setSegments] = useState([])
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>()
  const [filters, setFilters] = useReducer(
    (prev: IFilters, value: IFilters) => ({ ...prev, ...value }),
    { segment: DEFAULT_SEGMENT, status: true }
  )
  const [inputs, setInputs] = useState({ model: '', optional: '' })
  const [stocks, setStocks] = useState([])
  const [stockUpdate, setStockUpdate] = useState<Array<IStockUpdate>>([])
  const [showModal, setShowModal] = useState(false)
  const [showModalDeliveryDeadline, setShowModalDeliveryDeadline] =
    useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [textDeliveryDeadLine, setTextDeliveryDeadLine] = useState('')
  const [stockId, setStockId] = useState(0)

  const loadSegments = () => {
    api
      .get(SEGMENTS_ACTIVE)
      .then(({ data }) => {
        setSegments(data)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível listar os segmentos`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
  }

  const loadStocks = () => {
    api
      .get(STOCK_URL, {
        params: { ...filters },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        }
      })
      .then(({ data }) => {
        const updatedOptionalValues = data.values.map((stock: { optional: string }) => {
          const modifiedOptional = stock.optional.replace(/\|opt=/i, '');
          return { ...stock, optional: modifiedOptional };
        });
        setStocks(updatedOptionalValues)
        setFilterOptions(data.filters)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível listar os estoques`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
  }

  const loadFilterOptions = () => {
    // api
    //   .get(INVENTORY_MANAGEMENT_FILTERS_URL, {
    //     params: { ...filters },
    //     paramsSerializer: function (params) {
    //       return qs.stringify(params, { arrayFormat: 'brackets' })
    //     }
    //   })
    //   .then(({ data }) => {
    //     setFilterOptions(data)
    //   })
    //   .catch(() => {
    //     addToast({
    //       type: 'error',
    //       title: `Não foi possível carregar os filtros`,
    //       description: 'Por favor, entre em contato com o suporte.'
    //     })
    //   })
  }

  const updateStocks = () => {
    api
      .put(UPDATE_STOCK_URL, {
        stockUpdate
      })
      .then(() => {
        addToast({
          type: 'success',
          title: `Sucesso ao atualizar os estoques`,
          description:
            'Clique em atualizar para visualizar as quantidades alteradas'
        })
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível atualizar os estoques`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
      .finally(() => {
        setIsLoading(false)
        setShowModal(false)
      })
  }

  const updateDeliveryDeadLine = () => {
    api
      .post(UPDATE_DELIVERY_DEADLINE_URL, {
        StockId: stockId,
        DeadlineInfo: textDeliveryDeadLine
      })
      .then(() => {
        setTextDeliveryDeadLine('')
        setStockId(0)
        loadStocks()
        addToast({
          type: 'success',
          title: `Sucesso ao atualizar o prazo de entrega`,
          description: 'Clique em atualizar para visualizar'
        })
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível atualizar o prazo de entrega`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
      .finally(() => {
        setIsLoading(false)
        setShowModalDeliveryDeadline(false)
      })
  }

  useEffect(() => {
    loadSegments()
  }, [])

  useEffect(() => {
    loadFilterOptions()
    loadStocks()
  }, [filters])

  const handleChangeSelect = (name: string, value: string) => {
    setFilters({ [name]: value })
  }

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget
    setInputs((prev) => ({ ...prev, [name]: value }))
  }

  const handleSearch = (name: string, value: string) => {
    setFilters({ [name]: value })
  }

  const handleChangeInactive = (value: boolean) => {
    setFilters({ status: !value })
  }

  const handleCleanFilters = () => {
    setInputs({ model: '', optional: '' })
    setFilters({
      segment: DEFAULT_SEGMENT,
      modelCode: undefined,
      model: '',
      optional: '',
      status: true
    })
  }

  const handleUpdate = () => {
    loadStocks()
  }

  const handleAdd = () => {
    setShowModal(true)
  }

  const handleReturn = () => {
    history.push('/my-orders')
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleAddModal = () => {
    setIsLoading(true)
    updateStocks()
  }

  const handleAddModalDeliveryDeadline = () => {
    setIsLoading(true)
    updateDeliveryDeadLine()
  }

  const handleCloseModalDeliveryDeadline = () => {
    setShowModalDeliveryDeadline(false)
  }

  const handleModalDeliveryDeadline = (stockId: number) => {
    setStockId(stockId)
    api.get(`/offers/deadline-info/${stockId}`).then((res) => {
      const { data } = res
      setTextDeliveryDeadLine(data[0].deadlineInfo)
    })
    setShowModalDeliveryDeadline(true)
  }

  const handlerDeliveryDeadline = (event: any) => {
    const { value } = event.currentTarget
    setTextDeliveryDeadLine(value)
  }

  return (
    <S.Container>
      <Header />
      <h1 data-testid="title_page" className="title">
        Gestão contador de estoque
      </h1>
      <p data-testid="description">
        Selecione o segmento para gerenciar os veículos
      </p>

      <S.Filter>
        <S.WrapperFilters>
          <S.Wrapper>
            <span>Segmento</span>

            <Select
              className="select-segment input-medium"
              placeholder="Selecione o segmento"
              data-testid="segment"
              value={filters.segment}
              onChange={(value) => handleChangeSelect('segment', value)}
            >
              {segments.map(({ id, iD_Fleet, type, name }) => (
                <Select.Option key={id} value={String(iD_Fleet)}>
                  {findPersonType(type)?.label} - {name}
                </Select.Option>
              ))}
            </Select>
          </S.Wrapper>

          <S.Wrapper>
            <span>Código do modelo</span>

            <Select
              className="select-segment model-code"
              placeholder="Selecione"
              data-testid="model_code"
              value={filters.modelCode}
              onChange={(value) => handleChangeSelect('modelCode', value)}
            >
              {filterOptions?.modelCode.map((item, index) => (
                <Select.Option key={index} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </S.Wrapper>

          <S.Wrapper>
            <span>Modelo</span>

            <Input.Search
              className="input-model"
              name="model"
              data-testid="model"
              type="text"
              placeholder="Digite o modelo"
              value={inputs.model}
              onSearch={(value) => handleSearch('model', value)}
              onChange={handleChangeInput}
            />
          </S.Wrapper>
          <S.Wrapper>
            <span>Opcionais</span>

            <Input.Search
              name="optional"
              data-testid="optional"
              type="text"
              placeholder="Digite o opcional"
              value={inputs.optional}
              onSearch={(value) => handleSearch('optional', value)}
              onChange={handleChangeInput}
            />
          </S.Wrapper>
        </S.WrapperFilters>
        <S.FilterActions>
          <S.WrapperSwitch>
            <span>Mostrar inativos</span>
            <Switch
              data-testid="show_inactives"
              checked={!filters.status}
              onChange={handleChangeInactive}
            />
          </S.WrapperSwitch>
          <Button
            data-testid="btn_clear_filters"
            type="link"
            onClick={handleCleanFilters}
          >
            Limpar filtros
          </Button>
        </S.FilterActions>
      </S.Filter>
      <Table
        stocks={stocks}
        stockUpdate={stockUpdate}
        setStockUpdate={setStockUpdate}
        handleModalDeliveryDeadline={handleModalDeliveryDeadline}
      />

      <S.WrapperButtons>
        <Button onClick={handleReturn}>Voltar</Button>
        <S.ButtonsRight>
          <Button onClick={handleUpdate}>Atualizar</Button>
          <Button onClick={handleAdd}>Adicionar</Button>
        </S.ButtonsRight>
      </S.WrapperButtons>

      <Modal
        onCancel={handleCloseModal}
        width="300"
        footer={null}
        visible={showModal}
      >
        <S.ModalContent>
          {isLoading ? (
            <>
              <Loading />

              <p className="message-wait">Aguarde...</p>
            </>
          ) : (
            <>
              <p className="title-message">Adicionar veículos</p>
              <p className="message">
                Tem certeza que deseja adicionar os veículos?
              </p>

              <S.WrapperButtonsModal>
                <Button
                  data-testid="btn-add-modal"
                  className="btn-add"
                  onClick={handleAddModal}
                >
                  Adicionar
                </Button>
                <Button
                  data-testid="btn-return-modal"
                  onClick={handleCloseModal}
                >
                  Voltar
                </Button>
              </S.WrapperButtonsModal>
            </>
          )}
        </S.ModalContent>
      </Modal>

      <Modal
        onCancel={handleCloseModalDeliveryDeadline}
        width="300"
        footer={null}
        visible={showModalDeliveryDeadline}
      >
        <S.ModalContent>
          {isLoading ? (
            <>
              <Loading />

              <p className="message-wait">Aguarde...</p>
            </>
          ) : (
            <>
              <p className="title-message">Prazo de entrega</p>
              <Input.TextArea
                id="textDeliveryDeadline"
                name="textDeliveryDeadline"
                maxLength={100}
                onChange={handlerDeliveryDeadline}
                value={textDeliveryDeadLine}
              />

              <S.WrapperButtonsModal>
                <Button
                  data-testid="btn-add-modal"
                  className="btn-add"
                  onClick={handleAddModalDeliveryDeadline}
                >
                  Definir
                </Button>
              </S.WrapperButtonsModal>
            </>
          )}
        </S.ModalContent>
      </Modal>
      <Footer />
    </S.Container>
  )
}

export default InventoryManagement
