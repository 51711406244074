import iconPF from '../assets/icons/user.svg'
import iconPJ from '../assets/icons/pj.svg'
import { IPersonType } from './types'

export const personTypes: IPersonType[] = [
  {
    label: 'Pessoa Física',
    icon: iconPF,
    type: 'PF'
  },
  {
    label: 'Pessoa Jurídica',
    icon: iconPJ,
    type: 'PJ'
  }
]
