/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { Modal } from 'antd'

export const info = (
  title: string,
  message: string,
  image?: string,
  width?: number
) => {
  Modal.info({
    title: title,
    width: width ? width : 1000,
    content: (
      <div>
        {image ? (
          <img src={image} alt="Imagem" />
        ) : (
          <p>
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </p>
        )}
      </div>
    ),
    onOk() {}
  })
}
