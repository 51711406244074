/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect, useCallback, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Input, Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import moment from 'moment'
import { AiTwotonePrinter } from 'react-icons/ai'
import ReactLoading from 'react-loading'
import { defaultBackendUrl } from 'services/api'
import ReactToPrint from 'react-to-print';
import { setTabs } from '../../store/modules/tabsRegisterUser/actions'
import {
  Container,
  Content,
  Wrapper,
  WrapperClient,
  WrapperAction,
  WrapperLine,
  Modal,
  ModalContainer,
  Notes,
  WrapperInfo,
  ContentObs,
  WrapperInfoRadioButton,
  ImageCar,
  InfoContract,
  TitleDescription,
  Description,
  ContentLine,
  LineVertical,
  InfoValueMonth,
  LineDescriptionDiscount,
  LineCupomDiscount
} from './styles'
import api from '../../services/api'
import formatValue from '../../utils/formatValue'
import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'
import { useWindowSizeHook } from '../../hooks/useWindowHooks'
import Button from '../../components/Button'
import { useSelector, useDispatch } from 'react-redux'
import noImageCar from 'assets/defaultImages/no-image.png'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { DownloadOutlined, TagOutlined } from '@ant-design/icons'
import { IState } from '../../store'
import { ILocation } from '../../store/modules/location/types'
import jsPDF from 'jspdf'
import ProposalPDF from 'components/ProposalPDF'
import html2canvas from "html2canvas";


declare global {
  interface Window {
    dataLayer: any
  }
}

window.dataLayer = window.dataLayer || []

export type ProductProps = {
  model: string
  modelYear: number
  deadline: number
  monthlyInstallment: number
  color: string
  vehicleValue: number
  productId: number
  modelCode: number
  accessoryText: string
  optionalText: string
  monthlyKmValue: number
  overrunKm: number
  hullFranchiseValue: number
  annualAmount:number
  
}

const OrderSummary: FC = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const tabId = useSelector<IState, ILocation>((state) => state.tabs)
  const history = useHistory()
  const dispatch = useDispatch()

  const finalPlateStorage = localStorage.getItem('@FleetSolutions:finalPlate')
  const finalPlate = finalPlateStorage && JSON.parse(finalPlateStorage)
  const clientId = Number(localStorage.getItem('@FleetSolutions:userIdPf'))

  const { idTab } = useParams<any>()
  const { addToast } = useToast()
  const { userId, fullname, signOut } = useAuth()
  const [addressDelivery, setAddressDelivery] = useState({} as any)
  const [product, setProduct] = useState<ProductProps>({} as any)
  const [productIdNew, setProductIdNew] = useState('')
  const [couponOfferValue, setCouponOfferValue] = useState(0)
  const [customer, setCustomers] = useState({} as any)
  const [user, setUsers] = useState({} as any)
  const [userRenew, setUserRenew] = useState(false as boolean)
  const [orderSummary, setOrderSummary] = useState('')
  const [segment, setSegment] = useState('')
  const [selectedPayment, setSelectedPayment] = useState(2)
  const [allowCreditCard, setAllowCreditCard] = useState(false)
  const [paymentTypes, setPaymentTypes] = useState<any>(null)

  const [showModal, setShowModal] = useState(false)
  const [disabledOrder, setDisabledOrder] = useState(false)

  const [locationPFId, setLocationPFId] = useState('0')
  const { width } = useWindowSizeHook()

  const [vendedorMaster, setVendedorMaster] = useState<boolean>(false)
  const segmentFleetId = localStorage.getItem('@FleetSolutions:segmentId')

  const [observacao, setObservacao] = useState<string>('')
  const [novoCliente, setNovoCliente] = useState<string>('')
  const [renovacao, setRenovacao] = useState<string>('')
  const [aluguelVeiculoAdicional, setAluguelVeiculoAdicional] =
    useState<string>('')
  const [substituicao, setSubstituicao] = useState<string>('')

  const [rbSolicitacao, setRbSolicitacao] = useState<string>('')

  const [cupomDiscountValue, setCupomDiscountValue] = useState('')
  const [currentAddress, setCurrentAddress] = useState<any>({} as any)
  const [error, setError] = useState('')
  const [visibleDiscount, setVisibleDiscount] = useState(false)
  const [blockButton, setBlockButton] = useState(false)
  const [dealerName, setDealerName] = useState<any>(null)
  const hiddenContainerRef = useRef<HTMLDivElement>(null);

  const deliveryOption = Number(
    localStorage.getItem('@FleetSolutions:selectedDelivery')
  )

  const getCurrentAddress = String(
    localStorage.getItem('@FleetSolutions:getCurrentAddress')
  )

  const getCurrentCity = String(
    localStorage.getItem('@FleetSolutions:cityforPickup')
  )

  const getCurrentUF = String(
    localStorage.getItem('@FleetSolutions:ufForPickUp')
  )

  const getCurrentUFId = String(
    localStorage.getItem('@FleetSolutions:ufForPickUpId')
  )

  useEffect(() => {
    if(getCurrentAddress && getCurrentAddress !== 'null' && deliveryOption === 3){
      tryRequest(2, `/location/address/currentAddress/${Number(localStorage.getItem('@FleetSolutions:userIdPf'))}`)
      .then((response:any) => {
        localStorage.removeItem('@FleetSolutions:getCurrentAddress')
      })
      .catch(error => {
        console.error('Falha após 2 tentativas:', error);
      });
    }
  });


  useEffect(() => {
    // if(segmentFleetId === `13` || segmentFleetId === `20` || segmentFleetId === `28`){
    //   setSelectedPayment(1)
    // }
    api.get(`/segments/by-id-fleet/${segmentFleetId}`).then(({ data }) => {
      const firstActiveSegment = data?.find(
        (item: { status: boolean }) => item?.status === true
      )
      setPaymentTypes(firstActiveSegment.paymentTypes?.split('|').map(Number))
      setSelectedPayment(firstActiveSegment.paymentTypes?.split('|').map(Number)[0])
      localStorage.setItem('@FleetSolutions:typePaymentChoise', firstActiveSegment.paymentTypes?.split('|').map(Number)[0])
      setAllowCreditCard(firstActiveSegment.allowCreditCard)
      setOrderSummary(firstActiveSegment?.orderSummary)
      setSegment(firstActiveSegment)
    })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!fullname) {
      history.push('/')
    }
    const dealershipId = localStorage.getItem('@FleetSolutions:dealershipId')
    const locationId = localStorage.getItem('@FleetSolutions:locationIdPf')


    const isVendedorMaster =
      localStorage.getItem('@FleetSolutions:vendedorMaster') === 'true'
        ? true
        : false
    setVendedorMaster(isVendedorMaster)

    if (dealershipId && locationId === null) {

      if(deliveryOption === 3){
        

        tryRequest(2, `/location/address/currentAddress/${Number(localStorage.getItem('@FleetSolutions:userIdPf'))}`)
        .then((response:any) => {
          localStorage.removeItem('@FleetSolutions:getCurrentAddress')
        })
        .catch(error => {
          console.error('Falha após 2 tentativas:', error);
        });

      }
      else{
        api.get(`/dealerships/${dealershipId}`).then((response: any) => {
          setAddressDelivery(response.data)
        })
      }

    } else if (locationId !== null) {
      setLocationPFId(locationId)
      api.get(`/location/${locationId}`).then((response: any) => {
        const address = {
          name: response.data.name,
          street: response.data.address,
          addressNumber: response.data.number
        }
        setAddressDelivery(address)
      })
    }

    if(dealershipId){
      api.get(`/dealerships/${dealershipId}`).then((response: any) => {
        
        setDealerName(response.data.name)
      })
    }

    getOrderByUserId()
  }, [])


  useEffect(() => {
    api
      .get(
        `/customers/${Number(localStorage.getItem('@FleetSolutions:userIdPf'))}`
      )
      .then((response: any) => {
        setCustomers(response.data)
      })
      .catch((error) => {
        if (error.response) {
          const { status } = error.response
          if (status === 401) {
            signOut()
            history.push('/')
          }
        }
      })

    api
      .get(`/users/${Number(localStorage.getItem('@FleetSolutions:userIdPf'))}`)
      .then((response: any) => {
        setUsers(response.data)
      })
      .catch((error) => {
        if (error.response) {
          const { status } = error.response
          if (status === 401) {
            signOut()
            history.push('/')
          }
        }
      })
  }, [product])

  useEffect(() => {
    
    if(tabId?.id?.toString() === '4'){
      
      const locationId = localStorage.getItem('@FleetSolutions:locationIdPf')
      if(deliveryOption === 1 && locationId){
        setLocationPFId(locationId)
        api.get(`/location/${locationId}`).then((response: any) => {
          const address = {
            name: response.data.name,
            street: response.data.address,
            addressNumber: response.data.number
          }
          setAddressDelivery(address)
        })
      }
      else if(deliveryOption === 3){
        tryRequest(2, `/location/address/currentAddress/${Number(localStorage.getItem('@FleetSolutions:userIdPf'))}`)
        .then((response:any) => {
          localStorage.removeItem('@FleetSolutions:getCurrentAddress')
        })
        .catch(error => {
          console.error('Falha após 2 tentativas:', error);
        });
      }
    }
  }, [tabId])

  function tryRequest(maxRetries:any, url:any) {
    return new Promise((resolve, reject) => {
      function attempt() {
        api.get(url)
          .then(response => {
            setCurrentAddress(response.data);
            resolve(response.data);
          })
          .catch(error => {
            if (error.response && error.response.status === 404 && maxRetries > 0) {
              maxRetries--;
              attempt();
            } else {
              reject(error);
            }
          });
      }
      attempt();
    });
  }
  

  const getOrderByUserId = useCallback(async () => {
    const productId = localStorage.getItem('@FleetSolutions:productId')
    if (productId !== null) {
      await api
        .get(
          `offers/getfromproductanduser?productId=${productId}&userId=${clientId}`
        )
        .then((res) => {
          setProduct(res.data)
          api
          .get(
            `/users/CheckUserRenew/${Number(
              localStorage.getItem('@FleetSolutions:userIdPf')
            )}`
          )
          .then((canRenewResponse: any) => {
            const { data } = canRenewResponse
            if(data){
              api
              .get(
                `offers?modelCode=${res.data.modelCode}&monthlyKmValue=${res.data.monthlyKmValue}&color=${res.data.color}&deadLine=${res.data.deadline}&kickback=${encodeURIComponent(res.data.kickback)}&segmentId=${segmentFleetId}&salesChannel=${encodeURIComponent('S&D - Renovação')}`
              )
              .then((responseOffer) => {
                
                const { data } = responseOffer
                if(data.length){
                  setUserRenew(true)
                }
              })
            }
          })
        })
    }
  }, [idTab])

  const verifyOrder = useCallback(async () => {
    setBlockButton(true)
    try {
      await api
        .get(
          `/upload/${Number(localStorage.getItem('@FleetSolutions:userIdPf'))}`
        )
        .then((response: any) => {
          const { data } = response

          if (
            data.cnhImage === null ||
            data.cnhImage === '' ||
            data.cnhBackImage === null ||
            data.cnhBackImage === '' ||
            data.addressImage === null ||
            data.addressImage === '' ||
            data.holeriteImage === null ||
            data.holeriteImage === ''
          ) {
            history.push('/list-dealership/upload-file')

            addToast({
              title: 'Envio de documentação',
              type: 'error',
              description: 'Envie a documentação antes de concluir o pedido.'
            })
          }
        })
        .catch((error) => {
          const { status } = error.response
          if (status === 401) {
            signOut()
            history.push('/')
          } else {
            history.push('/list-dealership/upload-file')

            addToast({
              title: 'Envio de documentação',
              type: 'error',
              description: 'Envie a documentação antes de concluir o pedido.'
            })
          }
        })
    } catch (err) {
      history.push('/list-dealership/upload-file')

      addToast({
        title: 'Envio de documentação',
        type: 'error',
        description: 'Envie a documentação antes de concluir o pedido.'
      })
    }

    if (rbSolicitacao === '') {
      addToast({
        title: 'Atenção',
        description: 'Selecionar uma opção para a solicitação!.',
        type: 'error'
      })
      return
    }

    setShowModal(true)
  }, [rbSolicitacao])

  const renderCheck = () => {
    return (
      <WrapperAction>
        <Button type="button" className="btn-back" onClick={handleBackPage}>
          Voltar
        </Button>
        <Button disabled={blockButton} onClick={verifyOrder}>Concluir</Button>
      </WrapperAction>
    )
  }

  const handleBackPage = () => {
    dispatch(setTabs({ id: '3' }))
  }

  const handleOrder = useCallback(async () => {
    setDisabledOrder(true)

    if (!customer.cellPhone || !user.cpfCnpj || !user.fullname || !user.email) {
      setDisabledOrder(false)
      setShowModal(false)
      setBlockButton(false)
      addToast({
        title: 'Preencha todos os dados do cliente',
        type: 'error'
      })

      return
    }

    let msg = ''
    // msg = novoCliente
    // if (renovacao !== '') msg = msg === '' ? renovacao : msg + ' - ' + renovacao
    // if (aluguelVeiculoAdicional !== '')
    //   msg =
    //     msg === ''
    //       ? aluguelVeiculoAdicional
    //       : msg + ' - ' + aluguelVeiculoAdicional
    // if (substituicao !== '')
    //   msg = msg === '' ? substituicao : msg + ' - ' + substituicao

    // msg = msg !== '' ? msg + ' - ' + observacao : observacao

    // const listOrderMessage = []
    // if (msg !== '') {
    //   const orderMessage = {
    //     message: msg,
    //     UserId: JSON.parse(userId),
    //     TypeMessage: 'Enviado'
    //   }
    //   listOrderMessage.push(orderMessage)
    // }

    msg = rbSolicitacao
    if (observacao !== '') msg += ' - ' + observacao

    const listOrderMessage = []
    if (msg !== '') {
      const orderMessage = {
        message: msg,
        UserId: JSON.parse(userId),
        TypeMessage: 'Enviado'
      }
      listOrderMessage.push(orderMessage)
    }
    
    try {
      let payload:any = {
        productId: productIdNew ? productIdNew : product.productId,
        coupon: productIdNew ? cupomDiscountValue : null,
        productIdOld: productIdNew ? product.productId : null,
        userId: clientId,
        dealershipId: Number(
          localStorage.getItem('@FleetSolutions:dealershipId')
        ),
        dealershipGroupId: Number(
          localStorage.getItem('@FleetSolutions:dealershipGroupId')
        ),
        userIdDn: JSON.parse(userId),
        locationId: locationPFId !== null ? parseInt(locationPFId) : 0,
        aproved: true,
        reanalysis: true,
        vendedorMaster: vendedorMaster,
        typePayment: selectedPayment,
        finalPlate,
        deliveryOption: deliveryOption,
        orderMessage: listOrderMessage.length > 0 ? listOrderMessage : null,
        origem: 'CI'
      }

      if(getCurrentCity && getCurrentUF && getCurrentUFId && deliveryOption === 5){
        payload = {...payload, city: getCurrentCity, state: Number(getCurrentUFId)}
      }

      await api
        .post('/orders', payload)
        .then(() => {
          setShowModal(false)

          addToast({
            title: 'Pedido criado',
            description: 'Seu pedido foi criado com sucesso.',
            type: 'success'
          })

          setTimeout(() => {
            history.push('/my-orders')
          }, 2000)
          setVisibleDiscount(false)
          setProductIdNew('')
          setCouponOfferValue(0)

          localStorage.removeItem('@FleetSolutions:canRenew')
          localStorage.removeItem('@FleetSolutions:planModel')
          localStorage.removeItem('@FleetSolutions:clientType')
          localStorage.removeItem('@FleetSolutions:productId')
          localStorage.removeItem('@FleetSolutions:userIdPf')
          localStorage.removeItem('@FleetSolutions:cpfClient')
          localStorage.removeItem('@FleetSolutions:typePaymentChoise')
          localStorage.removeItem('@FleetSolutions:locationIdPf')
          localStorage.removeItem('@FleetSolutions:selectedDelivery')
          localStorage.setItem(
            '@FleetSolutions:uploadFileDocumentoUser',
            'false'
          )
          localStorage.removeItem('@FleetSolutions:isSDBlindado')
        })
        .catch((error) => {
          if (error.response) {
            setDisabledOrder(false)
            setShowModal(false)

            addToast({
              title: 'Erro ao criar pedido',
              description: error.response.data,
              type: 'error'
            })
          }
        })
    } catch (err) {
      setDisabledOrder(false)
      setShowModal(false)

      return addToast({
        title: 'Falha ao finalizar pedido',
        description: err.response
          ? err.response.data
          : 'Falha na criação do pedido',
        type: 'error'
      })
    }
  }, [
    idTab,
    product,
    userId,
    user,
    customer,
    locationPFId,
    vendedorMaster,
    finalPlate,
    novoCliente,
    renovacao,
    aluguelVeiculoAdicional,
    substituicao,
    observacao,
    rbSolicitacao
  ])

  const formatCpf = (value: any) => {
    return value
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  }

  const formatPhone = (value: any) => {
    return value
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
  }

  const handleCardOrBullet = ({ target }: RadioChangeEvent) => {
    setSelectedPayment(target.value)
    localStorage.setItem('@FleetSolutions:typePaymentChoise', target.value)
  }

  const handleNoteChange = (event: any) => {
    localStorage.setItem('@FleetSolutions:note', event.target.value)
    setObservacao(event.target.value)
  }

  const OnChangeNovoCliente = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setNovoCliente(e.target.value)
    } else {
      setNovoCliente('')
    }
  }

  const OnChangeRenovacao = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setRenovacao(e.target.value)
    } else {
      setRenovacao('')
    }
  }

  const OnChangeAluguelVeiculoAdicional = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setAluguelVeiculoAdicional(e.target.value)
    } else {
      setAluguelVeiculoAdicional('')
    }
  }

  const handleInputChange = (e: any) => {
    const value = e.target.value
    setCupomDiscountValue(value)
  }

  const OnChangeSubstituicao = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSubstituicao(e.target.value)
    } else {
      setSubstituicao('')
    }
  }

  const OnChangeRBSolicitacao = (e: RadioChangeEvent) => {
    setRbSolicitacao(e.target.value)
    setBlockButton(false)
  }

  const handleRemoveCoupun = () => {
    setVisibleDiscount(false)
    setProductIdNew('')
    setCouponOfferValue(0)
    setCupomDiscountValue('')
    setError('')
  }

  const handleValidation = async () => {
    if (cupomDiscountValue) {
      await api
        .post('/coupon/validationAsync', {
          coupon: cupomDiscountValue,
          offerId: product.productId,
          document: user?.cpfCnpj,
          email: user?.email
        })
        .then((response) => {
          if (response.data) {
            if (response.data.error) {
              setVisibleDiscount(false)
              setProductIdNew('')
              setCouponOfferValue(0)
              setError(response.data.error.message)
            } else {
              setError('')
              setVisibleDiscount(true)
              setProductIdNew(response.data.data.couponOfferId)
              setCouponOfferValue(response.data.data.couponOfferValue)
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            setDisabledOrder(false)
            setShowModal(false)

            addToast({
              title: 'Erro ao criar pedido',
              description: error.response.data,
              type: 'error'
            })
          }
        })
    }
  }

  const proposalDate = () => {
    const currentDate = new Date();

    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString();

    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');

    const formattedDateTime = `${day}/${month}/${year} - ${hours}:${minutes}`;

    return formattedDateTime;
  }

  const handleGeneratePdf = () => {
    if (hiddenContainerRef.current) {
        // Usa html2canvas para capturar a altura real do conteúdo HTML
        html2canvas(hiddenContainerRef.current, {
            scale: 1,
            useCORS: true // Permite o uso de imagens de outras origens
        }).then((canvas) => {
            const contentWidth = canvas.width;
            const contentHeight = canvas.height;

            // Define as dimensões do PDF com base no conteúdo
            const pdfWidth = contentWidth; 
            const pdfHeight = contentHeight;

            // Cria o PDF com o tamanho ajustado para evitar múltiplas páginas
            const doc:any = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: [pdfWidth, pdfHeight], // Define o tamanho do PDF para caber todo o conteúdo
            });

            // Adiciona o conteúdo HTML ao PDF
            doc.setFont("Open Sans", "normal");
            doc.html(hiddenContainerRef.current as HTMLElement, {
                x: 0,
                y: 0,
                html2canvas: {
                    scale: 1,
                    useCORS: true, // Permite cross-origin
                },
                callback: function (doc:any) {
                    // Ajuste final: removendo páginas em branco
                    const totalPages = doc?.internal?.getNumberOfPages();
                    if (totalPages > 1) {
                        doc.deletePage(totalPages); // Remove a última página em branco
                    }

                    // Salva o PDF com todo o conteúdo em uma única página
                    doc.save("Proposta_Comercial_Canal_Indireto.pdf");
                },
            });
        });
    }
};

  return (
    <div>
      <Container windowWidth={width}>
        <div ref={hiddenContainerRef} style={{ position: "absolute", zIndex: "-9999999999", overflow: "hidden", top: 0 }}>
          <ProposalPDF parameters={{product:product?.productId, model:{modelCode: product?.modelCode}, seller:fullname, color:product?.color, dealer:dealerName, deadline:product?.deadline, monthKmValue:product?.monthlyKmValue, totalValue:formatValue(product?.annualAmount), contractValue: formatValue(product?.vehicleValue), overrunKM: formatValue(product?.overrunKm), segmentSummary: orderSummary, proposalDate: proposalDate(), segment:segment}} />
        </div>
        <h1>Resumo do pedido</h1>
        <p>Aqui você encontra todas as informações sobre o seu pedido</p>
        {userRenew && (
          <p className="message-user-renew">
            Desconto aplicado na parcela mensal e valor do veículo
          </p>
        )}

        <Content>
          <div className="wrapper-print">
            {/* <ReactToPrint
              trigger={() => 
              <button className="btn-print">
                <div className="print">
                  <AiTwotonePrinter color="#236FBC" size={25} /> Imprimir
                </div>
              </button>
              }
              content={() => componentRef.current}
            /> */}
            <button onClick={() => handleGeneratePdf()} className="btn-print">
              <div className="print">
                <DownloadOutlined color="#236FBC" size={30} /> Exportar PDF da Proposta
              </div>
            </button>
          </div>
          <WrapperLine>
            <InfoContract>
              <ImageCar
                  src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${product.modelCode}.webp`}
                  fallbacks={[
                    `${defaultBackendUrl}/s3/veiculos/canal-indireto/${product.modelCode}.png`,
                    `${noImageCar}`
                  ]}
                alt={product.model}
              />
            </InfoContract>
            <ContentLine>
              <WrapperLine>
                <InfoContract>
                  <TitleDescription>Modelo:</TitleDescription>
                  <Description>
                    {!isEmpty(product) ? product.model : ''}
                  </Description>
                </InfoContract>
                <InfoContract>
                  <TitleDescription>Prazo:</TitleDescription>
                  <Description>
                    {!isEmpty(product) ? product.deadline : ''} meses
                  </Description>
                </InfoContract>
                <InfoContract>
                  <TitleDescription>Ano:</TitleDescription>
                  <Description>
                    {!isEmpty(product) ? product.modelYear : ''}
                  </Description>
                </InfoContract>
                <InfoContract>
                  <TitleDescription>Cor:</TitleDescription>
                  <Description>
                    {!isEmpty(product) ? product.color : ''}
                  </Description>
                </InfoContract>
                <InfoContract>
                  <TitleDescription>Valor do veículo:</TitleDescription>
                  <Description>{formatValue(product?.vehicleValue)}</Description>
                </InfoContract>
              </WrapperLine>
              <hr
                style={{
                  width: '650',
                  border: '0',
                  marginTop: '20',
                  marginBottom: '20',
                  borderTop: '1px solid #6c6c6c'
                }}
              />
              {visibleDiscount ? (
                <WrapperLine>
                  <InfoContract>
                    <TitleDescription>Parcela Mensal:</TitleDescription>
                    <LineDescriptionDiscount>
                      <Description style={{ paddingRight: '5' }}>De</Description>
                      <Description style={{ textDecorationLine: 'line-through' }}>
                        {`${formatValue(product?.monthlyInstallment)}`}
                      </Description>
                      <Description style={{ paddingLeft: '5' }}>Por</Description>
                    </LineDescriptionDiscount>
                  </InfoContract>
                  <InfoValueMonth style={{ paddingTop: '10' }}>
                    {formatValue(couponOfferValue)}
                  </InfoValueMonth>
                  <LineVertical />
                  <LineCupomDiscount>
                    <TagOutlined rotate={270} style={{ padding: '5' }} />
                    Com o cupom {cupomDiscountValue}
                  </LineCupomDiscount>
                  <Button
                    style={{
                      width: '200',
                      marginTop: '-6',
                      background: '#f1f1f1',
                      color: '#0161af',
                      border: '1px solid #0161af'
                    }}
                    onClick={handleRemoveCoupun}
                  >
                    Remover Cupom
                  </Button>
                </WrapperLine>
              ) : (
                <WrapperLine>
                  <InfoContract>
                    <TitleDescription>Parcela Mensal:</TitleDescription>
                    <InfoValueMonth>
                      {formatValue(product?.monthlyInstallment)}
                    </InfoValueMonth>
                  </InfoContract>
                  <LineVertical />
                  <div>
                    <Input
                      id="search-client"
                      type="text"
                      name="search-client"
                      placeholder="Cupom Promocional"
                      style={{
                        height: '40',
                        width: '300',
                        borderColor: error ? 'red' : '#005faf'
                      }}
                      onChange={handleInputChange}
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                  </div>

                  <Button
                    style={{ width: '100', marginTop: '-6' }}
                    onClick={handleValidation}
                  >
                    Aplicar
                  </Button>
                </WrapperLine>
              )}
            </ContentLine>
          </WrapperLine>

          <WrapperLine>
            <div>
              {product.accessoryText && product.accessoryText !== '' && (
                <p>
                  <b>Acessórios:</b> {product.accessoryText}
                </p>
              )}

              {product.optionalText && product.optionalText !== '' && (
                <p>
                  <b>Opcionais:</b> {product.optionalText}
                </p>
              )}
            </div>
          </WrapperLine>
          <hr />
          <WrapperClient>
            <h2>Dados do cliente</h2>
            <div>
              <WrapperLine>
                <InfoContract>
                  <TitleDescription>Nome: </TitleDescription>
                  <Description>
                    {!isEmpty(user) ? user.fullname : 'N/D'}
                  </Description>
                </InfoContract>
                <InfoContract>
                  <TitleDescription>E-mail: </TitleDescription>
                  <Description>{!isEmpty(user) ? user.email : 'N/D'}</Description>
                </InfoContract>
                <InfoContract>
                  <TitleDescription>RG: </TitleDescription>
                  <Description>
                    {!isEmpty(customer) ? customer.rg : 'N/D'}
                  </Description>
                </InfoContract>
                <InfoContract>
                  <TitleDescription>CPF: </TitleDescription>
                  <Description>
                    {!isEmpty(user) ? formatCpf(user.cpfCnpj) : 'N/D'}
                  </Description>
                </InfoContract>
                <InfoContract>
                  <TitleDescription>Data de nascimento: </TitleDescription>
                  <Description>
                    {!isEmpty(customer)
                      ? moment(customer.birthDate).format('DD/MM/yyyy')
                      : 'N/D'}
                  </Description>
                </InfoContract>
                <InfoContract>
                  <TitleDescription>Celular: </TitleDescription>
                  <Description>
                    {!isEmpty(customer) ? formatPhone(customer.cellPhone) : 'N/D'}
                  </Description>
                </InfoContract>
              </WrapperLine>
              <WrapperLine>
                <InfoContract>
                  <TitleDescription>Endereço: </TitleDescription>
                  <Description>
                    {!isEmpty(customer)
                      ? `${customer.address.street}, ${customer.address.number} - ${customer.address.complement} - ${customer.address.district}, ${customer.address.city}`
                      : 'N/D'}
                  </Description>
                </InfoContract>
              </WrapperLine>
            </div>
          </WrapperClient>
          <Wrapper>
            <h2>Local de retirada</h2>
            {deliveryOption === 2 ? (
              <>
                <p>
                  {!isEmpty(customer)
                    ? `${customer.address.street}, ${customer.address.number} - ${customer.address.complement} - ${customer.address.district}, ${customer.address.city}`
                    : 'N/D'}
                </p>
              </>
              ) : deliveryOption === 3 ? (
              <>
                <p>
                  {!isEmpty(customer)
                    ? `${currentAddress?.street}, ${currentAddress?.number} - ${currentAddress?.complement} - ${currentAddress?.district}, ${currentAddress?.city}`
                    : 'N/D'}
                </p>
              </>
              ) : deliveryOption === 4 ? (
                <>
                  <p>
                    DN do pedido
                  </p>
                </>
              
            ) : deliveryOption === 5 ? (
              <>
                <p>
                  {getCurrentCity + ' - ' + getCurrentUF}
                </p>
              </>
            ) : (
              <>
                {!isEmpty(addressDelivery) ? (
                  <>
                    <p>Concessionária: {addressDelivery.name}</p>
                    <p>
                      Endereço: {addressDelivery.street},{' '}
                      {addressDelivery.addressNumber}
                    </p>
                  </>
                ) : (
                  'Selecione o local da retirada'
                )}
              </>
            )}
          </Wrapper>

          <Wrapper>
            <h2>Resumo do pedido</h2>
            {orderSummary && (
              <p dangerouslySetInnerHTML={{ __html: orderSummary }} />
            )}
          </Wrapper>
          <Wrapper>
              <h2>Forma de pagamento</h2>
              <p>
                <Radio.Group
                  onChange={handleCardOrBullet}
                  value={selectedPayment}
                >
                  {paymentTypes?.includes(2) && 
                    <Radio value={2}>Boleto</Radio>
                  }
                  {paymentTypes?.includes(1) && 
                    <Radio value={1}>Cartão de Crédito</Radio>
                  }
                </Radio.Group>
              </p>
            </Wrapper>
        </Content>
        <ContentObs>
          <h2>Observações</h2>

          <Notes name="notes" maxLength={100} onChange={handleNoteChange} />
        </ContentObs>
        <WrapperInfo>
          <h2>Solicitação</h2>
          <WrapperInfoRadioButton>
            <Radio.Group
              onChange={OnChangeRBSolicitacao}
              value={rbSolicitacao}
              size="large"
            >
              <Radio value="Novo Cliente">Novo Cliente</Radio>
              <Radio value="Renovação">Renovação</Radio>
              <Radio value="Aluguel de veículo adicional">
                Aluguel de veículo adicional
              </Radio>
              <Radio value="Substituição">Substituição</Radio>
            </Radio.Group>
          </WrapperInfoRadioButton>
        </WrapperInfo>
        {renderCheck()}
        {showModal && (
          <ModalContainer>
            <Modal>
              {disabledOrder ? (
                <ReactLoading type="spinningBubbles" color="#246FBC" />
              ) : (
                <>
                  <h2>Pedido Efetuado!</h2>
                  <div>
                    <p>Aviso importante</p>
                    <p>
                      Estamos analisando a documentação enviada.
                      <br />
                      Em seguida enviaremos um sms para o número de celular
                      cadastrado, para validação do seu pedido.
                    </p>
                  </div>
                  <div>
                  <button onClick={() => {
                      setShowModal(false);
                      setBlockButton(false);
                  }}>Voltar</button>
                    <button onClick={handleOrder}>Confirmar pedido</button>
                  </div>
                </>
              )}
            </Modal>
          </ModalContainer>
        )}
      </Container>
      <Container className='printable' ref={componentRef}>
        <ImageCar
                  src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${product.modelCode}.webp`}
                  fallbacks={[
                    `${defaultBackendUrl}/s3/veiculos/canal-indireto/${product.modelCode}.png`,
                    `${noImageCar}`
                  ]}
                alt={product.model}
          />

          <div className='infos-group'>
                  <div className="title">
                    Informações do veículo:
                  </div>
                  <div className="items">
                    <div className="item">
                      <span>Modelo:</span>
                      {!isEmpty(product) ? product.model : ''}
                    </div>
                    <div className="item">
                      <span>Cor:</span>
                      {!isEmpty(product) ? product.color : ''}
                    </div>
                    <div className="item">
                      <span>Ano:</span>
                      {!isEmpty(product) ? product.modelYear : ''}
                    </div>

                  </div>
          </div>

          <div className='infos-group'>
                  <div className="title">
                    Informações do contrato:
                  </div>
                  <div className="items">

                    <div className="item">
                      <span>Período:</span>
                      {!isEmpty(product) ? product.deadline : ''} meses
                    </div>
                    <div className="item">
                      <span>Franquia mensal:</span>
                      {!isEmpty(product) ? product.monthlyKmValue : ''} Kms
                    </div>
                    <div className="item">
                      <span>Total da parcela:</span>
                      {couponOfferValue ? formatValue(couponOfferValue) : formatValue(product?.monthlyInstallment)}
                    </div>
                    <div className="item">
                      <span>Total do contrato:</span>
                      {formatValue(product?.vehicleValue)}
                    </div>
                    <div className="item">
                      <span>Valor do KM excedente:</span>
                      {formatValue(product?.overrunKm)}
                    </div>
                    <div className="item">
                      <span>Coparticipação em sinistro:</span>
                      {formatValue(product?.hullFranchiseValue)}
                    </div>
                  </div>
          </div>
          {orderSummary && (

                            <div className="infos-group resume">
                            <div className="title">Resumo do pedido</div>
                            <div className="items">
                                <div className="item" dangerouslySetInnerHTML={{ __html: orderSummary }}></div>
                            </div>
                        </div>
          )}
          <div className='infos-group footer-group'>

                  <div className="items">
                    <div className="item">
                      <span>Nome da Concessionária:</span>
                      {dealerName}
                    </div>
                    <div className="item">
                      <span>Nome do Vendedor:</span>
                      {fullname}
                    </div>
                    <div className="item">
                      <span>Data/hora da cotação:</span>
                      {proposalDate()}
                    </div>

                  </div>
          </div>
      </Container>
    </div>

  )
}

export default OrderSummary
