import styled from 'styled-components'

interface ContainerProps {
  disabled?: boolean
}

export const Container = styled.div<ContainerProps>`
  button {
    background: ${(props) => (props.disabled ? '#EAECEE' : '#0161af')};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    border-radius: 23px;
    height: 48px;
    border: 0;
    padding: 0 16px;
    color: white;
    margin: 10px auto;
    transition: background-color 0.2s;
    width: 256px;
    font-size: 18px;
    height: 41px;
  }

  .btn-em-breve {
    border: 2px solid grey;
    background-color: #fff;
    color: #808080;
    cursor: not-allowed;
  }
`
