/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'

const ImgWithFallback = ({
  src,
  fallbacks,
  type = 'image/webp',
  alt,
  onError,
  ...props
}: any) => {
  const [imageError, setImageError] = useState('')

  useEffect(() => {
    setImageError('')
  }, [src])

  const handleError = (e: any) => {
    if (onError) {
      onError()
      return
    }

    let index = 0

    fallbacks.forEach((fallback: string, i: number) => {
      if (imageError === fallback) {
        index = i + 1
      }
    })

    setImageError(fallbacks[index] || '')
  }

  return (
    <picture>
      <source srcSet={imageError || src} type={type} />
      <img
        src={imageError || fallbacks[0]}
        alt={alt}
        {...props}
        onError={handleError}
      />
    </picture>
  )
}

export default ImgWithFallback
