/* eslint-disable react-hooks/exhaustive-deps */
import { InputNumber } from 'antd'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import iconEdit from '../../assets/icons/edit-2.svg'

import * as S from './styles'

interface IStockUpdate {
  stockId: number
  quantity: number | undefined
}
interface IStockItem {
  segment: string
  modelCode: string
  model: string
  color: string
  optional: string
  quantity: string
  status: string
  stockId: number
  deadlineInfo: string
}
interface PropsType {
  stocks: Array<IStockItem>
  stockUpdate: Array<IStockUpdate>
  setStockUpdate: Dispatch<SetStateAction<IStockUpdate[]>>
  handleModalDeliveryDeadline: (stockId: number) => void
}

const defaultProps = {
  stocks: []
}

const Table: React.FC<PropsType> = ({
  stocks,
  stockUpdate,
  setStockUpdate,
  handleModalDeliveryDeadline
}) => {
  useEffect(() => {
    const createStockUpdate = ({ stockId }: IStockItem) => ({
      stockId,
      quantity: undefined
    })
    const defaultstockUpdate = stocks.map(createStockUpdate)

    setStockUpdate(defaultstockUpdate)
  }, [stocks])

  const handleChangeAmount = (stockId: number, value: number) => {
    setStockUpdate((prev: Array<IStockUpdate>) => {
      const update = (prevStock: IStockUpdate) => {
        if (stockId === prevStock.stockId) {
          return {
            ...prevStock,
            quantity: value || undefined
          }
        }

        return prevStock
      }

      return prev.map(update)
    })
  }

  const handleChangeDeliveryDeadline = (stockId: number) => {
    handleModalDeliveryDeadline(stockId)
  }

  return (
    <div style={{ overflowX: 'auto' }}>
      <S.TableContent data-testid="table">
        <thead>
          <tr>
            <th>Item</th>
            <th>Cód. Modelo</th>
            <th>Modelo</th>
            <th>Cor</th>
            <th>Opcional</th>
            <th>Quantidade Atual</th>
            <th>Adicionar</th>
            <th>Status</th>
            <th>Prazo de entrega</th>
          </tr>
        </thead>
        <tbody>
          {stocks.map((stock: IStockItem, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{stock.modelCode}</td>
              <td>{stock.model}</td>
              <td>{stock.color}</td>
              <td>{stock.optional}</td>
              <td>{stock.quantity}</td>
              <td>                
                {stocks.length === stockUpdate.length && (
                  <InputNumber
                    data-testid="add_amount"
                    value={stockUpdate[index].quantity}
                    controls={false}
                    min={Number(-stock.quantity)}
                    onChange={(value) =>
                      handleChangeAmount(stock.stockId, value)
                    }
                  />
                )}
              </td>
              <td>{stock.status}</td>
              <td>
              {stock.deadlineInfo}&nbsp;
                <button
                  onClick={() => handleChangeDeliveryDeadline(stock.stockId)}
                >
                  <img src={iconEdit} alt="Editar" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </S.TableContent>
    </div>
  )
}

Table.defaultProps = defaultProps
export default Table
