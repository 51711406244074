/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { uuid } from 'uuidv4'

import formatValue from 'utils/formatValue'
import noImageColor from 'assets/defaultImages/no-image-color.jpg'
import noImageCar from 'assets/defaultImages/no-image.png'
import * as S from './styles'
import { iPropsProducts } from 'pages/Offers'
import Button from 'components/Button'
import { useAuth } from 'hooks/auth'
import { useToast } from 'hooks/toast'
import api from 'services/api'
import { defaultBackendUrl } from 'services/api'
import ImgWithFallback from 'components/ImgWithFallback'
import Loading from 'components/Loading'

type PropsColors = {
  isAvailability: boolean
  typeOfPainting: string
  colorCode: string
  color: string
}

export interface PropsOffer {
  model: string
  modelCode: number
  monthlyInstallment: number
  isAvailable: boolean
  productWithColor: PropsColors[]
  isColorOption?: boolean
  type: string
  segmentId: string | undefined
  brandCode: number
  product: iPropsProducts
  handleAddProducts: (item: iPropsProducts[]) => void
}

const CardOffer = ({
  model,
  monthlyInstallment,
  isAvailable,
  modelCode,
  productWithColor,
  isColorOption,
  type,
  segmentId,
  brandCode,
  product,
  handleAddProducts
}: PropsOffer) => {
  const history = useHistory()
  const { vendedorMaster } = useAuth()
  const { addToast } = useToast()

  const [borderColor, setBorderColor] = useState('')
  const [modelCodeToBorder, setModelCodeToBorder] = useState(0)
  const [initialOrder, setInitialOrder] = useState<iPropsProducts>()
  const [quantity, setQuantity] = useState(1)
  const [quantityTotal, setQuantityTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<iPropsProducts[]>([] as any)

  const handleColorChange = async (
    itemColor: PropsColors,
    modelCode: number
  ) => {
    const { colorCode, color } = itemColor

    if (type !== 'pf') {
      setLoading(true)
      setBorderColor(colorCode)
      setModelCodeToBorder(modelCode)
      setQuantity(1)

      const data = await api
        .get(
          `/offers?modelCode=${modelCode}&color=${color}&segmentId=${segmentId}`
        )
        .then((res) => {
          
          return res.data
        })

      const { hullFranchiseValue, optional, overrunKm } = data[0]

      await api
        .post(`/offers`, {
          modelCode: [modelCode],
          brandCode: [brandCode],
          colors: [color],
          minMonthlyInstallment: 0,
          maxMonthlyInstallment: 0,
          page: true,
          quantityPerPage: 10,
          currentPage: 1,
          segmentId: Number(segmentId),
          salesChannel: Number(segmentId) === 9 ? 'S&D' : null
        })
        .then((response) => {

          const { data } = response

          const newInitialOrder = {
            ...data.itens[0],
            amountAvailable: data.itens[0].productWithColor[0].stockQuantity,
            offerOptional: data.itens[0].optional,
            color,
            hullFranchiseValue,
            optional,
            overrunKm,
            id: uuid()
          }

          setInitialOrder(newInitialOrder)
          setLoading(false)
        })
    }
  }

  const handleChangeDecrementQuantityProduct = () => {
    if (quantity === 1) {
      setQuantity(1)
    } else {
      setQuantity(quantity - 1)
    }
  }

  const handleChangeIncrementQuantityProduct = (max: number | undefined) => {
    if (quantity === max) {
      setQuantity(max)
    } else {
      setQuantity(quantity + 1)
    }
  }

  const handleAddCart = () => {
    setBorderColor('')
    if (initialOrder?.color === 'default') {
      return addToast({
        title: 'Selecione uma cor',
        type: 'error',
        description: 'Selecione a cor do veículo'
      })
    }

    addToast({
      title: 'Produto adicionado!',
      description: 'Produto adicionado ao carrinho com sucesso.',
      type: 'success'
    })

    if (initialOrder) {
      setProducts([
        ...products,
        {
          ...initialOrder,
          quantity
        }
      ])

      handleAddProducts([...products, { ...initialOrder, quantity }])
    }

    setQuantityTotal(quantityTotal + quantity)
    setInitialOrder(undefined)
  }

  const handleSelectProduct = (item: any) => {
    if (type !== 'pf') {
      const newInitialOrder = {
        productId: item.productId,
        color: 'default',
        modelCode: 1,
        model: 'model',
        id: Math.random()
      }
      setInitialOrder(newInitialOrder)
    } else {
      localStorage.setItem(
        '@FleetSolutions:productBrandCode',
        brandCode.toString()
      )
      if(Number(segmentId) === 9 && item?.isBlindado){
        localStorage.setItem(
          '@FleetSolutions:isSDBlindado',
          "true"
        )
      }
      else{
        localStorage.removeItem(
          '@FleetSolutions:isSDBlindado'
        )
      }
      history.push(`/offer/${item.modelCode}`)
    }
  }

  const isBlindado = (product:any) => {
    return product?.isBlindado
  }


  const handleQuantity = (item: iPropsProducts) => {
    const itemColor = productWithColor.filter((s: any) => s.stockQuantity > 0)
    const hasStock = initialOrder?.amountAvailable !== 0

    if (
      (initialOrder?.amountAvailable === 0 || itemColor.length === 0) &&
      !vendedorMaster
    ) {
      return <Button className="btn-em-breve">Em Breve</Button>
    }

    if (initialOrder?.color) {
      return (
        <S.WrapperQuantity>
          {hasStock && (
            <div className="wrapper-quantity">
              <Button onClick={handleChangeDecrementQuantityProduct}>
                {' '}
                -{' '}
              </Button>
              <span>{quantity}</span>
              <Button
                onClick={() =>
                  handleChangeIncrementQuantityProduct(
                    initialOrder?.amountAvailable
                  )
                }
              >
                {' '}
                +{' '}
              </Button>
            </div>
          )}
          <div className="stock">
            <p>
              {initialOrder !== undefined ? initialOrder.amountAvailable : ''}{' '}
              disponíveis
            </p>
          </div>

          <Button className="btn-add-cart" onClick={handleAddCart}>
            Adicionar
          </Button>
        </S.WrapperQuantity>
      )
    }

    return <Button onClick={() => handleSelectProduct(item)}>Selecionar</Button>
  }

  return (
    <S.Wrapper>
      <S.Card>
        <S.WrapperImage>    
          <ImgWithFallback
            className="image-car"
            src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${modelCode}.webp`}
            fallbacks={[
              `${defaultBackendUrl}/s3/veiculos/canal-indireto/${modelCode}.png`,
              `${noImageCar}`
            ]}
            alt={model}
          />
        </S.WrapperImage>

        <div className="wrapper-title">
          <p className="title-item">{model}</p>
        </div>

        {Number(segmentId) === 9 && isBlindado(product) && (
          <div className="blindado-badge">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 12 14"><path fill="#001E50" stroke="#001E50" d="m5.654 7.82 2.463-2.463.243.243L5.3 8.66 3.657 7.016l.243-.243L4.946 7.82l.354.354zm.195 4.923.151.048.151-.048c1.273-.404 2.316-1.211 3.128-2.393A6.83 6.83 0 0 0 10.5 6.4V2.903l-.324-.121-4-1.5L6 1.216l-.176.066-4 1.5-.324.121V6.4c0 1.445.408 2.766 1.221 3.95.812 1.182 1.855 1.99 3.128 2.393Zm3.572-2.029C8.505 11.974 7.368 12.776 6 13.15c-1.368-.374-2.505-1.177-3.42-2.436C1.634 9.415 1.166 7.982 1.166 6.4V2.68L6 .867l4.833 1.813V6.4c0 1.582-.468 3.015-1.412 4.314Z"></path></svg> Blindado
          </div>
        )}

        <div className="content-card">
        {loading && (
          <div className="loader">
            <Loading text={""} />
          </div>
        )}
        {isColorOption && (
          <S.ColorsOption>
            <span className="available-colors">Cores disponíveis</span>
            <S.WrapperColors>
              <S.Colors>
                <div>
                  {productWithColor.map((itemColor: PropsColors) => (
                    // eslint-disable-next-line
                    <img
                      key={itemColor.colorCode}
                      onError={(e: any) => {
                        e.target.onerror = null
                        e.target.src = noImageColor
                      }}
                      onClick={() => handleColorChange(itemColor, modelCode)}
                      className={`${
                        isAvailable === false && type === 'pf'
                          ? 'disabled-colors'
                          : 'colors'
                      }`}
                      style={{
                        border:
                          borderColor === itemColor.colorCode &&
                          modelCode === modelCodeToBorder
                            ? '2px solid #1890ff'
                            : ''
                      }}
                      title={itemColor.color}
                      loading="lazy"
                      src={`${defaultBackendUrl}/s3/veiculos/Cores/${itemColor.colorCode}.png`}
                    />
                  ))}
                </div>
              </S.Colors>
            </S.WrapperColors>
          </S.ColorsOption>
        )}

        <p className="value-offer">
          Plano inicial de: <span>{formatValue(monthlyInstallment)}</span>
        </p>

        {handleQuantity(product)}
        </div>


      </S.Card>
    </S.Wrapper>
  )
}

export default CardOffer
