import styled from 'styled-components'

export const Container = styled.div``

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;

  h1 {
    color: #17536c;
    margin-top: 30px;
    margin-bottom: 0px !important;
    font-size: 1.6em !important;
    text-align: center;
  }

  p {
    color: #3c505a;
    margin-top: 5px;
  }

  form {
    width: 80%;
    max-width: 500px;
    margin: 30px 0 100px 0;
    color: #0c5faf;

    .wrapper-button {
      float: right;
      margin-top: 20px;
      display: flex;

      button {
        width: 155px;
        height: 39px;
        border-radius: 24px;
        font-size: 14px;
      }

      .btn-cancel {
        border: 2px solid #3c505a;
        color: #3c505a;
        margin-right: 14px;
      }

      .btn-save {
        background: #236fbc 0% 0% no-repeat padding-box;
        border: 1px solid #226fbb;
        color: #fff;
      }
    }

    .other-style {
      // seleciona divs após label
      label ~ div {
        border: 1px solid #0c5faf;
        background: #ffffff;
      }
    }

    .email {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      div:first-child {
        width: 49%;
        margin: 0;
        background: #ffffff;
        border: 1px solid #0c5faf;
      }

      div:last-child {
        width: 49%;
        margin: 0;
      }
    }

    .wrapper-info {
      margin-top: 12px;

      select {
        width: 100%;
        margin: 0px;
        border-radius: 21px;
        height: 45px;
        padding: 5px;
        background: #ffffff;
        border: 1px solid #0c5faf;
      }

      input,
      select {
        color: #3c505a;
      }
    }
  }

  @media (max-width: 500px) {
    form {
      .email {
        flex-direction: column;
        align-items: normal;

        div {
          width: auto;
          margin-top: 5px;
        }
      }

      .wrapper-button {
        float: none;
      }
    }
  }

  .isActive {
    float: right;
    display: flex;
    margin-bottom: 10px;

    span {
      margin-right: 5px;
      align-items: center;
      justify-content: center;
    }
  }
`
