import { KeyboardDatePicker } from '@material-ui/pickers'

import styled, { keyframes } from 'styled-components'

export const Container = styled.div``

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0)
    }
`

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  animation: ${appearFromLeft} 1s;

  img {
    margin-bottom: 40px;
  }

  form {
    display: flex;
    flex-direction: row;
    width: 850px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    div {
      width: 400px;
      margin-bottom: 19px !important;
    }

    input {
      color: #000;
    }

    h2 {
      width: inherit;
      display: flex;
      justify-content: center;
      margin: 10px 0 30px 0;
      color: #236fbc;
      font-size: 16px;
      text-align: center;
    }

    .wrapper-btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      div {
        width: 190px;
      }

      button {
        width: 190px;
        height: 41px;
        font-size: 12px;
        text-transform: uppercase;
        margin: 8px;
      }

      .btn-back {
        background: transparent;
        border: 2px solid #226fbb;
        color: #226fbb;
      }
    }

    .efSwgr {
      border: 2px solid #cfd7d9;
      background-color: white;
      color: #797979;
      flex-direction: row-reverse;
    }

    .bdyEhz {
      flex-direction: row-reverse;
      border: 2px solid #236fbc;
    }

    div {
      margin: 0;
    }

    .custom-selects {
      .ant-select {
        .ant-select-selector {
          border-radius: 23px !important;
          height: 45px !important;
          width: 400px !important ;
          border: 1px solid #cfd7d9 !important;
        }

        .anticon {
          margin-bottom: 12px;
          margin-left: -10px;
        }
      }
    }

    .custom-select {
      .ant-select-selection-item {
        margin-top: -40px;
      }

      .ant-select-arrow {
        margin-top: -14px;
      }
    }

    @media (max-width: 465px) {
      width: 80%;

      div {
        width: 100%;
      }

      input {
        width: 80%;
      }

      .custom-selects {
        .ant-select {
          .ant-select-selector {
            border-radius: 23px !important;
            height: 45px !important;
            width: 100% !important ;
            border: 1px solid #cfd7d9 !important;
          }

          .anticon {
            margin-bottom: 12px;
            margin-left: -10px;
          }
        }
      }
    }

    div {
      &.custom-select {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 0;

        border-radius: 23px;
        border: 2px solid #cfd7d9;
        background-color: white;
        color: #797979;
        height: 42px;

        .ant-select {
          height: 42px;
          width: 300px;
          z-index: 2;

          @media (min-width: 768px) {
            width: 400px;
          }

          .ant-select-selector {
            background-color: transparent !important;
            border-radius: 30px;
            height: 42px;

            @media (min-width: 768px) {
              width: 400px;
            }

            .ant-select-selection-item {
              color: #797979;
              line-height: 0px;

              @media (min-width: 768px) {
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 984px) {
    form {
      flex-direction: column;
      justify-content: center;
    }
  }

  @media (max-width: 400px) {
    form {
      flex-direction: row;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  width: 100%;

  h1 {
    color: #17536c;
    margin-top: 30px;
    margin-bottom: 0px !important;
    font-size: 1.6em !important;
    text-align: center;
  }

  p {
    color: #3c505a;
    margin-top: 5px;
  }

  .MuiFormControl-root {
    overflow: hidden;
  }

  @media (max-width: 1024px) {
    max-width: initial;
  }
`

export const DatePicker = styled(KeyboardDatePicker)`
  height: 45px !important;

  .MuiInputBase-root {
    border-radius: 23px;
    border: 2px solid #cfd7d9;
    background-color: white;
    color: #797979;
    height: 50px !important;
    padding: 2px;
  }

  .MuiOutlinedInput-root {
    border: none;
  }

  .MuiInputAdornment-root {
    margin-right: -340px;
  }

  .MuiButtonBase-root {
    width: 0;
    height: 0;
    &:hover {
      background-color: transparent !important;
    }

    &:active {
      background: none !important;
    }

    span,
    svg {
      margin-top: 10px;
      margin-left: -5px;
    }
  }

  .MuiFormHelperText-root.Mui-error {
    color: #f44336;
    display: flex;
    margin-top: -20px;
    font-size: 12px;
  }
`

export const Wrapper = styled.div`
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0 !important;
  flex-wrap: wrap;

  & > p {
    color: #236fbc;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }

  .ant-radio-group {
    display: flex;
    margin-bottom: 0;
    align-items: center;

    .ant-radio-wrapper {
      display: flex;
      &:first-child {
        margin: 0 50px 0 43px;
      }

      .ant-radio {
        & > span {
          width: 30px;
          height: 30px;

          &::after {
            width: 15px;
            height: 15px;
            top: 6px;
            left: 7px;
          }
        }
      }

      & > span {
        color: #236fbc;
        font-size: 16px;
        font-weight: 600;
        width: unset;
      }
    }
  }

  @media (max-width: 984px) {
    width: auto !important;
  }
`
