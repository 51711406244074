import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 21px;
    color: #0c5faf;
    font-weight: 600;
  }

  p {
    font-size: 17px;
    color: #3c505a;
  }
`

export const SelectSegmentButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
