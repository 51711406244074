import React, { useEffect, useLayoutEffect, useState } from "react";
import { Container } from "./styles";
import { Select, Radio, Collapse, Button, Input, Switch, Pagination } from "antd";
import api from "services/api";
import "../../styles/bootstrap-grid.css";
import "../../styles/styles.css";
import "./styles.css";
import ClearIcon from "../../assets/icons/backspace.svg";
import ImgWithFallback from "../../components/ImgWithFallback";
import { defaultBackendUrl } from "services/api";
import noImageCar from "assets/defaultImages/no-image.png";
import { useToast } from "hooks/toast";
import { useDispatch } from "react-redux";
import { setTabs } from "../../store/modules/tabsRegisterUser/actions";
import { cepMask } from "utils";
import PaginationList from "components/PaginationList";
import { Estado } from "pages/PickupLocation";

const { Panel } = Collapse;

export interface Product {
    productId: number;
    planCode: string;
    planReference: string;
    plan: string;
    brandCode: number;
    brand: string;
    modelCode: number;
    model: string;
    optional: string;
    modelYear: number;
    manufactureYear: number;
    manufacturerCode: string;
    deadline: number;
    typeOfPainting: string;
    color: string;
    colorCode: string;
    monthlyInstallment: number;
    annualAmount: number;
    vehicleValue: number;
    monthlyKm: number;
    monthlyKmValue: number;
    overrunKm: number;
    hullFranchiseValue: number;
    segment: number;
    kickback: string;
    salesChannel: string;
    isAvailability: boolean;
    isDeleted: boolean;
    stockQuantity: number;
    deadlineInfo: string;
    accessoryText: string;
    optionalText: string;
    validUntil: Date;
    stotherIndex: string;
    assistence24Hours: string;
    maintenance: string;
    insuranceType: null;
    insuranceSpecs: string;
    insuranceTable: string;
    insuranceMaterialDamage: number;
    insuranceMoralDamage: number;
    insuranceBodilyHarm: number;
    insuranceAppDeath: number;
    insuranceAppDisability: number;
    insuranceGlass: boolean;
    serviceItemContract: string;
    tire: null;
    tireRefund: boolean;
    tireQuantity: number;
    tirePool: boolean;
    leadsBrings: null;
    leadsBringsQuantity: number;
    technicalReserve: string;
    technicalRefund: boolean;
    technicalQuantity: number;
    technicalPool: boolean;
    residualValue: number;
    publicPrice: number;
    listAccessories: ListAccessory[];
    listOptional: any[];
    quantity?:number;
}

export interface ListAccessory {
    accessoryId: number;
    productId: number;
    typeAccessory: string;
    accessoryDescription: string;
    accessoryBuyValue: number;
    accessorySaleValue: number;
    accessoryCode: number;
    dateCreated: Date;
    isDeleted: boolean;
}

export interface Address {
    zipCode?: string | null;
    state?: string | null;
    city?: string | null;
    district?: string | null;
    street?: string | null;
    number?: string | null;
    complement?: string | null;
}

const NewPickupLocation = () => {
    const dispatch = useDispatch();
    const { Option } = Select;
    const { addToast } = useToast();
    const [locationsOptions, setLocationsOptions] = useState([] as any);

    const [statesBr, setStatesBr] = useState<any>([]);
    const [cities, setCities] = useState<any>([]);

    const [dealerStates, setDealerStates] = useState<any>([]);
    const [dealerCities, setDealerCities] = useState<any>([]);

    const [customerAddress, setCustomerAddress] = useState<any>(null);
    const [currentLicensePlate, setCurrentLicensePlate] = useState<any>(null);

    const [currentDealerUf, setCurrentDealerUf] = useState<any>();
    const [currentDealerCity, setCurrentDealerCity] = useState<any>();
    const [currentDealer, setCurrentDealer] = useState<any>(null);

    const [currentUf, setCurrentUf] = useState<any>();
    const [currentUfId, setCurrentUfId] = useState<any>();
    const [currentCity, setCurrentCity] = useState<any>();

    const [canSelectLicensePlate, setCanSelectLicensePlate] = useState<boolean>(false);
    const [willChosseLicensePlate, setWillChosseLicensePlate] = useState<boolean>(false);
    const [deliveryTypes, setDeliveryTypes] = useState<any>(null);
    const [deliveryOptions, setDeliveryOptions] = useState<any>([] as any);
    const [product, setProduct] = useState<Product>();
    const [deliveryOption, setDeliveryOption] = useState<number|null>(null);
    const [currentAddress, setCurrentAddress] = React.useState<any>({
        zipCode: "",
        street: "",
        number: "",
        district: "",
        complement: "",
        city: "",
        state: "",
        canChosseLicensePlate: false,
    });
    const segmentId = Number(localStorage.getItem("@FleetSolutions:segmentId"));

    const [states, setStates] = useState<Estado[]>([]);
    const [citiesList, setCitiesList] = useState<Estado[]>([]);

    // const allOptions = [
    //     { label: "Concessionária/Parceiro", value: 1 },
    //     { label: "Endereço do cadastro do cliente", value: 2 },
    //     { label: "Indicar outro endereço", value: 3 },
    // ];

    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalRecords, setTotalRecords] = useState<any>(0);
    const [data, setData] = useState<any>();

    useEffect(() => {
        const productId = localStorage.getItem("@FleetSolutions:productId");
        api.get(`/offers/${productId}`).then((res) => {
            const { data } = res;
            setProduct(data);
        });
        api.get(`location/state`).then((response: any) => {
            setStatesBr(response.data);
        });
        const brandCode = localStorage.getItem("@FleetSolutions:productBrandCode");

        if (brandCode) {
            api.get(`location/state?brandCode=${brandCode}`).then((response: any) => {
                setDealerStates(response.data);
            });
        } else {
            api.get(`location/state`).then((response: any) => {
                setDealerStates(response.data);
            });

            api.get(`location/city?uf=${26}`).then((response: any) => {
                setDealerCities(response.data);
            });
        }

        api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`).then((response) => {
            setStates(response.data);
        });

        checkLicensePlate();
    }, []);

    useEffect(() => {
        if (data) {
            setTotalRecords(data.totalAmount);
        }
    }, [data]);

    useEffect(() => {
        
        api.get(`/segments/by-id-fleet/${segmentId}`).then(({ data }) => {
            const firstActiveSegment = data?.find((item: { status: boolean }) => item?.status === true);

            const deliveryTypesArray = firstActiveSegment.deliveryTypes?.split("|").map(Number) || [];

            const fetchData = async () => {
                const { deliveryOptions } = await fetchDeliveryOptions();

                setDeliveryOptions(
                    deliveryOptions
                        .map((item: { description: any; id: any }) => ({
                            label: item.description,
                            value: item.id,
                        }))
                        .filter((item: { value: any }) => {
                            return deliveryTypesArray.includes(Number(item.value));
                        })
                );
            };

            fetchData();
        });
    }, [segmentId]);

    const fetchDeliveryOptions = async () => {
        try {
            const [deliveryResponse] = await Promise.all([api.get("/enumparameters/DeliveryOptionEnum")]);
            return {
                deliveryOptions: deliveryResponse.data,
            };
        } catch (error) {
            return { deliveryOptions: [] };
        }
    };

    const visibleSegmentDeliveryPlace = () => {
        return true;
    };

    const getOptionals = (value: any) => {
        let optionals = "";

        if (!value) return;

        value.split("|").map((ite: string) => {
            if (ite.indexOf("opt") > -1) {
                optionals += ite.replace("opt=", "") + "; ";
            }
        });

        return optionals;
    };

    const checkLicensePlate = async (state?: any, city?: any) => {
        if (state && city) {
            try {
                const response = await api.get(`location/finalPlatePermissions/ByStateCity?state=${state}&city=${normalizeString(city)}`);
                setCanSelectLicensePlate(response ? true : false);
            } catch (error) {
                setCanSelectLicensePlate(false);
            }
        } else {
            try {
                let address = customerAddress || {};

                if (customerAddress === null) {
                    const response = await api.get(`/customers/${Number(localStorage.getItem("@FleetSolutions:userIdPf"))}`);
                    address = response.data.address;
                    setCustomerAddress(address);
                }

                const response = await api.get(`location/finalPlatePermissions/ByStateCity?state=${address.state}&city=${normalizeString(address.city)}`);
                setCanSelectLicensePlate(response ? true : false);
            } catch (error) {
                if (state && city) {
                    try {
                        const response = await api.get(`location/finalPlatePermissions/ByStateCity?state=${state}&city=${city}`);
                        setCanSelectLicensePlate(response ? true : false);
                    } catch (error) {
                        setCanSelectLicensePlate(false);
                    }
                } else {
                    setCanSelectLicensePlate(false);
                }
            }
        }
    };

    const normalizeString = (str: string) => {
        const specialCharacters: any = {
            Á: "A",
            À: "A",
            Ã: "A",
            Â: "A",
            É: "E",
            È: "E",
            Ê: "E",
            Í: "I",
            Ì: "I",
            Î: "I",
            Ó: "O",
            Ò: "O",
            Õ: "O",
            Ô: "O",
            Ú: "U",
            Ù: "U",
            Û: "U",
            Ç: "C",
        };

        const normalizedStr = Array.from(str)
            .map((char) => specialCharacters[char.toUpperCase()] || char.toUpperCase())
            .join("");

        return normalizedStr;
    };

    const handleProductList = () => {
        const handleClear = () => {
            localStorage.removeItem("@FleetSolutions:finalPlate");
            setCurrentLicensePlate(null);
            setCurrentDealerCity(null);
            setCurrentDealerUf(null);
            setCurrentCity(null);
            setCurrentUf(null);
            setCurrentDealer(null);
            setCurrentAddress({
                zipCode: "",
                street: "",
                number: "",
                district: "",
                complement: "",
                city: "",
                state: "",
                canChosseLicensePlate: false,
            });
            setLocationsOptions([]);
        };

        const CustomPanel = ({ header, onClear }: any) => {
            const handleClearClick = (e: { stopPropagation: () => void }) => {
                e.stopPropagation();
                onClear();
            };
            return (
                <div className="custom-header">
                    <span>{header}</span>
                    <div className="action">
                        <Button type="text" onClick={handleClearClick} className="clear-button">
                            <img src={ClearIcon} alt="Limpar" />
                            Limpar
                        </Button>
                    </div>
                </div>
            );
        };

        const onChangeOption = (event: any) => {
            console.log(event.target.value);
            setCurrentDealer(null);
            setDeliveryOption(event.target.value);
            checkLicensePlate();
            handleClear();
        };

        const renderAddressForm = () => {
            const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                const { name, value } = event.target;
                setCurrentAddress((prevAddress: any) => ({
                    ...prevAddress,
                    [name as keyof Address]: value || null,
                }));

                if (name === "zipCode") {
                    setCurrentAddress((prevAddress: any) => ({
                        ...prevAddress,
                        [name as keyof Address]: value.replace(/\D/g, "").replace(/(\d{5})(\d)/, "$1-$2") || null,
                    }));
                }
            };

            const handleStateChange = (value: string) => {
                setCurrentAddress((prevAddress: any) => ({
                    ...prevAddress,
                    state: value || null,
                    city: null,
                }));

                api.get(`location/city?uf=${value}`).then((response: any) => {
                    setCities(response.data);
                });
            };

            const handleCityChange = (value: string) => {
                setCurrentAddress((prevAddress: any) => ({
                    ...prevAddress,
                    city: value[0] || value || null,
                }));
            };

            const handleKeyUpCep = async (e: React.KeyboardEvent<HTMLInputElement>) => {
                const value = (e.target as HTMLInputElement).value;

                if (value.length !== 9) return;

                try {
                    const response = await api.get(`/ceps?cep=${(e.target as HTMLInputElement).value}`);
                    if (response.data.erro) return;

                    const [cepData] = await Promise.all([response.data]);

                    setCurrentAddress((prevAddress: any) => ({
                        ...prevAddress,
                        street: cepData.logradouro,
                        number: "",
                        district: cepData.bairro,
                        complement: "",
                        city: cepData.localidade,
                        state: statesBr?.find((item: { text: any }) => item.text === cepData.estado)?.value,
                    }));

                    checkLicensePlate(statesBr?.find((item: { text: any }) => item.text === cepData.estado)?.value, cepData.localidade);

                    const cityResponse = await api.get(`location/city?uf=${statesBr?.find((item: { text: any }) => item.text === cepData.estado)?.value}`);
                    setCities(cityResponse.data);
                } catch (error) {
                    addToast({
                        title: "Campo obrigatório",
                        type: "error",
                        description: "Endereço não encontrado",
                    });
                }
            };

            return (
                <form className="form">
                    <div className="container">
                        <div className="row mb-3">
                            <div className="col-3">
                                <label htmlFor="zipCode">CEP</label>
                                <Input placeholder="Digite o CEP" id="zipcode" name="zipCode" type="text" maxLength={9} value={currentAddress["zipCode"] || ""} onChange={handleInputChange} onKeyUp={handleKeyUpCep} />
                            </div>
                            <div className="col-6">
                                <label htmlFor="street">Endereço</label>
                                <Input placeholder="Digite o Endereço" id="street" name="street" type="text" maxLength={80} value={currentAddress["street"] || ""} onChange={handleInputChange} />
                            </div>
                            <div className="col-3">
                                <label htmlFor="zipCode">Número</label>
                                <Input placeholder="Insira o número" id="number" name="number" type="number" maxLength={5} value={currentAddress["number"] || ""} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <label htmlFor="zipCode">Complemento</label>
                                <Input placeholder="Digite o complemento" id="complement" name="complement" type="text" maxLength={40} value={currentAddress["complement"] || ""} onChange={handleInputChange} />
                            </div>
                            <div className="col-3">
                                <label htmlFor="zipCode">Bairro</label>
                                <Input placeholder="Digite o nome do Bairro" id="district" name="district" type="text" value={currentAddress["district"] || ""} onChange={handleInputChange} />
                            </div>

                            <div className="col-3">
                                <label htmlFor="zipCode">Estado</label>
                                <Select value={currentAddress["state"] || ""} onChange={(value: any) => handleStateChange(value)} placeholder="Selecione o Estado">
                                    {statesBr.map((option: any) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.text}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="col-3">
                                <label htmlFor="zipCode">Cidade</label>
                                <Select value={currentAddress["city"] || ""} onChange={(value: any) => handleCityChange(value)} placeholder="Selecione a Cidade">
                                    {cities?.map((option: any) => (
                                        <Option key={option} value={option}>
                                            {option}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                </form>
            );
        };

        const renderLicensePlate = () => {
            const handlePlateChange = (value: string[]) => {
                setCurrentLicensePlate(value);
                const finalPlateStorage = JSON.stringify(value ? value.join(" - ") : "S/PLACA");
                localStorage.setItem("@FleetSolutions:finalPlate", finalPlateStorage);
            };

            const onChange = (checked: boolean) => {
                setWillChosseLicensePlate(checked);
            };

            const licensePlateOptions = () => {
                return ["1 ou 2", "3 ou 4", "5 ou 6", "7 ou 8", "9 ou 0"];
            };
            return (
                <form>
                    <div className="container p-0">
                        <div className="row">
                            <div className="col-12">
                                <div className="title">
                                    Escolher Final de Placa
                                    <span>Você selecionou uma localização em que permite escolher o final da placa. Deseja escolher?</span>
                                </div>
                            </div>
                            <div className="col-12">
                                <Switch checked={willChosseLicensePlate} onChange={(value) => onChange(value)} />
                            </div>
                            <div className="col-12 d-flex align-items-center gap-row">
                                {willChosseLicensePlate && (
                                    <>
                                        <label htmlFor="zipCode">Escolha no mínimo duas opções:</label>
                                        <Select onChange={(value: any) => handlePlateChange(value)} placeholder="Selecione os finais de placa" mode="multiple">
                                            {licensePlateOptions().map((option: any) => (
                                                <Option key={option} value={option}>
                                                    {option}
                                                </Option>
                                            ))}
                                        </Select>
                                    </>
                                )}
                            </div>
                            {willChosseLicensePlate && (
                                <div className="col-12">
                                    <div className="obs d-flex justify-content-end mt-2">*Sujeito à disponibilidade de estoque, caso não haja o final de placa escolhido, entraremos em contato.</div>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            );
        };

        const renderDealershipForm = () => {
            const handleStateChange = (value: string) => {
                setTotalRecords(0);
                setCurrentDealerUf(value);
                setCurrentDealerCity(null);
                setLocationsOptions([]);
                const brandCode = localStorage.getItem("@FleetSolutions:productBrandCode");

                api.get(`location/city?uf=${value}&brandCode=${brandCode}`).then((response: any) => {
                    setDealerCities(response.data);
                });
            };

            const handleCityChange = (value: string) => {
                setCurrentDealerCity(value[0]);
                getDealers(currentDealerUf, value[0], 1);
            };

            const selectDealer = (value: number) => {
                setCurrentDealer(value);
            };

            const paginate = (page: any, pageSize: any) => {
                setCurrentPage(page);
                getDealers(currentDealerUf, currentDealerCity, page);
            };

            const getDealers = (uf: any, city: any, page: number) => {
                api.get(`location?UF=${uf}&city=${city}&page=true&quantityPerPage=5&currentPage=${page}&brandCode=${Number(localStorage.getItem("@FleetSolutions:productBrandCode"))}`).then(({ data }: any) => {
                    if (data.itens[0]) {
                        setLocationsOptions(data.itens);
                        setData(data);
                        checkLicensePlate(uf, city);
                    } else {
                        addToast({
                            title: "Sem dados",
                            type: "error",
                            description: "Não foi encontrado nenhum dado para a pesquisa.",
                        });
                    }
                });
            };

            return (
                <form className="form">
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="zipCode">Estado</label>
                                <Select value={currentDealerUf} onChange={(value: any) => handleStateChange(value)} placeholder="Selecione o Estado">
                                    {dealerStates.map((option: any) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.text}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="col-6">
                                <label htmlFor="zipCode">Cidade</label>
                                <Select value={currentDealerCity} onChange={(value: any) => handleCityChange(value)} placeholder="Selecione a Cidade">
                                    {dealerCities?.map((option: any) => (
                                        <Option key={option} value={option}>
                                            {option}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {locationsOptions.length > 0 && <div className="locations-title mt-4 mb-3">Locais disponíveis</div>}

                                {locationsOptions.map((item: any, key: number) => (
                                    <div className={currentDealer === item.id ? "location-item active" : "location-item"} key={item.id} onClick={() => selectDealer(item.id)}>
                                        <div className="name">{item.description}</div>
                                        <div className="address">
                                            {item.address}, {item.number}
                                            <br />
                                            {item.district} - {item.city}, {item.uf} - CEP: {item.zipCode}
                                        </div>
                                    </div>
                                ))}
                                <div className="pagination">
                                    <PaginationList paginate={(page: number) => paginate(page, null)} currentPage={currentPage} total={totalRecords} perPage={5} hideOnSinglePage={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            );
        };

        const renderUfAndCityForm = () => {
            const handleStateChange = (value: string) => {
                setCurrentUf(value);
                let stateUfInfos = states.find((item) => item.sigla === value)?.nome;
                let ufId = statesBr?.find((item: { text: string }) => item.text === stateUfInfos)?.value;
                setCurrentUfId(ufId)
                api.get(`location/city?uf=${ufId}`).then((response: any) => {
                    console.log(response.data);
                    setCitiesList(response.data);
                });
            };

            const handleCityChange = (value: string) => {
                let ufName = states?.find((item) => item.sigla === currentUf)?.nome;
                let ufId = statesBr?.find((item: { text: string }) => item.text === ufName)?.value;

                checkLicensePlate(currentUf, normalizeString(value));
                setCurrentCity(value[0]);
            };

            return (
                <form className="form">
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="zipCode">Estado</label>
                                <Select value={currentUf} onChange={(value: any) => handleStateChange(value)} placeholder="Selecione o Estado">
                                    {states.map((option: any) => (
                                        <Option key={option.sigla} value={option.sigla}>
                                            {option.sigla}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="col-6">
                                <label htmlFor="zipCode">Cidade</label>
                                <Select value={currentCity} onChange={(value: any) => handleCityChange(value)} placeholder="Selecione a Cidade">
                                    {citiesList?.map((option: any) => (
                                        <Option key={option} value={option}>
                                            {option}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                </form>
            );
        };

        return (
            <>
                <div className="container-fluid page-head">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <h1 className="page-title">Local de Retirada</h1>
                            <span className="page-subtitle">Selecione a forma de retirada do modelo</span>
                        </div>
                    </div>
                </div>
                {
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-10 offset-md-1">
                                <Collapse defaultActiveKey={0}>
                                    <Panel header={<CustomPanel header={product?.model} onClear={(e: any) => handleClear()} />} key={0}>
                                        <div className="car-infos">
                                            <div className="car-image">
                                                <ImgWithFallback className="image-car" src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${product?.modelCode}.webp`} fallbacks={[`${defaultBackendUrl}/s3/veiculos/canal-indireto/${product?.modelCode}.png`, `${noImageCar}`]} alt="Carro" />
                                            </div>
                                            <div className="infos">
                                                <div className="items">
                                                    <div className="item">
                                                        <span>Cor:</span>
                                                        {product?.color}
                                                    </div>
                                                    <span className="separator">|</span>
                                                    <div className="item">
                                                        <span>Prazo:</span>
                                                        {product?.deadline} meses
                                                    </div>
                                                    <span className="separator">|</span>

                                                    <div className="item">
                                                        <span>Km Mensal:</span>
                                                        {product?.monthlyKmValue} kms
                                                    </div>
                                                </div>
                                                <div className="items single">
                                                    <div className="item">
                                                        <span>Opcionais:</span>
                                                        {getOptionals(product?.optional)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="delivery-selection">
                                            <div className="title">Selecione um local para retirada ou entrega:</div>
                                            {visibleSegmentDeliveryPlace() && (
                                                <div className="options mb-4">
                                                    <Radio.Group options={deliveryOptions} onChange={(value) => onChangeOption(value)} value={deliveryOption} optionType="button" />
                                                </div>
                                            )}
                                            {deliveryOption === 3 && <div className="selected-option">{renderAddressForm()}</div>}
                                            {deliveryOption === 1 && <div className="selected-option">{renderDealershipForm()}</div>}
                                            {deliveryOption === 5 && <div className="selected-option">{renderUfAndCityForm()}</div>}
                                            {canSelectLicensePlate && <div className="license-plate-option">{renderLicensePlate()}</div>}
                                        </div>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    };

    const handleBackPage = () => {
        dispatch(setTabs({ id: "2" }));
    };
    const handleContinueOrder = () => {
        localStorage.setItem("@FleetSolutions:selectedDelivery", String(deliveryOption));
        localStorage.setItem("@FleetSolutions:locationIdPf", String(currentDealer || 0));
        localStorage.setItem("@FleetSolutions:cityforPickup", String(currentCity));
        localStorage.setItem("@FleetSolutions:ufForPickUp", String(currentUf));
        localStorage.setItem("@FleetSolutions:ufForPickUpId", String(currentUfId));


        if(!deliveryOption || deliveryOption === null){
            return addToast({
                title: "Selecione o local de retirada",
                description: "É necessário selecionar o local de retirada do veículo",
                type: "error",
            });
        }
        

        const isObjectEmptyOrNull = (obj: any) => {
            for (let key in obj) {
                if (key !== "complement" && (obj[key] === "" || obj[key] === null)) {
                    return true;
                }
            }
            return false;
        };
        if ((deliveryOption === 1 && (currentDealer === "" || currentDealer === null)) || (deliveryOption === 3 && isObjectEmptyOrNull(currentAddress)) || (deliveryOption === 5 && !currentCity && !currentUf)) {
            return addToast({
                title: "Selecione o local de retirada",
                description: "É necessário selecionar o local de retirada do veículo",
                type: "error",
            });
        }

        if (canSelectLicensePlate && willChosseLicensePlate && (currentLicensePlate === null || currentLicensePlate.length < 2)) {
            addToast({
                title: "Final de placa",
                description: "É necessário selecionar ao menos duas opções de final de placa.",
                type: "error",
            });
            return false;
        }

        if (deliveryOption === 3) {
            api.post("/location/address", {
                ...currentAddress,
                userId: Number(localStorage.getItem("@FleetSolutions:userIdPf")),
            })
                .then(() => {
                    localStorage.setItem("@FleetSolutions:getCurrentAddress", String("true"));
                })
                .catch(() => {
                    addToast({
                        title: "Cadastro pendente",
                        type: "error",
                        description: "Dados do local de retirada outros não foi possivel realizar o cadastro.",
                    });
                });
        }

        api.get(`/customers/${Number(localStorage.getItem("@FleetSolutions:userIdPf"))}`)
            .then(() => dispatch(setTabs({ id: "4" })))
            .catch(() => {
                addToast({
                    title: "Cadastro pendente",
                    type: "error",
                    description: "Finalize o cadastro para continuar com o pedido.",
                });
            });
    };

    return (
        <Container>
            {handleProductList()}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-10 offset-1">
                        <div className="buttons">
                            <div className="wrapper-btn">
                                <Button className="btn-back" onClick={handleBackPage}>
                                    Voltar
                                </Button>
                                <Button className="btn-continue" onClick={handleContinueOrder}>
                                    Continuar
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default NewPickupLocation;
