/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef } from 'react'
import { FiMail, FiLock } from 'react-icons/fi'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import api from '../../services/api'
import { useParams, useHistory } from 'react-router-dom'

import { Container, Content, AnimationContainer } from './styles'

import Input from '../../components/Input'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Button from '../../components/Button'
import getValidationErrors from '../../utils/getValidationErrors'
import { useToast } from '../../hooks/toast'
import logoImg from '../../assets/logo/logolm-cor.png'

interface ResetFormData {
  email: string
  NewPassword: string
  PasswordConfirmation: string
  PasswordResetToken: string
}

const ResetPassword: React.FC = () => {
  const { token } = useParams<any>()
  const { addToast } = useToast()
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const handleSubmit = useCallback(async (data: ResetFormData) => {
    data.PasswordResetToken = token

    try {
      formRef.current?.setErrors({})

      const schema = Yup.object().shape({
        email: Yup.string()
          .required('E-mail obrigatório')
          .email('Digite um e-mail válido'),
        NewPassword: Yup.string().required('Senha obrigatória'),
        PasswordConfirmation: Yup.string().oneOf(
          [Yup.ref('NewPassword'), undefined],
          'Confirmação incorreta'
        )
      })

      await schema.validate(data, {
        abortEarly: false
      })

      delete data.email

      await api
        .post('/change-password', data)
        .then((res) => {
          addToast({
            title: 'Senha alterada!',
            type: 'success',
            description: 'Sua senha foi definida com sucesso.'
          })

          history.push('/')
        })
        .catch((res) => {
          addToast({
            title: 'Erro ao alterar a senha!',
            type: 'error',
            description: res.response.data
          })
        })
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)

        formRef.current?.setErrors(errors)

        return
      }
    }
  }, [])

  return (
    <Container>
      <Header />
      <Content>
        <AnimationContainer>
          <img width="250" src={logoImg} alt="Fleet Solutions" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h2>Redefinir senha</h2>

            <Input
              icon={FiMail}
              placeholder="Email"
              id="email"
              name="email"
              type="email"
            />
            <br />
            <Input
              icon={FiLock}
              placeholder="Nova senha"
              id="NewPassword"
              name="NewPassword"
              type="password"
            />
            <br />
            <Input
              icon={FiLock}
              placeholder="Confirmação de senha"
              id="PasswordConfirmation"
              name="PasswordConfirmation"
              type="password"
            />
            <br />
            <Button type="submit">Alterar senha</Button>
          </Form>
        </AnimationContainer>
      </Content>
      <Footer />
    </Container>
  )
}

export default ResetPassword
