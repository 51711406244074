/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'antd'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiOutlineMenuAlt1 } from 'react-icons/hi'
import { BiDetail } from 'react-icons/bi'
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io'
import { Menu, MenuItem, Divider, Collapse, List } from '@material-ui/core'
import { useHistory, Link, useLocation } from 'react-router-dom'
import logoLmBranca from '../../assets/logo/logolm-branca.png'

import iconLogin from '../../assets/icons/login.svg'
import {
  Container,
  WrapperLogo,
  WrapperButton,
  Content,
  NavContainer,
  NavLink
} from './styles'
import SignIn from '../../pages/SignIn'
import { useAuth } from '../../hooks/auth'
import { loginApp } from '../../store/modules/login/actions'
import iconLogout from '../../assets/icons/logout.svg'
import api from '../../services/api'

interface IProps {
  isPositionAbsolute?: boolean
  isPositionFixed?: boolean
  home?: boolean
}

const Header: FC<IProps> = ({ isPositionAbsolute, isPositionFixed, home }) => {
  const { userId, fullname, role, signOut, dealershipId } = useAuth()
  const history = useHistory()
  const dispatch = useDispatch()
  const [loginIsRequired, setLoginIsRequired] = useState(false)
  const [openMenu, setOpenMenu] = useState(null)
  const [nameDealership, setNameDealership] = useState('')
  const [openCollapse, setOpenCollapse] = useState(false)
  const location = useLocation()
  const activeLink = location.pathname

  useEffect(() => {
    try {
      if (dealershipId) {
        api.get(`/dealerships/${dealershipId}`).then((res) => {
          const { data } = res
          setNameDealership(data.name)
        })
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    api
      .get(`/users/${userId}/logout`)
      .then((res) => {
        if (res.data.ok) {
          updateLogout(userId)
          handleLogout()
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const updateLogout = useCallback(async (userId: string) => {
    api
      .post(`/users/${userId}/UpdateLogout`)
      .then((res) => {
        console.log(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const handleChangeLogin = useCallback(() => {
    setLoginIsRequired(true)
    dispatch(loginApp({ status: true }))
  }, [])

  const handleOpenMenu = (event: any) => {
    setOpenMenu(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpenMenu(null)
  }

  const handleLogout = useCallback(() => {
    signOut()
    setOpenMenu(null)
    // history.push('/')
    window.location.replace(window.location.origin);
  }, [])

  const handleCollapseAdmin = () => {
    setOpenCollapse(!openCollapse)
  }

  const handleRedirectToMyOrders = () => {
    if (history !== undefined) {
      history.push('/my-orders')
    }
  }

  return (
    <Container
      isPositionAbsolute={isPositionAbsolute}
      isPositionFixed={isPositionFixed}
    >
      <Content>
        {loginIsRequired && <SignIn />}
        <WrapperLogo>
          <img
            className="logo"
            onClick={handleRedirectToMyOrders}
            src={logoLmBranca}
            alt="Fleet Solutions"
          />
          <NavContainer>
            {role === 'Admin da Concessionária' ? (
              <NavLink
                to="/dealerShips-dashboard"
                className={
                  activeLink === '/dealerShips-dashboard' ? 'active' : ''
                }
              >
                <AiOutlineBarChart size={20} />
                Dashboard
              </NavLink>
            ) : (
              <NavLink
                to="/dashboard"
                className={activeLink === '/dashboard' ? 'active' : ''}
              >
                <AiOutlineBarChart size={20} />
                Dashboard
              </NavLink>
            )}
            <NavLink
              to="/my-orders"
              className={activeLink === '/my-orders' ? 'active' : ''}
            >
              <BiDetail size={20} />
              Pedidos
            </NavLink>
          </NavContainer>
        </WrapperLogo>
        {!home && (
          <div className="wrapper-names">
            <p className="name-user">{fullname}</p>
            <p className="dealership-name">{nameDealership}</p>
          </div>
        )}

        <WrapperButton>
          {!fullname || home ? (
            <>
              <Button type="text" onClick={handleChangeLogin}>
                LOGIN <img src={iconLogin} alt="Icon Login" />
              </Button>
            </>
          ) : (
            <>
              <Button className="btn-menu" onClick={handleOpenMenu}>
                <HiOutlineMenuAlt1 size="30" />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={openMenu}
                keepMounted
                open={Boolean(openMenu)}
                onClose={handleCloseMenu}
              >
                <div className="menu">
                  <span className="spn-menu">MENU</span>
                  <Button className="btn-menu" onClick={handleCloseMenu}>
                    <HiOutlineMenuAlt1 size="30" />
                  </Button>
                </div>
                <MenuItem style={{ width: 200 }} onClick={handleCloseMenu}>
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to="/my-orders"
                  >
                    <span style={{ marginRight: 10 }}>Meus Pedidos</span>
                  </Link>
                </MenuItem>
                {(role === 'Super Admin' || role === 'Root') && (
                  <MenuItem style={{ width: 200 }} onClick={handleCloseMenu}>
                    <Link
                      style={{ textDecoration: 'none', color: '#000' }}
                      to="/dealerships-group"
                    >
                      <span style={{ marginRight: 10 }}>
                        Grupo de Concessionárias
                      </span>
                    </Link>
                  </MenuItem>
                )}
                {/* {(role === 'Super Admin' || role === 'Root') && (
                  <MenuItem style={{ width: 200 }} onClick={handleCloseMenu}>
                    <Link
                      style={{ textDecoration: 'none', color: '#000' }}
                      to="/dealerships-group"
                    >
                      <span style={{ marginRight: 10 }}>Status de Entrega</span>
                    </Link>
                  </MenuItem>
                )} */}
                {(role === 'Super Admin' ||
                  role === 'Admin do Grupo' ||
                  role === 'Root') && (
                  <MenuItem style={{ width: 200 }} onClick={handleCloseMenu}>
                    <Link
                      style={{ textDecoration: 'none', color: '#000' }}
                      to="/dealerships"
                    >
                      <span style={{ marginRight: 10 }}>Concessionárias</span>
                    </Link>
                  </MenuItem>
                )}
                {(role === 'Super Admin' ||
                  role === 'Admin do Grupo' ||
                  role === 'Admin da Concessionária' ||
                  role === 'Root') && (
                  <MenuItem style={{ width: 200 }} onClick={handleCloseMenu}>
                    <Link
                      style={{ textDecoration: 'none', color: '#000' }}
                      to="/users"
                    >
                      <span style={{ marginRight: 10 }}>Usuários</span>
                    </Link>
                  </MenuItem>
                )}

                {(role === 'Super Admin' || role === 'Root') && (
                  <MenuItem
                    onClick={handleCollapseAdmin}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <span style={{ marginRight: 10 }}>Admin</span>
                    {openCollapse ? <IoIosArrowDown /> : <IoIosArrowForward />}
                  </MenuItem>
                )}
                <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <MenuItem style={{ paddingLeft: 40 }}>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/reports"
                      >
                        <span style={{ marginRight: 10 }}>Relatórios</span>
                      </Link>
                    </MenuItem>
                    <MenuItem style={{ paddingLeft: 40 }}>
                      <Link
                        style={{ textDecoration: 'none', color: '#000' }}
                        to="/inventory-management"
                      >
                        <span style={{ marginRight: 10 }}>Estoque</span>
                      </Link>
                    </MenuItem>
                    {role === 'Root' && (
                      <MenuItem style={{ paddingLeft: 40 }}>
                        <Link
                          style={{ textDecoration: 'none', color: '#000' }}
                          to="/segments"
                        >
                          <span style={{ marginRight: 10 }}>Segmentos</span>
                        </Link>
                      </MenuItem>
                    )}
                    {role === 'Root' && (
                      <MenuItem style={{ paddingLeft: 40 }}>
                        <Link
                          style={{ textDecoration: 'none', color: '#000' }}
                          to="/integration-management"
                        >
                          <span style={{ marginRight: 10 }}>
                            Gestão de Integrações
                          </span>
                        </Link>
                      </MenuItem>
                    )}
                    {(role === 'Super Admin' || role === 'Root') && (
                      <MenuItem style={{ paddingLeft: 40 }}>
                        <Link
                          style={{ textDecoration: 'none', color: '#000' }}
                          to="/imageLoading"
                        >
                          <span style={{ marginRight: 10 }}>
                            Gestão de Conteúdo
                          </span>
                        </Link>
                      </MenuItem>
                    )}
                    {(role === 'Super Admin' || role === 'Root') && (
                      <MenuItem style={{ paddingLeft: 40 }}>
                        <Link
                          style={{ textDecoration: 'none', color: '#000' }}
                          to="/commissioning"
                        >
                          <span style={{ marginRight: 10 }}>
                            Comissionamentos
                          </span>
                        </Link>
                      </MenuItem>
                    )}
                    {(role === 'Super Admin' || role === 'Root') && (
                      <MenuItem style={{ paddingLeft: 40 }}>
                        <Link
                          style={{ textDecoration: 'none', color: '#000' }}
                          to="/license-plate-selection"
                        >
                          <span style={{ marginRight: 10 }}>
                            Escolha de final de placa
                          </span>
                        </Link>
                      </MenuItem>
                    )}
                  </List>
                </Collapse>
                <div className="menu-bottom">
                  <Divider />

                  <MenuItem style={{ marginTop: 20 }} onClick={handleLogout}>
                    <span>Sair</span>
                    <img
                      style={{ position: 'absolute', right: 30 }}
                      className="icon-menu"
                      src={iconLogout}
                      alt="Icon Notification"
                    />
                  </MenuItem>
                </div>
              </Menu>
            </>
          )}
        </WrapperButton>
      </Content>
    </Container>
  )
}

export default Header
