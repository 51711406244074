interface DownloadFile {
  name: string
  file: string
  type: keyof typeof mimeTypes
}

export const xlsx =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const docx =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

export const html = 'text/html'

export const mimeTypes = { xlsx, docx, html }

export const downloadFile = ({ name, file, type }: DownloadFile) => {
  const blob = new Blob([file], { type: mimeTypes[type] })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', `${name}.${type}`)
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href)
}
