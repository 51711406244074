import styled from 'styled-components'

export const Container = styled.div``

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;

  h1 {
    color: #17536c;
    margin-top: 30px;
    margin-bottom: 0px !important;
    font-size: 1.6em !important;
    text-align: center;
  }

  p {
    color: #3c505a;
    margin-top: 5px;
  }

  form {
    width: 80%;
    max-width: 500px;
    margin: 50px 0 100px 0;
    color: #0c5faf;

    p {
      color: #0c5faf;
      margin: 25px 0 0 0;
    }

    .wrapper-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      div {
        border-radius: 21px !important;
      }

      .ant-select-selector {
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .wrapper-button {
      float: right;
      margin-top: 20px;
      display: flex;

      button {
        width: 155px;
        height: 39px;
        border-radius: 24px;
        font-size: 14px;
      }

      .btn-cancel {
        border: 2px solid #3c505a;
        color: #3c505a;
        margin-right: 14px;
      }

      .btn-save {
        background: #236fbc 0% 0% no-repeat padding-box;
        border: 1px solid #226fbb;
        color: #fff;
      }
    }

    .other-style {
      div {
        background: #ffffff;
      }
    }

    .trash {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;

      .jIuKDF {
        width: 90%;
        background: #ffffff;
        border: 1px solid #0c5faf;
      }
    }

    .wrapper-info {
      margin-top: 12px;

      select {
        width: 100%;
        margin: 0px;
        border-radius: 21px;
        height: 45px;
        padding: 5px;
        background: #ffffff;
        border: 1px solid #0c5faf;
      }

      input,
      select {
        color: #3c505a;
      }

      .domains {
        margin-top: 7px;
        width: 78%;
        height: 40px;
        border-radius: 16px;
        padding: 6px 0 10px 17px;
        border: 1px solid #0c5faf;
      }

      .segments {
        padding: 0 10px;
        color: #000;
        font-weight: 500;
        width: 78%;
      }

      .wrapper-btn-add-domain {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;

        input::placeholder {
          font-size: 13px;
        }

        button {
          margin-left: 8px;
          border: none;
          font-size: 18px;
        }

        .fIBRHG {
          background: #fff;
        }

        .jIuKDF {
          width: 70%;
          background: #ffffff;
          border: 1px solid #0c5faf;
          width: 350px;
        }

        .fIBRHG,
        .fsfqkG,
        .kpTWNU,
        .bZEmZA {
          width: 390px !important;
        }
      }
    }

    .wrapper-info-address {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;

      .fIBRHG {
        background: #fff;

        input:nth-child(2) {
          max-width: 60px;
        }
      }

      .number,
      input:nth-child(2) {
        width: 30%;

        input {
          width: 20%;
        }
      }

      .address {
        width: 67%;
      }
    }

    .wrapper-segments {
      display: flex;
      justify-content: space-around;
      flex-direction: column;

      .wrapper-brand {
        display: flex;
        justify-content: space-around;

        .wrapper-segment {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 150px;
          margin: 10px;

          p {
            color: #000;
            margin: 0;
          }
        }
      }
    }

    .isActive {
      float: right;
      display: flex;
      margin-bottom: 15px;

      span {
        margin-right: 5px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media (max-width: 500px) {
    form {
      .wrapper-button {
        float: none;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`
