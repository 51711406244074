import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  background-color: #f1f1f1;

  .title {
    color: #17536c;
    margin-top: 30px;
    font-size: 1.6em !important;
    text-transform: uppercase;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  margin: 8px;

  span {
    font-size: 14px;
  }

  .select-segment {
    border: 1px solid #d9d9d9;
    text-align: left;
  }

  .model-code {
    width: 120px;
  }

  .input-model,
  .input-medium {
    width: 290px;
  }
`

export const WrapperButtonsModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const ModalContent = styled.div`
  text-align: center;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .message-wait {
    margin-top: 30px;
    font-weight: 500;
  }

  .title-message {
    color: #0c5faf;
    font-size: 22px;
    font-weight: 500;
  }

  .message,
  .message-wait {
    color: #000;
  }

  .btn-add {
    background-color: #0c5faf;
    color: #fff;
  }

  button {
    margin: 8px;
    border-radius: 12px;
    border: 2px solid #0c5faf;
    color: #0c5faf;
    font-weight: bold;
    font-size: 12px;
    width: 150px;
    text-transform: uppercase;
  }
`

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: right;
  width: 80%;
  margin: 30px auto;
  min-width: 350px;

  button {
    margin: 8px;
    border-radius: 12px;
    border: 2px solid #0c5faf;
    color: #0c5faf;
    font-weight: bold;
    font-size: 14px;

    @media (min-width: 620px) {
      width: 150px;
    }
  }
`

export const ButtonsRight = styled.div`
  button {
    background-color: #0c5faf;
    color: #fff;
  }
`

export const WrapperSwitch = styled.div`
  margin: 8px;

  span {
    margin-right: 10px;
  }
`

export const TableContent = styled.table`
  text-align: center;
  border-spacing: inherit;
  margin: 60px auto;
  border: 1pt solid #cfd7d9;
  width: 80%;

  input {
    width: 90px;
  }

  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  tr,
  td {
    border: 1pt solid #cfd7d9;
  }

  tr {
    font-size: 12px;
  }

  thead {
    tr {
      height: 36px;
      text-transform: uppercase;
    }
  }

  td {
    padding: 7px 15px;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    height: 36px;
  }

  .actions-button {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: 20px;
      background: transparent;
      border: none;
      margin: 4px;
    }
  }
`
