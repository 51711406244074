import styled, { keyframes } from 'styled-components'
import { shade } from 'polished'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ffMYBn {
    width: 100%;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  margin: 50px auto;
  width: 100%;
  max-width: 700px;

  @media (max-width: 1024px) {
    max-width: initial;
  }
`

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0)
    }
`

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  animation: ${appearFromLeft} 1s;

  a {
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;
    align-items: center;
    color: #0161af;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: ${shade(0.2, '#13306B')};
    }

    svg {
      margin-right: 16px;
    }
  }

  img {
    margin-bottom: 70px;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      margin-bottom: 24px;
    }

    @media (max-width: 465px) {
      width: 80%;

      input {
        width: 100%;
      }
    }
  }
`
