/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react'

import { Button, Select as SelectAntd } from 'antd'

import * as S from './styles'
import Footer from 'components/Footer'
import Header from 'components/Header'

import { Collapse } from '@material-ui/core'
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io'

import iconCalendar from '../../assets/icons/calendar.svg'
import { Bar, Column } from '@ant-design/charts'
import { findPersonType } from 'utils'
import { ISegment } from 'utils/types'

import api from 'services/api'

import { DatePicker } from 'antd'
import moment from 'moment'
import { useAuth } from 'hooks/auth'

const { RangePicker } = DatePicker

interface iDashboardParam {
  segments?: any[] | undefined
  initial: string
  final: string
  clientType?: number
  status?: any[] | undefined
  userIdDn?:string | number | undefined;
}

interface iSeller {
  userId: number
  fullname: string
}

const DealerShipsDashboard: FC = () => {
  const { roleId, dealershipId } = useAuth()

  const [openCollapseData, setOpenCollapseData] = useState(true)
  const [openCollapseDataSales, setOpenCollapseDataSales] = useState(true)
  const [segmentField, setSegmentField] = useState<ISegment | any>()
  const [segmentOptions, setSegmentOptions] = useState<ISegment[]>([])
  const [selectedFisicaJuridica, setSelectedFisicaJuridica] = useState('-1')
  const [selectedSeller, setSelectedSeller] = useState('-1')
  const [sellers, setSellers] = useState<iSeller[]>([])

  const [
    dealershipOrdersByClientTypesAsync,
    setDealershipOrdersByClientTypesAsync
  ] = useState([])
  const [
    signedContractsByModelForDealershipAsync,
    setSignedContractsByModelForDealershipAsync
  ] = useState([])
  const [signedContractsBySellerAsync, setSignedContractsBySellerAsync] =
    useState([])

    const [totalsOrdersBySellerAsync, setTotalsOrdersBySellerAsync] =
    useState([])
  const [
    totalVehiclesByDeadlineAndMonthlyKmValueAsync,
    setTotalVehiclesByDeadlineAndMonthlyKmValueAsync
  ] = useState([])
  const [
    dealershipOrdersByChannelAsync,
    setDealershipOrdersByChannelAsync
  ] = useState([])
  

  const [dataStart, setDataStart] = useState(moment().startOf('month'))
  const [dataEnd, setDataEnd] = useState(moment().endOf('month'))

  useEffect(() => {
    fetchDashboard()
  }, [dataStart, dataEnd, segmentField, selectedFisicaJuridica, sellers, selectedSeller])

  const fetchDashboard = async () => {
    try {
      const params: iDashboardParam = {
        initial: dataStart.format('YYYY-MM-DD'),
        final: dataEnd.format('YYYY-MM-DD'),
      }
      if (segmentField && segmentField.iD_Ibratan !== '-1') {
        params.segments = [Number(segmentField.iD_Ibratan)]
      }

      if (selectedFisicaJuridica && selectedFisicaJuridica !== '-1') {
        params.clientType = selectedFisicaJuridica === 'F' ? 1 : 0
      }

      

      if(selectedSeller && selectedSeller !== null && selectedSeller !== '-1'){
        params.userIdDn = selectedSeller
      }

      const [
        dealershipOrdersByClientTypesAsyncResponse,
        signedContractsByModelForDealershipAsyncResponse,
        signedContractsBySellerAsyncResponse,
        totalVehiclesByDeadlineAndMonthlyKmValueAsyncResponse,
        dealershipOrdersByChannelAsyncResponse,
        totalsOrdersBySellerAsyncResponse
      ] = await Promise.all([
        api.post('/charts/dealershipOrdersByClientTypesAsync', params),
        api.post('/charts/signedContractsByModelForDealershipAsync', params),
        api.post('/charts/signedContractsBySellerAsync', params),
        api.post(
          '/charts/TotalVehiclesByDeadlineAndMonthlyKmValueAsync',
          params
        ),
        api.post(
          '/charts/dealershipOrdersByChannelAsync',
          params
        ),
        api.post(
          '/charts/totalsOrdersBySellerAsync',
          params
        )
      ])

      setDealershipOrdersByClientTypesAsync(
        dealershipOrdersByClientTypesAsyncResponse.data ? dealershipOrdersByClientTypesAsyncResponse.data : []
      )
      setSignedContractsByModelForDealershipAsync(
        signedContractsByModelForDealershipAsyncResponse.data ? signedContractsByModelForDealershipAsyncResponse.data : []
      )
      setSignedContractsBySellerAsync(
        signedContractsBySellerAsyncResponse.data ? signedContractsBySellerAsyncResponse.data : []
      )
      setTotalVehiclesByDeadlineAndMonthlyKmValueAsync(
        totalVehiclesByDeadlineAndMonthlyKmValueAsyncResponse.data ? totalVehiclesByDeadlineAndMonthlyKmValueAsyncResponse.data : []
      )
      setDealershipOrdersByChannelAsync(
        dealershipOrdersByChannelAsyncResponse.data ? dealershipOrdersByChannelAsyncResponse.data : []
      )
      setTotalsOrdersBySellerAsync(
        totalsOrdersBySellerAsyncResponse.data ? totalsOrdersBySellerAsyncResponse.data : []
      )
    } catch (error) {
      console.error('Error fetching dashboard data:', error)
    }
  }

  useEffect(() => {
    fetchInitialData()
  }, [])

  const fetchInitialData = async () => {
    try {
      const [segmentsResponse, sellersResponse] = await Promise.all([
        api.get('/segments/active'),
        api.get(
          `/users/dealership-all-users-filter?roledId=${roleId}&dealershipId=${dealershipId}`
        )
      ])

      setSegmentOptions(segmentsResponse.data)
      setSellers(sellersResponse.data.itens)
    } catch (error) {
      console.error('Error fetching initial data:', error)
    }
  }

  const handleChangeField = (_: any, { value, children }: any) => {
    setSegmentField({ iD_Ibratan: value, type: children[0], name: children[2] })
  }

  const handleChangedSelectedFisicaJuridica = (_: any, { value }: any) => {
    setSelectedFisicaJuridica(value)
  }

  const handleChangedSeller = (_: any, { value }: any) => {
    setSelectedSeller(value)
  }

  const handleCleanFilters = () => {
    setSegmentField(null)
    setSelectedSeller('-1')
    setSelectedFisicaJuridica('-1')
    setDataStart(moment().startOf('month'))
    setDataEnd(moment().endOf('month'))
  }

  let chart

  const configDealershipOrdersByClientTypesAsync = {
    data: dealershipOrdersByClientTypesAsync,
    autoFit: false,
    xField: 'label',
    yField: 'percentual',
    point: {
      size: 30,
      shape: 'diamond'
    },
    label: {
      style: {
        fill: '#3D83A6'
      },
      content: (item: any) => {
        return `${item.percentual}% (${item.value})`
      }
    },
    tooltip: {
      formatter: (datum: any) => {
        return { name: datum.label, value: datum.percentual + '%' }
      }
    }
  }

  const configDealershipOrdersByChannelAsync = {
    data: dealershipOrdersByChannelAsync,
    autoFit: false,
    xField: 'label',
    yField: 'percentual',
    point: {
      size: 30,
      shape: 'diamond'
    },
    label: {
      style: {
        fill: '#3D83A6'
      },
      content: (item: any) => {
        return `${item.percentual}% (${item.value})`
      }
    },
    tooltip: {
      formatter: (datum: any) => {
        return { name: datum.label, value: datum.percentual + '%' }
      }
    }
  }

  const createChartConfig = (data: any, xField: string, yField: string) => ({
    data,
    autoFit: false,
    xField,
    yField,
    point: {
      size: 30,
      shape: 'diamond'
    },
    label: {
      style: {
        fill: '#3D83A6'
      }
    }
  })

  const handleCollapseData = () => {
    setOpenCollapseData(!openCollapseData)
  }

  const handleCollapseDataSales = () => {
    setOpenCollapseDataSales(!openCollapseDataSales)
  }

  const adjustDateMonth = (dataString1: string, dataString2: string) => {
    const [dia1, mes1, ano1] = dataString1.split('/')
    const [dia2, mes2, ano2] = dataString2.split('/')

    const dataInicio1 = moment(`${ano1}-${mes1}-${dia1}`, 'YYYY-MM-DD')
    const dataInicio2 = moment(`${ano2}-${mes2}-${dia2}`, 'YYYY-MM-DD')

    setDataStart(dataInicio1)
    setDataEnd(dataInicio2)
  }

  return (
    <S.Container>
      <Header />
      <S.Content>
        <S.SelectedOption>
          <h3>Dashboard</h3>
          <h4>|</h4>
          <S.PanelInputSelected>
            <img
              src={iconCalendar}
              style={{
                paddingLeft: '20px'
              }}
              alt="Calendario"
            />
            <RangePicker
              style={{ border: 0 }}
              format={'DD/MM/YYYY'}
              allowClear={false}
              value={[dataStart, dataEnd]}
              onChange={(_, dateString) => {
                if (dateString) {
                  adjustDateMonth(dateString[0], dateString[1])
                }
              }}
            />
          </S.PanelInputSelected>

          <S.PanelInputSelected>
            <label>Segmento:</label>

            <SelectAntd
              style={{ width: 400 }}
              onChange={handleChangeField}
              placeholder="Todos"
              value={segmentField?.iD_Ibratan}
            >
              <SelectAntd.Option key={-1} value={'-1'}>
                Todos
              </SelectAntd.Option>

              {segmentOptions.map(({ id, iD_Ibratan, type, name }) => (
                <SelectAntd.Option key={id?.toString()} value={iD_Ibratan}>
                  {findPersonType(type)?.label} - {name}
                </SelectAntd.Option>
              ))}
            </SelectAntd>
          </S.PanelInputSelected>

          <S.PanelInputSelected>
            <label>Tipo de Cliente:</label>

            <SelectAntd
              onChange={handleChangedSelectedFisicaJuridica}
              placeholder="Todos"
              value={selectedFisicaJuridica}
            >
              <SelectAntd.Option key={-1} value={'-1'}>
                Todos
              </SelectAntd.Option>

              <SelectAntd.Option key={'F'} value={'F'}>
                Física
              </SelectAntd.Option>
              <SelectAntd.Option key={'J'} value={'J'}>
                Jurídica
              </SelectAntd.Option>
            </SelectAntd>
          </S.PanelInputSelected>

          <S.PanelInputSelected>
            <label>Vendedores:</label>

            <SelectAntd
              style={{ width: 200 }}
              onChange={handleChangedSeller}
              placeholder="Todos"
              value={selectedSeller || ''}
            >
              <SelectAntd.Option key={-1} value={'-1'}>
                Todos
              </SelectAntd.Option>

              {sellers.map(({ userId, fullname }) => (
                <SelectAntd.Option key={userId?.toString()} value={userId}>
                  {fullname}
                </SelectAntd.Option>
              ))}
            </SelectAntd>
          </S.PanelInputSelected>

          <Button
            data-testid="btn_clear_filters"
            type="link"
            onClick={handleCleanFilters}
          >
            Limpar filtros
          </Button>
        </S.SelectedOption>
      </S.Content>

      <S.PainelChart>
        <S.HeaderCollapse onClick={handleCollapseData}>
          <span style={{ marginRight: '92%', color: '#17536C' }}>
            Pedidos Criados
          </span>
          {openCollapseData ? <IoIosArrowDown /> : <IoIosArrowForward />}
        </S.HeaderCollapse>
        <hr />
        <Collapse in={openCollapseData} timeout="auto" unmountOnExit>
          <S.ContentCharts>
            <S.Painel>
              <S.ContentPainelChart>
                <S.ContentPainelChartText>
                  <S.Title>Pedidos Criados</S.Title>
                  <S.SubTitle>% Por Tipo de Cliente</S.SubTitle>
                </S.ContentPainelChartText>
              </S.ContentPainelChart>

              <S.ContentChart>
                <Column
                  {...configDealershipOrdersByClientTypesAsync}
                  onReady={(chartInstance) => (chart = chartInstance)}
                />
              </S.ContentChart>
            </S.Painel>

            <S.Painel>
              <S.ContentPainelChart>
                <S.ContentPainelChartText>
                  <S.Title>Pedidos Criados</S.Title>
                  <S.SubTitle>Ranking de Vendedores</S.SubTitle>
                </S.ContentPainelChartText>
              </S.ContentPainelChart>

              <S.ContentChart>
                <Bar
                  {...createChartConfig(
                    totalsOrdersBySellerAsync,
                    'value',
                    'label'
                  )}
                  onReady={(chartInstance) => (chart = chartInstance)}
                />
              </S.ContentChart>
            </S.Painel>
            <S.Painel>
              <S.ContentPainelChart>
                <S.ContentPainelChartText>
                  <S.Title>Pedidos Criados</S.Title>
                  <S.SubTitle>Por plano</S.SubTitle>
                </S.ContentPainelChartText>
              </S.ContentPainelChart>

              <S.ContentChart>
                <Bar
                  {...createChartConfig(
                    totalVehiclesByDeadlineAndMonthlyKmValueAsync,
                    'value',
                    'label'
                  )}
                  onReady={(chartInstance) => (chart = chartInstance)}
                />
              </S.ContentChart>
            </S.Painel>
          </S.ContentCharts>
          <S.ContentCharts>

          <S.Painel>
              <S.ContentPainelChart>
                <S.ContentPainelChartText>
                  <S.Title>Pedidos Criados</S.Title>
                  <S.SubTitle>% Canal</S.SubTitle>
                </S.ContentPainelChartText>
              </S.ContentPainelChart>

              <S.ContentChart>
                <Column
                  {...configDealershipOrdersByChannelAsync}
                  onReady={(chartInstance) => (chart = chartInstance)}
                />
              </S.ContentChart>
            </S.Painel>

          </S.ContentCharts>
        </Collapse>
      </S.PainelChart>

      <S.PainelChart>
        <S.HeaderCollapse onClick={handleCollapseDataSales}>
          <span style={{ marginRight: '92%', color: '#17536C' }}>Vendas</span>
          {openCollapseDataSales ? <IoIosArrowDown /> : <IoIosArrowForward />}
        </S.HeaderCollapse>
        <hr />
        <Collapse in={openCollapseDataSales} timeout="auto" unmountOnExit>
          <S.ContentCharts>
            <S.Painel>
              <S.ContentPainelChart>
                <S.ContentPainelChartText>
                  <S.Title>Contratos Assinados</S.Title>
                  <S.SubTitle>Qtd. de Veículos por Modelo</S.SubTitle>
                </S.ContentPainelChartText>
              </S.ContentPainelChart>
              <S.ContentChart>
                <Bar
                  {...createChartConfig(
                    signedContractsByModelForDealershipAsync,
                    'value',
                    'label'
                  )}
                  onReady={(chartInstance) => (chart = chartInstance)}
                />
              </S.ContentChart>
            </S.Painel>
            <S.Painel>
              <S.ContentPainelChart>
                <S.ContentPainelChartText>
                  <S.Title>Vendas</S.Title>
                  <S.SubTitle>Ranking de Vendedores</S.SubTitle>
                </S.ContentPainelChartText>
              </S.ContentPainelChart>

              <S.ContentChart>
                <Bar
                  {...createChartConfig(
                    signedContractsBySellerAsync,
                    'value',
                    'label'
                  )}
                  onReady={(chartInstance) => (chart = chartInstance)}
                />
              </S.ContentChart>
            </S.Painel>
          </S.ContentCharts>
        </Collapse>
      </S.PainelChart>

      <Footer />
    </S.Container>
  )
}

export default DealerShipsDashboard
