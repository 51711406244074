import styled from "styled-components";

interface iStatus {
    status: boolean;
}

export const Status = styled.div<iStatus>`
    background-color: ${(props) => (props.status ? "green" : "red")};
    width: 12px;
    border-radius: 50%;
    height: 12px;
    margin: 0 auto;
`;

export const Container = styled.div`
    .ant-table-wrapper {
        width: 85%;
    }
    .ant-table-expanded-row .ant-table-cell {
        background-color: #3c505a;
        color: white;
    }

    .ant-table-thead th {
        background-color: #3c505a;
        color: white;
        &:last-child{
            text-align:center;
        }
    }

    .ant-table-tbody > tr > td {
        background-color: #f9f9f9;
    }

    .ant-pagination-item a,
    .ant-pagination-item:hover a,
    .ant-pagination-item:focus a,
    .ant-pagination-item-active a {
        color: #3c505a;
    }

    .ant-pagination-item:hover,
    .ant-pagination-item:focus,
    .ant-pagination-item-active {
        border-color: #3c505a;
    }

    .ant-spin-dot-item {
        background-color: #3c505a;
    }
    .ant-table-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-table.ant-table-bordered {
        background-color: #f9f9f9;
    }
    .ant-table-wrapper {
        background-color: #fff;
        margin-bottom: 45px;
    }
    .actions-button {
      width: 100%;
      display: flex;
      justify-content: center;

      button {
        width: 20px;
        background: transparent;
        border: none;
        margin: 4px;
      }
    }
    
    .filters{
        display:flex;
        width:85%;
        gap:15px;
        padding: 25px 0;
        .filter-item{
            flex:1;
            .ant-select, input, button{
                width:100% !important;
                border-radius: 4px;
                border: 1px solid #d9d9d9;
            }
            button{
                background: 0% 0% no-repeat padding-box padding-box rgb(35, 111, 188);
                border: 1px solid rgb(34, 111, 187);
                &.clean-filters{
                    background: none;
                    border: none;
                    width: auto !important;
                    padding: 0;
                }
            }
        }
    }
`;

export const WrapperTable = styled.div`
    width: 80%;
    margin-bottom: 100px;

    .pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        label {
            font-size: 14px;
            color: #767676;
            font-weight: 500;
        }

        button {
            margin: 2px;
        }

        .btn-page {
            width: 27px;
            height: 27px;
            background: #0c5faf 0% 0% no-repeat padding-box;
            border: 1px solid #e8e8e8;
            color: #fff;
            font: normal normal bold 11px/15px Open Sans;
        }

        .btn-previous {
            width: 59px;
            height: 27px;
            font: normal normal normal 11px/15px "Open Sans";
            border: 1px solid #e8e8e8;
            color: #b1afaf;
        }

        .btn-next {
            width: 59px;
            height: 27px;
            font: normal normal normal 11px/15px "Open Sans";
            border: 1px solid #e8e8e8;
        }
    }
    @media (max-width: 700px) {
        form {
            div {
                margin: 5px;
            }
        }
    }
`;



export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        color: #17536c;
        margin-top: 30px;
        margin-bottom: 0px !important;
        font-size: 1.6em !important;
        text-align: center;
    }

    form {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 80%;
        justify-content: space-between;

        button {
            width: 190px;
            margin-left: 8px;
            font-size: 14px;
        }

        .wrapper-search {
            display: flex;
            flex-direction: row;
            align-items: center;

            select {
                padding: 8px;
                border-radius: 23px;
                height: 41px;
            }

            div:nth-child(1) {
                margin: 0 15px;
                background: #ffffff;
            }

            div:nth-child(2) {
                margin: 0 15px;
                background: #ffffff;
            }

            div:nth-child(3) {
                margin: 0;
            }
        }
    }

    @media (max-width: 1237px) {
        p {
            text-align: center;
        }

        form {
            flex-direction: column;
            flex-wrap: wrap;

            .wrapper-search {
                flex-direction: column;
                flex-wrap: wrap;

                div {
                    margin-top: 10px !important;
                }
            }
        }
    }

    p {
        color: #3c505a;
        margin-top: 5px;
    }

    h1,
    p {
        text-align: center;
    }

    .wrapper-button {
        display: flex;
        flex-direction: row;
        width: 80%;
        justify-content: flex-end;

        button {
            width: 190px;
            font-size: 14px;
        }
    }
`;

export const ContentModal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        margin: 20px 0 0 0;
        font-size: 20px;
        color: #3c505a;
    }

    div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        button {
            width: 78px;
            border-radius: 6px;
            box-shadow: 0px 3px 6px #00000029;
            background: #0c5faf;
            margin: 10px;
            color: white;
        }
    }
`;
