/* eslint-disable @typescript-eslint/no-explicit-any */
import api from './api'

interface IPostOffers {
  modelCode?: Array<any>
  brandCode?: Array<any>
  colors?: Array<any>
  minMonthlyInstallment?: number
  maxMonthlyInstallment?: number
  page?: boolean
  currentPage?: number
  quantityPerPage: number
  segmentId: number
  salesChannel?: string | null
}

export const postOffers = async (params: IPostOffers) => {
  return api.post('/offers', params)
}

export const getAudiFilters = (params: {
  modelCode: number
  color: string
  deadLine: number
  monthlyKmValue: number
  segmentId: number
  listAccessories?: any[]
  listOptional?: any[]
  salesChannelFilter?:string | null;
}) => {
  const { listAccessories, listOptional, ...rest } = params

  return api.post(`/audifilters`, {
    ...rest,
    listAccessories:
      listAccessories && listAccessories.length > 0 ? listAccessories : null,
    listOptional: listOptional && listOptional.length > 0 ? listOptional : null,
    salesChannel: rest.segmentId === 9 ? (localStorage.getItem('@FleetSolutions:isSDBlindado') ? 'S&D - Blindado' : 'S&D') : null,
    listSalesChannel: rest.segmentId === 9 ? ['S&D - Blindado', 'S&D'] : null,
  })
}
