import styled from 'styled-components'

export const Container = styled.div`
  select {
    width: 200px;
    height: 50px;
    margin-left: 15px;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
    border-color: #ccc;
    border: 2px solid #ccc;
    border-radius: 5px;
  }
`
