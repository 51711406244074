import styled from 'styled-components'

export const Container = styled.div``

export const Content = styled.div`
  .img-banner {
    img {
      width: 100vw;
      height: 100vh;
    }
  }

  @media (max-width: 475px) {
    .img-banner {
      img {
        height: 300px;
        width: 500px;
      }
    }
  }

  @media (min-width: 476px) {
    .img-banner {
      img {
        height: auto;
        width: auto;
      }
    }
  }

  @media (min-width: 1350px) {
    .img-banner {
      img {
        height: 100vh;
        width: 100vw;
      }
    }
  }
`
