import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  background-color: #f1f1f1;

  @media (max-width: 768px) {
    text-align: left;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    text-align: left;
  }

  .title {
    color: #17536c;
    margin-top: 30px;
    font-size: 1.6em !important;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 1.2em;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
      font-size: 1.4em;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex: 0;
  flex-direction: row;
  padding-top: 30px;
  padding-left: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
    padding: 5px;
  }
`

export const SelectedOption = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
    padding: 5px;
  }

  h3 {
    color: #17536c;
    font-size: 24px;
    padding-right: 10px;
  }

  h4 {
    color: #cccccc;
    font-size: 20px;
    padding-right: 10px;
  }
`

export const PanelInputSelected = styled.div`
  height: 34px;
  border: 1px solid #cfd7d9;
  background-color: white;
  text-align: left;
  padding-right: 10px;
  display: flex;
  align-items: center;
  line-height: 1.1;

  label {
    text-align: left;
    color: #3c505a;
    padding-left: 10px;
    font-size: 12px;
  }
`

export const HeaderCollapse = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  height: auto;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #3c505a;
  font-weight: 700;
`

export const Filters = styled.div`
  display: flex;
  gap: 10px;

`

export const FiltersRow = styled.div`
  display: flex;
  gap: 10px;
`

export const ContentCharts = styled.div`
  display: flex;
  flex: 3;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
  }
`

export const PainelChart = styled.div`
  border: 1px solid #cfd7d9;
  border-radius: 10px;
  background-color: white;
  width: 98%;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
    padding: 5px;
  }

  hr {
    border: 0;
    border-top: 1px solid #cfd7d9;
    margin-left: 10px;
    margin-right: 10px;
  }
`

export const Painel = styled.div`
  border: 1px solid #cfd7d9;
  border-radius: 10px;
  background-color: white;
  width: 40%;
  height: 100%;
  margin: 20px;

  @media (max-width: 768px) {
    width: 98%;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 98%;
  }
`

export const PainelLine = styled.div`
  border: 1px solid #cfd7d9;
  border-radius: 10px;
  background-color: white;
  width: 100%;
  height: 100%;
  margin: 20px;
`

export const LinePainel = styled.div`
  border: 1px solid #cfd7d9;
  border-radius: 10px;
  background-color: white;
  width: 50%;
  height: 100%;
  margin: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 98%;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
    width: 98%;
  }
`

export const ContentChart = styled.div`
  margin: 20px;
`

export const ContentPainelChart = styled.div`
  display: flex;
  flex: 0;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 98%;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
    width: 98%;
  }
`

export const ContentPainelChartText = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
`

export const Title = styled.label`
  color: #666666;
  font-weight: 100;
  font-size: 12px;
  padding-left: 10px;
  text-align: left;
`

export const SubTitle = styled.label`
  font-size: 20px;
  text-align: left;
  padding-left: 10px;
`
