/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Contact,
  Content,
  Modal,
  ModalContainer,
  OrderInfo,
  WrapperLine,
  WrapperStep,
} from "./styles";

import noImageCar from "assets/defaultImages/no-image.png";
import ImgWithFallback from "components/ImgWithFallback";
import { useToast } from "hooks/toast";
import { defaultBackendUrl } from "services/api";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { setTabs } from "../../store/modules/tabsRegisterUser/actions";
import { cpfMask } from "../../utils/cpfMask";
import formatValue from "../../utils/formatValue";
import { phoneMask } from "../../utils/phoneMask";

import type { StepsProps } from "antd";
import { Input, Popover, Steps } from "antd";

const customDot: StepsProps["progressDot"] = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        step {index} status: {status}
      </span>
    }>
    {dot}
  </Popover>
);

const { Step } = Steps;

interface OrderItemStatus {
  orderItemStatusId: number;
  orderItemId: number;
  chassis: string;
  status: number;
  statusDescription: string;
  cpfCnpj: string;
  supplierRemoval: string;
  deliveryLocation: string;
  deliveryPlate: string;
  deliveryModel: string;
  deliveryColor: string;
  dateCreated: string;
}

interface Items {
  orderId: number;
  productId: number;
  model: string;
  color: string;
  monthlyKmValue: number;
  monthlyInstallment: number;
  deadline: number;
  modelCode: string;
  cityForPickup: string;
  uf: string;
  optional: string;
  statusDescription: string;
  orderItemStatus: OrderItemStatus[];
  dn: string;
  finalPlate?: string;
  orderItemId?:number;
}

interface Order {
  orderId: number;
  statusDescription: any;
  note: string;
  dealershipId: number;
  status: number;
  accessories: string;
  optionals: string;
  deliveryOption?: number;
  userIdDn?: number;
}

type OrderStatusPFViewProps = {
  id: number;
};

const listItemStatus = [
  "Aguardando Faturamento",
  "Aguardando Documentação",
  "Aguardando Preparação",
  "Aguardando Transporte",
  "Veículo Disponível",
  "Veículo Entregue",
];

const documentTypes = [
  { label: "CNH Frente", id: 105 },
  { label: "CNH Verso", id: 121 },
  { label: "RG Frente", id: 103 },
  { label: "RG Verso", id: 104 },
  { label: "Selfie", id: 106 },
  { label: "Comprovante de residencia", id: 5 },
  { label: "Holerite", id: 600 },
];

const cantEditStatusList = [0,-1,10,11,8,5, 7];

const OrderStatusPFView = ({ id }: OrderStatusPFViewProps) => {
  const { userId, dealershipId } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const validStatusValues = [11];

  const [isModalResponse, setIsModalResponse] = useState(false);
  const [textMessageJustify, setTextMessageJustify] = useState("");

  const [items, setItems] = useState<Items[]>([]);
  const [order, setOrder] = useState<Order>();
  const [dealership, setDealership] = useState({} as any);
  const [customer, setCustomer] = useState({} as any);
  const [currentAddress, setCurrentAddress] = useState({} as any);
  const [docsWithError, setdocsWithError] = useState("" as string);
  const [docErrors, setDocErrors] = useState<any[]>([]);
  const [canEditOrder, setCanEditOrder] = useState<boolean>(false);

  const loggedUserId = Number(localStorage.getItem('@FleetSolutions:userId'))

  

  useEffect(() => {
    if (!userId || !dealershipId) {
      history.push("/");
    }
    if (id && id !== undefined) {
      try {
        api.get(`/orderitems?orderId=${id}&noCache=true`).then((res) => {
          console.log(res.data)
          setItems(res.data);
        });
        handleUpdateOrder();
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  const handleUpdateOrder = async () => {
    let deliveryOption = 0;
    await api.get(`/orders/${id}`).then(async (res) => {
      deliveryOption = res.data.deliveryOption;
      setOrder(res.data);
      setCanEditOrder(!cantEditStatusList.includes(res?.data?.status!) && res.data.userIdDn === loggedUserId)

      if (res.data.locationId === 0) {
        if (res.data.deliveryOption === 3) {
          await api
            .get(`/location/address/currentAddress/${res.data.userId}`)
            .then(async (response: any) => {
              const address = {
                name: "OUTROS",
                street: response.data.street,
                addressNumber: response.data.number,
                ...response.data,
              };
              setDealership(address);
            });
        } else {
          await api
            .get(`/dealerships/${res.data.dealershipId}`)
            .then(async (response: any) => {
              setDealership(response.data);
            });
        }
      } else {
        await api
          .get(`/location/${res.data.locationId}`)
          .then((response: any) => {
            const address = {
              name: response.data.name,
              street: response.data.address,
              addressNumber: response.data.number,
            };
            setDealership(address);
          });
      }

      if (res.data.status === 14) {
        const resErrors = await api.get(
          `/upload/errors?userId=${res.data.userId}&orderId=${id}`,
        );
        let docsStr;
        if (resErrors.data.errors.length > 0) {
          const updatedArray = resErrors.data.errors.map(
            (item: { typeDocument: number }) => {
              const document = documentTypes.find(
                (doc) => doc.id === item.typeDocument,
              );
              return {
                ...item,
                name: document ? document.label : "",
              };
            },
          );

          setDocErrors(updatedArray);

          console.log(updatedArray);
        }
      }
      await api.get(`/customers/${res.data.userId}`).then(async (res) => {
        if (deliveryOption === 2) {
          const address = {
            name: "ENDEREÇO DO CLIENTE",
            street: res.data.address.street,
            addressNumber: res.data.address.number,
          };
          setDealership(address);
        }
        setCustomer(res.data);
      });
    });
  };

  const resendDoc = () => {
    dispatch(setTabs({ id: "1" }));
    history.push(
      `/list-dealership/upload-file/${customer?.user?.userId}/${order?.orderId}`,
    );
  };


  const editOrder = () => {
    api.get(`/offers/${items[0]?.productId}`).then((res) => {
      const payload = {
        "orderId": order?.orderId,
        "orderItemId": items[0]?.orderItemId,
        // "productId": null,
        "userIdDn": order?.userIdDn
      }
      localStorage.setItem(
        '@FleetSolutions:editData',
        JSON.stringify(payload)
      )
      const { data } = res;
      history.push(
        `/offers/pf/${data?.segment}`,
      );
    });
  };

  const handleResponse = async () => {
    try {
      await api.post(`/ordermessage`, {
        orderMessageId: 0,
        orderId: order?.orderId,
        message: textMessageJustify,
        dateCreated: new Date().toISOString(),
        userId: Number(userId),
        typeMessage: "Enviado",
      });
      setTextMessageJustify("");
      addToast({
        title: "Enviado com sucesso!",
        type: "success",
        description: `Sua mensagem foi enviada com sucesso!`,
      });
    } catch (err) {
      addToast({
        title: "Erro ao enviar mensagem",
        type: "error",
        description: `${err.response.data}`,
      });
    }

    setIsModalResponse(false);
  };

  const handleMessageJustify = (event: any) => {
    const { value } = event.currentTarget;
    setTextMessageJustify(value);
  };

  const isVisibleTimeLine = () => {
    if (
      order &&
      order.status !== undefined &&
      validStatusValues.includes(order?.status)
    ) {
      return true;
    }
    return false;
  };

  const getItemOrderStatus = (orderItemStatus: OrderItemStatus[]) => {
    if (isVisibleTimeLine()) {
      return orderItemStatus.length - 1;
    }
    return 0;
  };

  const getDocumentLabel = (documents: any[]) => {
    const labels = documents?.map((doc: any) => {
      const { label = "" } =
        documentTypes.find((docItem) => docItem.id === doc.typeDocument) || {};
      return label;
    });
    const str = labels?.join(", ");
    return str;
  };

  const setItemStatusPass = (orderItemStatus: string) => {
    let index = listItemStatus.indexOf(orderItemStatus);
    if (index < 0) index = 0;

    return index;
  };

  const DocumentItem = (typeDocument: any, error: any) => {
    return (
      <div key={typeDocument.typeDocument}>
        <p>Type Document: {typeDocument}</p>
        <p>Error: {error}</p>
      </div>
    );
  };

  return (
    <>
      <Content>
        <Contact>
          <div className="data-company">
            <h2>Dados do cliente</h2>

            <div className="wrapper-info">
              <div>
                <p className="title-description">CPF: </p>
                <p>{customer?.user && cpfMask(customer.user.cpfCnpj)}</p>
              </div>
              <div>
                <p className="title-description">RG: </p>
                <p>{customer?.rg}</p>
              </div>
            </div>
          </div>

          <div className="data-contact">
            <div className="wrapper">
              <div className="wrapper-info">
                <div>
                  <p className="title-description">Nome: </p>
                  <p>{customer?.user && customer.user.fullname}</p>
                </div>
                <div>
                  <p className="title-description">Celular: </p>
                  <p>{customer?.cellPhone && phoneMask(customer.cellPhone)}</p>
                </div>
              </div>
              <div className="wrapper-info">
                <div style={{ minWidth: "200px" }}>
                  <p className="title-description">E-mail: </p>
                  <p>{customer?.user && customer.user.email}</p>
                </div>
              </div>
            </div>
          </div>
        </Contact>
        <hr />

        <OrderInfo>
          <div className="data-order">
            <h2>Dados do pedido</h2>
            <div className="wrapper-info">
              <p className="title-description">
                <span>Número:</span> {order?.orderId}
              </p>
              <p className="title-description">
                <span>Status:</span> {order?.statusDescription}
              </p>
            </div>

            {canEditOrder && (
              <p>
                <button className="btn-resend-doc" onClick={editOrder}>
                  Editar pedido
                </button>
              </p>
            )}


            {customer && order?.status === 14 && (
              <p>
                <button className="btn-resend-doc" onClick={resendDoc}>
                  Reenviar documentação
                </button>
              </p>
            )}

            {order?.status === 8 && (
              <>
                <p>
                  <button className="btn-resend-doc" onClick={resendDoc}>
                    Ajustar pedido
                  </button>
                </p>
                <p>
                  <button
                    className="btn-resend-doc"
                    onClick={() => setIsModalResponse(true)}>
                    Resposta
                  </button>
                </p>
              </>
            )}
          </div>

          <div className="note">
            <p>
              Observação:{" "}
              {order?.status === 14 &&
                order?.note === null &&
                !docErrors.length &&
                "É necessário reenviar a documentação para análise."}
              {order?.status === 14 && !!docErrors.length && (
                <div>
                  {docErrors.map((item, index) => (
                    <div className="doc-item" key={"docWithError" + index}>
                      <div>
                        <b>{item.name}</b>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.error }}></div>
                    </div>
                  ))}
                </div>
              )}
              {order?.status !== 12 && order?.note}
              <br></br>
              {order?.status === 8 && (
                <p>
                  Clique no botão abaixo para ajustar as informações e reenviar
                  pedido
                </p>
              )}
            </p>
          </div>
        </OrderInfo>
        {items.length
          ? items.map((item: Items, idx: number) => (
              <>
                <WrapperLine key={idx}>
                  <div className="wrapper-img">
                    <ImgWithFallback
                      className="image-car"
                      src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.webp`}
                      fallbacks={[
                        `${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.png`,
                        `${noImageCar}`,
                      ]}
                      alt="Carro"
                    />
                    <div>
                      <p className="title-description">
                        <span>Modelo:</span> {item.model}
                      </p>
                      <p className="title-description">
                        <span>Cor: </span>
                        {item.color}
                      </p>
                      {((order?.accessories || order?.optionals) && (
                        <div>
                          {order?.accessories && (
                            <p className="title-description">
                              <span>Acessórios:</span> {order?.accessories}
                            </p>
                          )}
                          {order?.optionals && (
                            <p className="title-description">
                              <span>Opcionais inclusos:</span>{" "}
                              {order?.optionals}
                            </p>
                          )}
                        </div>
                      )) || (
                        <div>
                          <p className="title-description">
                            <span>Opcionais inclusos:</span> {item.optional}
                          </p>
                        </div>
                      )}
                      <div>
                        <p className="title-description">
                          <span>Final da placa:</span>{" "}
                          {item.finalPlate || "S/PLACA"}
                        </p>
                      </div>
                      <p className="title-description">
                        <span>Placa:</span>
                        {item.orderItemStatus[
                          getItemOrderStatus(item.orderItemStatus)
                        ] &&
                          ` ${
                            item.orderItemStatus[
                              getItemOrderStatus(item.orderItemStatus)
                            ].deliveryPlate
                              ? item.orderItemStatus[
                                  getItemOrderStatus(item.orderItemStatus)
                                ].deliveryPlate
                              : ""
                          }`}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="title-description">
                      <span>Período:</span> {item.deadline} meses
                    </p>
                    <p className="title-description">
                      <span>Km contratado:</span> {item.monthlyKmValue}{" "}
                    </p>
                    <p className="title-description">
                      <span>Parcela mensal:</span>{" "}
                      {formatValue(item.monthlyInstallment)}
                    </p>
                    <p className="title-description">
                      <span>Chassi:</span>
                      {item.orderItemStatus[
                        getItemOrderStatus(item.orderItemStatus)
                      ] &&
                        ` ${
                          item.orderItemStatus[
                            getItemOrderStatus(item.orderItemStatus)
                          ].chassis
                            ? item.orderItemStatus[
                                getItemOrderStatus(item.orderItemStatus)
                              ].chassis
                            : ""
                        }`}
                    </p>
                  </div>
                  <div>
                    {order?.deliveryOption === 3 ? (
                      <p className="title-description">
                        <span>Local de retirada:</span> {dealership.street},{" "}
                        {dealership.addressNumber} - {dealership.complement} -{" "}
                        {dealership.district}
                        <br /> - {dealership.city} {dealership.uf}
                      </p>
                    ) : (
                      <p className="title-description">
                        <span>Local de retirada:</span> {dealership.street},{" "}
                        {dealership.addressNumber} {item.uf}
                      </p>
                    )}

                    <p className="title-description">
                      <span>DN de entrega:</span> {dealership.name}
                    </p>
                    <p className="title-description">
                      <span>Status de entrega:</span>
                      {isVisibleTimeLine() &&
                        ` ${
                          item.orderItemStatus[
                            getItemOrderStatus(item.orderItemStatus)
                          ]?.statusDescription ?? ""
                        }`}
                    </p>
                  </div>
                  {isVisibleTimeLine() && (
                    <WrapperStep key={`step-${idx}`}>
                      <Steps
                        current={setItemStatusPass(
                          item?.orderItemStatus[
                            getItemOrderStatus(item.orderItemStatus)
                          ]?.statusDescription,
                        )}
                        progressDot={customDot}>
                        <Step title="Aguardando Atribuição do Veículo" />
                        <Step title="Veículo em Preparação - Documentação" />
                        <Step title="Veículo em Preparação de Acessórios" />
                        <Step title="Veículo em Transporte" />
                        <Step title="Aguardando disponibilidade de retirada" />
                        <Step title="Veículo Entregue" />
                      </Steps>
                    </WrapperStep>
                  )}
                </WrapperLine>
              </>
            ))
          : ""}
      </Content>
      {isModalResponse && (
        <ModalContainer>
          <Modal>
            <>
              <h2>Resposta</h2>
              <div>
                <p>Informe uma resposta:</p>
                <Input.TextArea
                  id="textDeliveryDeadline"
                  name="textDeliveryDeadline"
                  onChange={handleMessageJustify}
                  value={textMessageJustify}
                />
              </div>
              <div>
                <button
                  onClick={() => {
                    setTextMessageJustify("");
                    setIsModalResponse(false);
                  }}>
                  Voltar
                </button>
                <button onClick={handleResponse}>Confirmar resposta</button>
              </div>
            </>
          </Modal>
        </ModalContainer>
      )}
    </>
  );
};

export default OrderStatusPFView;
