import { createGlobalStyle } from 'styled-components'

import VWHeadlineOTBlack from '../fonts/vwheadlineot-black.ttf'
import VWHeadlineOTSemiBold from '../fonts/vwheadlineot-semibold.ttf'
import VWHeadlineOTBook from '../fonts/vwheadlineot-book.ttf'

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    @font-face {
        font-family: "VW Headline OT Black";
        src: url(${VWHeadlineOTBlack});
    }

    @font-face {
        font-family: "VW Headline OT SemiBold";
        src: url(${VWHeadlineOTSemiBold});
    }

    @font-face {
        font-family: "VW Headline OT Book";
        src: url(${VWHeadlineOTBook});
    }

    .actions-menu{
        .ant-dropdown-menu-title-content {
            flex: auto;
            font-weight: 700;
            color: #3C505A;
            .anticon{
                color:#000;
                margin-right:6px;
            }
        }
    }

    .table-filter{
        .ant-btn{
            font-size:12px;
            &.ant-btn-primary{
                border-color: #3c505a;
                background: #3c505a;
            }
        }
    }
    
    body, input {
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 500;
    }

    h1, button {
        font-family: 'Open Sans';
        font-weight: 700;
    }

    button {
        cursor: pointer;
    }

    .large-dropdown{
        min-width:450px !important;
    }

    .medium-dropdown{
        min-width:300px !important;
    }

    .filter-select{
        max-width:150px !important;
    }

    .ant-modal-close {
        margin-top: 20px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border: none !important;
    }

    .ant-modal-content {
        border-radius: 15px !important;
    }

    .eZyetP .tabs span svg {
      margin: 0;
      color: #000;
    }

    .ant-upload-list-item-error .ant-upload-list-item-name {
        color: #000;
        margin-right: 5px;
    }

    .MuiMenu-paper  {
        overflow: hidden !important;
    }
  
    .MuiMenu-paper {
        height: 98vh;
        top: 0;
        max-height: none !important;
        top: 0 !important;
        right: 0;
        /* left: auto !important; */
        width: 264px;

        .MuiList-padding {
            height: 100vh;
        }

        .menu {
            display: flex;
            justify-content: flex-end;
            margin: 25px;
            align-items: center;
            width: 100%;

            button {
                margin-right: 35px;
                border: transparent;
            }

            span {
                color: #3C505A;
                font-weight: 500;
            }
        }

        .menu-bottom {
            bottom: 30px;
            position: absolute;
            width: 100%;
        }
    }

    @media(max-width: 639px) {
        .ant-tabs-nav-list {
            flex-wrap: wrap;
        } 
    }

    .modal-ci {
        .ant-modal-header{
            border-radius: 16px 16px 0 0;
            padding: 16px;
        }
        .ant-modal-body{
            .form {
                background-color: #f1f1f1;
                padding: 32px;
                border-radius: 16px;
                position: relative;
                button {
                    min-width: 65px;
                    width: auto;
                    border-radius: 26px;
                    padding: 10px 16px 10px 16px;
                    font-size: 12px;
                    font-weight: 700;
                    height: auto;
                    &.btn-continue {
                        background-color: #0161af;
                        color: #fff;
                    }
                }
                .ant-select {
                    width: 100%;
                }
                input,
                .ant-select-selector {
                    border: 1px solid #d9d9d9 !important;
                    border-radius: 23px;
                    padding: 8px 16px 8px 16px;
                    
                }
                .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                    height: auto;
                }
            
                .all-selection-group {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    padding-bottom: 16px;
                    margin-bottom: 32px;
                    border-bottom: 1px solid #cccccc;
                }
            }
        }
        .ant-modal-footer{
            padding: 16px;
            border-color:#BEBEBE;
            button{
                font-size: 12px;
                border-radius: 46px;
                padding: 10px 16px;
                width: auto;
                height: auto;
                min-width: 71px;
                font-weight: 700;
                border-width: 2px;
                border-color:#0161AF;
                color:#0161AF;
                &.ant-btn-primary{
                    background-color:#0161AF;
                    color:#fff;
                }
            }
        }
    }
`
