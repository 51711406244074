import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #f1f1f1;

  .description {
    color: #3c505a;
    font-size: 20px;
    text-align: center;
    margin: 0;
  }

  .title {
    color: #17536c;
    font-size: 25px;
    text-transform: uppercase;
    text-align: center;
    margin: 28px 0 16px 0;
  }

  .buttons {
    width: 82%;
    margin: 30px auto;
    display: flex;
    justify-content: end;
    border-radius: 24px;
    flex-wrap: wrap;

    .btn-continue,
    .btn-save {
      width: 190px;
      height: 41px;
      font-size: 12px;
      text-transform: uppercase;
      margin: 8px;
    }

    .btn-save {
      background: transparent;
      border: 2px solid #226fbb;
      color: #226fbb;
    }

    .wrapper-button {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  @media (max-width: 760px) {
    .buttons {
      justify-content: center;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 82%;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 40px auto;
  align-items: center;
  font-family: 'Open Sans';
  padding-bottom: 16px;

  p {
    color: #0a0a0a;
  }
`

export const WrapperDoc = styled.div`
  background: #ffffff;
  border: 1px solid #005faf;
  border-radius: 36px;
  width: 80%;
  max-width: 787px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  margin: 12px;

  .left {
    p {
      margin: 0;
      color: #005faf;
      font-size: 14px;
      font-weight: bold;
    }

    .name-file {
      font-size: 18px;
      font-weight: bold;

      span {
        font-weight: 500;
      }
    }

    .know-more {
      color: #005faf;
      font-size: 12px;
      padding: 5px;
    }
  }

  .more-info {
    text-decoration: underline;
    font-size: 14px;
    color: #000;
    cursor: pointer;
  }

  .right {
    .upload {
      flex-direction: column;

      .ant-tooltip-inner {
        display: none;
      }
    }

    span.anticon.anticon-delete {
      display: block;
    }

    .btn-upload {
      width: 192px;
      border: 3px solid #005faf;
      background: #fff;
      color: #005faf;
      font-size: 11px;
      margin-left: 12px;
      align-items: center;
      display: flex;
      justify-content: space-between;

      svg {
        color: #005faf;
      }
    }
  }

  @media (max-width: 1024px) {
    height: auto;
    flex-direction: column;
    width: auto;

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`
