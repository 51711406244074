/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useCallback, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import api from '../../../services/api'

import { useToast } from '../../../hooks/toast'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header'

import {
  Container,
  WrapperTable,
  Table,
  Content,
  ContainerModal,
  ContentModal,
  WrapperDoc,
  ContentModalUpload,
  ContainerModalUpload,
  Title,
  Description,
  ButtonUpload
} from './styles'

import Button from '../../../components/Button'
import iconEdit from '../../../assets/icons/edit-2.svg'
import iconTrash from '../../../assets/icons/trash-2.svg'
import { cpfMask } from '../../../utils/cpfMask'
import { phoneMask } from '../../../utils/phoneMask'
import { useAuth } from '../../../hooks/auth'

import { Modal, Upload } from 'antd'
import DocumentsCompany from '../DocumentsCompany'
import Notes from './Notes'
import { AiOutlineUpload } from 'react-icons/ai'
import { UploadChangeParam } from 'antd/lib/upload'
import Loading from 'components/Loading'

interface ContactsProps {
  contactId: number
  name: string
  email: string
  cpf: string
  id: number
}

const CompanyContacts: FC = () => {
  const { dealershipId, role, userId: accountUserId } = useAuth()
  const { companyCnpj, orderId, clientType, userId } = useParams<any>()

  const history = useHistory()
  const { addToast } = useToast()

  const [contacts, setContacts] = useState<ContactsProps[]>([])
  const [isPendingSignatories, setIsPendingSignatories] = useState(false)
  const [isPowerOfAttorney, setIsPowerOfAttorney] = useState(false)
  const [showDocuments, setShowDocuments] = useState(false)
  const [showNotes, setShowNotes] = useState(false)
  const [dataUpload, setDataUpload] = useState(false)
  const [note, setNote] = useState('')
  const [showUpload, setShowUpload] = useState(true)
  const [isLoading, setLoading] = useState(false)

  const getLogisticsByCustomer = useCallback(async (status: string) => {
    await api
      .get(`/contacts/company/cnpj/${companyCnpj}`)
      .then((response: any) => {
        setContacts(response.data)
      })
  }, [])

  useEffect(() => {
    if (!accountUserId || !dealershipId) {
      history.push('/')
    }
    getLogisticsByCustomer('0')
  }, [getLogisticsByCustomer])

  const handleNewGroup = () => {
    history.push(`/new-contact/${companyCnpj}/${orderId}`)
  }

  const handleRemove = (id: number) => {
    api.delete(`/contacts?contactId=${id}`).then((response) => {
      addToast({
        title: 'Contato Removido!',
        type: 'success',
        description: 'O contato foi removido com sucesso.'
      })

      setContacts(contacts.filter((item) => item.contactId !== id))
    })
  }

  const handleSendToAwaitingContractSign = () => {
    if (contacts.length === 0) {
      addToast({
        title: 'Envar Signatários',
        type: 'error',
        description: 'Favor cadastrar um contato.'
      })
      return
    }

    const orderStatus = {
      OrderId: Number(orderId),
      Status: 15,
      Description: 'Aguardando envio de contrato'
    }

    api.post('/orderstatus', orderStatus).then((response) => {
      addToast({
        title: 'Informações enviadas',
        type: 'success',
        description: 'Informações enviadas com sucesso.'
      })

      history.push('/my-orders')
    })
  }

  const handleSendContract = () => {
    setLoading(true)

    api
      .post(`/orders/send-contract?orderId=${orderId}`)
      .then((res) => {
        if (res.data) {
          addToast({
            title: 'Contrato enviado!',
            type: 'success',
            description: 'O contrato foi enviado com sucesso.'
          })

          history.push('/my-orders')
        }
      })
      .catch((err) => {
        addToast({
          title: 'Erro ao enviar o contrato',
          type: 'error',
          description: 'Ocorreu um erro ao enviar o contrato'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleShowModal = () => {
    setIsPendingSignatories(!isPendingSignatories)
  }

  const handleShowModalIsPower = () => {
    setIsPowerOfAttorney(!isPowerOfAttorney)
  }

  const handleOk = () => {
    if (!note) return

    setIsPendingSignatories(false)
    api
      .post('/orders/SaveObservation', {
        orderId: Number(orderId),
        note: note
      })
      .then((response) => {
        history.push('/my-orders')
      })
  }

  const handleCancel = () => {
    setIsPendingSignatories(false)
  }

  const handleOkIsPowerOfAttorney = () => {
    setIsPowerOfAttorney(false)
    if (dataUpload) {
      addToast({
        title: 'Procuração enviada!',
        type: 'success',
        description: 'A procuração foi enviada com sucesso.'
      })
    }
  }

  const handleCancelIsPowerOfAttorney = () => {
    setIsPowerOfAttorney(false)
  }

  const handleSendDoc = async (config: any, formData: any, image: any) => {
    try {
      await api
        .post(
          `/document-company-upload?userId=${userId}&typeDocumentCompany=13`,
          formData,
          config
        )
        .then((res) => {
          setIsPendingSignatories(false)
        })
    } catch (err) {
      addToast({
        title: 'Erro ao fazer upload',
        type: 'error',
        description: `${err?.response.data}`
      })
    }
  }

  const handleFormatDocUpload = async (image: any) => {
    const formData = new FormData()

    if (image.file.size > 5000000 && image.file.status !== 'uploading') {
      return addToast({
        title: 'Falha ao enviar documento',
        type: 'error',
        description: 'O tamanho do arquivo é maior que 5 mb'
      })
    } else {
      if (image.file.status === 'done' || image.file.status === 'error') {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            Accept: 'text/plain'
          }
        }

        formData.append('file', image.file.originFileObj)
        setDataUpload(formData ? true : false)
        await handleSendDoc(config, formData, image)
      }
    }
  }

  const props = {
    name: 'file',
    multiple: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    showUploadList: showUpload,
    beforeUpload: (file: any) => {
      const { type } = file

      if (type !== 'application/pdf') {
        setShowUpload(false)
        addToast({
          title: 'Erro ao enviar arquivo',
          type: 'error',
          description: 'Envie arquivos do tipo PDF.'
        })

        return false
      }

      setShowUpload(true)
      return true
    },

    onChange(info: UploadChangeParam) {
      handleFormatDocUpload(info)
    }
  }

  const handleOpenModal = () => {
    setShowDocuments(!showDocuments)
  }

  const handleShowNotes = () => {
    setShowNotes(!showNotes)
  }

  return (
    <Container>
      <Header />
      <Content>
        <h1>CADASTRO DE CONTATOS</h1>
        <p>Visualize, edite e crie contatos</p>

        {isLoading ? (
          <>
            <Loading />

            <p className="message-wait">Aguarde, enviando contrato...</p>
          </>
        ) : (
          <>
            <div className="wrapper-button">
              <Button onClick={handleNewGroup}>NOVO CONTATO</Button>
            </div>
            <WrapperTable style={{ marginBottom: '20px' }}>
              <Table>
                <thead>
                  <tr>
                    <th className="th-left">Nome</th>
                    <th className="th-center">Telefone</th>
                    <th className="th-center">CPF</th>
                    <th className="th-center">E-mail</th>
                    <th className="th-right">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.length ? (
                    contacts.map((item: any, i: number) => {
                      return (
                        <tr key={i}>
                          <td>{item.name}</td>
                          <td style={{ textAlign: 'center' }}>
                            {phoneMask(item.phone)}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {cpfMask(item.cpf)}
                          </td>
                          <td style={{ textAlign: 'center' }}>{item.email}</td>
                          <td className="actions-button">
                            <Link
                              to={`/edit-contact/${companyCnpj}/${orderId}/${item.contactId}`}
                            >
                              <button>
                                <img src={iconEdit} alt="Editar" />
                              </button>
                            </Link>
                            <button
                              onClick={() => handleRemove(item.contactId)}
                            >
                              <img src={iconTrash} alt="Deletar" />
                            </button>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>Nenhum registro encontrado! </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="wrapper-link">
                {role !== 'Atendimento' && (
                  <button className="btn-show-notes" onClick={handleShowNotes}>
                    Observação
                  </button>
                )}

                {role !== 'Atendimento' && (
                  <button
                    className="btn-power-of-attorney"
                    onClick={handleShowModalIsPower}
                  >
                    Inserir Procuração
                  </button>
                )}
              </div>

              <div className="wrapper-btn-show-files">
                {role === 'Atendimento' && (
                  <button className="btn-show-files" onClick={handleOpenModal}>
                    Download de documentos
                  </button>
                )}
              </div>
            </WrapperTable>

            <ContainerModalUpload>
              <Modal
                footer={null}
                title=""
                visible={isPowerOfAttorney}
                onOk={handleOkIsPowerOfAttorney}
                onCancel={handleCancelIsPowerOfAttorney}
                // style={{ position: 'absolute' }}
              >
                <Title>Carregar documento</Title>
                <Description>Faça upload da procuração</Description>

                <ContentModalUpload>
                  <WrapperDoc>
                    <div className="container-name-file">
                      <p className="name-file">Procuração</p>
                      <p>Arquivos (PDF) até 5 mb</p>
                    </div>

                    <div className="right">
                      <Upload
                        className="upload"
                        {...props}
                        multiple={false}
                        maxCount={1}
                        accept=".pdf"
                      >
                        <ButtonUpload>
                          <AiOutlineUpload size={17} /> CLIQUE PARA CARREGAR
                        </ButtonUpload>
                      </Upload>
                    </div>
                  </WrapperDoc>
                  <Button
                    className="btn-submit"
                    disabled={!dataUpload ? true : false}
                    onClick={handleOkIsPowerOfAttorney}
                  >
                    Enviar
                  </Button>
                </ContentModalUpload>
              </Modal>
            </ContainerModalUpload>

            <div
              className="wrapper-button"
              style={{ justifyContent: 'space-between' }}
            ></div>

            <div
              className="wrapper-button"
              style={{ justifyContent: 'space-between' }}
            >
              {/* <Button onClick={handleSendContacts}>ENVIAR CONTATOS</Button> */}

              <Link to="/my-orders">
                <Button className="btn-back">VOLTAR</Button>
              </Link>

              <div className="button-rigth" style={{ display: 'flex' }}>
                {role === 'Atendimento' && (
                  <>
                    <Button className="btn-back" onClick={handleShowModal}>
                      PENDENTE INFORMAÇÕES
                    </Button>
                    <Button
                      className="btn-submit-contract"
                      onClick={handleSendContract}
                    >
                      ENVIAR CONTRATO
                    </Button>
                  </>
                )}
              </div>

              {role !== 'Atendimento' && (
                <Button
                  onClick={handleSendToAwaitingContractSign}
                  style={{ marginLeft: '10px' }}
                >
                  ENVIAR INFORMAÇÕES
                </Button>
              )}
            </div>
            <DocumentsCompany
              showModal={showDocuments}
              onOpenModal={handleOpenModal}
              clientType={clientType}
              userId={userId}
            />
            <Notes
              orderId={orderId}
              showModalNotes={showNotes}
              onOpenModalNotes={handleShowNotes}
            />
            <ContainerModal>
              <Modal
                footer={null}
                title=""
                visible={isPendingSignatories}
                onCancel={handleCancel}
                style={{
                  backgroundColor: '#838383',
                  padding: '8px',
                  marginTop: '62px'
                }}
              >
                <ContentModal>
                  <p>Inserir Observação:</p>
                  <form>
                    <textarea
                      name="note"
                      onChange={(e) => setNote(e.target.value)}
                    ></textarea>
                    {
                      // eslint-disable-next-line
                  <a onClick={handleOk}>Enviar</a>
                    }
                  </form>
                </ContentModal>
              </Modal>
            </ContainerModal>
          </>
        )}
      </Content>
      <Footer />
    </Container>
  )
}

export default CompanyContacts
