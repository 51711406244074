/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { WrapperLoading, Container } from './styles'

const Loading = (props: any) => {
  const { text } = props
  return (
    <Container>
      <WrapperLoading />
      <span>{text}</span>
    </Container>
  )
}

export default Loading
