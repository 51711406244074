import React from 'react'

import { Container } from './styles'

const Terms = () => (
  <Container>
    <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>
      Termos e Condições
    </h2>
    <p>
      Produto destinado exclusivamente à pessoa física e idade superior a 21
      anos que possua CNH válida. A disponibilidade, entrega e devolução do
      veículo da oferta será feita exclusivamente nas concessionárias
      Volkswagen/MAN e Audi, de acordo com a opção feita na contratação.
    </p>
    <p>
      Caso a quilometragem final contratada seja excedida, o valor será cobrado
      ao final do contrato, conforme plano contratado.{' '}
    </p>
    <p>
      Os produtos Volkswagen Sign&Drive e Volkswagen Truck Rental possuem
      autoproteção. O produto Audi Luxury Signature possui seguro veicular.
    </p>
    <p>
      As revisões periódicas devem ser realizadas exclusivamente na rede de
      concessionárias Volkswagen/MAN e Audi, inclusive as manutenções que não
      são cobertas pelos planos contratados. O veículo possui película solar e
      tapetes.
    </p>
    <p>
      Oferta sujeita à análise e aprovação de crédito, bem como disponibilidade
      de estoque. A depender do caso, novas condições poderão ser apresentadas.
    </p>
  </Container>
)

export default Terms
