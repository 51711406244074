/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import api from '../../services/api'
import { Container, Content } from './styles'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import UploadPessoaFisica from '../../pages/UploadPessoaFisica'
import UploadFile from '../UploadFile'
import OrderSummary from '../OrderSummary'
import { IState } from '../../store'
import { ILocation } from '../../store/modules/location/types'
import { setTabs } from '../../store/modules/tabsRegisterUser/actions'
import RegisterCompany from '../RegisterCompany'
import PickupLocation from '../PickupLocation'
import RegisterUser from '../RegisterUser'
import PickupLocationPF from '../../pages/PickupLocationPF'
import OrderSummaryPF from '../OrderSummaryPF'
import { useAuth } from '../../hooks/auth'
import UploadFileOthers from 'pages/UploadFileOthers'
import NewPickupLocation from 'pages/PickupLocationPF/index_new'

const ListDealership: React.FC = () => {
  const dispatch = useDispatch()
  const { orderId, isCompany } = useParams<any>()
  const history = useHistory()
  const { userId } = useAuth()

  const { TabPane } = Tabs
  const tabId = useSelector<IState, ILocation>((state) => state.tabs)
  const [clientType, setClientType] = useState('pj')

  const [order, setOrder] = useState<Order>()
  const [tab, setTab] = useState('0')
  const [tabRegisterUserPage, setTabRegisterUserPage] = useState('1')
  const [tabUploadPage, setTabUploadPage] = useState('4')
  const [tabPickupLocationPage, setTabPickupLocationPage] = useState('2')
  const [tabOrderSummaryPage, setTabOrderSummaryPage] = useState('3')

  interface Order {
    orderId: number
    statusDescription: any
    note: string
    status: number
  }

  useEffect(() => {
    if (!userId) {
      history.push('/')
    }

    const ct = localStorage.getItem('@FleetSolutions:clientType')
    if (ct) {
      setClientType(ct.toString())
    }

    if (ct === 'pf' || orderId) {
      setTabUploadPage('1')
      setTabRegisterUserPage('2')
      setTabPickupLocationPage('3')
      setTabOrderSummaryPage('4')
    }
    // setTab('3')
    setTab(tabId.id.toString())
    // setTab('4')
  }, [tabId.id])

  const callback = (key: string) => {
    setTab(key)
    dispatch(setTabs({ id: key }))
  }

  useEffect(() => {
    if (orderId && orderId !== undefined) {
      try {
        api.get(`/orders/${orderId}`).then((res) => {
          setOrder(res.data)
        })
      } catch (err) {
        console.log('ListDealership', JSON.stringify(err, undefined, 4))
      }
    }
  }, [])

  return (
    <Container>
      <Header />
      <Content>
        <Tabs
          className={tab === tabPickupLocationPage ? 'tabs local-retirada' : 'tabs'}
          activeKey={tab}
          defaultActiveKey="1"
          onChange={callback}
          renderTabBar={(props, TabNavList) => (
            <TabNavList {...props} mobile={true} />
          )}
        >
          {(clientType === 'pf' && !isCompany) || (orderId && !isCompany) ? (
            <>
              <TabPane disabled tab={<span>Carregar documentos</span>} key={1}>
                {order?.status === 8 ? (
                  <UploadFileOthers />
                ) : (
                  <UploadPessoaFisica />
                )}
              </TabPane>
              <TabPane disabled tab={<span>Cadastro</span>} key={2}>
                <RegisterUser />
              </TabPane>
              {order?.status !== 8 && (
                <>
                  <TabPane
                    tab={<span>Local de retirada</span>}
                    disabled
                    key={tabPickupLocationPage}
                  >
                    <NewPickupLocation />

                    {/* <PickupLocationPF /> */}
                  </TabPane>
                  <TabPane
                    tab={<span>Resumo do pedido</span>}
                    key={tabOrderSummaryPage}
                    disabled
                  >
                    <OrderSummaryPF />
                  </TabPane>
                </>
              )}
            </>
          ) : (
            <>
              {isCompany ? (
                <>
                  <TabPane
                    disabled
                    tab={<span>Carregar documentos</span>}
                    key={tabUploadPage}
                  >
                    {order?.status === 8 ? (
                      <UploadFileOthers />
                    ) : (
                      <UploadFile />
                    )}
                  </TabPane>
                  <TabPane
                    disabled
                    tab={<span>Cadastro</span>}
                    key={tabRegisterUserPage}
                  >
                    <RegisterCompany />
                  </TabPane>
                </>
              ) : (
                <>
                  <TabPane
                    disabled
                    tab={<span>Cadastro</span>}
                    key={tabRegisterUserPage}
                  >
                    <RegisterCompany />
                  </TabPane>
                  <TabPane
                    disabled
                    tab={<span>Local de retirada.</span>}
                    key={tabPickupLocationPage}
                  >
                    <PickupLocation />
                  </TabPane>
                  <TabPane
                    disabled
                    tab={<span>Resumo do pedido</span>}
                    key={tabOrderSummaryPage}
                  >
                    <OrderSummary />
                  </TabPane>
                  <TabPane
                    disabled
                    tab={<span>Carregar documentos</span>}
                    key={tabUploadPage}
                  >
                    <UploadFile />
                  </TabPane>
                </>
              )}
            </>
          )}
        </Tabs>
      </Content>
      <Footer />
    </Container>
  )
}

export default ListDealership
