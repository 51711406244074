/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Footer from '../../../../components/Footer'
import Header from '../../../../components/Header'
import api from '../../../../services/api'
import Input from '../../../../components/Input'
import { Container, Content } from './styles'
import getValidationErrors from '../../../../utils/getValidationErrors'
import { useToast } from '../../../../hooks/toast'
import { cpfMask } from '../../../../utils/cpfMask'
import { phoneMask } from '../../../../utils/phoneMask'
import { useAuth } from '../../../../hooks/auth'

interface CreateContactFormData {
  CompanyId?: number
  name: string
  Cpf: string
  email: string
  phone: number
}

const NewContact: FC = () => {
  const { userId, dealershipId } = useAuth()
  const { id, companyCnpj, orderId } = useParams<any>()

  const history = useHistory()
  const { addToast } = useToast()
  const formRef = useRef<FormHandles>(null)
  const [callApi, setCallApi] = useState(false)
  const [contact, setContact] = useState<CreateContactFormData>()

  const [cpf, setCpf] = useState('')
  const [phone, setPhone] = useState('')

  useEffect(() => {
    if (!userId || !dealershipId) {
      history.push('/')
    }
    if (id) {
      api.get(`/contacts/${id}`).then((response) => {
        setContact(response.data)
        setCpf(cpfMask(response.data.cpf))
        setPhone(phoneMask(response.data.phone))
      })
    }
  }, [id])

  const initialValue = {
    Name: contact?.name,
    phone: contact?.phone,
    Email: contact?.email
  }

  const handleNewContact = useCallback(
    async (data: CreateContactFormData) => {
      if (!callApi) {
        return
      }

      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          Name: Yup.string().required('Nome obrigatório'),
          Cpf: Yup.string().required('CPF obrigatório').min(14),
          Email: Yup.string().required('E-mail obrigatório')
        })

        await schema.validate(data, {
          abortEarly: false
        })

        data.Cpf = data.Cpf.replace(/\.|-/g, '')
        data.CompanyId = companyCnpj

        if (contact) {
          api.put(`/contacts?contactId=${id}`, data)

          addToast({
            title: 'Contato editado!',
            type: 'success',
            description: 'Contato editado com sucesso.'
          })

          setTimeout(() => {
            history.push(`/contacts-company/${companyCnpj}/${orderId}`)
          }, 1000)

          return
        } else {
          api
            .post('/contacts', data)
            .then((res) => {
              setTimeout(() => {
                history.push(`/contacts-company/${companyCnpj}/${orderId}`)
              }, 1000)

              addToast({
                title: 'Contato salvo!',
                type: 'success',
                description: 'Contato salvo com sucesso.'
              })
            })
            .catch((err: any) => {
              addToast({
                title: 'CPF inválido!',
                type: 'error',
                description: 'Digite um CPF válido'
              })
            })

          return
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err)

          formRef.current?.setErrors(erros)

          return
        }
      }
    },
    [addToast, callApi, contact, history]
  )

  const handleChangeCpf = (e: any) => {
    setCpf(cpfMask(e.target.value))
  }

  const handleFormatPhone = (item: any) => {
    setPhone(phoneMask(item.target.value))
  }

  return (
    <Container>
      <Header />
      <Content>
        <h1>INSERIR DADOS DE CONTATO</h1>
        <p>Preencha os dados abaixo, sobre o responsável pelos veículos</p>

        <Form
          initialData={initialValue}
          onSubmit={handleNewContact}
          ref={formRef}
        >
          <div className="wrapper-info other-style">
            <label>Nome</label>
            <Input id="Name" name="Name" type="text" />
          </div>
          <div className="wrapper-info other-style">
            <label>Telefone</label>
            <Input
              id="phone"
              name="phone"
              type="text"
              onChange={handleFormatPhone}
              value={phone}
            />
          </div>
          <div className="wrapper-info other-style">
            <label>CPF</label>
            <Input
              onChange={handleChangeCpf}
              value={cpf}
              id="Cpf"
              name="Cpf"
              type="text"
            />
          </div>
          <div className="wrapper-info other-style">
            <label>E-mail</label>
            <Input id="Email" name="Email" type="email" />
          </div>
          <div className="wrapper-button">
            <Link to={`/contacts-company/${companyCnpj}/${orderId}`}>
              <button className="btn-cancel">Cancelar</button>
            </Link>

            <button
              onClick={() => setCallApi(true)}
              type="submit"
              className="btn-save"
            >
              Salvar
            </button>
          </div>
        </Form>
      </Content>
      <Footer />
    </Container>
  )
}

export default NewContact
