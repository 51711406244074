import styled from 'styled-components'

export const Container = styled.div`
  background-color: #f1f1f1;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media (max-width: 620px) {
    flex-direction: column;
  }
`

export const Menu = styled.div`
  width: 310px;
  height: 59vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;

  hr {
    width: 80%;
    margin: 0 auto;
  }

  p {
    margin-left: 25px;
  }

  .title-report {
    font-weight: 500;
    margin-bottom: 0;
  }

  .active {
    color: #136ab2;
    font-weight: 500;
  }

  .report {
    margin: 15px 0 4px 55px;
    font-size: 14px;
    cursor: pointer;
  }

  @media (max-width: 620px) {
    height: 70px;
    width: 100%;
    flex-direction: row;
    margin-bottom: 20px;

    p {
      margin: 6px !important;
    }

    .title-report,
    hr {
      display: none;
    }
  }
`

export const HeaderReport = styled.div`
  background-color: #3c505a;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 8px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

export const WrapperFields = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  min-height: 200px;
  flex-wrap: wrap;

  button {
    width: 150px;
    font-weight: normal;
    font-size: 14px;
    margin-top: 35px;
  }

  .message-report {
    width: 100%;
    margin-bottom: -60px;
  }
`

export const ContainerReport = styled.div`
  width: 100%;
  max-width: 1440px;
  margin-top: 40px;
`

export const WrapperDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
  width: 220px;
  margin: 8px;

  label {
    color: #136ab2;
  }

  select,
  input {
    width: 220px;
    height: 36px;
    border-radius: 18px;
    padding: 6px;
    border: 1px solid #136ab2;
  }
`

export const ReportsData = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  h1 {
    width: inherit;
    display: flex;
    justify-content: center;
    color: #17536c;
    font-size: 25px;
    text-align: center;
  }

  p {
    color: #3c505a;
    text-align: center;
  }
`
