export interface ISegment {
  id: string
  label: string
}

export const vwPF = '9'
export const vwPJ = '1'
export const audiPF = '11'
export const audiPJ = '7'
export const truckPJ = '4'
export const truckAPJ = '22'
export const rwPF = '3'
export const repPF = '5'
export const id4PF = '24'

export const vwPFLabel = 'VW - PF'
export const vwPJLabel = 'VW - PJ'
export const audiPFLabel = 'Audi - PF'
export const audiPJLabel = 'Audi - PJ'
export const truckPJLabel = 'Trucks - PJ'
export const rwPFLabel = 'RW - PF'
export const repPFLabel = 'REP - PF'

export const segmentVWPF = { id: vwPF, label: vwPFLabel }
export const segmentVWPJ = { id: vwPJ, label: vwPJLabel }
export const segmentAudiPF = { id: audiPF, label: audiPFLabel }
export const segmentAudiPJ = { id: audiPJ, label: audiPJLabel }
export const segmentTruckPJ = { id: truckPJ, label: truckPJLabel }
export const segmentRWPF = { id: rwPF, label: rwPFLabel }
export const segmentRepPF = { id: repPF, label: repPFLabel }

export const segments: Array<ISegment> = [
  segmentVWPF,
  segmentVWPJ,
  segmentAudiPF,
  segmentAudiPJ,
  segmentTruckPJ,
  segmentRWPF,
  segmentRepPF
]

export const hasSegment = (args: { id: string; segments: string }) => {
  return args.segments.indexOf(args.id) >= 0
}
