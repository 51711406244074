import React from 'react'
import { Upload } from 'antd'
import { AiOutlineUpload } from 'react-icons/ai'
import { UploadChangeParam } from 'antd/lib/upload'

import Button from '../../components/Button'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'
import { WrapperDoc } from './styles'

type UploadDocOtherProps = {
  docName: string
  description: string
  fileType: string
  typeDocName: string
  downloadDoc?: string | null
  numberDoc: number
}

const UploadDocOther = ({
  docName,
  description,
  fileType,
  downloadDoc
}: UploadDocOtherProps) => {
  const { addToast } = useToast()
  const userIdCompany = localStorage.getItem('@FleetSolutions:userIdCompany')
    ? Number(localStorage.getItem('@FleetSolutions:userIdCompany'))
    : Number(localStorage.getItem('@FleetSolutions:userIdPf'))

  const props = {
    name: 'file',
    multiple: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    maxCount: 1,
    accept: 'image/jpeg,image/png,application/pdf',
    onChange(info: UploadChangeParam) {
      if (info.file.type)
        info.file.name = `Outros.${info.file.type.split('/')[1]}`

      handleFormatDocUpload(info)
    }
  }

  const handleFormatDocUpload = async (image: any) => {
    const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf']

    const formData = new FormData()

    if (!allowedFileTypes.includes(image.file.type)) {
      return addToast({
        title: 'Falha ao enviar documento',
        type: 'error',
        description:
          'Formato de arquivo inválido. Permitido apenas JPG, PNG ou PDF.'
      })
    }

    if (image.file.size > 5000000 && image.file.status !== 'uploading') {
      return addToast({
        title: 'Falha ao enviar documento',
        type: 'error',
        description: 'O tamanho do arquivo é maior que 5 mb'
      })
    } else {
      if (image.file.status === 'done' || image.file.status === 'error') {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            Accept: 'text/plain'
          }
        }

        formData.append('File', image.file.originFileObj)
        formData.append('UserId', userIdCompany.toString())
        formData.append('FileType', '16')

        await handleSendDoc(config, formData, image)
      }
    }
  }

  const handleSendDoc = async (config: any, formData: any, image: any) => {
    try {
      await api.post(`/newdocuments`, formData, config)
    } catch (err) {
      addToast({
        title: 'Erro ao fazer upload',
        type: 'error',
        description: `${err.response.data}`
      })
    }
  }

  return (
    <WrapperDoc>
      <div className="left">
        <p className="name-file">
          {docName}
          <span>{description}</span>
        </p>
        <p>{fileType}</p>
      </div>
      <div className="right">
        <Upload className="upload" {...props}>
          <Button className="btn-upload">
            <AiOutlineUpload size={17} /> CLIQUE PARA
            {downloadDoc ? 'SUBSTIRUIR' : 'CARREGAR'}
          </Button>
        </Upload>
        {downloadDoc && (
          <a
            href={`data:application/pdf;base64,${downloadDoc}`}
            download="file.pdf"
          >
            {docName}
          </a>
        )}
      </div>
    </WrapperDoc>
  )
}

export default UploadDocOther
