import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #f1f1f1;

  .description {
    color: #3c505a;
    font-size: 20px;
    text-align: center;
    margin: 0;
  }

  .wrapper-loading {
    display: flex;
    justify-content: center;
  }

  .title {
    color: #17536c;
    font-size: 25px;
    text-transform: uppercase;
    text-align: center;
    margin: 28px 0 16px 0;
  }

  .wrapper-btn {
    width: 100%;
    max-width: 900px;
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    flex-wrap: wrap;

    div {
      width: 190px;
    }

    @media (max-width: 768px) {
      justify-content: center;
    }

    button {
      width: 190px;
      height: 41px;
      font-size: 12px;
      text-transform: uppercase;
      margin: 8px;
    }

    .btn-add {
      background: transparent;
      color: #000000;
      margin-top: -35px;
      margin-left: 650px;
    }

    .btn-back {
      background: transparent;
      border: 2px solid #226fbb;
      color: #226fbb;
    }
  }

  @media (max-width: 760px) {
    .buttons {
      justify-content: center;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 82%;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 40px auto;
  align-items: center;
  font-family: 'Open Sans';
  padding-bottom: 16px;

  p {
    color: #0a0a0a;
  }
`

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

export const Modal = styled.div`
  width: 300px;
  height: 300px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;

  @media (min-width: 425px) {
    width: 400px;
  }

  h2 {
    font-size: 24px;
    color: #0c5faf;
    font-weight: 600;
    text-align: center;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-child(2) {
      justify-content: flex-start;
      flex-direction: column;
      width: 80%;

      p {
        font-size: 14px;
        text-align: left;

        &:first-child {
          font-weight: 700;
          margin-bottom: 15px;
        }
      }
    }

    &:last-child {
      width: 90%;
      margin-top: 30px;

      button {
        width: 48%;
        border: none;
        color: white;
        border-radius: 24px;
        border: 2px solid #236fbc;
        font-size: 14px;
        height: 38px;

        &:first-child {
          background-color: white;
          color: #236fbc;
        }

        &:last-child {
          background-color: #236fbc;
        }
      }
    }
  }
`

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 275px;
  justify-content: center;

  h3 {
    font-size: 21px;
    color: #0c5faf;
    font-weight: 500;
  }

  p {
    margin: 0;
  }

  input {
    border: 2px solid #cfd7d9;
    border-radius: 30px;
    width: 256px;
    height: 41px;
  }

  > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  button {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }
`

export const Table = styled.table`
  width: 100%;
  text-align: center;
  border-spacing: inherit;
  margin: 15px 0px 30px 0px;
  border: 1pt solid #cfd7d9;

  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  tr,
  td {
    border: 1pt solid #cfd7d9;
  }

  tr {
    font-size: 14px;
  }

  thead {
    tr {
      height: 36px;
    }
  }

  td {
    padding: 7px 15px;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    height: 36px;
  }

  .large {
    width: 110px;
  }

  .status-color-wait {
    color: #ba1818;
  }

  .status-color-wait-finance {
    color: #ce651a;
  }

  .status-color-available {
    color: #369617;
  }

  .status-color-license-plate {
    color: #ba1818;
  }

  .status-color-active {
    color: #167c9d;
  }
`
