import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import api from "../../../services/api";
import { Collapse } from "@material-ui/core";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { Button, Switch, Upload, Select as SelectAntd, Form } from "antd";
import * as Yup from "yup";
import { Link, useHistory, useParams } from "react-router-dom";
import NewInput from "../../../components/Input/newInput";
import Select from "../../../components/Select";
import { Container, Content } from "./styles";
import getValidationErrors from "../../../utils/getValidationErrors";
import { useToast } from "../../../hooks/toast";
import NewTextAreaFleet from "components/TextareaFleet/newTextArea";
import { AiOutlineUpload } from "react-icons/ai";
import { UploadChangeParam } from "antd/lib/upload";
import { DOWNLOAD_DOCX } from "utils/urls";
import { downloadFile } from "utils/downloadFile";
import SimpleColorPicker from "components/ColorPicker";
import { message } from 'antd';


const { Option } = SelectAntd;

interface iTemplateUplaod {
  id: number;
  description: string;
}

interface iFilesContract {
  file: string;
  name: string;
}

interface CreateSegmentsFormData {
  id?: number | string;
  type: string;
  name: string;
  prefix: string;
  status: boolean;
  allowCreditCard: boolean;
  emailFrom: string;
  emailHost: string;
  emailPort: string;
  emailPwd: string;
  emailUser: string;
  templateUnico: string;
  diasExpirar: number | string;
  orderSummary: string;
  clientId: string;
  privateKeyFile: string;
  signerEmail: string;
  impersonatedUserId: string;
  iD_Ibratan: string;
  iD_Fleet: number | string;
  filesContract?: iFilesContract[];
  tokenCrm?: string | number | null;
  sendCrm?: boolean;
  merchantAccountAdyen?: string;
  headerBackground?: string;
  headerText?: string;
  bodyHeadline?: string;
  bodySubtitle?: string;
  bodyLabels?: string;
  bodyText?: string;
  bodyBackgroudBox?: string;
  footerBackground?: string;
  footerTitle?: string;
  footerText?: string;
}

const INITIAL_VALUES = {
  id: "",
  type: "",
  name: "",
  prefix: "",
  status: false,
  allowCreditCard: false,
  diasExpirar: "",
  orderSummary: "",
  emailFrom: "",
  emailHost: "",
  emailPort: "",
  emailPwd: "",
  emailUser: "",
  templateUnico: "",
  clientId: "",
  privateKeyFile: "",
  signerEmail: "",
  impersonatedUserId: "",
  iD_Ibratan: "",
  iD_Fleet: "",
  filesContract: [],
  tokenCrm: null,
  sendCrm: false,
  merchantAccountAdyen: "",
  headerBackground: "#000000",
  headerText: "#000000",
  bodyHeadline: "#000000",
  bodySubtitle: "#000000",
  bodyLabels: "#000000",
  bodyText: "#000000",
  bodyBackgroudBox: "#000000",
  footerBackground: "#000000",
  footerTitle: "#000000",
  footerText: "#000000",
};

const NewSegments: FC = () => {
  const { id } = useParams<any>();
  const history = useHistory();
  const { addToast } = useToast();
  const [active, setActive] = useState(true);
  const [personalizedJourney, setPersonalizedJourney] = useState(true);
  const [sendCrm, setSendCrm] = useState(true);
  const [tokenCrm, setTokenCrm] = useState<string>("");
  const [merchantAccountAdyen, setMerchantAccountAdyen] = useState<string>("");
  const [allowCreditCard, setAllowCreditCard] = useState(false);
  const formRef = useRef<any>(null);
  const [callApi, setCallApi] = useState(false);
  const [type, setType] = useState("PJ");
  const [segments, setSegments] = useState<CreateSegmentsFormData>(INITIAL_VALUES);
  const [openCollapseData, setOpenCollapseData] = useState(true);
  const [openCollapseConfig, setOpenCollapseConfig] = useState(false);
  const [openCollapseDocusign, setOpenCollapseDocusign] = useState(false);
  const [openCollapseArchive, setOpenCollapseArchive] = useState(false);
  const [openCollapseResume, setOpenCollapseResume] = useState(false);
  const [openCollapseProposal, setOpenCollapseProposal] = useState(false);
  const [showUpload, setShowUpload] = useState(true);
  const [errors, setErrors] = useState<any>();
  const [nameFile, setNameFile] = useState("");
  const [listUploadTemplate, setListUploadTemplate] = useState([]);
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState<any>([]);
  const [selectedDeliveryTypes, setSelectedDeliveryTypes] = useState<any>([]);
  const [deliveryOptions, setDeliveryOptions] = useState<any>([] as any);
  const [paymentOptions, setPaymentOptions] = useState<any>([] as any);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);


  useEffect(() => {
    if (id) {
      api.get(`/segments/${id}/edit`).then((response) => {
        const { status, personalizedJourney, allowCreditCard, type, sendCrm, tokenCrm, merchantAccountAdyen, deliveryTypes, paymentTypes } = response.data;
        setSegments(response.data);
        setActive(status);
        setPersonalizedJourney(personalizedJourney);
        setAllowCreditCard(allowCreditCard);
        setType(type);
        setSendCrm(sendCrm);
        setTokenCrm(tokenCrm);
        setMerchantAccountAdyen(merchantAccountAdyen || "");
        setSelectedDeliveryTypes(deliveryTypes?.split("|").map(Number));
        setSelectedPaymentTypes(paymentTypes?.split("|").map(Number));
        fetchImage(response.data);
      });
    }

    api.get("/templatemailenum").then((response) => {
      setListUploadTemplate(response.data);
    });

    const fetchData = async () => {
      const { deliveryOptions, paymentOptions } = await fetchOptions();
      setDeliveryOptions(deliveryOptions);
      setPaymentOptions(paymentOptions);
    };

    fetchData();
  }, []);

  const fetchOptions = async () => {
    try {
      const [deliveryResponse, paymentResponse] = await Promise.all([api.get("/enumparameters/DeliveryOptionEnum"), api.get("/enumparameters/TypePaymentEnum")]);
      return {
        deliveryOptions: deliveryResponse.data,
        paymentOptions: paymentResponse.data,
      };
    } catch (error) {
      console.error("Erro ao buscar as opções:", error);
      return { deliveryOptions: [], paymentOptions: [] };
    }
  };

  const handleChangeActive = () => {
    setActive(!active);
  };

  const handlePersonalizedJourney = () => {
    setPersonalizedJourney(!personalizedJourney);
  };

  const handleCRM = (event: any) => {
    setSendCrm(event);
    if (!event) {
      setTokenCrm("");
    }
  };

  const handleChangeAllowCreditCard = (event: any) => {
    setAllowCreditCard(!allowCreditCard);
    if (!event) {
      setMerchantAccountAdyen("");
    }
  };

  const handleChangePaymentTypes = (value: any) => {
    setSelectedPaymentTypes(value);
    setAllowCreditCard(value.includes(1));
    const updatedErros = errors;
    if (value.length && updatedErros?.paymentTypes) {
      delete updatedErros.paymentTypes;
      setErrors(updatedErros);
    }
  };

  const handleChangeDeliveryTypes = (value: any) => {
    const updatedErros = errors;
    if (value.length && updatedErros?.deliveryTypes) {
      delete updatedErros.deliveryTypes;
      setErrors(updatedErros);
    }
    setSelectedDeliveryTypes(value);
  };

  const handleChangeType = (event: any) => {
    setType(event.target.value);
  };

  const handleCollapseData = () => {
    setOpenCollapseData(!openCollapseData);
  };

  const handleCollapseConfig = () => {
    setOpenCollapseConfig(!openCollapseConfig);
  };

  const handleCollapseDocusign = () => {
    setOpenCollapseDocusign(!openCollapseDocusign);
  };

  const handleCollapseArchive = () => {
    setOpenCollapseArchive(!openCollapseArchive);
  };

  const handleCollapseResume = () => {
    setOpenCollapseResume(!openCollapseResume);
  };

  const handleCollapseProposal = () => {
    setOpenCollapseProposal(!openCollapseProposal);
  };

  const handleInput = ({ name, value }: any) => {
    setSegments({ ...segments, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const payload = {
      ...segments,
      status: active,
      personalizedJourney: personalizedJourney,
      allowCreditCard: allowCreditCard,
      type: type,
      sendCrm: sendCrm,
      tokenCrm: sendCrm ? segments.tokenCrm : null,
      paymentTypes: selectedPaymentTypes?.join("|"),
      deliveryTypes: selectedDeliveryTypes?.join("|"),
      headerBackground: segments.headerBackground || "#000000",
      headerText: segments.headerText || "#000000",
      bodyHeadline: segments.bodyHeadline || "#000000",
      bodySubtitle: segments.bodySubtitle || "#000000",
      bodyLabels: segments.bodyLabels || "#000000",
      bodyText: segments.bodyText || "#000000",
      bodyBackgroudBox: segments.bodyBackgroudBox || "#000000",
      footerBackground: segments.footerBackground || "#000000",
      footerTitle: segments.footerTitle || "#000000",
      footerText: segments.footerText || "#000000",
    };

    if (!callApi) {
      return;
    }

    const defaultSchema = {
      type: Yup.string().required("Campo obrigatório"),
      name: Yup.string().required("Campo obrigatório"),
      prefix: Yup.string().required("Campo obrigatório"),
      status: Yup.string().required("Campo obrigatório"),
      diasExpirar: Yup.string().required("Campo obrigatório"),
      orderSummary: Yup.string().required("Campo obrigatório"),
      emailFrom: Yup.string().required("Campo obrigatório"),
      emailHost: Yup.string().required("Campo obrigatório"),
      emailPort: Yup.string().required("Campo obrigatório"),
      emailUser: Yup.string().required("Campo obrigatório"),
      templateUnico: Yup.string().required("Campo obrigatório"),
      clientId: Yup.string().required("Campo obrigatório"),
      signerEmail: Yup.string().required("Campo obrigatório"),
      paymentTypes: Yup.string().required("Selecione pelo menos uma opção"),
      deliveryTypes: Yup.string().required("Selecione pelo menos uma opção"),
      impersonatedUserId: Yup.string().required("Campo obrigatório"),
      iD_Ibratan: Yup.string().required("Campo obrigatório"),
      iD_Fleet: Yup.string().required("Campo obrigatório"),
      tokenCrm: Yup.string()
        .nullable()
        .when("sendCrm", {
          is: true,
          then: Yup.string().required("Campo obrigatório"),
          otherwise: Yup.string().nullable(),
        }),
      merchantAccountAdyen: Yup.string()
        .nullable()
        .when("allowCreditCard", {
          is: (allowCreditCard) => allowCreditCard === true && !id,
          then: Yup.string().required("Campo obrigatório"),
          otherwise: Yup.string().nullable(),
        }),
      headerBackground: Yup.string().required("Campo obrigatório"),
      headerText: Yup.string().required("Campo obrigatório"),
      bodyHeadline: Yup.string().required("Campo obrigatório"),
      bodySubtitle: Yup.string().required("Campo obrigatório"),
      bodyLabels: Yup.string().required("Campo obrigatório"),
      bodyText: Yup.string().required("Campo obrigatório"),
      bodyBackgroudBox: Yup.string().required("Campo obrigatório"),
      footerBackground: Yup.string().required("Campo obrigatório"),
      footerTitle: Yup.string().required("Campo obrigatório"),
      footerText: Yup.string().required("Campo obrigatório"),
    };

    try {
      if (segments.id) {
        const schema = Yup.object().shape({
          ...defaultSchema,
        });

        await schema.validate(payload, {
          abortEarly: false,
        });

        payload.id = id;
        await api.put("/segments", payload);
      } else {
        const schema = Yup.object().shape({
          ...defaultSchema,
          emailPwd: Yup.string().required("Campo obrigatório"),
          privateKeyFile: Yup.string().required("Campo obrigatório"),
        });

        await schema.validate(payload, {
          abortEarly: false,
        });
        await api.post("/segments", payload);
      }

      addToast({
        title: "Editado!",
        type: "success",
        description: "Segmento editado com sucesso.",
      });
      setTimeout(() => {
        history.push("/segments");
      }, 1000);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationErrors(err);
        setErrors(erros);
        addToast({
          title: "Erro no preenchimento",
          type: "error",
          description: "Verifique os campos do formulário.",
        });
      } else {
        addToast({
          title: "Ocorreu um erro!",
          type: "error",
          description: "Não foi possível salvar os dados.",
        });
      }
    }
  };

  const propsHtml = {
    name: "file",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    accept: ".html",
    maxCount: 1,
    showUploadList: showUpload,
    beforeUpload: (file: any) => {
      const { type } = file;
      if (type !== "text/html") {
        setShowUpload(false);
        addToast({
          title: "Erro ao enviar arquivo",
          type: "error",
          description: "Envie arquivos do tipo HTML.",
        });

        return false;
      }

      setShowUpload(true);
      return true;
    },
    onChange(info: UploadChangeParam): void {
      const { type } = info.file;
      if (type !== "text/html") {
        return;
      }

      handleFormatDocUploadHML(info);
    },
  };

  const props = {
    name: "file",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    accept: ".docx",
    maxCount: 1,
    showUploadList: showUpload,
    beforeUpload: (file: any) => {
      const { type } = file;
      if (type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        setShowUpload(false);
        addToast({
          title: "Erro ao enviar arquivo",
          type: "error",
          description: "Envie arquivos do tipo DOCX.",
        });

        return false;
      }

      setShowUpload(true);
      return true;
    },
    onChange(info: UploadChangeParam): void {
      const { type } = info.file;
      if (type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        return;
      }

      handleFormatDocUpload(info);
    },
  };

  const handleFormatDocUploadHML = async (image: any) => {
    const formData = new FormData();
    if (image.file.size > 5000000 && image.file.status !== "uploading") {
      return addToast({
        title: "Falha ao enviar documento",
        type: "error",
        description: "O tamanho do arquivo é maior que 5 mb",
      });
    } else {
      if (image.file.status === "done" || image.file.status === "error") {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            Accept: "text/plain",
          },
        };

        formData.append("fileRequest", image.file.originFileObj);

        await handleSendDocHML(config, formData, image, segments.iD_Fleet.toString());
      }
    }
  };

  const handleSendDocHML = async (config: any, formData: any, image: any, segment: string) => {
    try {
      await api.post(`/s3files/UploadDocFileS3?segmentPath=${segment}&fileName=${nameFile}&bucket=1`, formData, config);
    } catch (err) {
      addToast({
        title: "Erro ao fazer upload",
        type: "error",
        description: `${err.response.data}`,
      });
    }
  };


  const handleColorChange = (event: { target: { name: any; value: any; }; }) => {
    const { name, value } = event.target;
    setSegments((prevSegments) => ({
      ...prevSegments,
      [name]: value,
    }));
  };


  const handleFormatDocUpload = async (image: any) => {
    const formData = new FormData();
    if (image.file.size > 5000000 && image.file.status !== "uploading") {
      return addToast({
        title: "Falha ao enviar documento",
        type: "error",
        description: "O tamanho do arquivo é maior que 5 mb",
      });
    } else {
      if (image.file.status === "done" || image.file.status === "error") {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            Accept: "text/plain",
          },
        };

        formData.append("fileRequest", image.file.originFileObj);

        await handleSendDoc(config, formData, image, segments.iD_Fleet.toString());
      }
    }
  };

  const handleSendDoc = async (config: any, formData: any, image: any, segment: string) => {
    try {
      await api.post(`/s3files/UploadDocFileS3?segmentPath=${segment}&fileName=${nameFile}`, formData, config);
    } catch (err) {
      addToast({
        title: "Erro ao fazer upload",
        type: "error",
        description: `${err.response.data}`,
      });
    }
  };

  const handleDownload = (name: string, id: number | string | undefined) => {
    const apiUrl = `${DOWNLOAD_DOCX}?fileName=${name}&segmentPath=${id}`;
    const nameFile = name.replace(".docx", "");
    api.get(apiUrl, { responseType: "blob" })
      .then((response) => {
        downloadFile({
          name: nameFile,
          file: response.data,
          type: "docx",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          title: "Erro ao baixar relatório",
          description: "Por favor, entre em contato com o suporte.",
        });
      });
  };

  const handleDownloadHtml = (name: string, id: number | string | undefined) => {
    const type = "text/html";
    const apiUrl = `${DOWNLOAD_DOCX}?fileName=${name}&segmentPath=${id}&type=${type}&bucket=1`;
    const nameFile = name.replace(".html", "");
    api.get(apiUrl, { responseType: "blob" })
      .then((response) => {
        downloadFile({
          name: nameFile,
          file: response.data,
          type: "html",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          title: "Erro ao baixar relatório",
          description: "Por favor, entre em contato com o suporte.",
        });
      });
  };

  const fetchImage = async (segment:any) => {
    try {
      const response = await api.get('/s3files', {
        params: {
          fileName: `logo${segment?.id}.png`,
          segmentPath: 'template-proposal',
          type: 'image/png',
          bucket: 'Image',
        },
        responseType: 'blob'
      });

      const imageUrl = URL.createObjectURL(response.data);
      setImageSrc(imageUrl);
    } catch (error) {

    }
  };

  
  const handleImageUpload = async (event:any) => {
    const file = event.target.files[0];

    if (file.type !== 'image/png') {
      return message.error('Apenas imagens PNG são permitidas.');
    }

    if (file.size > 5000000) {
      return message.error('O tamanho do arquivo é maior que 5 MB.');
    }

    const formData = new FormData();
    formData.append('file', file);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Accept: 'text/plain',
      },
    };

    try {
      const response = await api.post(
        `/s3files/UploadImageS3?code=logo${segments.id}&type=PathTemplateProposal`,
        formData,
        config
      );

      if(response){
        fetchImage(segments);
        message.success('Imagem enviada com sucesso!');
      }


    } catch (error) {
      message.error('Erro ao fazer upload da imagem.');
    }
  };

  return (
    <Container>
      <Header />
      <Content>
        <h1>NOVO SEGMENTO</h1>
        <p>Cadastre um novo segmento</p>

        <form ref={formRef} onSubmit={handleSubmit} noValidate>
          <div className="collapse-item" onClick={handleCollapseData}>
            <span style={{ marginRight: 375 }}>Dados básicos</span>
            {openCollapseData ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </div>
          <Collapse in={openCollapseData} timeout="auto" unmountOnExit>
            <div className="segmentOptions">
              <div className="isActive">
                <span>SEGMENTO ATIVO</span>
                <Switch onChange={handleChangeActive} checked={active} />
              </div>
              <div className="isActive">
                <span>JORNADA PERSONALIZADA</span>
                <Switch onChange={handlePersonalizedJourney} checked={personalizedJourney} />
              </div>
            </div>
            <div className="wrapper-info other-style">
              <label>ID Segmento</label>
              <NewInput id="iD_Fleet" name="iD_Fleet" type="number" value={segments.iD_Fleet} onChange={handleInput} error={errors?.iD_Fleet} />
            </div>
            <div className="wrapper-info">
              <label>Tipo de Pessoa</label>
              <Select value={type} id="type" name="type" onChange={handleChangeType}>
                <option key="PJ" value="PJ">
                  JURIDICA
                </option>
                <option key="PF" value="PF">
                  FÍSICA
                </option>
              </Select>
            </div>
            <div className="wrapper-info other-style">
              <label>Nome Segmento</label>
              <NewInput id="name" name="name" type="text" value={segments.name} onChange={handleInput} error={errors?.name} />
            </div>
            <div className="wrapper-info other-style">
              <label>Prefixo Pedido</label>
              <NewInput id="prefix" name="prefix" maxLength={3} type="text" value={segments.prefix} onChange={handleInput} error={errors?.prefix} />
            </div>
            <div className="wrapper-info other-style">
              <label>Ponto de atendimento</label>
              <NewInput id="iD_Ibratan" name="iD_Ibratan" type="number" value={segments.iD_Ibratan} onChange={handleInput} error={errors?.iD_Ibratan} />
            </div>
            <div className="wrapper-info">
              <div>
                <Form.Item label="Formas de entrega aceitas" validateStatus={errors?.deliveryTypes ? "error" : ""} help={errors?.deliveryTypes}>
                  <SelectAntd mode="multiple" style={{ width: "100%" }} placeholder="Selecione as opções" onChange={handleChangeDeliveryTypes} value={selectedDeliveryTypes}>
                    {deliveryOptions?.map((option: any) => (
                      <Option key={option.property} value={option.id}>
                        {option.description}
                      </Option>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </div>
              <Form.Item label="Formas de pagamento aceitas" validateStatus={errors?.paymentTypes ? "error" : ""} help={errors?.paymentTypes}>
                <SelectAntd mode="multiple" style={{ width: "100%" }} placeholder="Selecione as opções" onChange={handleChangePaymentTypes} value={selectedPaymentTypes}>
                  {paymentOptions?.map((option: any) => (
                    <Option key={option.property} value={option.id}>
                      {option.description}
                    </Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </div>
            {allowCreditCard && (
              <div className="wrapper-info other-style">
                <label>Merchant Account</label>
                <NewInput id="merchantAccountAdyen" name="merchantAccountAdyen" type="text" value={segments.merchantAccountAdyen!} onChange={handleInput} error={errors?.merchantAccountAdyen} />
              </div>
            )}
          </Collapse>

          <div className="collapse-item" onClick={handleCollapseConfig}>
            <span style={{ marginRight: 320 }}>Configuração de e-mail</span>
            {openCollapseConfig ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </div>
          <Collapse in={openCollapseConfig} timeout="auto" unmountOnExit>
            <div className="wrapper-info other-style">
              <label>Remetente</label>
              <NewInput id="emailFrom" name="emailFrom" type="text" value={segments.emailFrom} onChange={handleInput} error={errors?.emailFrom} />
            </div>
            <div className="wrapper-info other-style">
              <label>Servidor</label>
              <NewInput id="emailHost" name="emailHost" type="text" value={segments.emailHost} onChange={handleInput} error={errors?.emailHost} />
            </div>
            <div className="wrapper-info other-style">
              <label>Porta</label>
              <NewInput id="emailPort" name="emailPort" type="number" value={segments.emailPort} onChange={handleInput} error={errors?.emailPort} />
            </div>
            <div className="wrapper-info other-style">
              <label>Usuário</label>
              <NewInput id="emailUser" name="emailUser" type="text" value={segments.emailUser} onChange={handleInput} error={errors?.emailUser} />
            </div>
            <div className="wrapper-info other-style">
              <label>Senha</label>
              <NewInput id="emailPwd" name="emailPwd" type="text" value={segments.emailPwd} onChange={handleInput} error={errors?.emailPwd} />
            </div>
            <div className="wrapper-info other-style">
              <label>Template</label>
              <NewInput id="templateUnico" name="templateUnico" type="text" value={segments.templateUnico} onChange={handleInput} error={errors?.templateUnico} />
            </div>

            <div className="wrapper-info other-style">
              <label>Templates de e-mail</label>
              {listUploadTemplate.map((itemTemplate: iTemplateUplaod, i) => (
                <div key={i}>
                  <button onClick={() => handleDownloadHtml(itemTemplate.description, segments.iD_Fleet)} style={{ width: "220" }}>
                    {itemTemplate.description}
                  </button>
                  <Upload className="upload" {...propsHtml}>
                    <Button className="btn-upload" onClick={() => setNameFile(itemTemplate.description)}>
                      <AiOutlineUpload size={17} /> CLIQUE PARA SUBIR O ARQUIVO
                    </Button>
                  </Upload>
                </div>
              ))}
            </div>
          </Collapse>

          <div className="collapse-item" onClick={handleCollapseDocusign}>
            <span style={{ marginRight: 415 }}>Docusign</span>
            {openCollapseDocusign ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </div>
          <Collapse in={openCollapseDocusign} timeout="auto" unmountOnExit>
            <div className="wrapper-info other-style">
              <label>DiasExpirar</label>
              <NewInput id="diasExpirar" name="diasExpirar" type="number" value={segments.diasExpirar} onChange={handleInput} error={errors?.diasExpirar} />
            </div>
            <div className="wrapper-info other-style">
              <label>ClientId</label>
              <NewInput id="clientId" name="clientId" type="text" value={segments.clientId} onChange={handleInput} error={errors?.clientId} />
            </div>
            <div className="wrapper-info other-style">
              <label>SignerEmail</label>
              <NewInput id="signerEmail" name="signerEmail" type="text" value={segments.signerEmail} onChange={handleInput} error={errors?.signerEmail} />
            </div>
            <div className="wrapper-info other-style">
              <label>ImpersonatedUserId</label>
              <NewInput id="impersonatedUserId" name="impersonatedUserId" type="text" value={segments.impersonatedUserId} onChange={handleInput} error={errors?.impersonatedUserId} />
            </div>
            <div className="wrapper-info other-style">
              <label>PrivateKeyFile</label>
              <NewTextAreaFleet name="privateKeyFile" value={segments?.privateKeyFile} onChange={handleInput} error={errors?.privateKeyFile} />
            </div>
          </Collapse>

          <div className="collapse-item" onClick={handleCollapseArchive}>
            <span style={{ marginRight: 420 }}>Arquivos</span>
            {openCollapseArchive ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </div>
          <Collapse in={openCollapseArchive} timeout="auto" unmountOnExit>
            <div className="wrapper-info other-style">
              <label>Arquivos</label>
              {segments.filesContract?.map((item: iFilesContract, i) => (
                <div key={i}>
                  <button onClick={() => handleDownload(item.name, segments.iD_Fleet)} style={{ width: "220" }}>
                    {item.name}
                  </button>
                  <Upload className="upload" {...props}>
                    <Button className="btn-upload" onClick={() => setNameFile(item.name)}>
                      <AiOutlineUpload size={17} /> CLIQUE PARA SUBIR O ARQUIVO
                    </Button>
                  </Upload>
                </div>
              ))}
            </div>
          </Collapse>

          <div className="collapse-item" onClick={handleCollapseResume}>
            <span style={{ marginRight: 350 }}>Resumo do pedido</span>
            {openCollapseResume ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </div>
          <Collapse in={openCollapseResume} timeout="auto" unmountOnExit>
            <div className="wrapper-info other-style">
              <label>Resumo do pedido</label>
              <NewTextAreaFleet name="orderSummary" value={segments?.orderSummary} onChange={handleInput} error={errors?.orderSummary} />
            </div>
          </Collapse>

          <div className="collapse-item" onClick={handleCollapseProposal}>
            <span style={{ marginRight: 333 }}>Geração de Proposta</span>
            {openCollapseProposal ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </div>
            <Collapse in={openCollapseProposal} timeout="auto" unmountOnExit>
              <div className="colors-wrapper">
              <SimpleColorPicker
                    label="Fundo do Cabeçalho"
                    id="headerBackground"
                    name="headerBackground"
                    value={segments?.headerBackground || '#000000'}
                    onChange={handleColorChange}
                    error={errors?.headerBackground}
                />
                <SimpleColorPicker
                    label="Texto do Cabeçalho"
                    id="headerText"
                    name="headerText"
                    value={segments?.headerText|| '#000000'}
                    onChange={handleColorChange}
                    error={errors?.headerText}
                />
                <SimpleColorPicker
                    label="Título do Corpo"
                    id="bodyHeadline"
                    name="bodyHeadline"
                    value={segments?.bodyHeadline|| '#000000'}
                    onChange={handleColorChange}
                    error={errors?.bodyHeadline}
                />
                <SimpleColorPicker
                    label="Subtítulo do Corpo"
                    id="bodySubtitle"
                    name="bodySubtitle"
                    value={segments?.bodySubtitle|| '#000000'}
                    onChange={handleColorChange}
                    error={errors?.bodySubtitle}
                />
                <SimpleColorPicker
                    label="Labels do Corpo"
                    id="bodyLabels"
                    name="bodyLabels"
                    value={segments?.bodyLabels|| '#000000'}
                    onChange={handleColorChange}
                    error={errors?.bodyLabels}
                />
                <SimpleColorPicker
                    label="Texto do Corpo"
                    id="bodyText"
                    name="bodyText"
                    value={segments?.bodyText|| '#000000'}
                    onChange={handleColorChange}
                    error={errors?.bodyText}
                />
                <SimpleColorPicker
                    label="Fundo da Caixa do Corpo"
                    id="bodyBackgroudBox"
                    name="bodyBackgroudBox"
                    value={segments?.bodyBackgroudBox|| '#000000'}
                    onChange={handleColorChange}
                    error={errors?.bodyBackgroudBox}
                />
                <SimpleColorPicker
                    label="Fundo do Rodapé"
                    id="footerBackground"
                    name="footerBackground"
                    value={segments?.footerBackground|| '#000000'}
                    onChange={handleColorChange}
                    error={errors?.footerBackground}
                />
                <SimpleColorPicker
                    label="Título do Rodapé"
                    id="footerTitle"
                    name="footerTitle"
                    value={segments?.footerTitle|| '#000000'}
                    onChange={handleColorChange}
                    error={errors?.footerTitle}
                />

                <SimpleColorPicker
                    label="Texto do Rodapé"
                    id="footerText"
                    name="footerText"
                    value={segments?.footerText|| '#000000'}
                    onChange={handleColorChange}
                    error={errors?.footerText}
                />
              </div>
              <div>
                {imageSrc ? (
                    <>
                        <span style={{color: '#000000'}}>Logo da proposta</span>
                    </>
                ) : (
                    <p>Fazer upload do logo</p>
                )}

                {imageSrc && (
                  <div className="logo-box" style={{ backgroundColor: segments?.headerBackground }}>
                    <img src={imageSrc} alt="Imagem recuperada" style={{ maxWidth: "300px", marginTop: "20px" }} />
                  </div>
                )}
                {imageSrc && (
                    <p>Substituir Logo</p>
                )}
                <input style={{ marginBottom: "30px" }} type="file" accept="image/png" onChange={handleImageUpload} />
              </div>
            </Collapse>


          <div className="CRM-area">
            <div className="CRM-item">
              <span>Integração CRM</span>
              <Switch onChange={handleCRM} checked={sendCrm} />
            </div>
            {sendCrm && (
              <div className="CRM-item">
                <div className="wrapper-info other-style">
                  <label>Token CRM</label>
                  <NewInput id="tokenCrm" name="tokenCrm" type="text" value={segments.tokenCrm!} onChange={handleInput} error={errors?.tokenCrm} />
                </div>
              </div>
            )}
          </div>

          <div className="wrapper-button">
            <Link to="/segments">
              <button className="btn-cancel">Cancelar</button>
            </Link>
            <button onClick={() => setCallApi(true)} type="submit" className="btn-save">
              Salvar
            </button>
          </div>
        </form>
      </Content>
      <Footer />
    </Container>
  );
};

export default NewSegments;

