/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Modal } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ContentModal, Container, ToastContainer } from './styles'
import Input from '../../components/Input'
import Button from '../../components/Button'
import getValidationErrors from '../../utils/getValidationErrors'
import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'
import { IState } from '../../store'
import { ILogin } from '../../store/modules/login/types'
import { loginApp } from '../../store/modules/login/actions'
import ForgotPassword from '../ForgotPassword'
import api from '../../services/api'
import logoLmCor from '../../assets/logo/logolm-cor.png'
import { FiAlertCircle, FiCheckCircle, FiInfo, FiXCircle } from 'react-icons/fi'

interface SignInFormData {
  login: string
  password: string
  dealership: boolean
}

const icons = {
  info: <FiInfo size={24} />,
  error: <FiAlertCircle size={24} />,
  success: <FiCheckCircle size={24} />
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const { signIn } = useAuth()
  const { addToast } = useToast()
  const dispatch = useDispatch()
  const status = useSelector<IState, ILogin>((state) => state.login)
  const [isForgotPassword, setIsForgotPassword] = useState(false)

  const [hasSessionParam, setHasSessionParam] = useState(false);
  const [showSessionMessage, setShowSessionMessage] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('session')) {
      setHasSessionParam(true);
    } else {
      setHasSessionParam(false);
    }
  }, []);

  useEffect(() => {
    if (hasSessionParam) {
      setShowSessionMessage(true)
      history.replace('/');
    }
  }, [hasSessionParam, history]);



  const handleOk = () => {
    dispatch(loginApp({ status: false }))
  }

  const handleSubmitLogin = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          login: Yup.string().required('E-mail/CPF obrigatório'),
          password: Yup.string().required('Senha obrigatória')
        })

        await schema.validate(data, {
          abortEarly: false
        })

        await api
          .post('/signin/checkUserResetPassword', {
            login: data.login,
            password: data.password
          })
          .then(async (res) => {
            setShowSessionMessage(false)
            if (res.data.isResetPassword) {
              history.push(`/reset-password/${res.data.passwordResetToken}`)
            } else {
              await signIn({
                login: data.login,
                password: data.password,
                dealership: true
              })

              dispatch(loginApp({ status: false }))
              history.push('/my-orders')

              const chat = document.getElementById('znv-chat-auto-0')

              if (chat) {
                chat.style.display = 'block'
              }
            }
          })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)

          formRef.current?.setErrors(errors)
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Verifique as informações inseridas'
        })
      }
    },
    [signIn, addToast]
  )

  const forgotPasswordModal = () => {
    setIsForgotPassword(true)
    dispatch(loginApp({ status: false }))
  }

  return (
    <Container>
      
      {showSessionMessage && 
      <ToastContainer type={"error"} $hasDescription={Number(!!"message.description")}>
        {icons['error']}
          <div>
            <strong>Sua sessão expirou.</strong>
            <p>Faça login novamente para continuar</p>
          </div>
          <button onClick={() => setShowSessionMessage(false)} type="button">
            <FiXCircle size={18} />
          </button>
      </ToastContainer>
      }
      <Modal footer={null} title="" visible={status.status} onOk={handleOk}>
        <ContentModal>
          <img width="350" src={logoLmCor} alt="Fleet Solutions" />

          <Form ref={formRef} onSubmit={handleSubmitLogin}>
            <Input
              placeholder="E-mail/CPF"
              id="login"
              name="login"
              type="text"
            />
            <br />
            <Input
              placeholder="Senha"
              id="password"
              name="password"
              type="password"
            />
            <br />
            <Button type="submit">Entrar</Button>
            {
              // eslint-disable-next-line
              <a onClick={forgotPasswordModal}>Esqueci minha senha</a>
            }
          </Form>
        </ContentModal>
      </Modal>
      {isForgotPassword && <ForgotPassword />}
    </Container>
  )
}

export default SignIn
