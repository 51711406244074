import api from './api'

interface IGetFilters {
  segmentId?: number
}

export const getFilters = async (params: IGetFilters = {}) => {
  return api.get('/filters', {
    params
  })
}
