/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import iconTrash from '../../assets/icons/trash-2.svg'
import { Select } from 'antd'
import { useToast } from '../../hooks/toast'

import { Container, SelectedItem, AddButton, ItemsTable, Items } from './styles'

interface Change {
  value: string
}

interface Optionals {
  name: string
  items: [
    {
      value: string
    }
  ]
}

interface Props {
  data: Optionals[]
  onChange: (value: any) => void
}

interface ModelProps {
  description: string
}

const SelectOptions: React.FC<Props> = ({ data, onChange }) => {
  const [selectListOption, setSelectListOption] = useState<ModelProps[]>([])
  const [selectItem, setSelectItem] = useState('Selecione')
  const { addToast } = useToast()

  const addItemSelect = () => {
    if (selectItem === 'Selecione') return

    const found = selectListOption.find(
      (element) => element.description === selectItem
    )
    if (found) {
      addToast({
        title: 'Atenção.',
        description: `${selectItem} já existe na lista!`,
        type: 'error'
      })
    } else {
      const newItem = [...selectListOption]
      newItem.push({ description: selectItem })
      setSelectListOption(newItem)
      setSelectItem('Selecione')
      changeList(newItem)
    }
  }

  useEffect(() => {
    setSelectItem('Selecione')
  }, [data])

  const changeList = (newItem: any) => {
    const listItem = newItem.map((element: any) => {
      return element.description
    })
    onChange(listItem)
  }

  const handleRemove = (item: string) => {
    const list = selectListOption.filter(
      (element) => element.description !== item
    )
    setSelectListOption(list)
    changeList(list)
  }

  const handleChange = ({ value }: Change) => {
    setSelectItem(value)
  }

  return (
    <Container>
      <SelectedItem className="select-item">
        <Select
          onChange={(optional: string) => handleChange({ value: optional })}
          value={selectItem}
          style={{
            width: 269,
            border: '1px solid #0C5FB1',
            marginLeft: '-5px'
          }}
        >
          {data.map((item, idx: number) => (
            <option key={`ID-${idx}`} value={item.name}>
              {item.name}
            </option>
          ))}
        </Select>
        <AddButton onClick={addItemSelect}>Adicionar +</AddButton>
      </SelectedItem>
      <ItemsTable className="items-table">
        <Items>
          <tbody>
            {selectListOption.map(
              (item: ModelProps, index: number) =>
                item.description && (
                  <tr key={index}>
                    <td style={{ width: '95%', fontSize: '14px' }}>
                      {item.description}
                    </td>
                    <td>
                      <button
                        className="delete-button"
                        onClick={() => handleRemove(item.description)}
                      >
                        <img src={iconTrash} alt="Deletar" />
                      </button>
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </Items>
      </ItemsTable>
    </Container>
  )
}

export default SelectOptions
