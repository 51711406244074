import React, { useState } from 'react'
import { Upload } from 'antd'
import { AiOutlineUpload } from 'react-icons/ai'
import { UploadChangeParam } from 'antd/lib/upload'

import Button from '../../components/Button'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'
import { WrapperDoc } from './styles'

type UploadDocProps = {
  docName: string
  description: string
  fileType: string
  typeDocName: string
  downloadDoc?: string | null
  numberDoc: number
}

const UploadDoc = ({
  docName,
  description,
  fileType,
  typeDocName,
  downloadDoc,
  numberDoc
}: UploadDocProps) => {
  const { addToast } = useToast()
  const userIdCompany = Number(
    localStorage.getItem('@FleetSolutions:userIdCompany')
  )

  const [showUpload, setShowUpload] = useState(true)
  const [typeDoc, setTypeDoc] = useState('')

  const props = {
    name: 'file',
    multiple: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    accept: '.pdf',
    maxCount: 1,
    showUploadList: showUpload,
    beforeUpload: (file: any) => {
      const { type } = file
      if (type !== 'application/pdf') {
        setShowUpload(false)
        addToast({
          title: 'Erro ao enviar arquivo',
          type: 'error',
          description: 'Envie arquivos do tipo PDF.'
        })

        return false
      }

      setShowUpload(true)
      return true
    },
    onChange(info: UploadChangeParam) {
      const { type } = info.file
      if (type !== 'application/pdf') {
        return
      }

      if (info.file.type)
        info.file.name = `${translateDocName(typeDoc)}.${
          info.file.type.split('/')[1]
        }`

      handleFormatDocUpload(info)
    }
  }

  const translateDocName = (docName: string) => {
    if (docName === 'contrato-social-ou-ccmei') {
      return 'Contrato Social ou CCMEI'
    } else if (docName === 'declaracao-simples-ultimo-ano') {
      return 'Declaração do SIMPLES Último Ano'
    } else if (docName === 'irpf-socio-ultimo-ano') {
      return 'IRPF do Socio Último Ano'
    } else if (docName === 'ultima-alteracao-contratual') {
      return 'Última Alteração Contratual'
    } else if (docName === 'irpj-ultimo-ano') {
      return 'IRPJ Último Ano'
    } else if (docName === 'relacao-faturamento-ultimo-ano') {
      return 'Relação de Faturamento Último Ano'
    } else if (docName === 'balanco-ano-anterior') {
      return 'Balanço ano anterior'
    } else if (docName === 'balanco-ano-vigente') {
      return 'Balanço ano vigente'
    } else if (docName === 'dre-ano-vigente') {
      return 'DRE ano vigente'
    } else if (docName === 'dre-ano-anterior') {
      return 'DRE ano anterior'
    } else if (docName === 'relacao-endividamento-bancario') {
      return 'Relação de Endividamento Bancário'
    } else if (docName === 'procuracao') {
      return 'Procuração'
    } else {
      return 'Outros'
    }
  }

  const handleFormatDocUpload = async (image: any) => {
    const formData = new FormData()
    if (image.file.size > 5000000 && image.file.status !== 'uploading') {
      return addToast({
        title: 'Falha ao enviar documento',
        type: 'error',
        description: 'O tamanho do arquivo é maior que 5 mb'
      })
    } else {
      if (image.file.status === 'done' || image.file.status === 'error') {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            Accept: 'text/plain'
          }
        }

        formData.append('file', image.file.originFileObj)

        await handleSendDoc(config, formData, image)
      }
    }
  }

  const handleSendDoc = async (config: any, formData: any, image: any) => {
    try {
      await api.post(
        `/document-company-upload?userId=${userIdCompany}&typeDocumentCompany=${numberDoc}`,
        formData,
        config
      )
    } catch (err) {
      addToast({
        title: 'Erro ao fazer upload',
        type: 'error',
        description: `${err.response.data}`
      })
    }
  }

  return (
    <WrapperDoc
      onClick={() => setTypeDoc(typeDocName)}
      onDrop={() => setTypeDoc(typeDocName)}
    >
      <div className="left">
        <p className="name-file">
          {docName}
          <span>{description}</span>
        </p>
        <p>{fileType}</p>
      </div>
      <div className="right">
        <Upload className="upload" {...props}>
          <Button className="btn-upload">
            {' '}
            <AiOutlineUpload size={17} /> CLIQUE PARA{' '}
            {downloadDoc ? 'SUBSTIRUIR' : 'CARREGAR'}
          </Button>
        </Upload>
        {downloadDoc && (
          <a
            href={`data:application/pdf;base64,${downloadDoc}`}
            download="file.pdf"
          >
            {docName}
          </a>
        )}
      </div>
    </WrapperDoc>
  )
}

export default UploadDoc
