import styled from 'styled-components'

export const Container = styled.div``

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;

  h1 {
    color: #17536c;
    margin-top: 30px;
    margin-bottom: 0px !important;
    font-size: 1.6em !important;
    text-align: center;
  }

  p {
    color: #3c505a;
    margin-top: 5px;
  }

  form {
    width: 80%;
    max-width: 500px;
    margin: 20px 0 60px 0;
    color: #0c5faf;

    .show-group {
      display: flex;
      flex-direction: column;

      width: 100%;

      .info-group {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        border: 1px solid #0c5faf;
        border-radius: 21px;
        padding: 5px;
        margin-top: 16px;
        background: #ffffff;

        span {
          margin-left: 8px;
        }

        button {
          margin-right: -30px;
          border: none;
          background: transparent;
        }
      }
    }

    .collapse-item {
      color: rgba(60, 80, 90);
      border-bottom: 2px solid rgba(60, 80, 90);
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .wrapper-button {
      float: right;
      margin-top: 20px;
      display: flex;

      button {
        width: 155px;
        height: 39px;
        border-radius: 24px;
        font-size: 14px;
      }

      .btn-cancel {
        border: 2px solid #3c505a;
        color: #3c505a;
        margin-right: 14px;
      }

      .btn-save {
        background: #236fbc 0% 0% no-repeat padding-box;
        border: 1px solid #226fbb;
        color: #fff;
      }
    }

    .other-style {
      div {
        background: #ffffff;
      }
    }

    .email {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      div {
        width: 49%;
        margin: 0;
        background: #ffffff;
      }
    }

    .trash {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;

      .jIuKDF {
        width: 90%;
        background: #ffffff;
      }
    }

    .wrapper-info {
      margin-top: 12px;

      select {
        width: 100%;
        margin: 0px;
        border-radius: 21px;
        height: 45px;
        padding: 5px;
        background: #ffffff;
        border: 1px solid #0c5faf;
      }

      input,
      select {
        color: #3c505a;
      }

      button {
        margin-right: -30px;
        border: none;
        background: transparent;
      }

      .wrapper-add-domain {
        display: flex;
        flex-direction: row;
        justify-content: end;
        flex-wrap: wrap;
        align-items: center;
        min-width: 720px;

        div {
          width: 70%;
          background: #fff;
        }

        input::placeholder {
          font-size: 13px;
        }

        button {
          margin-left: 8px;
          border: none;
        }
      }
    }

    .segmentOptions{
      display:flex;
      justify-content: space-between;
    }

    .isActive {
      float: right;
      display: flex;

      span {
        margin-right: 5px;
        align-items: center;
        justify-content: center;
      }
    }

    .allowCreditCard {
      padding-top: 10px;
      float: left;
      display: flex;

      span {
        margin-right: 5px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media (max-width: 900px) {
    form {
      .email {
        flex-direction: column;
        align-items: normal;

        div {
          width: auto;
          margin-top: 5px;
        }
      }

      .wrapper-button {
        float: none;
      }

      .wrapper-add-domain {
        min-width: 100% !important;
      }
    }
  }

  @media (min-width: 900px) {
    form {
    }
  }
`


export const SelectedData = styled.div`

width: 500px;
margin: 0 0 140px 0;
.head{
    display: flex;
    flex: 1;
    justify-content: space-between;
    width: 100%;
    background: #3c505a;
    border-radius: 50px;
    padding: 8px 20px;
    color: #fff;
}
.list{
    div{
        display: flex;
        justify-content: space-between;
        padding: 8px 20px;
        span{
            flex:1;
            display:flex;
            &.state{
                justify-content:center;
            }
            &.action{
                justify-content: end;
                .delete-button {
                    width: 18px;
                    background:none;
                    border:none;
                    cursor:pointer;
                    img {
                      width: 14px;
                    }
                  }
            }
        }
    }
}
`