import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import ForgotPassword from 'pages/ForgotPassword'
import ResetPassword from 'pages/ResetPassword'
import ConfirmEmail from 'pages/ConfirmEmail'
import UploadFile from 'pages/UploadFile'
import RegisterUser from 'pages/RegisterUser'
import Home from 'pages/Home'
import Offers from 'pages/Offers'
import Checkout from 'pages/Checkout'
import OrderSummer from 'pages/OrderSummary'
import OrderStatus from 'pages/OrderStatus'
import OrderStatusPF from 'pages/OrderStatusPF'
import ListDealership from 'pages/ListDealership'
import MyOrders from 'pages/Orders'
import Dealerships from 'pages/Dealerships'
import DealersList from 'pages/Dealerships/DealersList'
import NewDealership from 'pages/Dealerships/NewDealership'
import DealershipsGroup from 'pages/DealershipsGroup'
import NewGroupDealership from 'pages/DealershipsGroup/NewGroupDealership'
// import Users from 'pages/Users'
import UsersList from 'pages/Users/UsersList'
import NewUser from 'pages/Users/NewUser'
import PickupLocation from 'pages/PickupLocation'
import RegisterCompany from 'pages/RegisterCompany'
import CompanyContacts from 'pages/RegisterCompany/CompanyContacts'
import CustomerContacts from 'pages/RegisterCompany/CustomerContacts'
import NewContact from 'pages/RegisterCompany/CompanyContacts/NewContact'
import AppProvider from 'hooks'
import Offer from 'pages/Offers/Offer'
import Reports from 'pages/Reports'
import InventoryManagement from 'pages/InventoryManagement'
import ContactPF from 'pages/RegisterUser/ContactPF/ContactPF'
import SegmentsManagement from 'pages/Segments'
import NewSegments from 'pages/Segments/NewSegments'
import ImageLoading from 'pages/ImageLoading'
import IntegrationManagement from 'pages/IntegrationManagement'
import Commissioning from 'pages/Commissioning'
import CreateUpdateCommissioning from 'pages/Commissioning/CreateUpdateCommissioning'
import Dashboard from 'pages/Dashboard'
import DealerShipsDashboard from 'pages/DealerShipsDashboard'
import LicensePlateSelection from 'pages/LicensePlateSelection'
import OrdersList from 'pages/NewOrdersList'

const Routes: React.FC = () => {
  const LOGIN_URLS = ['/', '/audi', '/trucks', '/vw']

  return (
    <Switch>
      <AppProvider>
        <Route path={LOGIN_URLS} exact component={Home} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/vehicle-details" component={PickupLocation} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/dealerShips-dashboard" component={DealerShipsDashboard} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password/:token" component={ResetPassword} />
        <Route
          path="/register-company/:companyCnpj"
          component={RegisterCompany}
        />
        <Route
          path="/contacts-company/:companyCnpj/:orderId/:clientType?/:userId?"
          component={CompanyContacts}
        />

        <Route
          path="/contacts-customer/:companyCnpj/:orderId/:clientType?/:userId?"
          component={CustomerContacts}
        />

        <Route
          path="/new-contact/:companyCnpj/:orderId"
          component={NewContact}
        />
        <Route
          path="/edit-contact/:companyCnpj/:orderId/:id"
          component={NewContact}
        />
        <Route path="/edit-contact-pf/:userIdPf" component={ContactPF} />

        <Route path="/confirm-email" component={ConfirmEmail} />
        <Route path="/upload-file" component={UploadFile} />
        <Route path="/register-user" component={RegisterUser} />
        <Route path="/order-summary" component={OrderSummer} />
        <Route path="/order-status/:id" component={OrderStatus} />
        <Route path="/order-status-pf/:id" component={OrderStatusPF} />
        <Route
          path="/list-dealership/:tabName/:infoClient?/:orderId?/:isCompany?"
          component={ListDealership}
        />
        <Route path="/my-orders" component={MyOrders} />
        <Route path="/orders" component={OrdersList} />
        <Route path="/dealerships" component={DealersList} />
        <Route path="/new-dealership" component={NewDealership} />
        <Route path="/edit-dealership/:id" component={NewDealership} />
        <Route path="/dealerships-group" component={DealershipsGroup} />
        <Route
          path="/edit-dealership-group/:id"
          component={NewGroupDealership}
        />
        <Route path="/new-group-dealership" component={NewGroupDealership} />
        <Route path="/users" component={UsersList} />
        <Route path="/new-user" component={NewUser} />
        <Route path="/edit-user/:id" component={NewUser} />
        <Route path="/offers/:type/:segmentId" exact component={Offers} />
        <Route path="/offer/:id" component={Offer} />
        <Route path="/reports" component={Reports} />
        <Route path="/inventory-management" component={InventoryManagement} />
        <Route path="/commissioning" component={Commissioning} />
        <Route
          path="/commissio/create-update/:id?"
          component={CreateUpdateCommissioning}
        />
        <Route path="/segments" component={SegmentsManagement} />
        <Route path="/imageLoading" component={ImageLoading} />
        <Route path="/new-segments" component={NewSegments} />
        <Route path="/edit-segments/:id" component={NewSegments} />
        <Route
          path="/integration-management"
          component={IntegrationManagement}
        />
        <Route path="/license-plate-selection" component={LicensePlateSelection} />
      </AppProvider>
    </Switch>
  )
}

export default Routes
