/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
import React, { FC, useEffect, useState, useRef, useCallback } from 'react'
import api from '../../../services/api'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useAuth } from '../../../hooks/auth'

import Input from '../../../components/Input'
import Select from '../../../components/Select'
import { Container, Content } from './styles'
import { useToast } from '../../../hooks/toast'
import { cpfMask } from '../../../utils/cpfMask'

import rolesDePara from '../../../utils/rolesdePara'

import { Switch } from 'antd'

interface NewUserFormData {
  userId: number
  login: string
  roleId: number
  dealershipId: number
  domain: string
  email: string
  fullname: string
  webSiteUrl: string
  cpfCnpj: string
  deleted: boolean
  resetPassword: boolean
  vendedorMaster: boolean
  logout: boolean
}

const NewUser: FC = () => {
  const { id } = useParams<any>()
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const { addToast } = useToast()
  const [dealerships, setDealerships] = useState([])
  const [domains, setDomains] = useState([])
  const [roles, setRoles] = useState([])
  const [dealershipId, setDealershipId] = useState(0)
  const [roleId, setRoleId] = useState(0)
  const [domain, setDomain] = useState('')
  const [user, setUser] = useState<NewUserFormData>()
  const [email, setEmail] = useState('')
  const [cpf, setCpf] = useState('')

  const [active, setActive] = useState(true)

  const [resetPassword, setResetPassword] = useState(true)

  const [statusOld, setStatusOld] = useState(true)

  const [vendedorMaster, setVendedorMaster] = useState(false)

  const {
    role,
    dealershipGroupId: userDealershipGroupId,
    dealershipId: userDealershipId,
    roleId: roleUserId,
    userId
  } = useAuth()

  useEffect(() => {
    if (
      !userId ||
      !(
        role === 'Super Admin' ||
        role === 'Admin do Grupo' ||
        role === 'Admin da Concessionária' ||
        role === 'Root'
      )
    ) {
      history.push('/')
    }

    if (id) {
      api.get(`/users/dealership/${id}`).then((response) => {
        const { dealershipID, roleID } = response.data
        setDealershipId(dealershipID)
        setRoleId(roleID)
        setUser(response.data)
        setCpf(cpfMask(response.data.cpfCnpj))
        setDomain('@mailinator.com')
        setEmail(response.data.email.split('@')[0])

        setActive(!response.data.deleted)

        setStatusOld(response.data.deleted)

        setResetPassword(false)

        setVendedorMaster(response.data.vendedorMaster)

        const currentDomain = '@' + response.data.email.split('@')[1]

        api.get(`/dealerships/${dealershipID}`).then((response: any) => {
          setDomains(response.data.domainList)

          setTimeout(() => {
            setDomain(currentDomain)
          }, 1000)
        })
      })
    } else {
      api.get(`/dealerships/${userDealershipId}`).then((response: any) => {
        setDomains(response.data.domainList)
      })
    }
  }, [statusOld])

  useEffect(() => {
    let filter = '?page=false'

    if (role === 'Admin do Grupo') {
      filter += `&DealershipGroupId=${userDealershipGroupId}`
    } else if (role === 'Admin da Concessionária') {
      filter += `&DealershipId=${userDealershipId}`

      setDealershipId(parseInt(userDealershipId))
    }


    api.get(`/dealerships/GetWithFilters${filter}`).then((response: any) => {
      setDealerships(response.data.itens)
    })

    api
      .get(`/roles/dealership/rolesbyrole/${roleUserId}`)
      .then((response: any) => {
        response.data.map((r: any) => {
          r.name = rolesDePara(r.name)
        })
        setRoles(response.data.filter((obj: { name: string }) => obj.name !== ''))
      })
  }, [])

  const initialValue = {
    fullname: user?.fullname,
    cpfCnpj: user?.cpfCnpj,
    email: user?.email
  }

  const handleChangeRoles = (event: any) => {
    setRoleId(event.target.value)
  }

  const handleChangeDealerships = (event: any) => {
    setDealershipId(event.target.value)

    api.get(`/dealerships/${event.target.value}`).then((response: any) => {
      setDomains(response.data.domainList)
    })
  }

  const handleChangeDomains = (event: any) => {
    setDomain(event.target.value)
  }

  const handleSubmitNewUser = useCallback(
    async (data: NewUserFormData) => {
      data.dealershipId = Number(dealershipId)
      data.roleId = Number(roleId)
      data.webSiteUrl = window.location.origin
      data.domain = domain.replace('@', '')
      data.email = email
      data.deleted = !active
      data.resetPassword = resetPassword
      data.vendedorMaster = vendedorMaster
      data.logout = true

      if (data.cpfCnpj.length < 14) {
        return formRef.current?.setErrors({
          cpfCnpj: 'Formato incorreto'
        })
      }

      try {
        formRef.current?.setErrors({})

        if (id) {
          data.userId = Number(id)
          const tx = 'rdpn' + btoa(id)
          await api
            .put(`/users/update-dealership/${id}/${tx}`, data)
            .then(() => {
              if (
                (role === 'Super Admin' || role === 'Root') &&
                statusOld === true &&
                data.deleted === false
              ) {
                const emailCompleto = `${email}${domain}`
                resetPassWord(emailCompleto).then((res) => {
                  console.log('Passo 2 - E-mail reset password ja enviado!')
                })
              } else {
                addToast({
                  title: 'Usuário alterado!',
                  type: 'success',
                  description: 'Usuário alterado com sucesso.'
                })
              }

              history.push('/users')
            })
            .catch((err) => {
              let msgErro = 'Por favor, entre em contato com o suporte.'
              msgErro = err.response.data
              addToast({
                type: 'error',
                title: 'Erro de Cadastro de usuário',
                description: msgErro
              })
            })
        } else {
          await api
            .post('/signup/signUp-dealership-client', data)
            .then(() => {
              addToast({
                title: 'Usuário criado!',
                type: 'success',
                description: 'Usuário criado com sucesso.'
              })

              history.push('/users')
            })
            .catch((err) => {
              let msgErro = 'Por favor, entre em contato com o suporte.'
              msgErro = err.response.data
              addToast({
                type: 'error',
                title: 'Erro de Cadastro de usuário',
                description: msgErro
              })
            })
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro de Cadastro de usuário',
          description: 'Por favor, entre em contato com o suporte.'
        })
      }
    },
    [
      dealershipId,
      roleId,
      domain,
      email,
      active,
      statusOld,
      resetPassword,
      vendedorMaster
    ]
  )

  const verifyDomain = (value: any) => {
    const nameEmail = value.target.value.replace(/@/g, '')

    setEmail(nameEmail)
  }

  const handleChangeCpf = (e: any) => {
    setCpf(cpfMask(e.target.value))
  }

  const handleChangeActive = () => {
    setActive(!active)
  }

  const handleChangeResetPassword = () => {
    setResetPassword(!resetPassword)
  }

  const handleChangeVendedorMaster = () => {
    setVendedorMaster(!vendedorMaster)
  }

  const resetPassWord = async (email: string) => {
    const data = {
      webSiteUrl: window.location.origin,
      email: email,
      IsCanalIndireto: true
    }

    await api.post('/reset-password', data).then((res) => {
      const { status } = res
      if (status === 200) {
        return addToast({
          type: 'success',
          title: 'Alteração de usuário',
          description:
            'Usuário alterado com sucesso. Enviamos um link para o seu e-mail para redefinição de senha.'
        })
      }
    })
  }

  return (
    <Container>
      <Header />
      <Content>
        {id === undefined ? (
          <>
            <h1 data-testid="title_new_user">NOVO USUÁRIO</h1>
            <p>Cadastre um novo usuário</p>
          </>
        ) : (
          <>
            <h1>EDITAR USUÁRIO</h1>
            <p>Altere os dados do usuário</p>
          </>
        )}

        <Form
          initialData={initialValue}
          onSubmit={handleSubmitNewUser}
          ref={formRef}
        >
          <div className="wrapper-info">
            {(role === 'Super Admin' || role === 'Root') && (
              <div className="isActive">
                <span>USUÁRIO ATIVO</span>
                <Switch
                  onChange={handleChangeActive}
                  checked={active}
                  disabled={!id}
                />
              </div>
            )}

            <label>Concessionária</label>
            <Select
              value={dealershipId}
              onChange={handleChangeDealerships}
              disabled={role === 'Admin da Concessionária'}
            >
              <option value="0">Selecione</option>
              {dealerships.map((item: any) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </Select>
          </div>
          <div className="wrapper-info">
            <label>Perfil</label>
            <Select
              value={roleId}
              id="roleId"
              name="role"
              onChange={handleChangeRoles}
            >
              <option value="0">Selecione</option>
              {roles.map((item: any, i: number) => {
                return (
                  <option key={item.roleId} value={item.roleId}>
                    {item.name}
                  </option>
                )
              })}
            </Select>
          </div>
          <div className="wrapper-info other-style">
            <label>Nome</label>
            <Input id="fullname" name="fullname" type="text" maxLength={150} />
          </div>
          <div className="wrapper-info other-style">
            <label>Login/CPF</label>
            <Input
              id="cpfCnpj"
              name="cpfCnpj"
              type="text"
              onChange={handleChangeCpf}
              value={cpf}
            />
          </div>
          <div className="wrapper-info">
            <label>Email</label>
            <div className="email">
              <Input
                value={email}
                onChange={verifyDomain}
                id="email"
                name="email"
                type="text"
                maxLength={50}
              />
              <Select
                id="domain"
                name="domain"
                onChange={handleChangeDomains}
                value={domain}
              >
                <option value="">Selecione</option>
                {domains.map((item: any) => {
                  return (
                    <option key={item} value={'@' + item}>
                      @{item}
                    </option>
                  )
                })}
              </Select>
            </div>
          </div>
          <div className="wrapper-info">
            {(role === 'Super Admin' || role === 'Root') && (
              <div className="isActive">
                <span>Redefinir senha no próximo login</span>
                <Switch
                  onChange={handleChangeResetPassword}
                  checked={resetPassword}
                />
              </div>
            )}
          </div>
          <br />
          <div className="wrapper-info">
            {(role === 'Super Admin' || role === 'Root') && (
              <div className="isActive">
                <span>Vendedor Master</span>
                <Switch
                  onChange={handleChangeVendedorMaster}
                  checked={vendedorMaster}
                />
              </div>
            )}
          </div>
          <br />
          <br />
          <div className="wrapper-button">
            <Link to="/users">
              <button className="btn-cancel">Cancelar</button>
            </Link>

            <button type="submit" className="btn-save">
              Salvar
            </button>
          </div>
        </Form>
      </Content>
      <Footer />
    </Container>
  )
}

export default NewUser
