import { Button, Table } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const SelectedItem = styled.div`
  display: flex;
  margin: 5px;
`

export const AddButton = styled(Button)`
  font-weight: bold;
  margin-left: 4px;
  width: 160px;
  color: #236fbc;
`
export const ItemsTable = styled.div`
  display: flex;
  width: 90%;
`

export const Items = styled(Table)`
  text-align: left;
  border-spacing: inherit;
  flex-direction: row;
  flex-wrap: wrap;

  td {
    padding: 7px 0px;
    width: 450px;
  }
`
