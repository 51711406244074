import React, { useState, useEffect } from "react";
import { useToast } from 'hooks/toast'
import Header from "components/Header";
import Footer from "components/Footer";
import api from "../../services/api";
import Select from "components/Select";
import { Container, Content, SelectedData } from "./styles";
import iconTrash from '../../assets/icons/trash-2.svg'


export interface Estado {
    id:      number;
    sigla:   string;
    nome:    string;
    regiao?: Estado;
}

export interface Cidade {
    id:                number;
    nome:              string;
    microrregiao:      Microrregiao;
    "regiao-imediata": RegiaoImediata;
}

export interface Microrregiao {
    id:          number;
    nome:        string;
    mesorregiao: Mesorregiao;
}

export interface Mesorregiao {
    id:   number;
    nome: string;
    UF:   Uf;
}

export interface Uf {
    id:      number;
    sigla:   string;
    nome:    string;
    regiao?: Uf;
}

export interface RegiaoImediata {
    id:                     number;
    nome:                   string;
    "regiao-intermediaria": Mesorregiao;
}



interface FormData {
    state: number | string | "";
    city: string | "";
}

interface SavedData {
    id: number;
    stateDescription: string;
    state: number;
    city: string;
    isDeleted: boolean;
    dateCreated: Date;
    dateUpdated: Date;
    dateDeleted: null;
}

const LicensePlateSelection: React.FC = () => {
    const { addToast } = useToast()
    const [states, setStates] = useState<Estado[]>([]);
    const [cities, setCities] = useState<Cidade[]>([]);
    const [selectedState, setSelectedState] = useState<number | "">("");
    const [selectedCity, setSelectedCity] = useState<string | "">("");
    const [formData, setFormData] = useState<FormData>({
        state: "",
        city: "",
    });
    const [savedData, setSavedData] = useState<SavedData[]>([]);
    const [isFormFilled, setIsFormFilled] = useState(false);

    useEffect(() => {
        api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`).then((response) => {
            setStates(response.data);
        });
    }, []);

    useEffect(() => {
        if (selectedState) {
            api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState}/municipios`).then((response) => {
                setCities(response.data);
            });
        } else {
            setCities([]);
        }
    }, [selectedState]);

    useEffect(() => {
        api.get("location/finalPlatePermissions").then((response) => {
            setSavedData(response.data);
        });
    }, []);

    const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedState(Number(event.target.value));
        setFormData({
            ...formData,
            state: states.filter(item => item.id === Number(event.target.value))[0]?.sigla,
        });
    };

    const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCity(event.target.value);
        setFormData({
            ...formData,
            city: normalizeString(event.target.value),
        });
        setIsFormFilled(true);
    };

    const cleanForm = () => {
        setSelectedState('');
        setSelectedCity('');
        setFormData({
            state: '',
            city: ''
        });
        setIsFormFilled(false);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (selectedState && selectedCity) {
            api.post("location/finalPlatePermissions", formData).then(() => {
                setFormData({ state: "", city: "" });
                setSelectedState("");
                setSelectedCity("");
                api.get("location/finalPlatePermissions").then((response) => {
                    setSavedData(response.data);
                });
                return addToast({
                    title: 'Sucesso ao incluir cidade',
                    type: 'success',
                    description: 'Nova cidade adicionada com sucesso'
                })
            }).catch((error) => {
                return addToast({
                    title: 'Falha ao incluir cidade.',
                    type: 'error',
                    description: 'A cidade selecionada já está cadastrada'
                })
            });
        }
    };

    const handleDelete = (id: number) => {
        api.delete(`location/finalPlatePermissions/${id}`).then(() => {
            api.get("location/finalPlatePermissions").then((response) => {
                setSavedData(response.data);
            });
            return addToast({
                title: 'Sucesso ao excluír cidade',
                type: 'success',
                description: 'Cidade excluída com sucesso'
            })
        });
    };

    const normalizeString = (str:string) => {
        const specialCharacters:any = {
          'Á': 'A',
          'À': 'A',
          'Ã': 'A',
          'Â': 'A',
          'É': 'E',
          'È': 'E',
          'Ê': 'E',
          'Í': 'I',
          'Ì': 'I',
          'Î': 'I',
          'Ó': 'O',
          'Ò': 'O',
          'Õ': 'O',
          'Ô': 'O',
          'Ú': 'U',
          'Ù': 'U',
          'Û': 'U',
          'Ç': 'C',
        };
      
        const normalizedStr = Array.from(str)
          .map((char) => specialCharacters[char.toUpperCase()] || char.toUpperCase())
          .join('');
      
        return normalizedStr;
      }

    return (
        <Container>
            <Header />
            <Content>
                <h1 data-testid="title_page" className="title">
                    FINAL DE PLACA
                </h1>
                <p data-testid="description">Gestão de cidades que permitem escolha do final de placa</p>

                <form onSubmit={handleSubmit}>
                    <div className="wrapper-info">
                        <label>Selecione um Estado</label>
                        <Select value={selectedState} id="type" name="type" onChange={handleStateChange}>
                            <option value=""></option>
                            {states.map((state) => (
                                <option key={state.sigla} value={state.id}>
                                    {state.nome}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className="wrapper-info">
                        <label>Selecione uma Cidade</label>
                        <Select value={selectedCity} id="type" name="type" onChange={handleCityChange}>
                            <option value=""></option>
                            {cities.map((city) => (
                                <option key={city.nome} value={city.nome}>
                                    {city.nome}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className="wrapper-button">
                        <button onClick={cleanForm} className="btn-cancel">Cancelar</button>

                        <button type="submit" disabled={!isFormFilled} className="btn-save">
                            Salvar
                        </button>
                    </div>
                </form>
                <SelectedData>
                    <div className="selected-data">
                        <div className="head">
                            <div className="item">Cidade</div>
                            <div className="item">Estado</div>
                            <div className="item">Ação</div>
                        </div>
                        <div className="list">
                            {savedData.map((item) => (
                                <div key={item.id}>
                                    <span className="city">{item.city}</span>
                                    <span className="state">{item.stateDescription}</span>
                                    <span className="action">
                                        <button className="delete-button" onClick={() => handleDelete(item.id)}>
                                            <img src={iconTrash} alt="Deletar" />
                                        </button>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </SelectedData>
            </Content>

            <Footer />
        </Container>
    );
};

export default LicensePlateSelection;
