/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react'
import 'antd/dist/antd.css'
import { Container, Content } from './styles'
import { Link, useHistory, useParams } from 'react-router-dom'

import Header from '../../components/Header'
import Button from '../../components/Button'

import { useAuth } from '../../hooks/auth'
import Footer from 'components/Footer'
import OrderStatusView from 'pages/OrderStatusView'
import OrderMessageView from 'pages/OrderMessageView'
import OrderHistoryView from 'pages/OrderHistoryView'

import { Tabs } from 'antd'

const OrderStatus: FC = () => {
  const { userId, role, dealershipId } = useAuth()
  const { id } = useParams<any>()

  const history = useHistory()
  const [tab, setTab] = useState('1')
  const { TabPane } = Tabs

  useEffect(() => {
    if (!userId || !dealershipId) {
      history.push('/')
    }
  }, [])

  const callback = (key: string) => {
    setTab(key)
  }

  return (
    <Container>
      <Header />
      <h1 className="title">STATUS DO PEDIDO</h1>
      <p className="description">
        Aqui você encontra todas as informações sobre o status do pedido.
      </p>
      <Content>
        <Tabs
          className="tabs"
          activeKey={tab}
          defaultActiveKey="1"
          onChange={callback}
          style={{ width: '100%' }}
          renderTabBar={(props, TabNavList) => (
            <TabNavList {...props} mobile={true} />
          )}
        >
          <TabPane tab={<span>Resumo</span>} key={1}>
            <OrderStatusView id={id} />
          </TabPane>
          <TabPane tab={<span>Histórico de Mensagens</span>} key={2}>
            <OrderMessageView id={id} />
          </TabPane>
          {(role === 'Super Admin' || role === 'Root') && (
            <TabPane tab={<span>Histórico do Pedido</span>} key={3}>
              <OrderHistoryView id={id} />
            </TabPane>
          )}
        </Tabs>
      </Content>
      <div className="buttons">
        <Link to="/my-orders">
          <Button className="btn-back">VOLTAR</Button>
        </Link>
      </div>

      <Footer />
    </Container>
  )
}

export default OrderStatus
