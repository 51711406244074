import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table, Pagination, Input, Button, Modal, Select } from "antd";
import api from "services/api";
import { useAuth } from "hooks/auth";
import { ColumnType } from "antd/lib/table";
import { Container, Content, Status, ContentModal } from "./styles";
import Footer from "components/Footer";
import Header from "components/Header";
import iconTrash from "../../../assets/icons/trash-2.svg";
import iconEdit from "../../../assets/icons/edit-2.svg";
import rolesDePara from "../../../utils/rolesdePara";
import { useToast } from "hooks/toast";
const { Option } = Select;

type RoleProps = {
    name: string;
    roleId: number;
};

type DealershipProps = {
    id: number;
    name: string;
};

const { Search } = Input;

const UsersList = () => {
    const [roleFilter, setRoleFilter] = useState(0);
    const [dealerFilter, setDealerFilter] = useState(0);
    const [nameFilter, setNameFilter] = useState<any>(null);
    const [cleanedFilters, setCleanedFilters] = useState(false);
    const [roles, setRoles] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [idRemove, setIdRemove] = useState(0);
    const [roleByRoles, setRoleByRoles] = useState<RoleProps[]>([]);
    const [listDealership, setListDealership] = useState<DealershipProps[]>([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const { role, dealershipGroupId: userDealershipGroupId, dealershipId, roleId: roleUserId, userId } = useAuth();
    const { addToast } = useToast();

    const fetchData = async (page = 1, pageSize = 10) => {
        let filter = "";

        if (role === "Admin do Grupo") {
            filter += `&dealershipGroupId=${userDealershipGroupId}`;
        } else if (role === "Admin da Concessionária") {
            filter += `&dealershipId=${dealershipId}`;
        }

        filter = filterSuperAdmin(filter);

        setLoading(true);

        if(nameFilter){
            filter = filter + '&name=' + nameFilter;
        }

        if(roleFilter){
            filter = filter + '&roleId=' + roleFilter;
        }

        if(dealerFilter){
            filter = filter + '&dealershipId=' + dealerFilter;
        }

        if(nameFilter || roleFilter || dealerFilter){
            setPagination({
                ...pagination,
                current: 1,
            });
        }

        // Fazer a chamada para a API
        const response = await api.get(`users/dealership-all-users-filter?page=true&currentPage=${page}&quantityPerPage=${pageSize}${filter}`);

        // Extrair os dados e informações de paginação do response
        const { itens, totalAmount } = response.data;

        setData(itens);
        setPagination({
            ...pagination,
            current: page,
            pageSize,
            total: totalAmount,
        });

        setLoading(false);
    };

    useEffect(() => {
        getDealers();
        getRoles();
        getRolesByRole();
        fetchData();
    }, []);

    useEffect(() => {
        if(cleanedFilters){
            fetchData();
            setCleanedFilters(false)
        }
    }, [cleanedFilters]);

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        const { current, pageSize } = pagination;
        fetchData(current, pageSize);
    };

    const filterSuperAdmin = (filter: string) => {
        if (role && filter === "" && (role.toLocaleLowerCase() === "super admin" || role.toLocaleLowerCase() === "root")) {
            filter += `&roleUserLogged=${role.toLocaleLowerCase() === "root" ? "root" : "super_admin"}`;
        }
        return filter;
    };

    const isRolesByRole = (id: number) => {
        let isVisibleActions = false;
        roleByRoles.map((r: RoleProps) => {
            if (r.roleId === id && isVisibleActions === false) {
                isVisibleActions = true;
            }
        });
        return isVisibleActions;
    };

    const getDealers = async () => {
        let filter = "?page=false";

        if (role === "Admin do Grupo") {
            filter = `?page=false&DealershipGroupId=${userDealershipGroupId}`;
        } else if (role === "Admin da Concessionária") {
            filter = `?page=false&DealershipId=${dealershipId}`;
        }

        await api.get(`/dealerships/GetWithFilters/${filter}`).then((response) => {
            setListDealership(response.data.itens);
        });
    };

    const getRoles = () => {
        api.get("/roles/dealership").then((response) => {
            response.data.map((r: RoleProps) => {
                r.name = rolesDePara(r.name);
            });
            setRoles(response.data);
        });
    };

    const getRolesByRole = () => {
        api.get(`/roles/dealership/rolesbyrole/${roleUserId}`).then((response) => {
            response.data.map((role: RoleProps) => {
                role.name = rolesDePara(role.name);
            });

            setRoleByRoles(response.data);
        });
    };

    const handleRemove = (id: number) => {
        api.delete(`/users/${id}`).then(() => {
            setOpenModal(false);
            addToast({
                title: "Usuário Removido!",
                type: "success",
                description: "O usuário foi removido com sucesso.",
            });

            fetchData();
        });
    };

    const modalOk = () => {
        handleRemove(idRemove);
    };

    const modalClose = () => {
        setOpenModal(false);
    };

    const handleFilterByRole = (roleId:any) => {
        setRoleFilter(roleId !== 0 ? roleId : 0)
    };

    const handleFilterByDealership = (dealerId:any) => {
        setDealerFilter(dealerId !== 0 ? dealerId : 0)
    };

    const setFilterName = (name: string) => {
        setNameFilter(name ? name : null)
    };

    const cleanFilters = () => {
        setRoleFilter(0)
        setDealerFilter(0)
        setNameFilter(null)
        setCleanedFilters(true)
    }


    const columns: ColumnType<any>[] = [
        {
            title: "Nome",
            dataIndex: "fullname",
            key: "fullname",
        },
        {
            title: "Perfil",
            dataIndex: "role",
            key: "role",
            render: (text: any, record: { role: { role: any } }) => record?.role?.role,
        },
        {
            title: "Concessionária",
            dataIndex: "dealership",
            key: "dealership",
            render: (text: any, record: { dealership: { name: any } }) => record?.dealership?.name,
        },
        {
            title: "Status",
            dataIndex: "inactive",
            key: "inactive",
            render: (text: any, record: { inactive: boolean }) => <Status status={!record?.inactive} />,
        },
        {
            title: "Ação",
            dataIndex: "acao",
            key: "acao",
            render: (text: any, record) => {
                return (
                    isRolesByRole(record.roleID) && (
                        <div className="actions-button">
                            <Link to={`/edit-user/${record.userId}`}>
                                <button key={`edit-button-${record.userId}`}>
                                    <img src={iconEdit} alt="Editar" />
                                </button>
                            </Link>
                        </div>
                    )
                );
            },
        },
    ];

    return (
        <Container>
            <Header />
            <Content>
                <h1 data-testid="title_page">USUÁRIOS</h1>
                <p data-testid="description">Visualize, edite e crie usuários</p>
                <div className="filters">
                    <div className="filter-item">
                        <Input value={nameFilter} onChange={(item) => setFilterName(item.target.value)} className="input" placeholder="Nome" id="doc" name="doc" type="text" />
                    </div>

                    {role !== "Admin da Concessionária" && (
                        <>
                            <div className="filter-item">
                                <Select value={roleFilter} style={{ width: "200px", borderRadius: "4px", border: "1px solid #d9d9d9" }} onChange={(item) => handleFilterByRole(item)}>
                                    <Option value={0} key="role-default">
                                        Perfil
                                    </Option>
                                    {roles.map((item: RoleProps, i: number) => (
                                        <Option value={item.roleId} key={i}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="filter-item">
                                <Select value={dealerFilter} style={{ width: "200px", borderRadius: "4px", border: "1px solid #d9d9d9" }} onChange={(item) => handleFilterByDealership(item)}>
                                    <Option value={0} key="dealership-default">
                                        Concessionária
                                    </Option>

                                    {listDealership.map((item) => (
                                        <Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </>
                    )}
                    <div className="filter-item">
                        <Button className="clean-filters" key="clean-default" type="link" onClick={cleanFilters}>Limpar filtros</Button>
                    </div>
                    <div className="filter-item">
                        <Button type="primary" onClick={() => fetchData()}>BUSCAR</Button>
                    </div>
                    <div className="filter-item">
                        <Link to={`/new-user`}>
                            <Button type="primary" data-testid="btn_new_user">NOVO USUÁRIO</Button>
                        </Link>
                    </div>
                </div>
                <Table columns={columns} dataSource={data} pagination={pagination} loading={loading} onChange={handleTableChange} rowKey={(record: any) => record.userId} />

                <Modal footer={null} title="" visible={openModal} onCancel={modalClose}>
                    <ContentModal>
                        <p>Deseja desabilitar esse usuário?</p>
                        <div>
                            <button onClick={() => modalOk()}>Sim</button>
                            <button onClick={() => modalClose()}>Não</button>
                        </div>
                    </ContentModal>
                </Modal>
            </Content>
            <Footer />
        </Container>
    );
};

export default UsersList;
