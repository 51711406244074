import styled from 'styled-components'

export const Wrapper = styled.main`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`

export const Card = styled.div`
  height: 470px;
  width: 274px;
  justify-content: space-around;
  display: flex;
  align-items: flex-start;
  margin: 0 25px 35px 0;
  flex-direction: column;

  button {
    width: 227px;
    height: 41px;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
  }

  .value-offer {
    span {
      font-size: 17px;
      color: #070707;
      font-family: 'VW Headline OT SemiBold';
    }
  }

  .blindado-badge{
      align-items: center;
      background-color: #eef3f9;
      display: flex;
      gap: 2px;
      height: 20px;
      padding: 2px 4px;
      width: -moz-fit-content;
      width: fit-content;
      color: #001e50;
      font-weight: 700;
      font-size: 12px;
      position: absolute;
  }
    

  .wrapper-title {
    height: 130px;
    display: flex;
    align-items: baseline;

    .title-item {
      font-size: 19px;
      margin: 5px 0;
      font-family: 'VW Headline OT Black';
    }
  }
    
  .content-card{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    flex-direction: column;
      .loader{
          position: absolute;
          background: #ffffffa8;
          width: 100%;
          height: 100%;
          display: flex;
      }
  }

  .unavailable-button {
    border: 2px solid grey;
    background-color: #fff;
    color: #808080;
    cursor: not-allowed;
  }

  .wrapper-quantity {
    width: 227px;
    height: 41px;
    background: #f7f7f7;
    border: 2px solid #005faf;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 23px;

    span {
      font-size: 17px;
      color: #070707;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      color: #005faf;
      background: #f7f7f7;
      font-size: 26px;
      padding: 0;
      margin: 0;
    }
  }

  .stock {
    color: #999;
    font-size: 14px;
    display: block;
    margin-top: 10px;
    text-align: center;
  }
`

export const WrapperImage = styled.div`
  .image-car {
    width: 274px;
    height: 140px;
  }
`

export const WrapperValueOffer = styled.p``

export const ColorsOption = styled.div``

export const WrapperColors = styled.div``

export const WrapperQuantity = styled.div`
  width: 100%;

  .btn-add-cart {
    background: #3c505a;
  }
`

export const Colors = styled.div`
  img {
    margin-right: 5px;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    padding: 2px;
    cursor: pointer;
  }
`
