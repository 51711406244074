import React, { useEffect, useState } from 'react'
import { Select as SelectAntd } from 'antd'
import { ISegment } from 'utils/types'
import api from 'services/api'
import iconTrash from '../../../assets/icons/trash-2.svg'
import { findPersonType } from 'utils/helpers'

interface Props {
  value: ISegment[]
  onSet: (arg: ISegment[]) => void
}

const SegmentList = ({ value, onSet }: Props) => {
  const [segmentField, setSegmentField] = useState<ISegment | any>()
  const [segmentOptions, setSegmentOptions] = useState<ISegment[]>([])

  useEffect(() => {
    api.get('/segments/active').then(({ data }) => {
      setSegmentOptions(data)
    })
  }, [])

  const isListedSegment = (iD_Fleet: number) => {
    const same = (item: ISegment) => {
      return iD_Fleet === item?.iD_Fleet
    }

    return value.find(same) !== undefined
  }

  const handleChangeField = (_: any, { value, children }: any) => {
    setSegmentField({ iD_Fleet: value, type: children[0], name: children[2] })
  }

  const handleAdd = () => {
    const shouldAddSegment =
      segmentField && !isListedSegment(segmentField.iD_Fleet)

    if (shouldAddSegment) {
      onSet([...value, segmentField])
    }
  }

  const handleRemove = (iD_Fleet: number) => {
    const different = (segment: ISegment) => iD_Fleet !== segment?.iD_Fleet
    const rest = value.filter(different)

    onSet(rest)
  }

  return (
    <>
      <div className="wrapper-info">
        <label>SEGMENTO</label>

        <div className="wrapper-btn-add-domain wrapper-content">
          <SelectAntd
            style={{
              width: 250,
              border: '1px solid #0C5FB1'
            }}
            onChange={handleChangeField}
            value={segmentField?.iD_Fleet}
            placeholder="Selecione"
          >
            {segmentOptions.map(({ id, iD_Fleet, type, name }) => (
              <SelectAntd.Option key={id?.toString()} value={iD_Fleet}>
                {findPersonType(type)?.label} - {name}
              </SelectAntd.Option>
            ))}
          </SelectAntd>

          <div>
            <button type="button" onClick={handleAdd}>
              Adicionar +
            </button>
          </div>
        </div>
      </div>

      <div className="wrapper-info trash ">
        {value.map(({ iD_Fleet, type, name }: ISegment) => {
          return (
            <>
              <div className="segments">{`${
                findPersonType(type)?.label || type
              } - ${name}`}</div>

              <img
                style={{ cursor: 'pointer' }}
                onClick={() => handleRemove(iD_Fleet)}
                src={iconTrash}
                alt="Icon remover segmento"
              />
            </>
          )
        })}
      </div>
    </>
  )
}

export default SegmentList
