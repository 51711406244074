/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useCallback, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import api from '../../../services/api'

import { useToast } from '../../../hooks/toast'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header'

import {
  Container,
  WrapperTable,
  WrapperFiles,
  Table,
  Content,
  ContainerModal,
  ContentModal,
  WrapperDoc,
  ContentModalUpload,
  ContainerModalUpload,
  Title,
  Description,
  ButtonUpload
} from './styles'

import Button from '../../../components/Button'
import iconEdit from '../../../assets/icons/edit-2.svg'
import { cpfMask } from '../../../utils/cpfMask'
import { phoneMask } from '../../../utils/phoneMask'
import { useAuth } from '../../../hooks/auth'

import { Modal, Upload } from 'antd'
import { AiOutlineUpload } from 'react-icons/ai'
import { UploadChangeParam } from 'antd/lib/upload'
import Notes from '../CompanyContacts/Notes'
import Loading from 'components/Loading'

interface ContactsProps {
  userId: number
  fullname: string
  email: string
  cpfCnpj: string
  phone: string
}

interface IFiles {
  extensionCNH: string
  cnhImage: string
  extensionCNHBack: string
  cnhBackImage: string
  extensionDocumentAddress: string
  addressImage: string
  extensionHolerite: string
  holeriteImage: string
}

const DOC_NAMES = {
  cnhFrente: 'CNH-Frente',
  cnhVerso: 'CNH-Verso',
  comprovanteEndereco: 'Comprovante-de-endereço',
  comprovanteRenda: 'Comprovante-de-renda'
}

const CompanyContacts: FC = () => {
  const { dealershipId } = useAuth()
  const { orderId, userId } = useParams<any>()

  const history = useHistory()
  const { addToast } = useToast()
  const { role } = useAuth()

  const [contacts, setContacts] = useState<ContactsProps[]>([])
  const [isPendingSignatories, setIsPendingSignatories] = useState(false)
  const [isPowerOfAttorney, setIsPowerOfAttorney] = useState(false)
  const [showDocuments, setShowDocuments] = useState(false)
  const [showNotes, setShowNotes] = useState(false)
  const [dataUpload, setDataUpload] = useState(false)
  const [note, setNote] = useState('')
  const [showUpload, setShowUpload] = useState(true)
  const [isLoading, setLoading] = useState(false)

  const [isLoadingDownload, setLoadingDownload] = useState(false)

  const [files, setFiles] = useState<IFiles>()
  const [docName, setDocName] = useState('')

  const getLogisticsByCustomer = useCallback(async () => {
    await api.get(`/users/${userId}`).then((response: any) => {
      setContacts([
        {
          userId: response.data.userId,
          fullname: response.data.fullname,
          email: response.data.email,
          cpfCnpj: response.data.cpfCnpj,
          phone: response.data.cellPhone
        }
      ])
    })
  }, [])

  const getLinkDownload = useCallback(async () => {
    setLoadingDownload(true)
    await api.get(`/upload/${userId}`).then((response: any) => {
      setFiles(response.data)
    })
    setLoadingDownload(false)
  }, [])

  useEffect(() => {
    if (!userId || !dealershipId) {
      history.push('/')
    }
    getLogisticsByCustomer()
    getLinkDownload()
  }, [getLogisticsByCustomer, getLinkDownload])

  const handleSendToAwaitingContractSign = () => {
    if (contacts.length === 0) {
      addToast({
        title: 'Envar Signatários',
        type: 'error',
        description: 'Favor cadastrar um contato.'
      })
      return
    }

    const orderStatus = {
      OrderId: Number(orderId),
      Status: 15,
      Description: 'Aguardando envio de contrato'
    }

    api.post('/orderstatus', orderStatus).then(() => {
      addToast({
        title: 'Informações enviadas',
        type: 'success',
        description: 'Informações enviadas com sucesso.'
      })

      history.push('/my-orders')
    })
  }

  const handleSendContract = () => {
    setLoading(true)

    api
      .post(`/orders/send-contract?orderId=${orderId}`)
      .then((res) => {
        if (res.data) {
          addToast({
            title: 'Contrato enviado!',
            type: 'success',
            description: 'O contrato foi enviado com sucesso.'
          })

          history.push('/my-orders')
        }
      })
      .catch(() => {
        addToast({
          title: 'Erro ao enviar o contrato',
          type: 'error',
          description: 'Ocorreu um erro ao enviar o contrato'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleShowModal = () => {
    setIsPendingSignatories(!isPendingSignatories)
  }

  const handleShowModalIsPower = () => {
    setIsPowerOfAttorney(!isPowerOfAttorney)
  }

  const handleOk = () => {
    if (!note) return

    setIsPendingSignatories(false)
    api
      .post('/orders/SaveObservation', {
        orderId: Number(orderId),
        note: note
      })
      .then(() => {
        history.push('/my-orders')
      })
  }

  const handleCancel = () => {
    setIsPendingSignatories(false)
  }

  const handleOkIsPowerOfAttorney = () => {
    setIsPowerOfAttorney(false)
    if (dataUpload) {
      addToast({
        title: 'Documentação enviada!',
        type: 'success',
        description: 'A documentação foi enviada com sucesso.'
      })
    }
  }

  const handleCancelIsPowerOfAttorney = () => {
    setIsPowerOfAttorney(false)
  }

  const handleCancelDocument = () => {
    setShowDocuments(false)
  }

  const sendFile = (formData: any, apiUrl: string) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Accept: 'text/plain'
      }
    }

    api.post(`${apiUrl}?userId=${userId}`, formData, config).catch((err) => {
      addToast({
        title: 'Erro ao fazer upload',
        type: 'error',
        description: `${err.response.data}`
      })
    })
  }

  const handleFormatDocUpload = async (image: any) => {
    if (image.file.size > 5000000 && image.file.status !== 'uploading') {
      return addToast({
        title: 'Falha ao enviar documento',
        type: 'error',
        description: 'O tamanho do arquivo é maior que 5 mb'
      })
    } else {
      if (image.file.status === 'done' || image.file.status === 'error') {
        const formData = new FormData()
        formData.append('uploadRequest', image.file.originFileObj)

        setDataUpload(formData ? true : false)

        switch (docName) {
          case DOC_NAMES.cnhFrente:
            return sendFile(formData, '/upload/UploadCnhAsync')
          case DOC_NAMES.cnhVerso:
            return sendFile(formData, '/upload/UploadCnhBackAsync')
          case DOC_NAMES.comprovanteEndereco:
            return sendFile(formData, '/upload/UploadProofOfAddressAsync')
          case DOC_NAMES.comprovanteRenda:
            return sendFile(formData, '/upload/UploadProofOfIncomeAsync')
          default:
            return null
        }
      }
    }
  }

  const props = {
    name: 'file',
    multiple: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    maxCount: 1,
    showUploadList: showUpload,
    onChange(info: UploadChangeParam) {
      info.file.name = `${docName}.${info.file.type?.split('/')[1]}`
      handleFormatDocUpload(info)
    }
  }

  const handleOpenModal = () => {
    setShowDocuments(!showDocuments)
  }

  const handleShowNotes = () => {
    setShowNotes(!showNotes)
  }

  return (
    <Container>
      <Header />
      <Content>
        <h1>CADASTRO DE CONTATOS</h1>
        <p>Visualize, edite e crie contatos</p>

        {isLoading ? (
          <>
            <Loading />

            <p className="message-wait">Aguarde, enviando contrato...</p>
          </>
        ) : (
          <>
            <WrapperTable style={{ marginBottom: '20px' }}>
              <Table>
                <thead>
                  <tr>
                    <th className="th-left">Nome</th>
                    <th className="th-center">Telefone</th>
                    <th className="th-center">CPF</th>
                    <th className="th-center">E-mail</th>
                    <th className="th-right">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.length ? (
                    contacts.map((item: any, i: number) => {
                      return (
                        <tr key={i}>
                          <td>{item.fullname}</td>
                          <td style={{ textAlign: 'center' }}>
                            {phoneMask(item.phone)}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {cpfMask(item.cpfCnpj)}
                          </td>
                          <td style={{ textAlign: 'center' }}>{item.email}</td>
                          <td className="actions-button">
                            <Link to={`/edit-contact-pf/${item.userId}`}>
                              <button>
                                <img src={iconEdit} alt="Editar" />
                              </button>
                            </Link>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>Nenhum registro encontrado! </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="wrapper-link">
                {role !== 'Atendimento' && (
                  <button className="btn-show-notes" onClick={handleShowNotes}>
                    Observação
                  </button>
                )}

                {role !== 'Atendimento' && (
                  <button
                    className="btn-power-of-attorney"
                    onClick={handleShowModalIsPower}
                  >
                    Inserir documentos
                  </button>
                )}
              </div>

              <div className="wrapper-btn-show-files">
                {role === 'Atendimento' && (
                  <button className="btn-show-files" onClick={handleOpenModal}>
                    Download de documentos
                  </button>
                )}
              </div>
            </WrapperTable>

            <Notes
              orderId={orderId}
              showModalNotes={showNotes}
              onOpenModalNotes={handleShowNotes}
            />

            <ContainerModalUpload>
              <Modal
                footer={null}
                title=""
                visible={isPowerOfAttorney}
                onOk={handleOkIsPowerOfAttorney}
                onCancel={handleCancelIsPowerOfAttorney}
              >
                <Title>Carregar documento</Title>
                <Description>Faça upload da documentação</Description>

                <ContentModalUpload>
                  <WrapperDoc>
                    <div className="container-name-file">
                      <p className="name-file">CNH FRENTE</p>
                      <p>Arquivos (JPG, PNG ou PDF) até 5 mb</p>
                    </div>

                    <div className="right">
                      <Upload className="upload" {...props}>
                        <ButtonUpload
                          onClick={() => setDocName(DOC_NAMES.cnhFrente)}
                        >
                          {' '}
                          <AiOutlineUpload size={17} /> CLIQUE PARA CARREGAR
                        </ButtonUpload>
                      </Upload>
                    </div>
                  </WrapperDoc>

                  <WrapperDoc>
                    <div className="container-name-file">
                      <p className="name-file">CNH VERSO</p>
                      <p>Arquivos (JPG, PNG ou PDF) até 5 mb</p>
                    </div>

                    <div className="right">
                      <Upload className="upload" {...props}>
                        <ButtonUpload
                          onClick={() => setDocName(DOC_NAMES.cnhVerso)}
                        >
                          {' '}
                          <AiOutlineUpload size={17} /> CLIQUE PARA CARREGAR
                        </ButtonUpload>
                      </Upload>
                    </div>
                  </WrapperDoc>

                  <WrapperDoc>
                    <div className="container-name-file">
                      <p className="name-file">COMPROVANTE DE ENDEREÇO</p>
                      <p>Arquivos (JPG, PNG ou PDF) até 5 mb</p>
                    </div>

                    <div className="right">
                      <Upload className="upload" {...props}>
                        <ButtonUpload
                          onClick={() =>
                            setDocName(DOC_NAMES.comprovanteEndereco)
                          }
                        >
                          {' '}
                          <AiOutlineUpload size={17} /> CLIQUE PARA CARREGAR
                        </ButtonUpload>
                      </Upload>
                    </div>
                  </WrapperDoc>

                  <WrapperDoc>
                    <div className="container-name-file">
                      <p className="name-file">COMPROVANTE DE RENDA</p>
                      <p>Arquivos (JPG, PNG ou PDF) até 5 mb</p>
                    </div>

                    <div className="right">
                      <Upload className="upload" {...props}>
                        <ButtonUpload
                          onClick={() => setDocName(DOC_NAMES.comprovanteRenda)}
                        >
                          {' '}
                          <AiOutlineUpload size={17} /> CLIQUE PARA CARREGAR
                        </ButtonUpload>
                      </Upload>
                    </div>
                  </WrapperDoc>
                  <Button
                    className="btn-submit"
                    disabled={!dataUpload ? true : false}
                    onClick={handleOkIsPowerOfAttorney}
                  >
                    Enviar
                  </Button>
                </ContentModalUpload>
              </Modal>
            </ContainerModalUpload>

            <div
              className="wrapper-button"
              style={{ justifyContent: 'space-between' }}
            ></div>

            <div
              className="wrapper-button"
              style={{ justifyContent: 'space-between' }}
            >
              {/* <Button onClick={handleSendContacts}>ENVIAR CONTATOS</Button> */}

              <Link to="/my-orders">
                <Button className="btn-back">VOLTAR</Button>
              </Link>

              <div className="button-rigth" style={{ display: 'flex' }}>
                {role === 'Atendimento' && (
                  <>
                    <Button className="btn-back" onClick={handleShowModal}>
                      PENDENTE INFORMAÇÕES
                    </Button>
                    <Button
                      className="btn-submit-contract"
                      onClick={handleSendContract}
                    >
                      ENVIAR CONTRATO
                    </Button>
                  </>
                )}
              </div>

              {role !== 'Atendimento' && (
                <Button
                  onClick={handleSendToAwaitingContractSign}
                  style={{ marginLeft: '10px' }}
                >
                  ENVIAR INFORMAÇÕES
                </Button>
              )}
            </div>

            <ContainerModal>
              <Modal
                footer={null}
                title="Documentos disponíveis para download"
                visible={showDocuments}
                onOk={handleOkIsPowerOfAttorney}
                onCancel={handleCancelDocument}
              >
                <>
                  {isLoadingDownload ? (
                    <Loading />
                  ) : (
                    <>
                      {files !== undefined && (
                        <WrapperFiles>
                          {files.cnhImage !== null && (
                            <a
                              href={`data:application/${files.extensionCNH};base64,${files.cnhImage}`}
                              download={`${orderId}-CNH-Frente.${files.extensionCNH}`}
                            >
                              CNH FRENTE
                            </a>
                          )}
                          {files.cnhBackImage !== null && (
                            <a
                              href={`data:application/${files.extensionCNHBack};base64,${files.cnhBackImage}`}
                              download={`${orderId}-CNH-Atras.${files.extensionCNHBack}`}
                            >
                              CNH VERSO
                            </a>
                          )}
                          {files.addressImage !== null && (
                            <a
                              href={`data:application/${files.extensionDocumentAddress};base64,${files.addressImage}`}
                              download={`${orderId}-Documento-endereço.${files.extensionDocumentAddress}`}
                            >
                              Comprovante de endereço
                            </a>
                          )}
                          {files.holeriteImage !== null && (
                            <a
                              href={`data:application/${files.extensionHolerite};base64,${files.holeriteImage}`}
                              download={`${orderId}-Holerite.${files.extensionHolerite}`}
                            >
                              Comprovante de Renda
                            </a>
                          )}
                        </WrapperFiles>
                      )}
                    </>
                  )}
                </>
              </Modal>
            </ContainerModal>

            <ContainerModal>
              <Modal
                footer={null}
                title=""
                visible={isPendingSignatories}
                onCancel={handleCancel}
                style={{
                  backgroundColor: '#838383',
                  padding: '8px',
                  marginTop: '62px'
                }}
              >
                <ContentModal>
                  <p>Inserir Observação:</p>
                  <form>
                    <textarea
                      name="note"
                      onChange={(e) => setNote(e.target.value)}
                      required
                    ></textarea>
                    {
                      // eslint-disable-next-line
                  <a onClick={handleOk}>Enviar</a>
                    }
                  </form>
                </ContentModal>
              </Modal>
            </ContainerModal>
          </>
        )}
      </Content>
      <Footer />
    </Container>
  )
}

export default CompanyContacts
