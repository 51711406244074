import styled from 'styled-components'

interface iStatus {
  status: boolean
}

export const Status = styled.div<iStatus>`
  background-color: ${(props) => (props.status ? 'green' : 'red')};
  width: 12px;
  border-radius: 50%;
  height: 12px;
`

export const Container = styled.div``

export const WrapperTable = styled.div`
  width: 80%;
  margin-bottom: 100px;

  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    label {
      font-size: 14px;
      color: #767676;
      font-weight: 500;
    }

    button {
      margin: 2px;
    }

    .btn-page {
      width: 27px;
      height: 27px;
      background: #0c5faf 0% 0% no-repeat padding-box;
      border: 1px solid #e8e8e8;
      color: #fff;
      font: normal normal bold 11px/15px Open Sans;
    }

    .btn-previous {
      width: 59px;
      height: 27px;
      font: normal normal normal 11px/15px 'Open Sans';
      border: 1px solid #e8e8e8;
      color: #b1afaf;
    }

    .btn-next {
      width: 59px;
      height: 27px;
      font: normal normal normal 11px/15px 'Open Sans';
      border: 1px solid #e8e8e8;
    }
  }
  @media (max-width: 700px) {
    form {
      div {
        margin: 5px;
      }
    }
  }
`

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: inherit;
  margin: 15px 0px 50px 0px;
  border: none;

  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  tbody {
    .wrapper-status {
      display: flex;
      justify-content: center;
      margin: 0 auto;

      div {
        margin: 8px;
      }
    }

    .actions-button {
      width: 100%;
      display: flex;
      justify-content: center;

      button {
        width: 20px;
        background: transparent;
        border: none;
        margin: 4px;
      }
    }
  }

  tr {
    font-size: 14px;

    img {
      margin-left: 8px;
    }

    .th-center {
      text-align: center !important;
    }

    .status {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #51ae30;
      margin-right: 4px;
    }
  }

  td {
    padding: 10px;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    padding: 10px;
  }

  .large {
    width: 110px;
  }

  .th-left {
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
  }

  .th-right {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: #17536c;
    margin-top: 30px;
    margin-bottom: 0px !important;
    font-size: 1.6em !important;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    justify-content: space-between;

    button {
      width: 190px;
      margin-left: 8px;
      font-size: 14px;
    }

    .wrapper-search {
      display: flex;
      flex-direction: row;
      align-items: center;

      select {
        padding: 8px;
        border-radius: 23px;
        height: 41px;
      }

      div:nth-child(1) {
        margin: 0 15px;
        background: #ffffff;
      }

      div:nth-child(2) {
        margin: 0 15px;
        background: #ffffff;
      }

      div:nth-child(3) {
        margin: 0;
      }
    }
  }

  @media (max-width: 1237px) {
    p {
      text-align: center;
    }

    form {
      flex-direction: column;
      flex-wrap: wrap;

      .wrapper-search {
        flex-direction: column;
        flex-wrap: wrap;

        div {
          margin-top: 10px !important;
        }
      }
    }
  }

  p {
    color: #3c505a;
    margin-top: 5px;
  }

  h1,
  p {
    text-align: center;
  }

  .wrapper-button {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: flex-end;

    button {
      width: 190px;
      font-size: 14px;
    }
  }
`
