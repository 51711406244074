/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'

import { Container, WrapperTable, Table, Content, Status } from './styles'
import Header from 'components/Header'
import Footer from 'components/Footer'
import api from 'services/api'
import { useToast } from 'hooks/toast'
import { Link, useHistory } from 'react-router-dom'
import iconEdit from '../../assets/icons/edit-2.svg'
import iconTrash from '../../assets/icons/trash-2.svg'
import { useAuth } from '../../hooks/auth'

interface SegmentsData {
  id?: number
  type: string
  name: string
  prefix: string
  status: boolean
  diasExpirar: number
  iD_Ibratan: string
  iD_Fleet: number
}

const SegmentsManagement = () => {
  const history = useHistory()
  const { addToast } = useToast()
  const [segments, setSegments] = useState<SegmentsData[]>([])
  const { role, userId } = useAuth()

  useEffect(() => {
    if (!userId || !(role === 'Root')) {
      history.push('/')
    }
  }, [])

  const loadSegments = () => {
    api
      .get('/segments')
      .then(({ data }) => {
        setSegments(data)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível listar os segmentos`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
  }

  useEffect(() => {
    loadSegments()
  }, [])

  const handleRemove = (id: number) => {
    api.get(`/segments/${id}/edit`).then((response) => {
      const data = response.data
      data.status = false
      api.put('/segments', data)

      addToast({
        title: 'Segmento Atlerado!',
        type: 'success',
        description: 'Segmento editado com sucesso.'
      })

      const itemSegment = segments.filter((item) => item.id !== id)
      itemSegment.push(data)
      setSegments(itemSegment)
    })
  }

  return (
    <Container>
      <Header />
      <Content>
        <h1 data-testid="title_page">GESTÃO DE SEGMENTOS</h1>
        <p data-testid="description">Editar ou criar segmentos</p>
        <div className="wrapper-button">
          <Link to="/new-segments">
            <Button data-testid="btn_new_group">NOVO SEGMENTO</Button>
          </Link>
        </div>

        <WrapperTable>
          <Table data-testid="table">
            <thead>
              <tr>
                <th className="th-left">ID</th>
                <th>TIPO DE PESSOA</th>
                <th>NOME SEGMENTO</th>
                <th>PREFIXO PEDIDO</th>
                <th>PONTO ATENDIMENTO</th>
                <th>STATUS</th>
                <th className="th-right">AÇÃO</th>
              </tr>
            </thead>
            <tbody>
              {segments.length ? (
                segments.map((item: any, i: number) => {
                  return (
                    <tr key={i}>
                      <td>{item.iD_Fleet}</td>
                      <td>{item.type === 'PJ' ? 'Jurídica' : 'Física'}</td>
                      <td>{item.name}</td>
                      <td>{item.prefix}</td>
                      <td>{item.iD_Ibratan}</td>
                      <td style={{ textAlign: 'left' }}>
                        <div className="wrapper-status">
                          <Status status={item.status} />
                          {item.status ? 'Ativo' : 'Inativo'}
                        </div>
                      </td>
                      <td className="actions-button">
                        <Link to={`/edit-segments/${item.id}`}>
                          <button>
                            <img src={iconEdit} alt="Editar" />
                          </button>
                        </Link>
                        <button onClick={() => handleRemove(item.id)}>
                          <img src={iconTrash} alt="Deletar" />
                        </button>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={7}>Nenhum registro encontrado!</td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="pagination" style={{ display: 'none' }}>
            <label>Mostrando 4 de 4 registros</label>
            <div>
              <button className="btn-previous">Anterior</button>
              <button className="btn-page">1</button>
              <button className="btn-next">Próximo</button>
            </div>
          </div>
        </WrapperTable>
      </Content>
      <Footer />
    </Container>
  )
}

export default SegmentsManagement
