import { combineReducers } from 'redux'
import offers from './offers/reducer'
import login from './login/reducer'
import location from './location/reducer'
import tabs from './tabsRegisterUser/reducer'
import clientType from './clientType/reducer'

export default combineReducers({
  offers,
  login,
  location,
  tabs,
  clientType
})
