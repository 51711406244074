import styled from 'styled-components'

export const WrapperDoc = styled.div`
  background: #ffffff;
  border: 1px solid #005faf;
  border-radius: 36px;
  width: 100%;
  max-width: 787px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  margin: 12px;

  .left {
    p {
      margin: 0;
      color: #005faf;
      font-size: 14px;
      font-weight: bold;
    }

    .name-file {
      font-size: 18px;
      font-weight: bold;

      span {
        font-weight: 500;
      }
    }
  }

  .more-info {
    text-decoration: underline;
    font-size: 14px;
    color: #000;
    cursor: pointer;
  }

  .right {
    .upload {
      flex-direction: column;

      .ant-tooltip-inner {
        display: none;
      }

      span.anticon.anticon-delete {
        display: block;
      }
    }

    .btn-upload {
      width: 192px;
      border: 3px solid #005faf;
      background: #fff;
      color: #005faf;
      font-size: 11px;
      margin-left: 12px;
      align-items: center;
      display: flex;
      justify-content: space-between;

      svg {
        color: #005faf;
      }
    }
  }

  @media (max-width: 1024px) {
    height: auto;
    flex-direction: column;
    width: auto;

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`
