/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-useless-computed-key: "error" */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import * as S from './styles'

import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Button from '../../components/Button'
import api from 'services/api'
import { useToast } from 'hooks/toast'
import { useAuth } from 'hooks/auth'
import {
  REPORTS_DEALERSHIP_URL,
  REPORTS_ORDERS_URL,
  REPORTS_STOCKS_URL,
  REPORTS_USERS_URL,
  REPORTS_CUSTOMERS_URL,
  REPORTS_ENTREGA_URL,
  SEGMENTS_ACTIVE,
  SEGMENTS_FILTER
} from 'utils/urls'
import { downloadFile } from 'utils/downloadFile'
import Loading from 'components/Loading'
import { findPersonType } from 'utils/helpers'
import { ISegment } from 'utils/types'

type ReportTitle =
  | 'Pedidos'
  | 'Concessionárias'
  | 'Usuários'
  | 'Estoque'
  | 'Entrega'
  | 'Clientes'

interface ISegments {
  Pedidos: Array<ISegment>
  Estoque: Array<ISegment>
}

const Reports = () => {
  const { addToast } = useToast()
  const { fullname, role } = useAuth()
  const history = useHistory()

  const [titleReport, setTitleReport] = useState<ReportTitle>('Pedidos')
  const [segmentId, setSegment] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [clientType, setClientType] = useState('2')
  const [segments, setSegments] = useState<ISegments>({
    Pedidos: [],
    Estoque: []
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const segmentApiUrls = [
      { label: 'Pedidos', url: `${SEGMENTS_FILTER}?status=true` },
      { label: 'Estoque', url: SEGMENTS_ACTIVE }
    ]

    const loadSegments = (label: string, url: string) => {
      api
        .get(url)
        .then(({ data }) => {
          setSegments((prev) => ({ ...prev, [label]: data }))
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: `Não foi possível encontrar os segmentos de ${label}`,
            description: 'Por favor, entre em contato com o suporte.'
          })
        })
    }

    segmentApiUrls.forEach(({ label, url }) => {
      loadSegments(label, url)
    })
  }, [])

  useEffect(() => {
    if (!fullname || (role !== 'Super Admin' && role !== 'Root'))
      history.push('/')
  }, [fullname, history, role])

  const getReportSetup = (title: ReportTitle) => {
    let targetList: ISegment[] = []

    if (titleReport === 'Pedidos' || titleReport === 'Entrega') {
      targetList = segments.Pedidos
    }

    if (titleReport === 'Estoque') {
      targetList = segments.Estoque
    }

    const targetSegment = targetList.find(
      (segment: ISegment) => segment.id === Number(segmentId)
    )

    const reportSettings = {
      Pedidos: {
        apiUrl: `${REPORTS_ORDERS_URL}?typeOrder=${targetSegment?.iD_Ibratan}&type=${targetSegment?.type}&from=${startDate}&to=${endDate}`,
        isValidFields: ![segmentId, startDate, endDate].includes('')
      },
      Concessionárias: {
        apiUrl: REPORTS_DEALERSHIP_URL,
        isValidFields: true
      },
      Entrega: {
        apiUrl: `${REPORTS_ENTREGA_URL}?typeOrder=${targetSegment?.iD_Ibratan}&type=${targetSegment?.type}&from=${startDate}&to=${endDate}`,
        isValidFields: true
      },
      Usuários: { apiUrl: REPORTS_USERS_URL, isValidFields: true },
      Clientes: {
        apiUrl: `${REPORTS_CUSTOMERS_URL}?typeClients=${clientType}`,
        isValidFields: true
      },
      Estoque: {
        apiUrl: targetSegment
          ? REPORTS_STOCKS_URL(String(targetSegment.iD_Fleet))
          : '',
        isValidFields: segmentId !== ''
      }
    }

    return reportSettings[title]
  }

  const handleChangeReport = (title: ReportTitle) => {
    setTitleReport(title)
  }

  const handleChangeSegment = (event: { target: { value: string } }) => {
    setSegment(event.target.value)
  }

  const handleChangeStartDate = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value

    setStartDate(value)
  }

  const handleChangeEndDate = (event: React.FormEvent<HTMLInputElement>) => {
    setEndDate(event.currentTarget.value)
  }

  const handleDownload = () => {
    const { isValidFields, apiUrl } = getReportSetup(titleReport)

    if (!isValidFields) {
      addToast({
        title: 'Filtros pendente',
        type: 'error',
        description: 'Selecione todos os filtros para baixar o relatório.'
      })
      return
    }

    setIsLoading(true)
    api
      .get(apiUrl, { responseType: 'blob' })
      .then((response) => {
        downloadFile({
          name: `Relatório de ${titleReport}`,
          file: response.data,
          type: 'xlsx'
        })
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)

        addToast({
          type: 'error',
          title: 'Erro ao baixar relatório',
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
  }

  const SegmentSelect = ({ options }: { options: Array<ISegment> }) => {
    return (
      <>
        <label>Segmento</label>
        <select onChange={handleChangeSegment} value={segmentId}>
          <option value="">Selecione</option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {findPersonType(option.type)?.label} - {option.name}
            </option>
          ))}
        </select>
      </>
    )
  }

  const handleClientType = (event: { target: { value: string } }) => {
    setClientType(event.target.value)
  }

  return (
    <S.Container>
      <Header />
      <S.Content>
        <S.Menu>
          <p data-testid="title-report" className="title-report">
            Relatórios
          </p>
          <hr />
          <p
            className={`report ${titleReport === 'Clientes' ? 'active' : ''}`}
            onClick={() => handleChangeReport('Clientes')}
          >
            Clientes
          </p>
          <p
            className={`report ${titleReport === 'Pedidos' ? 'active' : ''}`}
            onClick={() => handleChangeReport('Pedidos')}
          >
            Pedidos
          </p>
          <p
            className={`report ${
              titleReport === 'Concessionárias' ? 'active' : ''
            }`}
            onClick={() => handleChangeReport('Concessionárias')}
          >
            Concessionárias
          </p>
          <p
            className={`report ${titleReport === 'Entrega' ? 'active' : ''}`}
            onClick={() => handleChangeReport('Entrega')}
          >
            Entrega
          </p>
          <p
            className={`report ${titleReport === 'Usuários' ? 'active' : ''}`}
            onClick={() => handleChangeReport('Usuários')}
          >
            Usuários
          </p>
          <p
            className={`report ${titleReport === 'Estoque' ? 'active' : ''}`}
            onClick={() => handleChangeReport('Estoque')}
          >
            Estoque
          </p>
        </S.Menu>
        <S.ReportsData>
          <h1>RELATÓRIOS</h1>
          <p>Filtre e importe todos os relatórios que precise.</p>
          {isLoading && <Loading />}

          <S.ContainerReport>
            <S.HeaderReport>
              <span>{titleReport}</span>
            </S.HeaderReport>
            <S.WrapperFields>
              {(titleReport === 'Pedidos' || titleReport === 'Entrega') && (
                <>
                  <S.Wrapper>
                    <SegmentSelect options={segments.Pedidos} />
                  </S.Wrapper>
                  <S.WrapperDate>
                    <S.Wrapper>
                      <label>Data inicial</label>
                      <input
                        type="date"
                        max={endDate}
                        onChange={handleChangeStartDate}
                      />
                    </S.Wrapper>
                    <S.Wrapper>
                      <label>Data final</label>
                      <input
                        type="date"
                        min={startDate}
                        onChange={handleChangeEndDate}
                      />
                    </S.Wrapper>
                  </S.WrapperDate>
                </>
              )}

              {titleReport === 'Estoque' && (
                <S.Wrapper>
                  <SegmentSelect options={segments.Estoque} />
                </S.Wrapper>
              )}

              {titleReport === 'Concessionárias' && (
                <p className="message-report">
                  Clique para baixar relatório de concessionárias
                </p>
              )}

              {titleReport === 'Usuários' && (
                <p className="message-report">
                  Baixar relatório de usuários do canal indireto
                </p>
              )}

              {titleReport === 'Clientes' && (
                <S.Wrapper>
                  <label>Tipo de cliente</label>
                  <select onChange={handleClientType} value={clientType}>
                    <option value="2">Pessoa Física</option>
                    <option value="1">Pessoa Jurídica</option>
                  </select>
                </S.Wrapper>
              )}

              <Button disabled={isLoading} onClick={handleDownload}>
                Baixar excel
              </Button>
            </S.WrapperFields>
          </S.ContainerReport>
        </S.ReportsData>
      </S.Content>
      <Footer />
    </S.Container>
  )
}

export default Reports
