import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  background-color: #f1f1f1;

  .title {
    color: #17536c;
    margin-top: 30px;
    font-size: 1.6em !important;
    text-transform: uppercase;
  }
`

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  flex-wrap: wrap;
  padding: 20px;
  margin: 40px auto;
  width: 80%;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  margin: 8px;

  span {
    font-size: 14px;
  }

  .select-segment {
    border: 1px solid #d9d9d9;
    text-align: left;
  }

  .model-code {
    width: 120px;
  }

  .input-model,
  .input-medium {
    width: 290px;
  }
`

export const FilterActions = styled.div``

export const WrapperButtonsModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ModalContent = styled.div`
  text-align: center;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .message-wait {
    margin-top: 30px;
    font-weight: 500;
  }

  .title-message {
    color: #0c5faf;
    font-size: 22px;
    font-weight: 500;
  }

  .sub-title {
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
  }

  .message,
  .message-wait {
    color: #000;
  }

  .btn-add {
    background-color: #0c5faf;
    color: #fff;
  }

  button {
    margin: 8px;
    border-radius: 12px;
    font-weight: bold;
    font-size: 12px;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
  }

  .btn-refer {
    color: red;
  }

  .upload {
    flex-direction: column;

    .ant-tooltip-inner {
      display: none;
    }

    span.anticon.anticon-delete {
      display: block;
    }
  }
`

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 30px auto;
  min-width: 350px;

  button {
    margin: 8px;
    border-radius: 12px;
    border: 2px solid #0c5faf;
    color: #0c5faf;
    font-weight: bold;
    font-size: 14px;

    @media (min-width: 620px) {
      width: 150px;
    }
  }
`

export const ButtonsRight = styled.div`
  button {
    background-color: #0c5faf;
    color: #fff;
  }
`

export const WrapperSwitch = styled.div`
  margin: 8px;

  span {
    margin-right: 10px;
  }
`

export const WrapperFilters = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 80%;
`

export const WrapperButtonsCenter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 30px auto;
  margin-top: 50px;
  min-width: 350px;

  button {
    margin: 8px;
    border: 0px;
    font-weight: bold;
    font-size: 14px;

    @media (min-width: 620px) {
      width: 150px;
    }
  }
`
