/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import 'antd/dist/antd.css'
import { Content, Table, WrapperLine, WrapperLogo } from './styles'
import { useHistory } from 'react-router-dom'

import api, { defaultBackendUrl } from '../../services/api'

import { useAuth } from '../../hooks/auth'
import Loading from 'components/Loading'
import { formatDateTime } from 'utils/formatDateTime'
import ImgWithFallback from 'components/ImgWithFallback'
import { Steps } from 'antd'
import { Step } from 'material-ui'
import { WrapperStep } from 'pages/OrderStatusPFView/styles'
import formatValue from 'utils/formatValue'
import noImageCar from "assets/defaultImages/no-image.png";


interface Items {
  orderStatusId: number
  orderId: number
  userId: number
  status: number
  description: string
  note: string
  dateCreated: string
  userCancellation: string
  justifyCancellation: string
  userLoggedId: number
  userLoggedName: string
}

type OrderAlterHistoryViewProps = {
  id: number
}

const OrderAlterHistoryView = ({ id }: OrderAlterHistoryViewProps) => {
  const { userId, dealershipId } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()

  const [items, setItems] = useState<Items[]>([])

  useEffect(() => {
    if (!userId || !dealershipId) {
      history.push('/')
    }
    try {
      api
        .get(`/orderitemshistory/${id}`)
        .then((res) => {
          setItems(res.data)
          setIsLoading(false)
        })
        .catch((err) => {
          setIsLoading(false)
        })
    } catch (err) {
      setIsLoading(false)
    }
  }, [])

  return (
    <>
      <Content>
      {items.length
          ? items.map((item: any, idx: number) => (
              <>
                <WrapperLine key={idx}>
                  <div className="wrapper-img">
                    <ImgWithFallback
                      className="image-car"
                      src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.webp`}
                      fallbacks={[
                        `${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.png`,
                        `${noImageCar}`,
                      ]}
                      alt="Carro"
                    />
                    <div>
                      <p className="title-description">
                        <span>Modelo:</span> {item.model}
                      </p>
                      <p className="title-description">
                        <span>Cor: </span>
                        {item.color}
                      </p>
                      <div>
                        <p className="title-description">
                          <span>Final da placa:</span>{" "}
                          {item.finalPlate || "S/PLACA"}
                        </p>
                      </div>

                    </div>
                  </div>
                  <div>
                    <p className="title-description">
                      <span>Período:</span> {item.deadline} meses
                    </p>
                    <p className="title-description">
                      <span>Km contratado:</span> {item.monthlyKmValue}{" "}
                    </p>
                    <p className="title-description">
                      <span>Parcela mensal:</span>{" "}
                      {formatValue(item.monthlyInstallment)}
                    </p>

                  </div>

                </WrapperLine>
              </>
            ))
          : ""}
      </Content>
    </>
  )
}

export default OrderAlterHistoryView
