export const formatDate = (date: string) => {
  const formattedDate = new Date(date)
  const year = formattedDate.getFullYear()
  const month = formattedDate.getMonth() + 1
  const day = formattedDate.getDate()

  return `${day <= 9 ? '0' : ''}${day}/${month <= 9 ? '0' : ''}${month}/${year}`
}

export const converterData = (dateString: string) => {
  const partes = dateString.split('/')
  const novaData = `${partes[2]}-${partes[1]}-${partes[0]}`
  return novaData
}
