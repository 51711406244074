/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import api from '../../../services/api'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Switch } from 'antd'
import * as Yup from 'yup'
import { Link, useHistory, useParams } from 'react-router-dom'

import Input from '../../../components/Input'
import { Container, Content } from './styles'
import getValidationErrors from '../../../utils/getValidationErrors'
import { useToast } from '../../../hooks/toast'

interface CreateDealershipGroupFormData {
  id?: number
  name: string
  status: boolean
}

const NewGroupDealership: FC = () => {
  const { id } = useParams<any>()
  const history = useHistory()
  const { addToast } = useToast()
  const [active, setActive] = useState(true)
  const formRef = useRef<FormHandles>(null)
  const [callApi, setCallApi] = useState(false)
  const [dealershipGroup, setDealershipGroup] =
    useState<CreateDealershipGroupFormData>()

  useEffect(() => {
    if (id) {
      api.get(`/dealerships/DealershipsGroup/${id}`).then((response) => {
        const { status } = response.data
        setDealershipGroup(response.data)
        setActive(status)
      })
    }
  }, [])

  const initialValue = {
    name: dealershipGroup?.name
  }

  const handleChangeActive = () => {
    setActive(!active)
  }

  const handleNewDealership = useCallback(
    async (data: CreateDealershipGroupFormData) => {
      data.status = active

      if (!callApi) {
        return
      }

      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório')
        })

        await schema.validate(data, {
          abortEarly: false
        })

        if (dealershipGroup) {
          data.id = id
          api.put('/dealerships/DealershipsGroup', data)

          addToast({
            title: 'Concessionária editada!',
            type: 'success',
            description: 'Concessionária editada com sucesso.'
          })

          setTimeout(() => {
            history.push('/dealerships-group')
          }, 1000)

          return
        } else {
          api.post('/dealerships/DealershipsGroup', data)

          addToast({
            title: 'Concessionária criada!',
            type: 'success',
            description: 'Concessionária criada com sucesso.'
          })

          setTimeout(() => {
            history.push('/dealerships-group')
          }, 1000)

          return
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err)

          formRef.current?.setErrors(erros)

          return
        }
      }
    },
    [active, callApi]
  )

  return (
    <Container>
      <Header />
      <Content>
        <h1>NOVO GRUPO DE CONCESSIONÁRIAS</h1>
        <p>Crie um novo grupo de concessionárias</p>

        <Form
          initialData={initialValue}
          onSubmit={handleNewDealership}
          ref={formRef}
        >
          <div className="isActive">
            <span>GRUPO ATIVO</span>
            <Switch onChange={handleChangeActive} checked={active} />
          </div>
          <div className="wrapper-info other-style">
            <label>Nome</label>
            <Input id="name" name="name" type="text" />
          </div>
          <div className="wrapper-button">
            <Link to="/dealerships-group">
              <button className="btn-cancel">Cancelar</button>
            </Link>

            <button
              onClick={() => setCallApi(true)}
              type="submit"
              className="btn-save"
            >
              Salvar
            </button>
          </div>
        </Form>
      </Content>
      <Footer />
    </Container>
  )
}

export default NewGroupDealership
