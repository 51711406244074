import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0)
    }
`

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  animation: ${appearFromLeft} 1s;

  img {
    margin-bottom: 40px;
  }

  .custom-selects {
    .ant-select {
      .ant-select-selector {
        border-radius: 23px !important;
        height: 45px !important;
        width: 400px !important ;
        border: 1px solid #cfd7d9 !important;
      }
      .ant-select-arrow {
        margin-top: -14px;
      }
      .anticon {
        margin-left: -10px;
      }
    }
  }

  .address-selects{
    .ant-select-selection-item{
      color:#242424
    }
  }

  .custom-select {
    .ant-select-selection-item {
      margin-top: -40px;
    }

    .ant-select-arrow {
      margin-top: -14px;
    }
  }

  form {
    display: flex;
    flex-direction: row;
    width: 850px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    div {
      width: 400px;
      margin-bottom: 19px;
    }

    h2 {
      width: inherit;
      display: flex;
      justify-content: center;
      margin: 10px 0 30px 0;
      color: #236fbc;
      font-size: 16px;
      text-align: center;
    }

    .jxFTYV {
      border: 3px solid #cfd7d9;
    }

    .wrapper-btn {
      width: 100%;
      display: flex;
      justify-content: end;
      margin-top: 20px;

      div {
        width: 190px;
      }

      button {
        width: 190px;
        height: 41px;
        font-size: 12px;
        text-transform: uppercase;
        margin: 8px;
      }
    }

    @media (max-width: 465px) {
      width: 80%;

      div {
        width: 100%;
      }

      input {
        width: 80%;
      }
    }

    div {
      &.custom-select {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 0;

        border-radius: 23px;
        border: 1px solid #0c5faf;
        height: 41px;

        label {
          position: absolute;
          left: 0;
          color: #797979 !important;
          z-index: 1;
          transition: 0.4s;
          bottom: 16px;

          &.active {
            background-color: white;
            bottom: 48px;
            font-size: 14px;
            z-index: 3;
            padding: 0 5px;
          }
        }

        .ant-select {
          height: 59px;
          width: 300px;
          z-index: 2;

          @media (min-width: 768px) {
            width: 400px;
          }

          .ant-select-selector {
            background-color: transparent !important;
            border: 2.5px solid #236fbc;
            padding: 0 10px !important;
            border-radius: 30px;
            height: 59px;

            @media (min-width: 768px) {
              padding: 18px 30px !important;
              width: 400px;
            }

            .ant-select-selection-item {
              color: #797979;
              line-height: 0px;

              @media (min-width: 768px) {
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 984px) {
    form {
      flex-direction: column;
      justify-content: center;
    }
  }

  @media (max-width: 400px) {
    form {
      flex-direction: row;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  width: 100%;
  max-width: 700px;

  h1 {
    width: inherit;
    display: flex;
    justify-content: center;
    color: #17536c;
    font-size: 25px;
    text-align: center;
  }

  p {
    font-size: 20px;
    color: #3c505a;
    text-align: center;
  }

  @media (max-width: 1024px) {
    max-width: initial;
  }
`

export const Wrapper = styled.div`
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0 !important;
  flex-wrap: wrap;

  & > p {
    color: #236fbc;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }

  .ant-radio-group {
    display: flex;
    margin-bottom: 0;
    align-items: center;

    .ant-radio-wrapper {
      display: flex;
      &:first-child {
        margin: 0 50px 0 43px;
      }

      .ant-radio {
        & > span {
          width: 20px;
          height: 20px;

          &::after {
            width: 20px;
            height: 20px;
            top: 7px;
            left: 7px;
          }
        }
      }

      & > span {
        color: #236fbc;
        font-size: 16px;
        font-weight: 600;
        width: unset;
      }
    }
  }

  @media (max-width: 984px) {
    width: auto !important;
  }
`

export const InputMaskStyle = styled.div`
  position: relative;
  z-index: 0;

  label {
    position: absolute;
    left: 0;
    color: #797979 !important;
    z-index: 1;
    transition: 0.4s;
    bottom: 37px;

    &.active {
      background-color: white;
      bottom: 67px;
      padding: 0 5px;
    }
  }

  & > input {
    width: 300px !important;
    height: 59px !important;
    border: 2.5px solid #236fbc;
    border-radius: 30px;
    padding: 18px 30px;
    margin: 0 0 20px 0;

    @media (min-width: 768px) {
      width: 400px !important;
    }
  }
`
