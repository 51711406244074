import React from 'react'
import { findPersonType } from 'utils/helpers'
import { Container } from './styles'

interface Props {
  segmentName: string
  customerType: string
  idFleet: number
  idIbratan:string
}

const SelectSegmentButton = ({ segmentName, customerType, idFleet, idIbratan }: Props) => {
  const personType = findPersonType(customerType)

  const handleSaveToLocalStorage = () => {
    localStorage.setItem('@FleetSolutions:selectedSegment', JSON.stringify({ segmentName, customerType, idFleet, idIbratan }))
  }
  
  return (
    <Container to={`/offers/${personType?.type.toLowerCase()}/${idFleet}`} onClick={handleSaveToLocalStorage}>
      <img src={personType?.icon} alt={customerType} />

      <span>
        {personType?.label} - {segmentName}
      </span>
    </Container>
  )
}

export default SelectSegmentButton
