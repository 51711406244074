import React, {
  InputHTMLAttributes,
  useRef,
  useState,
  useCallback
} from 'react'
import { IconBaseProps } from 'react-icons'
import { FiAlertCircle } from 'react-icons/fi'
import { Container, Error } from './styles'

interface Inputprops extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  containerStyle?: object
  icon?: React.ComponentType<IconBaseProps>
  value: string | number
  onChange: any
  error?: string
}

const NewInput: React.FC<Inputprops> = ({
  name,
  containerStyle = {},
  icon: Icon,
  value,
  onChange,
  error,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)

  const handleInputChange = (event: any) => {
    onChange({ name: event.target.name, value: event.target.value })
  }

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)
    setIsFilled(!!inputRef.current?.value)
  }, [])

  const hanleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  return (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      style={containerStyle}
    >
      {Icon && <Icon size={20} />}
      <input
        name={name}
        value={value || ''}
        onChange={handleInputChange}
        onFocus={hanleInputFocus}
        onBlur={handleInputBlur}
        ref={inputRef}
        required
        {...rest}
      />

      {error && (
        <Error title={error}>
          <FiAlertCircle color="#C53030" size={20} />
        </Error>
      )}
    </Container>
  )
}

export default NewInput
