import styled from 'styled-components'

export const TableContent = styled.table`
  text-align: center;
  border-spacing: inherit;
  margin: 60px auto;
  border: 1pt solid #cfd7d9;
  width: 80%;

  input {
    width: 90px;
  }

  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  tr,
  td {
    border: 1pt solid #cfd7d9;
  }

  tr {
    font-size: 12px;
  }

  thead {
    tr {
      height: 36px;
      text-transform: uppercase;
    }
  }

  td {
    padding: 7px 15px;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    height: 36px;
  }

  button {
    width: 25px;
    height: 25px;
    color: #cfd7d9;
    background: #ff8c00;
    border: 1pt solid #ff8c00;
  }
`
