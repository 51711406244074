import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Space, Table, TablePaginationConfig, TableProps, Card, Select, Row, Col, Input, Button, DatePicker, Switch, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import Header from "components/Header";
import api from "services/api";
import { FilterValue } from "antd/lib/table/interface";
import { StyledTableWrapper, PageContainer, Content, PageHead, ContentModal, ContentModalContrato, ContractModal, ButtonsWrapper } from "./styles";
import Footer from "components/Footer";
import { cnpjMask, converterData, cpfMask, formatDate, ISegment, SEGMENTS } from "utils";
import formatValue from "utils/formatValue";
import {
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    FormOutlined,
    MoreOutlined,
    PlusOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "hooks/auth";
import moment from "moment";
import OrderModal from "pages/Orders/OrderModal";
import { useToast } from "hooks/toast";
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const clientTypes = [
    { label: "Pessoa Física", value: 1 },
    { label: "Pessoa Jurídica", value: 0 },
];

const { Option } = Select;

interface Reservation {
    dealershipId?: number;
    dealershipGroupId?: number;
    role?: string;
    cpfCnpj?: number;
    orderId?: number;
    client?: number;
    status?: number;
    userDnId?: number;
    QuantityPerPage?: number;
    Page?: boolean;
    CurrentPage?: number;
    dateStart?: string | Date;
    dateEnd?: string | Date;
    clientName?: string;
    segment?: number;
}

interface ReservationFilters {
    dealershipId?: number;
    dealershipGroupId?: number;
    role?: string;
    cpfCnpj?: number;
    orderId?: number;
    client?: number;
    status?: number;
    userDnId?: number;
    QuantityPerPage?: number;
    Page?: boolean;
    CurrentPage?: number;
    dateStart?: string | Date | null;
    dateEnd?: string | Date | null;
    clientName?: string;
    segment?: number;
    filters?: Record<string, (boolean | React.Key)[] | null>;
}

interface Order {
    prefix?: string;
    segmentName?: string;
    orderId?: number;
    clientType?: number;
    name?: string;
    cpfCnpj?: string;
    dateCreated?: Date;
    dateLastUpdated?: Date;
    totalOrder?: number;
    userId?: number;
    orderStatusId?: number;
    orderStatus?: string;
    orderCanCancel?: boolean;
    dealershipId?: number;
    dealershipGroupId?: number;
}

interface OrderQuantity {
    awaitingBilling?: number;
    awaitingDocumentation?: number;
    awaitingPreparation?: number;
    awaitingTransport?: number;
    vehicleDelivered?: number;
    vehicleAvailable?: number;
    orderCreated?: number;
    authencity?: number;
    antifraud?: number;
    credit?: number;
    contract?: number;
    pendingLM?: number;
    pendingVendedor?: number;
    pendingCliente?: number;
}

interface StatusOrdersV2 {
    orderItemQuantity: OrderQuantity;
    orderQuantityV2: OrderQuantity;
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
}

const calculatePercentage = (value: number, total: number): string => (isNaN((value / total) * 100) ? "0%" : `${((value / total) * 100).toFixed(2)}%`);

const StyledCard = styled.div<{ bgColor: string }>`
    background-color: #ffffff;
    color: #000;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    text-align: center;
    border: 1px solid #e0e0e0;
    display: flex;
    &.small {
        padding: 16px 8px;
    }
    .dot {
        background-color: ${(props: { bgColor: any }) => props.bgColor};
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 8px;
        margin-top: 3px;
        min-width: 10px;

        &.small {
            width: 5px;
            height: 5px;
            min-width: 5px;
        }
    }

    .title {
        font-size: 17px;
        font-weight: 700;
        color: #3c505a;
        line-height: 1;
        &.small {
            font-size: 11px;
            text-align: left;
            min-height: 24px;
        }
    }
    .values {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .value {
        font-size: 18px;
        font-weight: 700;
        color: #3c505a;
        margin-bottom: 4px;
        margin-right: 0;
        margin-left: auto;
        border-left: 1px solid #e0e0e0;
        padding-left: 15px;
        display: flex;
        align-items: center;
        &.small {
            padding-left: 5px;
            font-size: 14px;
        }
    }

    .percentage {
        border: 1px solid #c2c2c2;
        background: #f5f5f5;
        text-align: center;
        border-radius: 5px;
        margin-top: 6px;
        font-size: 12px;
        width: min-content;
        padding: 2px 8px;
    }
`;

const StatusCard = ({ title, value, total, bgColor, smallTitle }: { title: string; value: number; total: number; bgColor: string; smallTitle: boolean }) => (
    <Col>
        <StyledCard className={smallTitle ? "small" : ""} bgColor={bgColor}>
            <div className={smallTitle ? "dot small" : "dot"}></div>
            <div className="values">
                <div className={smallTitle ? "title small" : "title"}>{title}</div>
                <div className="percentage">{calculatePercentage(value, total)}</div>
            </div>
            <div className={smallTitle ? "value small" : "value"}>{value}</div>
        </StyledCard>
    </Col>
);
const ViewTypeStageDelivery = ({ statusOrdersV2 }: { statusOrdersV2: StatusOrdersV2 }) => {
    const total =
        (statusOrdersV2?.orderItemQuantity?.awaitingBilling ?? 0) +
        (statusOrdersV2?.orderItemQuantity?.awaitingDocumentation ?? 0) +
        (statusOrdersV2?.orderItemQuantity?.awaitingPreparation ?? 0) +
        (statusOrdersV2?.orderItemQuantity?.awaitingTransport ?? 0) +
        (statusOrdersV2?.orderItemQuantity?.vehicleDelivered ?? 0) +
        (statusOrdersV2?.orderItemQuantity?.vehicleAvailable ?? 0);

    const data = [
        { title: "Aguardando Atribuição de Veículo", value: statusOrdersV2?.orderItemQuantity?.awaitingBilling ?? 0, bgColor: "red" },
        { title: "Veículo em Preparação - Documentação", value: statusOrdersV2?.orderItemQuantity?.awaitingDocumentation ?? 0, bgColor: "#FF772B" },
        { title: "Veículo em Preparação de Acessórios", value: statusOrdersV2?.orderItemQuantity?.awaitingPreparation ?? 0, bgColor: "purple" },
        { title: "Veículo em Transporte", value: statusOrdersV2?.orderItemQuantity?.awaitingTransport ?? 0, bgColor: "#0000FF" },
        { title: "Aguardando Disponibilidade de Retirada", value: statusOrdersV2?.orderItemQuantity?.vehicleAvailable ?? 0, bgColor: "yellow" },
        { title: "Veículo Entregue", value: statusOrdersV2?.orderItemQuantity?.vehicleDelivered ?? 0, bgColor: "#1BD964" },
    ];

    return (
        <div className="statusCardList">
            {data.map((item) => (
                <StatusCard smallTitle={true} key={item.title} title={item.title} value={item.value} total={total} bgColor={item.bgColor} />
            ))}
        </div>
    );
};

const ViewTypeStageSales = ({ statusOrdersV2 }: { statusOrdersV2: StatusOrdersV2 }) => {
    const total =
        (statusOrdersV2?.orderQuantityV2?.orderCreated ?? 0) +
        (statusOrdersV2?.orderQuantityV2?.authencity ?? 0) +
        (statusOrdersV2?.orderQuantityV2?.antifraud ?? 0) +
        (statusOrdersV2?.orderQuantityV2?.credit ?? 0) +
        (statusOrdersV2?.orderQuantityV2?.contract ?? 0);

    const data = [
        { title: "Criação do Pedido", value: statusOrdersV2?.orderQuantityV2?.orderCreated ?? 0, bgColor: "#F84339" },
        { title: "Autenticidade", value: statusOrdersV2?.orderQuantityV2?.authencity ?? 0, bgColor: "#FF772B" },
        { title: "Antifraude", value: statusOrdersV2?.orderQuantityV2?.antifraud ?? 0, bgColor: "#8649BA" },
        { title: "Crédito", value: statusOrdersV2?.orderQuantityV2?.credit ?? 0, bgColor: "#0000FF" },
        { title: "Contrato", value: statusOrdersV2?.orderQuantityV2?.contract ?? 0, bgColor: "#1BD964" },
    ];

    return (
        <div className="statusCardList">
            {data.map((item) => (
                <StatusCard smallTitle={false} key={item.title} title={item.title} value={item.value} total={total} bgColor={item.bgColor} />
            ))}
        </div>
    );
};

const ViewTypePending = ({ statusOrdersV2 }: { statusOrdersV2: StatusOrdersV2 }) => {
    const total =
        (statusOrdersV2?.orderQuantityV2?.pendingLM ?? 0) +
        (statusOrdersV2?.orderQuantityV2?.pendingVendedor ?? 0) +
        (statusOrdersV2?.orderQuantityV2?.pendingCliente ?? 0);

    const data = [
        { title: "LM Mobilidade", value: statusOrdersV2?.orderQuantityV2?.pendingLM ?? 0, bgColor: "#FF0000" },
        { title: "Vendedor", value: statusOrdersV2?.orderQuantityV2?.pendingVendedor ?? 0, bgColor: "#ff772b" },
        { title: "Cliente", value: statusOrdersV2?.orderQuantityV2?.pendingCliente ?? 0, bgColor: "#800080" },
    ];

    return (
        <div className="statusCardList">
            {data.map((item) => (
                <StatusCard smallTitle={false} key={item.title} title={item.title} value={item.value} total={total} bgColor={item.bgColor} />
            ))}
        </div>
    );
};

const OrdersList: React.FC = () => {
    const history = useHistory();
    const { dealershipId, dealershipGroupId, role, userId, roleId } = useAuth();
    const [segmentOptions, setSegmentOptions] = useState<any[]>([]);
    const [newOrder, setNewOrder] = useState(false);
    const [loading, setLoading] = useState(true);
    const [ordersList, setOrdersList] = useState<Order[]>([]);
    const [viewType, setViewType] = useState<string>("2");
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [isNewView, setisNewView] = useState(localStorage.getItem("@FleetSolutions:newView") ? true : false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [textCancel, setTextCancel] = useState("");
    const [orderIdCancel, setOrderIdCancel] = useState(0);
    const { addToast } = useToast();
    const [contractModalVisible, setContractModalVisible] = useState<boolean>(false);
    const [currentContract, setCurrentContract] = useState<any>(null);
    const [contractOwner, setContractOwner] = useState<any>(null);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 20,
        },
    });
    const [reloadData, setReloadData] = useState(false);
    const [statusOrdersV2, setStatusOrdersV2] = useState<StatusOrdersV2>({
        orderItemQuantity: {
            awaitingBilling: 0,
            awaitingDocumentation: 0,
            awaitingPreparation: 0,
            awaitingTransport: 0,
            vehicleDelivered: 0,
            vehicleAvailable: 0,
        },
        orderQuantityV2: {
            orderCreated: 0,
            authencity: 0,
            antifraud: 0,
            credit: 0,
            contract: 0,
            pendingLM: 0,
            pendingVendedor: 0,
            pendingCliente: 0,
        },
    });

    const [ordersFilters, setOrdersFilters] = useState<ReservationFilters>({
        dealershipId: Number(dealershipId),
        dealershipGroupId: Number(dealershipGroupId),
        role: role,
        userDnId: 0,
        QuantityPerPage: tableParams?.pagination?.pageSize,
        Page: true,
        CurrentPage: tableParams?.pagination?.current,
        dateStart: moment().subtract(1, "years").format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
    });

    const statusList: any = [
        { value: "-1", label: "Todos" },
        { value: ",1,", label: "Criado" },
        { value: ",12,13,14,", label: "Autenticidade" },
        { value: ",2,3,4,", label: "Antifraude" },
        { value: ",19,20,21,", label: "Cartão de Crédito" },
        { value: ",18,5,6,7,8,", label: "Crédito" },
        { value: ",9,10,15,16,", label: "Contrato" },
        { value: ",11,", label: "Concluído" },
        { value: ",17,", label: "Logística" },
        { value: ",-99,", label: "Autoatendimento" },
        { value: ",-98,", label: "Concessionária", show: roleId.toString() === "265" || roleId.toString() === "298" },
        { value: ",0,", label: "Cancelado" },
        { value: ",-1,", label: "Expirado" },
    ];

    useEffect(() => {
        if (!segmentOptions.length) {
            loadSegments();
        }
        getOrders();
    }, [reloadData]);

    const loadSegments = () => {
        api.get(`/dealerships/${dealershipId}`).then((res) => {
            const { data } = res;
            api.get(SEGMENTS)
                .then(({ data }) => {
                    const filtered = data.filter((item1: { iD_Fleet: any; iD_Ibratan: any }) => {
                        return res.data.listSegments.some((item2: { idSegment: any; idIbratan: any }) => {
                            return item1.iD_Fleet === item2.idSegment && item1.iD_Ibratan === item2.idIbratan;
                        });
                    });
                    const transformedArray = filtered.map((item: { name: any; iD_Ibratan: any }) => ({
                        label: item.name,
                        value: item.iD_Ibratan,
                    }));
                    setSegmentOptions(transformedArray);
                })
                .catch(() => {
                    // addToast({
                    //   type: 'error',
                    //   title: `Não foi possível listar os segmentos`,
                    //   description: 'Por favor, entre em contato com o suporte.'
                    // })
                });
        });
    };

    const handleChangeView = (value: string) => setViewType(value);

    const onChange: TableProps<Order>["onChange"] = (pagination, filters, sorter, extra) => {
        if (extra?.action === "paginate") {
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                },
            });

            setOrdersFilters({
                ...ordersFilters,
                CurrentPage: pagination.current,
                QuantityPerPage: pagination.pageSize,
            });
        }
        if (extra?.action === "paginate" || extra?.action === "filter") {
            setLoading(true);
            setReloadData((prev) => !prev);
        }
    };

    const showOrderStatus = (item: Order) => {
        localStorage.setItem("@FleetSolutions:userIdCompany", item?.userId?.toString()!);

        if (item.clientType === 0) {
            history.push(`/order-status/${item.orderId}`);
        } else {
            history.push(`/order-status-pf/${item.orderId}`);
        }
    };

    const getOrders = async () => {
        let reqFilters = ordersFilters;
        delete reqFilters.filters;

        try {
            const [ordersResponse, totalsResponse] = await Promise.all([
                api.get(`/dealership-management`, { params: ordersFilters }),
                api.get(`/dealership-management/totals/v2`, { params: ordersFilters }),
            ]);

            const { data: ordersData } = ordersResponse;
            const { data: totalsData } = totalsResponse;

            if (ordersData) {
                setTotalAmount(ordersData.items.totalAmount);
                setOrdersList(ordersData.items.itens);
                setTableParams((prevParams) => ({
                    ...prevParams,
                    pagination: {
                        ...prevParams.pagination,
                        total: ordersData.items.totalAmount,
                    },
                }));
            }

            if (totalsData) {
                setStatusOrdersV2(totalsData);
            }

            if (ordersData && totalsData) {
                setLoading(false);
            }
        } catch (error) {
            console.error("Failed to fetch data", error);
        }
    };

    const handleMenuClick = (e: { key: string }) => {
        console.log("click", e);
    };

    const handleDatesValue = (data: any) => {
        return data ? moment(data) : null;
    };

    const handleFilterDate = async (start: string, end: string) => {
        const updatedFilters = {
            ...ordersFilters,
            dateStart: start !== "" ? start : null,
            dateEnd: end !== "" ? end : null,
        };

        setOrdersFilters(updatedFilters);

        setLoading(true);
        setReloadData((prev) => !prev);
    };

    const showContractDialog = (item: any) => {
        setCurrentContract(item);
        api.get(`/orders/check-contract-userassigned/${item.orderId}/${Number(localStorage.getItem("@FleetSolutions:userId"))}`).then((response) => {
            setContractOwner(response.data);
            setContractModalVisible(true);
        });
    };

    const modalClose = () => {
        setCurrentContract(null);
        setContractModalVisible(false);
    };

    const modalOk = () => {
        showSendContract(currentContract);
    };

    const showSendContract = (item: any) => {
        const data = {
            orderId: item.orderId,
            userIdContractAssigned: Number(localStorage.getItem("@FleetSolutions:userId")),
        };
        api.put(`/orders/update-contract-userassigned?orderId=${item.orderId}&userIdContractAssigned=${Number(localStorage.getItem("@FleetSolutions:userId"))}`).then(
            (response) => {
                setCurrentContract(null);
                setContractModalVisible(false);
                if (item.clientType === 0) {
                    history.push(`/contacts-company/${item.cpfCnpj}/${item.orderId}/${item.clientType}/${item.userId}`);
                } else {
                    history.push(`/contacts-customer/${item.cpfCnpj}/${item.orderId}/${item.clientType}/${item.userId}`);
                }
            }
        );
    };

  const showOldSendContract = (item: any) => {
    if (item.clientType === 0) {
      history.push(
        `/contacts-company/${item.cpfCnpj}/${item.orderId}/${item.clientType}/${item.userId}`
      )
    } else {
      history.push(
        `/contacts-customer/${item.cpfCnpj}/${item.orderId}/${item.clientType}/${item.userId}`
      )
    }
  }

    const handleRemove = (item: Order) => {
        setOrderIdCancel(item?.orderId!);
        setShowCancelDialog(true);
    };

    const handleFilterChange = (columnKey: string, value: any, confirm?: () => void) => {
        const newFilters = { ...ordersFilters.filters, [columnKey]: value ? [value] : [] };

        // Verifica se não há nenhum filtro selecionado
        const noFiltersSelected = Object.values(newFilters).every((filter) => !filter || filter.length === 0);

        const updatedFilters = {
            ...ordersFilters,
            CurrentPage: 1,
            [columnKey]: value ? value : null,
            filters: newFilters,
            dateStart: columnKey !== "client" && !noFiltersSelected ? null : moment().subtract(1, "years").format("YYYY-MM-DD"),
            dateEnd: columnKey !== "client" && !noFiltersSelected ? null : moment().format("YYYY-MM-DD"),
        };

        setOrdersFilters(updatedFilters);

        if (confirm) {
            confirm();
        }
    };

    const handleSubmitCancel = async () => {
        if (textCancel) {
            await api
                .post(`/orderstatus`, {
                    orderIdCancel,
                    justifyCancellation: textCancel,
                })
                .then((response) => {
                    setShowCancelDialog(false);
                    history.go(0);
                })
                .catch((error) => {
                    addToast({
                        title: "Atenção!",
                        type: "error",
                        description: "Erro ao cancelar pedido",
                    });
                });
        } else {
            addToast({
                title: "Atenção!",
                type: "error",
                description: "Precisa ser informado um texto para justificar o cancelamento.",
            });
        }
    };

    const getMenuItems = (item: any, role: any) => {
        const menuItems = [];
        const cantEditStatusList = [0, -1, 10, 11, 8, 5, 7];
        const loggedUserId = Number(localStorage.getItem("@FleetSolutions:userId"));

        menuItems.push(
            <Menu.Item key="awaitingCreditAnalysis" onClick={() => showOrderStatus(item)}>
                <EyeOutlined /> Visualizar
            </Menu.Item>
        );

        // if (!cantEditStatusList.includes(item.orderStatusId!) && item.userId === loggedUserId) {
        //     menuItems.push(
        //         <Menu.Item key="contract" onClick={() => showContractDialog(item)}>
        //             <EditOutlined /> Editar pedido
        //         </Menu.Item>
        //     );
        // }

        if (item.orderStatusId === 15 && role === "Atendimento") {
            menuItems.push(
                <Menu.Item key="contract" onClick={() => showContractDialog(item)}>
                    <FormOutlined /> Enviar contrato
                </Menu.Item>
            );
        }

        if (item.orderStatusId === 16 && role !== "Atendimento") {
            menuItems.push(
                <Menu.Item key="oldSendContract" onClick={() => showOldSendContract(item)}>
                    <ExclamationCircleOutlined /> {item.orderStatus}
                </Menu.Item>
            );
        }

        if (item.orderCanCancel) {
            menuItems.push(
                <Menu.Item key="cancelOrder" onClick={() => handleRemove(item)}>
                    <CloseOutlined /> Cancelar pedido
                </Menu.Item>
            );
        }

        return menuItems;
    };

    const columns: ColumnsType<Order> = [
        {
            title: "Pedido",
            sorter: (a, b) => (a.orderId || 0) - (b.orderId || 0),
            render: (order: Order) => (
                <a href="#" onClick={() => showOrderStatus(order)}>
                    {(order.prefix || "") + (order.orderId || "")}
                </a>
            ),
            fixed: "left",
            key: "orderId",
            width: 120,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div className="table-filter" style={{ padding: 8 }}>
                    <Input
                        type="number"
                        placeholder="Buscar por nº do pedido"
                        value={selectedKeys[0] || ""}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleFilterChange("orderId", selectedKeys[0], confirm)}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange("orderId", null, confirm);
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Limpar
                        </Button>
                        <Button type="primary" onClick={() => handleFilterChange("orderId", selectedKeys[0], confirm)} size="small" style={{ width: 90 }}>
                            Buscar
                        </Button>
                    </Space>
                </div>
            ),
            filteredValue: ordersFilters?.orderId ? [ordersFilters.orderId] : null,
            filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
        },
        {
            title: "Segmento",
            sorter: (a, b) => (a.segmentName || "").localeCompare(b.segmentName || ""),
            dataIndex: "segmentName",
            key: "segmentName",
            width: 130,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div className="table-filter" style={{ padding: 8 }}>
                    <Select
                        placeholder="Selecione um segmento"
                        value={selectedKeys[0] || undefined}
                        onChange={(e) => setSelectedKeys(e ? [e] : [])}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    >
                        {segmentOptions.map((option) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                    <Space>
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange("segment", null, confirm);
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Limpar
                        </Button>
                        <Button type="primary" onClick={() => handleFilterChange("segment", selectedKeys[0], confirm)} size="small" style={{ width: 90 }}>
                            Buscar
                        </Button>
                    </Space>
                </div>
            ),
            filteredValue: ordersFilters?.segment ? [ordersFilters.segment] : null,
        },
        {
            title: "Tipo",
            render: (order) => `${order.clientType === 0 ? "Jurídica" : "Física"}`,
            key: "clientType",
            sorter: (a, b) => (a.clientType || 0) - (b.clientType || 0),
            width: 90,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div className="table-filter" style={{ padding: 8 }}>
                    <Select
                        placeholder="Selecione PF ou PJ"
                        value={selectedKeys[0] || undefined}
                        onChange={(e) => setSelectedKeys(e ? [e] : [])}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    >
                        {clientTypes.map((option) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                    <Space>
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange("client", null, confirm);
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Limpar
                        </Button>
                        <Button type="primary" onClick={() => handleFilterChange("client", selectedKeys[0], confirm)} size="small" style={{ width: 90 }}>
                            Buscar
                        </Button>
                    </Space>
                </div>
            ),
            filteredValue: ordersFilters?.client ? [ordersFilters.client] : null,
        },
        {
            title: "Nome/Razão Social",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => (a.name || "").localeCompare(b.name || ""),
            width: 170,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div className="table-filter" style={{ padding: 8 }}>
                    <Input
                        placeholder="Buscar por nome"
                        value={selectedKeys[0] || ""}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleFilterChange("clientName", selectedKeys[0], confirm)}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange("clientName", null, confirm);
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Limpar
                        </Button>
                        <Button type="primary" onClick={() => handleFilterChange("clientName", selectedKeys[0], confirm)} size="small" style={{ width: 90 }}>
                            Buscar
                        </Button>
                    </Space>
                </div>
            ),
            filteredValue: ordersFilters?.clientName ? [ordersFilters.clientName] : null,
            filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
        },
        {
            title: "Documento",
            key: "cpfCnpj",
            sorter: (a, b) => (a.cpfCnpj || "").localeCompare(b.cpfCnpj || ""),
            render: (order: Order) => {
                if (!order) return null;
                if (order.clientType === 0) return cnpjMask(order.cpfCnpj);
                if (order.clientType === 1) return cpfMask(order.cpfCnpj);
                return null;
            },
            width: 130,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div className="table-filter" style={{ padding: 8 }}>
                    <Input
                        placeholder="Buscar por documento"
                        value={selectedKeys[0] || ""}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleFilterChange("cpfCnpj", selectedKeys[0], confirm)}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange("cpfCnpj", null, confirm);
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Limpar
                        </Button>
                        <Button type="primary" onClick={() => handleFilterChange("cpfCnpj", selectedKeys[0], confirm)} size="small" style={{ width: 90 }}>
                            Buscar
                        </Button>
                    </Space>
                </div>
            ),
            filteredValue: ordersFilters?.cpfCnpj ? [ordersFilters.cpfCnpj] : null,
            filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
        },
        {
            title: "Data de Inclusão",
            render: (order) => `${formatDate(order.dateCreated)}`,
            key: "dateCreated",
            width: 150,
        },
        {
            title: "Data de Status",
            render: (order) => `${formatDate(order.dateLastUpdated)}`,
            key: "dateLastUpdated",
            width: 120,
        },
        {
            title: "Valor Total",
            render: (order) => `${formatValue(order.totalOrder)}`,
            key: "totalOrder",
            width: 120,
        },
        {
            title: <span className="status-column-header">Status</span>,
            render: (order: Order) => <span className="status-badge">{order.orderStatus}</span>,
            sorter: (a, b) => (a.orderStatus || "").localeCompare(b.orderStatus || ""),
            key: "orderStatus",
            width: 350,
            ellipsis: true,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div className="table-filter" style={{ padding: 8 }}>
                    <Select
                        placeholder="Selecione um status"
                        value={selectedKeys[0] || undefined}
                        onChange={(e) => setSelectedKeys(e ? [e] : [])}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    >
                        {statusList.map(
                            (option: any) =>
                                (option.show === undefined || option.show) && (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                )
                        )}
                    </Select>
                    <Space>
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange("status", null, confirm);
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Limpar
                        </Button>
                        <Button type="primary" onClick={() => handleFilterChange("status", selectedKeys[0], confirm)} size="small" style={{ width: 90 }}>
                            Buscar
                        </Button>
                    </Space>
                </div>
            ),
            filteredValue: ordersFilters?.status ? [ordersFilters.status] : null,
        },
        {
            title: "Ações",
            key: "actions",
            fixed: "right",
            width: 80,
            render: (_, record) => (
                <Dropdown overlayClassName="actions-menu" overlay={<Menu>{getMenuItems(record, role)}</Menu>} trigger={["click"]}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <MoreOutlined />
                        </Space>
                    </a>
                </Dropdown>
            ),
        },
    ];

    const renderView = () => {
        switch (viewType) {
            case "1":
                return <ViewTypePending statusOrdersV2={statusOrdersV2} />;
            case "2":
                return <ViewTypeStageSales statusOrdersV2={statusOrdersV2} />;
            case "3":
                return <ViewTypeStageDelivery statusOrdersV2={statusOrdersV2} />;
            default:
                return null;
        }
    };

    const handleNewOrder = () => {
        localStorage.removeItem("@FleetSolutions:orderItems");
        setNewOrder(true);
    };

    const onChangeView = (event: any) => {
        setisNewView(event);
        if (!event) {
            localStorage.removeItem("@FleetSolutions:newView");
        } else {
            localStorage.setItem("@FleetSolutions:newView", "true");
        }
    };

    const handleChangeTextCancel = (event: { target: { value: React.SetStateAction<string> } }) => {
        setTextCancel(event.target.value);
    };

    return (
        <>
            <Header />
            <PageContainer>
                <div className="new-view">
                    Manter nova tela de pedidos como padrão.
                    <Switch checked={isNewView} onChange={onChangeView} />
                </div>
                <Content>
                    <PageHead>
                        <h1>Meus Pedidos</h1>
                        <span className="separator"></span>
                        <div className="dates-filter">
                            <RangePicker
                                value={[handleDatesValue(ordersFilters?.dateStart), handleDatesValue(ordersFilters?.dateEnd)]}
                                style={{ border: 0 }}
                                format={dateFormat}
                                onChange={(_, dateString) => {
                                    if (dateString) {
                                        handleFilterDate(
                                            dateString[0] ? converterData(dateString[0]) : dateString[0],
                                            dateString[1] ? converterData(dateString[1]) : dateString[1]
                                        );
                                    }
                                }}
                            />
                        </div>
                        <span className="separator"></span>
                        <div className="orders-totals">
                            <span>{totalAmount}</span>
                            pedidos no período
                            <br /> selecionado
                        </div>
                        <span className="separator"></span>
                        {role !== "Atendimento" && (
                            <Button onClick={handleNewOrder}>
                                <PlusOutlined /> Novo Pedido
                            </Button>
                        )}
                    </PageHead>
                    <Select className="view-selection" value={viewType} onChange={handleChangeView} style={{ marginBottom: 16 }}>
                        <Option value="1">Por Pendência</Option>
                        <Option value="2">Por Etapa no Processo de Vendas</Option>
                        <Option value="3">Por Etapa no Processo de Entrega</Option>
                    </Select>
                    {renderView()}
                    <StyledTableWrapper>
                        <Table
                            scroll={{ x: 1300 }}
                            columns={columns}
                            pagination={tableParams.pagination}
                            dataSource={ordersList}
                            onChange={onChange}
                            loading={loading}
                            rowKey={(record) => (record.orderId ? record.orderId.toString() : "key_" + Math.random().toString(36).slice(2, 11))}
                        />
                    </StyledTableWrapper>
                </Content>
            </PageContainer>
            <Footer />
            <OrderModal visible={newOrder} onCancel={() => setNewOrder(false)} />
            <Modal visible={showCancelDialog} onCancel={() => setShowCancelDialog(false)} footer="">
                <ContentModal>
                    <h3>Cancelar pedido</h3>

                    <p
                        style={{
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        Esta operação não poderá ser desfeita.
                        <br />
                        Deseja continuar?
                    </p>

                    <p>Para continuar o cancelamento preencha o motivo*:</p>

                    <textarea style={{ width: "100%", minHeight: 100 }} onChange={handleChangeTextCancel} />

                    <ButtonsWrapper>
                        <button
                            onClick={() => {
                                handleSubmitCancel();
                            }}
                        >
                            Confirmar
                        </button>
                        <button
                            onClick={() => {
                                setShowCancelDialog(false);
                            }}
                            className="cancel"
                        >
                            Cancelar
                        </button>
                    </ButtonsWrapper>
                </ContentModal>
            </Modal>
            <ContractModal>
                <Modal footer={null} title="" visible={contractModalVisible} onCancel={modalClose}>
                    <ContentModalContrato>
                        {contractOwner ? (
                            <p>Pedido assumido pelo usuário {contractOwner} para envio deste contrato, deseja assumir?</p>
                        ) : (
                            <p>Esta ação permite que você seja o responsável pelo envio deste contrato, deseja continuar?</p>
                        )}

                        <div>
                            <button onClick={() => modalOk()}>Sim</button>
                            <button className="cancel" onClick={() => modalClose()}>
                                Não
                            </button>
                        </div>
                    </ContentModalContrato>
                </Modal>
            </ContractModal>
        </>
    );
};

export default OrdersList;
