import styled, { css } from 'styled-components'

import Tooltip from '../Tooltip'

interface ContainerProps {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
}

export const Container = styled.div<ContainerProps>`
    border-radius: 23px;
    border: 1px solid #0c5faf;
    padding: 16px;
    height: 42px;

    display: flex;
    align-items: center;
    
    & + div {
         margin-top: 0px
         margin-bottom: 9px;
    }  

    ${(props) =>
      props.isErrored &&
      css`
        border-color: #c53030 !important;
      `}

    ${(props) =>
      props.isFocused &&
      css`
        color: #0161af;
        border-color: #0161af !important;
      `}

    ${(props) =>
      props.isFilled &&
      css`
        color: #0161af;
      `}

    input {
        flex: 1;
        background: transparent;
        border: 0;

        &::placeholder {
            color: #666360;
        }
    }

    svg {
        margin-right: 16px
    }
  
`

export const Error = styled(Tooltip)`
  height: 11px;
  margin-left: 16px;
  text-align: right;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`
