import styled, { css } from 'styled-components'
import { animated } from 'react-spring'

export const Container = styled.div``


interface ContainerProps {
  type?: 'success' | 'error' | 'info'
  $hasDescription: number
}

const toastTypeVariations = {
  info: css`
    background: #ebf8ff;
    color: #3172b7;
  `,
  success: css`
    background: #e6fffa;
    color: #2e656a;
  `,
  error: css`
    background: #fddede;
    color: #c53030;
  `
}

export const ToastContainer = styled(animated.div)<ContainerProps>`
  width: 360px;
  z-index: 99;
  position: fixed !important;
  padding: 16px 30px 16px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  top: 30px;
  right: 0;
  display: flex;

  & + div {
    margin-top: 8px;
  }

  ${(props) => toastTypeVariations[props.type || 'info']}

  > svg {
    margin: 4px 12px 0 0;
  }

  div {
    flex: 1;

    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 16px;
    top: 19px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
  }

  ${(props) =>
    !props.$hasDescription &&
    css`
      align-items: center;

      svg {
        margin-top: 0;
      }
    `}
`



export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    margin-top: 20px;
  }

  img {
    margin: 20px;
  }

  input {
    width: 290px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 20px 0 0 0;
      font-size: 20px;
      color: #3c505a;
    }

    button {
      box-shadow: 0px 3px 6px #00000029;
      background: #0c5faf;
    }

    .jxFTYV {
      border: 2px solid #8994a0;
    }
  }

  @media (max-width: 411px) {
    form {
      input {
        width: 80%;
      }
      button {
        width: 250px;
      }
    }
  }
`
