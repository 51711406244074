import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #f1f1f1;

  .description {
    color: #3c505a;
    font-size: 20px;
    text-align: center;
    margin: 0;
  }

  .title {
    color: #17536c;
    font-size: 25px;
    text-transform: uppercase;
    text-align: center;
    margin: 28px 0 16px 0;
  }

  .buttons {
    width: 82%;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    border-radius: 24px;
    flex-wrap: wrap;

    .btn-continue,
    .btn-back,
    .btn-save {
      width: 190px;
      height: 41px;
      font-size: 12px;
      text-transform: uppercase;
      margin: 8px;
    }

    .btn-back,
    .btn-save {
      background: transparent;
      border: 2px solid #226fbb;
      color: #226fbb;
    }

    .wrapper-button {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  @media (max-width: 760px) {
    .buttons {
      justify-content: center;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 82%;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 40px auto;
  align-items: center;
  font-family: 'Open Sans';
  padding: 25px;
  color: #000000;

  h2 {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #0668b4;
  }
`
