import styled from 'styled-components'

export const Container = styled.div`
  background: rgb(60, 80, 90);
  color: #fff;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1100px;
  padding: 30px;
  justify-content: space-evenly;
  margin: 0 auto;
  flex-wrap: wrap;

  h3 {
    color: #fff;
  }
`

export const FooterF = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 12px;

  p {
    margin-bottom: 20px;
  }

  hr {
    margin-bottom: 24px;
    width: 80%;
    border: 1px solid #cfd7d9;
    opacity: 1;
  }
`

export const Category = styled.div`
  width: 200px;

  li {
    list-style: none;
    font-size: 13px;
    margin-top: 5px;
  }

  .social-media {
    margin: 0;
    font-size: 14px;
  }

  img {
    margin-right: 8px;
  }
`

export const CategoryMinimal = styled.div`
  width: 150px;

  li {
    list-style: none;
    font-size: 13px;
    margin-top: 5px;
  }

  .social-media {
    margin: 0;
    font-size: 14px;
  }

  img {
    margin-right: 8px;
  }
`

export const Contact = styled.div`
  p {
    font-size: 13px;
  }

  .icons {
    img {
      margin-right: 17px;
    }
  }
`

export const Info = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;

  a {
    color: #fff;
    margin: 4px;
  }
`
