import styled from 'styled-components'

export const Container = styled.div`
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3c505a;

  .batch-update{
    display: flex;
    justify-content: flex-end;
    margin: 25px 0 0 0;
    .ant-btn{
      background-color: #3c505a;
      border-color: #3c505a;
      color:#fff;
      &:disabled{
        opacity:0.4;
      }
    }
  }

  button {
    width: 208px;
    font-size: 15px;
  }

  nav {
    margin: 0 0 50px 16px;

    button {
      width: 38px;
    }
  }

  .ant-btn{
    width:auto;
    font-size:12px
  }

  h1 {
    color: #17536c;
    margin-top: 30px;
    margin-bottom: 0px !important;
    font-size: 1.6em !important;
    text-align: center;
  }

  p {
    color: #3c505a;
    margin-top: 5px;
  }

  .wrapper-button {
    width: 80%;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 500px) {
    .wrapper-button {
      justify-content: center;
    }
  }
`

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 21px;
    color: #0c5faf;
    font-weight: 600;
  }

  p {
    font-size: 17px;
    color: #3c505a;
  }
`

export const WrapperStatus = styled.div`
  width: 80%;
  height: 77px;
  background: #fff;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  .status {
    display: flex;
    flex-direction: row;
    align-items: center;

    .box-finance {
      width: 49px;
      height: 44px;
      background: #3c505a;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      font-weight: 500;

      p {
        color: #fff;
        font-size: 20px;
      }
    }

    .license-plate {
      background: #3c505a;
    }

    .available {
      background: #3c505a;
    }

    .active {
      background: #3c505a;
    }
  }

  @media (max-width: 857px) {
    flex-direction: column;
    height: auto;
    width: auto;
    align-items: baseline;

    .status {
      margin: 15px;
    }
  }
`

export const Select = styled.select`
  width: 265px;
  height: 31px;
  /*margin-left: 10px;*/
  font-size: 14px;
  color: #8994a0;
  border: 1px solid #cfd7d9;
  background: #fff;
`

export const WrapperTable = styled.div`
  width: 95%;

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    select {
      width: 200px;
    }

    .wrapper-input {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 253px;

      img {
        margin-left: -30px;
        cursor: pointer;
      }

      label {
        margin-right: 4px;
      }

      div {
        border-radius: 0;
        display: flex;
        flex-direction: row-reverse;
        background: #ffffff;
        border: 1px solid #cfd7d9;
        height: 31px;

        svg {
          margin: 0 -10px 0 5px;
          width: 15px;
          color: #0161af;
        }
      }

      input {
        width: 100%;
      }

      input::placeholder {
        font-size: 14px;
      }
    }

    .select-auto {
      width: 265px;
      /*margin-left: 10px;*/
      font-size: 14px;
      color: #8994a0 !important;
      border: 1px solid #cfd7d9 !important;
      background: #fff;
    }
  }

  @media (max-width: 700px) {
    form {
      div {
        margin: 5px;
      }
    }
  }

  .div-filtros {
    display: flex;
    flex-direction: column;
  }
`

export const Table = styled.table`
  width: 100%;
  text-align: center;
  border-spacing: inherit;
  margin: 15px 0px 30px 0px;
  border: 1pt solid #cfd7d9;

  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  tr,
  td {
    border: 1pt solid #cfd7d9;
  }

  tr {
    font-size: 14px;
  }

  thead {
    tr {
      height: 36px;
    }
  }

  td {
    padding: 7px 15px;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    height: 36px;
  }

  .large {
    width: 110px;
  }

  .status-color-wait {
    color: #ba1818;
  }

  .status-color-wait-finance {
    color: #ce651a;
  }

  .status-color-available {
    color: #369617;
  }

  .status-color-license-plate {
    color: #ba1818;
  }

  .status-color-active {
    color: #167c9d;
  }
`

export const WrapperLogo = styled.div`
  margin: 20px;
`

export const SelectSegmentButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`
